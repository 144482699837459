import React from 'react';
import { useSelector } from 'react-redux';
import { IonProgressBar, IonCard, IonCardContent, IonText } from '@ionic/react';
import { selectClicks } from '../../../../store/game/travel/selectors/clicks.selector';
import { TRAVEL_CLICKS } from '../../../../app/data/constants';
import { selectCurrentSystemName } from '../../../../store/game/systems/selectors/current-system.selector';
import { selectLastTravelId } from '../../../../store/game/travel/selectors/last-travel.selector';
import { TravelEncounters } from '../../../TravelEncounters/TravelEncounters';
import { EncounterContainer } from './EncounterContainer';

const TravelStatus: React.FC = () => {
  const currentSystemName = useSelector(selectCurrentSystemName);
  const clicks = useSelector(selectClicks);
  const distance = TRAVEL_CLICKS;
  const progress = (distance - clicks) / distance;

  const travelId = useSelector(selectLastTravelId);

  return (
    <IonCard>
      <IonCardContent>
        <TravelEncounters travelId={travelId.toString()} />
        <IonText className="ion-text-right" color="dark">
          <h1>{currentSystemName}</h1>
        </IonText>
        <IonProgressBar value={progress} />
        <EncounterContainer />
      </IonCardContent>
    </IonCard>
  );
};

export default TravelStatus;
