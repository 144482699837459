import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlayerCargo,
  selectPlayerShip,
} from '../store/game/ship/ship.selectors';
import { getTradeItemQuantity } from '../app/ui/cargo';
import { sellCargo } from '../store/game/ship/ship.actions';
import { increaseAvailableTradeItemAmount } from '../store/game/systems/systems.actions';
import { TradeItemType } from '../app/data/types/trade-item/trade-item-type';
import { earnCredits } from '../store/game/balance/balance.actions';
import { selectFinalSellPrice } from '../store/derived/computational-selectors';
import { selectCurrentSystem } from '../store/game/systems/selectors/current-system.selector';
import { SELL_CARGO_REASON } from '../app/ui/change-balance-reasons';
import { formatMoney } from '../app/ui/format-money';

const useSellItem = (tradeItemType: TradeItemType) => {
  const dispatch = useDispatch();
  const currentSystem = useSelector(selectCurrentSystem);
  const cargo = useSelector(selectPlayerCargo, (a, b) => a.length === b.length);
  const priceToSell = useSelector(selectFinalSellPrice(tradeItemType));
  const quantity = getTradeItemQuantity(cargo, tradeItemType);
  const isAvailable = quantity > 0 && priceToSell > 0;

  const getSellLabel = useCallback(() => {
    if (priceToSell <= 0) {
      return 'Not in consumption';
    }
    if (quantity <= 0) {
      return '0 items to sell';
    }
    return `Sell all for ${priceToSell * quantity}`;
  }, [quantity, priceToSell]);

  const sellCargoHandler = useCallback(() => {
    const totalCost = priceToSell * quantity;
    dispatch(
      earnCredits({
        amount: totalCost,
        reason: `${SELL_CARGO_REASON}: ${quantity} ${tradeItemType} x ${formatMoney(
          priceToSell,
        )} cr.`,
      }),
    );
    dispatch(sellCargo(tradeItemType));
    dispatch(
      increaseAvailableTradeItemAmount({
        systemIndex: currentSystem.nameIndex,
        tradeItemType,
        amount: quantity,
      }),
    );
  }, [currentSystem, dispatch, tradeItemType, priceToSell, quantity]);

  return [sellCargoHandler, isAvailable, getSellLabel] as const;
};

export default useSellItem;
