import {
  ANARCHY,
  CAPITALISM,
  COMMUNISM,
  CONFEDERACY,
  CORPORATE,
  CYBERNETIC,
  DEMOCRACY,
  DICTATORSHIP,
  FASCISM,
  FEUDALISM,
  MILITARISM,
  MONARCHISM,
  PACIFISM,
  SOCIALISM,
  SATORI,
  TECHNOCRACY,
  THEOCRACY,
} from '../../data/types/politic/politic-type';
import { PoliticConfigs } from '../../data/types/politic/politic-config';
import {
  FIREARMS,
  FOOD,
  GAMES,
  MACHINES,
  MEDICINE,
  NARCOTICS,
  ORE,
  ROBOTS,
  WATER,
} from '../../data/types/trade-item/trade-item-type';

export const politicConfigs: PoliticConfigs = {
  [ANARCHY]: {
    strengthPolice: 0,
    strengthPirates: 7,
    strengthTraders: 1,
    minTechLevel: 0,
    maxTechLevel: 5,
    bribeLevel: 7,
    illegals: [],
    wanted: [FOOD],
  },
  [CAPITALISM]: {
    strengthPolice: 3,
    strengthPirates: 2,
    strengthTraders: 7,
    minTechLevel: 4,
    maxTechLevel: 7,
    bribeLevel: 1,
    illegals: [],
    wanted: [ORE],
  },
  [COMMUNISM]: {
    strengthPolice: 6,
    strengthPirates: 4,
    strengthTraders: 4,
    minTechLevel: 1,
    maxTechLevel: 5,
    bribeLevel: 5,
    illegals: [],
    wanted: [],
  },
  [CONFEDERACY]: {
    strengthPolice: 4,
    strengthPirates: 3,
    strengthTraders: 5,
    minTechLevel: 1,
    maxTechLevel: 6,
    bribeLevel: 3,
    illegals: [],
    wanted: [GAMES],
  },
  [CORPORATE]: {
    strengthPolice: 6,
    strengthPirates: 2,
    strengthTraders: 7,
    minTechLevel: 4,
    maxTechLevel: 7,
    bribeLevel: 2,
    illegals: [],
    wanted: [ROBOTS],
  },
  [CYBERNETIC]: {
    strengthPolice: 7,
    strengthPirates: 7,
    strengthTraders: 5,
    minTechLevel: 6,
    maxTechLevel: 7,
    bribeLevel: 0,
    illegals: [NARCOTICS, FIREARMS],
    wanted: [ORE],
  },
  [DEMOCRACY]: {
    strengthPolice: 3,
    strengthPirates: 2,
    strengthTraders: 5,
    minTechLevel: 3,
    maxTechLevel: 7,
    bribeLevel: 2,
    illegals: [],
    wanted: [GAMES],
  },
  [DICTATORSHIP]: {
    strengthPolice: 4,
    strengthPirates: 5,
    strengthTraders: 3,
    minTechLevel: 0,
    maxTechLevel: 7,
    bribeLevel: 2,
    illegals: [],
    wanted: [],
  },
  [FASCISM]: {
    strengthPolice: 7,
    strengthPirates: 7,
    strengthTraders: 1,
    minTechLevel: 4,
    maxTechLevel: 7,
    bribeLevel: 0,
    illegals: [NARCOTICS],
    wanted: [MACHINES],
  },
  [FEUDALISM]: {
    strengthPolice: 1,
    strengthPirates: 6,
    strengthTraders: 2,
    minTechLevel: 0,
    maxTechLevel: 3,
    bribeLevel: 6,
    illegals: [],
    wanted: [FIREARMS],
  },
  [MILITARISM]: {
    strengthPolice: 7,
    strengthPirates: 0,
    strengthTraders: 6,
    minTechLevel: 2,
    maxTechLevel: 7,
    bribeLevel: 0,
    illegals: [NARCOTICS],
    wanted: [ROBOTS],
  },
  [MONARCHISM]: {
    strengthPolice: 4,
    strengthPirates: 3,
    strengthTraders: 4,
    minTechLevel: 0,
    maxTechLevel: 5,
    bribeLevel: 4,
    illegals: [],
    wanted: [MEDICINE],
  },
  [PACIFISM]: {
    strengthPolice: 2,
    strengthPirates: 1,
    strengthTraders: 5,
    minTechLevel: 0,
    maxTechLevel: 3,
    bribeLevel: 1,
    illegals: [FIREARMS],
    wanted: [],
  },
  [SOCIALISM]: {
    strengthPolice: 2,
    strengthPirates: 5,
    strengthTraders: 3,
    minTechLevel: 0,
    maxTechLevel: 5,
    bribeLevel: 6,
    illegals: [],
    wanted: [],
  },
  [SATORI]: {
    strengthPolice: 1,
    strengthPirates: 1,
    strengthTraders: 1,
    minTechLevel: 0,
    maxTechLevel: 1,
    bribeLevel: 0,
    illegals: [NARCOTICS, FIREARMS],
    wanted: [],
  },
  [TECHNOCRACY]: {
    strengthPolice: 6,
    strengthPirates: 3,
    strengthTraders: 6,
    minTechLevel: 4,
    maxTechLevel: 7,
    bribeLevel: 2,
    illegals: [],
    wanted: [WATER],
  },
  [THEOCRACY]: {
    strengthPolice: 6,
    strengthPirates: 1,
    strengthTraders: 4,
    minTechLevel: 0,
    maxTechLevel: 4,
    bribeLevel: 0,
    illegals: [],
    wanted: [NARCOTICS],
  },
};
