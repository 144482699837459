export default function makeArray<T>(
  length: number,
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  makeItem: any,
): Array<T> {
  const ar: Array<T> = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < length; index++) {
    if (typeof makeItem === 'function') {
      ar[index] = makeItem(index) as T;
    } else {
      ar[index] = makeItem as T;
    }
  }
  return [...ar];
}
