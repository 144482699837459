import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonCard,
  IonCardContent,
  IonGrid,
  IonCol,
  IonRow,
} from '@ionic/react';
import { isEquipmentToSell } from '../../../../app/core/equipment/equipment';
import EquipmentItem from './EquipmentItem';
import {
  EquipmentType,
  equipmentConfigs,
} from '../../../../app/config/equipment.configs';
import { EquipmentItemType } from '../../../../app/data/types/ship/equipment/equipment';

type EquipmentListProps = {
  equipmentType: EquipmentType;
};

const EquipmentList: React.FC<EquipmentListProps> = ({ equipmentType }) => {
  const configs = equipmentConfigs[equipmentType];
  const equipmentItemTypesToSell = (Object.keys(
    configs,
  ) as EquipmentItemType[]).filter(isEquipmentToSell(configs));

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{equipmentType}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>Name</IonCol>
            <IonCol>Price</IonCol>
            <IonCol>Action</IonCol>
          </IonRow>
          {equipmentItemTypesToSell.map((equipmentItemType) => (
            <EquipmentItem
              key={equipmentItemType}
              configs={configs}
              equipmentItemType={equipmentItemType}
            />
          ))}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default EquipmentList;
