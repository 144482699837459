import { IShip } from '../../../data/types/ship/ship';
import { add, prop } from 'ramda';
import { unique } from '../../../utils/unique-array';
import { getBuyingPrice } from './buying-price';

export function getCargoValue(ship: IShip): number {
  return ship.cargo
    .map(prop('type'))
    .filter(unique)
    .map(getBuyingPrice(ship.cargo))
    .reduce(add, 0);
}
