import { createAction } from 'typesafe-actions';
import { IScores } from '../../../app/data/types/game/scores';
import { ScoreRecord } from '../../../app/data/types/score-record';
import {
  INIT_SCORES,
  INCREASE_REPUTATION,
  REGISTER_POLICE_SCORE_CHANGE,
  RESET_POLICE_SCORE,
} from './scores.action-types';

export const initScores = createAction(INIT_SCORES)<IScores>();
export const increaseReputation =
  createAction(INCREASE_REPUTATION)<Omit<ScoreRecord, 'index'>>();
export const registerPoliceScoreChange = createAction(
  REGISTER_POLICE_SCORE_CHANGE,
)<Omit<ScoreRecord, 'index'>>();

export const resetPoliceScore =
  createAction(RESET_POLICE_SCORE)<Omit<ScoreRecord, 'index' | 'score'>>();
