import { ShipType } from '../../../data/types/ship/ship-type';
import { IGadget } from '../../../data/types/ship/gadget/gadget';
import { getRandom } from '../../../utils';
import { gadgetConfigs } from '../../../config/gadget/gadget.configs';
import { gadgets } from '../../../data/types/ship/gadget/gadget-type';
import { shields } from '../../../data/types/ship/shield/shield-type';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { GADGETS } from '../../../config/equipment.configs';
import { HARD } from '../../../data/constants/difficulty';
import { makeGadget } from '../../../factories/ship-equipment';
import { getEquipmentType } from './get-equipment';

export const getOpponentGadgets = (
  opponentType: ShipType,
  tries: number,
  difficulty: number,
): IGadget[] => {
  const gadgetSlots = shipConfigs[opponentType].equipmentSlots[GADGETS];
  if (gadgetSlots < 1) {
    return [];
  }

  let gadgetsToFill = gadgetSlots;
  if (difficulty <= HARD) {
    gadgetsToFill = getRandom(0, gadgetSlots);
    if (gadgetsToFill < gadgetSlots) {
      if (tries > 4) {
        gadgetsToFill += 1;
      } else if (tries > 2) {
        gadgetsToFill += getRandom(0, gadgetSlots - gadgetsToFill);
      }
    }
  }

  return Array(gadgetsToFill)
    .fill(0)
    .map(() => makeGadget(getEquipmentType(gadgetConfigs, gadgets, tries)));
};
