import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import TradeItemCard from './TradeItem';
import { selectCurrentSystemTradeItemsInConsumption } from '../../../../store/game/systems/selectors/current-system.selector';
import { shallowEqual, useSelector } from 'react-redux';

const TradeCard: React.FC = () => {
  const tradeItemTypesInConsumption = useSelector(
    selectCurrentSystemTradeItemsInConsumption,
    shallowEqual,
  );

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>You can trade following goods:</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className="ion-no-padding">
          {tradeItemTypesInConsumption.map((tradeItemType) => (
            <TradeItemCard key={tradeItemType} tradeItemType={tradeItemType} />
          ))}
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default TradeCard;
