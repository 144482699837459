import {
  ENGINEER_SKILL,
  FIGHTER_SKILL,
  PILOT_SKILL,
  SkillType,
  TRADER_SKILL,
} from '../../../data/types/crew-member/skill';
import { IShip } from '../../../data/types/ship/ship';
import { DISCOUNT_COMPUTER } from '../../../data/types/crew-member/personal-equipment';
import { hasEquipment } from '../../equipment/equipment';
import {
  AUTO_REPAIR_SYSTEM,
  CLOAKING_DEVICE,
  NAVIGATING_SYSTEM,
  TARGETING_SYSTEM,
} from '../../../data/types/ship/gadget/gadget-type';
import { CLOAKBONUS, SKILLBONUS } from '../../../data/constants';
import { fromNullable, map, toNullable } from 'fp-ts/Option';
import { CrewMember } from '../../../data/types/crew-member/crew-member';
import { pipe } from 'rxjs';

export const getSkillBonus = (skillType: SkillType) => (ship: IShip) => {
  switch (skillType) {
    case TRADER_SKILL:
      return pipe(
        map<CrewMember, boolean>((commander) =>
          commander.equipment.includes(DISCOUNT_COMPUTER),
        ),
        toNullable,
      )(fromNullable(ship.commander))
        ? 1
        : 0;
    case FIGHTER_SKILL:
      return hasEquipment(ship, TARGETING_SYSTEM) ? SKILLBONUS : 0;
    case PILOT_SKILL: {
      const skillBonus = hasEquipment(ship, NAVIGATING_SYSTEM) ? SKILLBONUS : 0;
      const cloakBonus = hasEquipment(ship, CLOAKING_DEVICE) ? CLOAKBONUS : 0;
      return skillBonus + cloakBonus;
    }
    case ENGINEER_SKILL:
      return hasEquipment(ship, AUTO_REPAIR_SYSTEM) ? SKILLBONUS : 0;
    default:
      return 0;
  }
};
