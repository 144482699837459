import { System } from '../../data/types/system/system';
import { SystemStatus } from '../../data/types/system/system-status';
import { TradeItemType } from '../../data/types/trade-item/trade-item-type';
import { getSystemStatus } from '../solar-system/system-status';
import { getStandardPrice } from './trade-items.prices';

export interface StatusStat {
  day: number;
  status: SystemStatus;
}

export interface TradeItemStat {
  day: number;
  price: number;
}

const VISIBLE_HISTORY_FRAME = 10; // past 10 days;

export const getPricesHistory = (
  system: System,
  tradeItemType: TradeItemType,
) => (difficulty: number, currentDay: number): TradeItemStat[] =>
  Array(VISIBLE_HISTORY_FRAME)
    .fill(0)
    .map((value, index) => currentDay + index + 1 - VISIBLE_HISTORY_FRAME)
    .map((day) => ({
      day,
      price: getStandardPrice(tradeItemType, system, day),
    }));

export const getStatusHistory = (system: System) => (
  currentDay: number,
): StatusStat[] =>
  Array(VISIBLE_HISTORY_FRAME)
    .fill(0)
    .map((value, index) => currentDay + index + 1 - VISIBLE_HISTORY_FRAME)
    .map((day) => ({
      day,
      status: getSystemStatus(day, system),
    }));
