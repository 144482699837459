import { IonSplitPane, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, useParams, useRouteMatch } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import ConfirmationsCenter from '../alerts/Confirmations';
import NotificationsCenter from '../alerts/Notifications';
import configureStore from '../../store';
import { BalancePage } from './Balance/Balance';
import BuyEquipment from './BuyEquipment/BuyEquipment';
import BuyShips from './BuyShips/BuyShips';
import Commander from './Commander/Commander';
import QuestStatus from './debug/QuestStatus/QuestStatus';
import PlayerShipPage from './Ship/PlayerShip';
import Systems from './debug/Systems/Systems';
import TravelHistory from './debug/TravelHistory/TravelHistory';
import Home from './Home/Home';
import Loader from './Loader';
import Menu from './Menu/Menu';
import PoliceRecords from './police-records/PoliceRecords';
import ReputationRecords from './reputation-records/ReputationRecords';
import SettingsPage from './settings/Settings';
import { Bank } from './Bank/Bank';
import { GADGETS, SHIELDS, WEAPONS } from '../../app/config/equipment.configs';
import { Cargo } from './Cargo/Cargo';
import { ToastsCenter } from '../alerts/Toasts';
import { persistStore } from 'redux-persist';
import { BalanceAlertsCenter } from '../alerts/BalanceAlerts';

const Game: React.FC = () => {
  const match = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  localStorage.setItem('current', id);

  const store = configureStore(id);

  return (
    <Provider store={store}>
      <PersistGate
        loading={<p>Loading state...</p>}
        persistor={persistStore(store)}
      >
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu baseUrl={match.url} />
            <IonRouterOutlet id="main">
              <Route path={`${match.url}/game`} exact>
                <Home />
              </Route>
              <Route path={`${match.url}/bank`} exact>
                <Bank />
              </Route>
              <Route path={`${match.url}/buy-weapons`} exact>
                <BuyEquipment equipmentType={WEAPONS} />
              </Route>
              <Route path={`${match.url}/buy-shields`} exact>
                <BuyEquipment equipmentType={SHIELDS} />
              </Route>
              <Route path={`${match.url}/buy-gadgets`} exact>
                <BuyEquipment equipmentType={GADGETS} />
              </Route>
              <Route path={`${match.url}/buy-ships`} exact>
                <BuyShips />
              </Route>
              <Route path={`${match.url}/commander`} exact>
                <Commander />
              </Route>
              <Route path={`${match.url}/galaxy`} exact>
                <Systems />
              </Route>
              <Route path={`${match.url}/ship`} exact>
                <PlayerShipPage />
              </Route>
              <Route path={`${match.url}/cargo`} exact>
                <Cargo />
              </Route>
              <Route path={`${match.url}/history`} exact>
                <TravelHistory />
              </Route>
              <Route path={`${match.url}/balance`} exact>
                <BalancePage />
              </Route>
              <Route path={`${match.url}/police-score`} exact>
                <PoliceRecords />
              </Route>
              <Route path={`${match.url}/reputation`} exact>
                <ReputationRecords />
              </Route>
              <Route path={`${match.url}/quests`} exact>
                <QuestStatus />
              </Route>
              <Route path={`${match.url}/settings`} exact>
                <SettingsPage />
              </Route>
              <Route path={match.url} exact>
                <Loader />
              </Route>
            </IonRouterOutlet>
            <NotificationsCenter />
            <ConfirmationsCenter />
            <ToastsCenter />
            <BalanceAlertsCenter />
          </IonSplitPane>
        </IonReactRouter>
      </PersistGate>
    </Provider>
  );
};

export default Game;
