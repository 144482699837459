import { v4 as uuidv4 } from 'uuid';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './balance-alerts.actions';
import { BalanceAlert } from './balance-alert';

export type BalanceAlertsActions = ActionType<typeof actions>;

export type BalanceAlertsState = BalanceAlert[];

const initialBalanceAlertsState: BalanceAlertsState = [];

export const balanceAlertsReducer = createReducer<
  BalanceAlertsState,
  BalanceAlertsActions
>(initialBalanceAlertsState)
  .handleAction(
    actions.addBalanceAlert,
    (state, { payload: { header, message, color } }) => [
      ...state,
      {
        id: uuidv4(),
        header,
        message,
        color,
      },
    ],
  )
  .handleAction(actions.disposeBalanceAlert, (state, action) =>
    state.filter((item) => item.id !== action.payload),
  );
