import memoize from 'fast-memoize';
import { getSeedRandom } from '../../utils';
import { System } from '../../data/types/system/system';
import {
  SystemStatus,
  systemStatuses,
  UNEVENTFUL,
} from '../../data/types/system/system-status';
import { getSystemUniqueId } from './system-unique-id';

export const getSystemStatus = memoize(
  (currentDay: number, system: System): SystemStatus => {
    if (currentDay < -8) {
      return UNEVENTFUL;
    }

    const statusSeed = `${currentDay}${getSystemUniqueId(system)}`;
    const yesterdayStatus = getSystemStatus(currentDay - 1, system);
    // there is a 15% chance that status changes
    const chance = getSeedRandom(statusSeed)(0, 100);
    if (chance < 15) {
      // if system had an actual status yesterday, it becomes uneventful
      if (yesterdayStatus !== UNEVENTFUL) {
        return UNEVENTFUL;
      }

      // otherwise we pick up random status
      return systemStatuses[
        getSeedRandom(`${statusSeed}${chance}`)(0, systemStatuses.length - 1)
      ];
    }

    // if we don't get 15% chance, we remain in the same status as yesterday
    return yesterdayStatus;
  },
);

export const isEventfulStatus = (day: number) => (system: System) =>
  getSystemStatus(day, system) !== UNEVENTFUL;
