import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../..';
import { mapState } from '../../operators/map-state';
import { addNotification } from '../notifications/notifications.actions';
import {
  increaseReputation,
  registerPoliceScoreChange,
} from './scores.actions';
import {
  selectIsPoliceRecordLabelChanged,
  selectIsReputationScoreLabelChanged,
  selectPoliceRecordLabel,
  selectReputationLabel,
} from './scores.selectors';
import { addToast } from '../toasts/toasts.actions';

const changePoliceScoreEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(registerPoliceScoreChange)),
    mapState(selectIsPoliceRecordLabelChanged)(state$),
    filter((isChanged) => isChanged),
    mapState(selectPoliceRecordLabel)(state$),
    map((label) =>
      addToast({
        header: 'Police record score change',
        message: `Your police record score is ${label} now`,
      }),
    ),
  );

const increaseReputationEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(increaseReputation)),
    mapState(selectIsReputationScoreLabelChanged)(state$),
    filter((isChanged) => isChanged),
    mapState(selectReputationLabel)(state$),
    map((label) =>
      addToast({
        header: 'Promoted',
        message: `Your reputation rank is ${label} now`,
      }),
    ),
  );

export const scoreEpics = [changePoliceScoreEpic, increaseReputationEpic];
