import { pipe } from 'rxjs';
import { selectCurrentTravelEncounters } from './current-travel-encounters.selector';
import { mapEntitiesToArray } from '../../../entities';
import { PLAYER, POLICE } from '../../../../app/data/interaction/actor-type';
import { isEventOf } from '../../../../app/core/encounter/interaction';
import { SUBMIT } from '../../../../app/data/interaction/action-type';

export const selectIsInspected = pipe(
  selectCurrentTravelEncounters,
  mapEntitiesToArray,
  (encounters) =>
    encounters.some(
      (encounter) =>
        encounter.type === POLICE &&
        mapEntitiesToArray(encounter.log).some(isEventOf(PLAYER, SUBMIT)),
    ),
);
