import { prop } from 'ramda';
import { pipe } from 'rxjs';
import { selectCurrentSystem } from '../game/systems/selectors/current-system.selector';
import { selectAmountOfVisits } from '../game/travel/travel.selectors';
import { RootState } from '../index';

export const selectCurrentSystemVisitsAmount = (state: RootState) => {
  return selectAmountOfVisits(
    pipe(selectCurrentSystem, prop('nameIndex'))(state),
  )(state);
};
