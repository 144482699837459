import {
  EncounterActionType,
  ARREST,
  ATTACK,
} from '../../../../data/interaction/action-type';
import { PSYCHOPATHSCORE } from '../../../../data/constants/police-record-score';

export const getPoliceSurrenderReaction = (
  policeScore: number,
): EncounterActionType => {
  if (policeScore <= PSYCHOPATHSCORE) {
    return ATTACK;
  }

  return ARREST;
};
