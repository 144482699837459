import { v4 as uuidv4 } from 'uuid';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './toasts.actions';
import { Toast } from './toast';

export type ToastsActions = ActionType<typeof actions>;

export type ToastsState = Toast[];

const initialToastsState: ToastsState = [];

export const toastsReducer = createReducer<ToastsState, ToastsActions>(
  initialToastsState,
)
  .handleAction(actions.addToast, (state, { payload: { header, message } }) => [
    ...state,
    {
      id: uuidv4(),
      header,
      message,
    },
  ])
  .handleAction(actions.disposeToast, (state, action) =>
    state.filter((item) => item.id !== action.payload),
  );
