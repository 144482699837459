import { IShip } from '../../data/types/ship/ship';
import { Installable } from '../../data/types/ship/equipment/installable';
import {
  EquipmentItemType,
  getEquipmentType,
} from '../../data/types/ship/equipment/equipment';
import { TRADER_SKILL } from '../../data/types/crew-member/skill';
import { equipmentConfigs } from '../../config/equipment.configs';
import { getSkillLevel } from '../crew-member/skill/skill-level';

export const baseEquipmentSellPrice = (equipmentConfig: Installable): number =>
  (equipmentConfig.price * 3) / 4;

export const baseEquipmentPrice = (equipmentItemType: EquipmentItemType) => (
  difficulty: number,
  ship: IShip,
): number => {
  const configs = equipmentConfigs[
    getEquipmentType(equipmentItemType)
  ] as Record<string, Installable>;
  const { price } = configs[equipmentItemType];
  return (price * (100 - getSkillLevel(TRADER_SKILL)(ship, difficulty))) / 100;
};
