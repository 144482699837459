import {
  PSYCHOPATHSCORE,
  VILLAINSCORE,
} from '../../../data/constants/police-record-score';
import { IShip } from '../../../data/types/ship/ship';
import { isWildOnBoard } from '../../ship/crew/is-on-board';

// The police will try to hunt you down with better ships if you are
// a villain, and they will try even harder when you are considered to
// be a psychopath (or are transporting Jonathan Wild)
export function getPoliceWantedLevel(
  policeRecordScore: number,
  ship: IShip,
): number {
  if (policeRecordScore < VILLAINSCORE && !isWildOnBoard(ship)) {
    return 3;
  }

  if (policeRecordScore < PSYCHOPATHSCORE || isWildOnBoard(ship)) {
    return 5;
  }

  return 1;
}
