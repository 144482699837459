import { IShip } from '../../../data/types/ship/ship';
import { SkillType } from '../../../data/types/crew-member/skill';
import { pipe } from 'ramda';
import { findMaxCrewSkill } from './max-crew-skill';
import { applyPersonalEquipment } from './apply-personal-equipment';
import { adaptSkillToDifficulty } from './adapt-to-difficulty';

export const getSkillLevel = (skillType: SkillType) => (
  ship: IShip,
  difficulty: number,
) =>
  pipe(
    findMaxCrewSkill(skillType),
    applyPersonalEquipment(skillType)(ship),
    adaptSkillToDifficulty(difficulty),
  )(ship);
