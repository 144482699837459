import { IShip } from '../../data/types/ship/ship';
import { hasEquipment } from '../equipment/equipment';
import { CLOAKING_DEVICE } from '../../data/types/ship/gadget/gadget-type';
import { ENGINEER_SKILL } from '../../data/types/crew-member/skill';
import { getSkillLevel } from '../crew-member/skill/skill-level';

export const isShipHiddenForOpponent = (difficulty: number) => (
  ship: IShip,
  opponentShip: IShip,
): boolean => {
  const getEngineerSkill = getSkillLevel(ENGINEER_SKILL);
  return (
    hasEquipment(ship, CLOAKING_DEVICE) &&
    getEngineerSkill(ship, difficulty) >
      getEngineerSkill(opponentShip, difficulty)
  );
};
