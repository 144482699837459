import { IShip } from '../../data/types/ship/ship';
import { shipConfigs } from './ship.configs';
import { ShipConfig } from '../../data/types/ship/ship-config';
import memoize from 'fast-memoize';
import { ShipType } from '../../data/types/ship/ship-type';

export const getShipConfig = memoize(
  (ship: IShip): ShipConfig => shipConfigs[ship.type],
);

export const getShipTypeConfig = memoize(
  (type: ShipType): ShipConfig => shipConfigs[type],
);
