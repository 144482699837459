// Solar systems
export const MAXSOLARSYSTEM = 120;
export const ACAMARSYSTEM = 0;
export const BARATASSYSTEM = 6;
export const DALEDSYSTEM = 17;
export const DEVIDIASYSTEM = 22;
export const GEMULONSYSTEM = 32;
export const JAPORISYSTEM = 41;
export const KRAVATSYSTEM = 50;
export const MELINASYSTEM = 59;
export const NIXSYSTEM = 67;
export const OGSYSTEM = 70;
export const REGULASSYSTEM = 82;
export const SOLSYSTEM = 92;
export const UTOPIASYSTEM = 109;
export const ZALKONSYSTEM = 118;
