import React, { useCallback } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  selectDebt,
  selectInterest,
} from '../../../store/game/status/status.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAvailableCredits,
  selectMaxLoan,
} from '../../../store/derived/computational-selectors';
import { addNotification } from '../../../store/game/notifications/notifications.actions';
import {
  getLoan,
  payLoanBack,
} from '../../../store/game/status/status.actions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export const Bank: React.FC = () => {
  const debt = useSelector(selectDebt);
  const credits = useSelector(selectAvailableCredits);
  const interest = useSelector(selectInterest);
  const maxLoan = useTypedSelector(selectMaxLoan);
  const availableLoan = Math.max(0, maxLoan - debt);
  const nextLoanAmount = Math.min(availableLoan, 100);
  const dispatch = useDispatch();

  const payBack = useCallback(() => {
    if (credits <= 0) {
      dispatch(
        addNotification({
          title: 'No money',
          message: 'You can not pay back anything',
        }),
      );
    } else {
      dispatch(payLoanBack(Math.min(credits, debt)));
    }
  }, [debt, dispatch, credits]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Bank</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>Current debt</IonLabel>
            <IonNote slot="end">{debt}</IonNote>
          </IonItem>
        </IonList>
        <IonList>
          <IonItem>
            <IonLabel>Interest</IonLabel>
            <IonNote slot="end">{interest} cr. per day (10%)</IonNote>
          </IonItem>
        </IonList>
        <IonList>
          <IonItem>
            <IonLabel>Max possible loan</IonLabel>
            <IonNote slot="end">{maxLoan}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>You can loan</IonLabel>
            <IonNote slot="end">{availableLoan}</IonNote>
          </IonItem>
        </IonList>

        <IonButton
          disabled={nextLoanAmount < 1}
          onClick={() => dispatch(getLoan(nextLoanAmount))}
        >
          Get loan ({nextLoanAmount} cr.)
        </IonButton>

        <IonButton disabled={debt <= 0} onClick={payBack}>
          Pay back
        </IonButton>
      </IonContent>
    </IonPage>
  );
};
