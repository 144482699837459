import { of } from 'rxjs';
import { Epic } from 'redux-observable';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { addNotification } from '../../../notifications/notifications.actions';
import { GameActions } from '../../../..';
import { onEncounterEvent, onInitEncounter } from '../encounter.pipe-operators';
import { mapState } from '../../../../operators/map-state';
import {
  selectImprisonmentLength,
  selectInitialPoliceAction,
  selectPoliceBribeOffer,
  selectPoliceIllegalCargoFine,
} from './police.selectors';
import { spendCredits } from '../../../balance/balance.actions';
import {
  ARREST,
  ATTACK,
  BRIBE,
  FLEE,
  LET_GO,
  SUBMIT,
  SUBMIT_APPROVED,
  SUBMIT_FAILED,
  SURRENDER,
} from '../../../../../app/data/interaction/action-type';
import { POLICE } from '../../../../../app/data/interaction/actor-type';
import { arrive, move } from '../../../travel/travel.actions';
import { increaseDays } from '../../../status/status.actions';
import { confiscateCargo } from '../../../ship/ship.actions';
import {
  FIREARMS,
  NARCOTICS,
} from '../../../../../app/data/types/trade-item/trade-item-type';
import {
  registerPoliceScoreChange,
  resetPoliceScore,
} from '../../../scores/scores.actions';
import { TRAFFICKING } from '../../../../../app/data/constants/police-record';
import { makeOpponentReactWhenPlayerDoing } from '../opponent.epics.factories';
import { makeActionEvent } from '../../make-action-event';
import { TravelActions } from '../../../travel/travel.reducer';
import {
  BRIBE_REASON,
  ILLEGALS_FINE_REASON,
} from '../../../../../app/ui/change-balance-reasons';

const encountPolice: Epic<TravelActions, TravelActions> = (action$, state$) =>
  action$.pipe(
    onInitEncounter(POLICE),
    mapState(selectInitialPoliceAction)(state$),
    map(makeActionEvent(POLICE)),
  );

const bribePoliceEffect: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(POLICE, LET_GO),
    mapState(selectPoliceBribeOffer)(state$),
    switchMap((amount) =>
      of(
        spendCredits({
          amount,
          reason: BRIBE_REASON,
        }),
      ),
    ),
  );

const submitFailedPoliceEffect: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onEncounterEvent(POLICE, SUBMIT_FAILED),
    mapState(selectPoliceIllegalCargoFine)(state$),
    switchMap((amount) => [
      confiscateCargo(NARCOTICS),
      confiscateCargo(FIREARMS),
      spendCredits({ amount, reason: ILLEGALS_FINE_REASON }),
      registerPoliceScoreChange({ score: TRAFFICKING, reason: 'trafficking' }),
      addNotification({
        title: 'Inspection failed',
        message:
          'Police found illegals in your cargo. It was confiscated and you paid a fine. Police remembers that you are trafficking',
      }),
    ]),
  );

const submitApprovedPoliceEffect: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    onEncounterEvent(POLICE, SUBMIT_APPROVED),
    switchMapTo([
      addNotification({
        title: 'Nothing found',
        message:
          'Your ship cargo is clean. Your police record score is increased.',
        action: move(),
      }),
      registerPoliceScoreChange({
        score: -TRAFFICKING,
        reason: 'clean police inspection',
      }),
    ]),
  );

const onPlayerArrestEffect: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onEncounterEvent(POLICE, ARREST),
    mapState(selectImprisonmentLength)(state$),
    switchMap((imprisonment) => [
      addNotification({
        title: 'Imprisonment',
        message: `You have spent ${imprisonment} days in a prison`,
      }),
      arrive(),
      increaseDays(imprisonment),
      resetPoliceScore({ reason: 'released from a prison' }),
    ]),
  );

export const policeEpics = [
  encountPolice,
  makeOpponentReactWhenPlayerDoing(POLICE, ATTACK),
  makeOpponentReactWhenPlayerDoing(POLICE, SUBMIT),
  makeOpponentReactWhenPlayerDoing(POLICE, BRIBE),
  makeOpponentReactWhenPlayerDoing(POLICE, SURRENDER),
  makeOpponentReactWhenPlayerDoing(POLICE, FLEE),

  submitFailedPoliceEffect,
  submitApprovedPoliceEffect,
  bribePoliceEffect,
  onPlayerArrestEffect,
];
