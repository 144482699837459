import {
  CAPTAIN,
  DRAGONFLY,
  MANTIS,
  PIRATE,
  POLICE,
  SCARAB,
  SPACE_MONSTER,
  TRADER,
} from './actor-type';

export const canEscape = [POLICE, TRADER, PIRATE] as const;

export const canIgnore = [POLICE, TRADER, PIRATE] as const;

export const canReceiveDamage = [
  POLICE,
  PIRATE,
  TRADER,
  CAPTAIN,
  MANTIS,
  SPACE_MONSTER,
  SCARAB,
  DRAGONFLY,
] as const;
