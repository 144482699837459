import {
  ATTACK,
  BUY,
  FLEE,
  IGNORE,
  MUTUAL_IGNORE,
  PLUNDER,
  SELL,
  SURRENDER,
  TRADE,
} from './action-type';

export const possibleTraderActions = [
  IGNORE,
  FLEE,
  ATTACK,
  SURRENDER,
  SELL,
  BUY,
] as const;

export type PossibleTraderAction = typeof possibleTraderActions[number];

export const possiblePlayerReactionToTraderIgnore = [
  ATTACK,
  MUTUAL_IGNORE,
] as const;
export const possiblePlayerReactionToTraderAttack = [ATTACK, FLEE] as const;
export const possiblePlayerReactionToTraderSurrender = [
  ATTACK,
  FLEE,
  PLUNDER,
] as const;
export const possiblePlayerReactionToTraderTrade = [
  ATTACK,
  MUTUAL_IGNORE,
  TRADE,
] as const;

export type PossibleReactionToTrader =
  | typeof possiblePlayerReactionToTraderIgnore[number]
  | typeof possiblePlayerReactionToTraderAttack[number]
  | typeof possiblePlayerReactionToTraderSurrender[number]
  | typeof possiblePlayerReactionToTraderTrade[number];

export const possibleReactionsToTrader: Record<
  PossibleTraderAction,
  readonly PossibleReactionToTrader[]
> = {
  [IGNORE]: possiblePlayerReactionToTraderIgnore,
  [FLEE]: possiblePlayerReactionToTraderIgnore,
  [BUY]: possiblePlayerReactionToTraderTrade,
  [SELL]: possiblePlayerReactionToTraderTrade,
  [ATTACK]: possiblePlayerReactionToTraderAttack,
  [SURRENDER]: possiblePlayerReactionToTraderSurrender,
};
