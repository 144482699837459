import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPlayerName } from '../../../store/game/ship/ship.selectors';

export const PlayerNameMenuLabel: React.FC = () => {
  const playerName = useSelector(selectPlayerName);
  return (
    <IonNote slot="end" className="ion-text-uppercase">
      {playerName}
    </IonNote>
  );
};
