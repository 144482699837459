import { IShip } from '../data/types/ship/ship';
import { System } from '../data/types/system/system';
import { realDistance } from '../core/coordinates/coordinates';
import { getFuelTanks } from '../core/ship/fuel/fuel-tanks-size';
import { getCurrentFuelLevel } from '../core/ship/fuel/current-fuel-level';
import { hasWormholeWith } from '../core/wormholes/has-wormhole';

export const canPossiblyReach = (ship: IShip) => (currentSystem: System) => (
  destination: System,
) =>
  realDistance(currentSystem.coordinates, destination.coordinates) <=
  getFuelTanks(ship);

export const canReach = (ship: IShip) => (currentSystem: System) => (
  destination: System,
) =>
  realDistance(currentSystem.coordinates, destination.coordinates) <=
  getCurrentFuelLevel(ship);

export const getReachableSystems = (
  ship: IShip,
  currentSystem: System,
  systems: System[],
): System[] =>
  systems
    .filter((nextSystem) => nextSystem.nameIndex !== currentSystem.nameIndex)
    .filter(
      (nextSystem) =>
        canPossiblyReach(ship)(currentSystem)(nextSystem) ||
        hasWormholeWith(currentSystem, nextSystem),
    );

export const getSystemsToTravel = (
  currentSystem: System,
  ship: IShip,
  systems: System[],
): System[] =>
  systems
    .filter((nextSystem) => nextSystem.nameIndex !== currentSystem.nameIndex)
    .filter(
      (nextSystem) =>
        canReach(ship)(currentSystem)(nextSystem) ||
        hasWormholeWith(currentSystem, nextSystem),
    );
