import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPoliceRecordScore } from '../../../store/game/scores/scores.selectors';
import {
  getPoliceRecordScoreLabel,
  getReputationColor,
} from '../../../app/ui/scores.labels';

export const PoliceRecordMenuLabel: React.FC = () => {
  const policeRecordScore = useSelector(selectPoliceRecordScore);
  return (
    <IonNote
      slot="end"
      color={getReputationColor(policeRecordScore)}
      className="ion-text-uppercase">
      {getPoliceRecordScoreLabel(policeRecordScore)}
    </IonNote>
  );
};
