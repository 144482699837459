import { ShipConfig } from '../data/types/ship/ship-config';
import { ShipType, shipTypes } from '../data/types/ship/ship-type';
import { shipConfigs } from '../config/ship/ship.configs';
import { EquipmentType } from '../config/equipment.configs';

const getSellableShipConfigs = () => {
  return shipTypes
    .map((type) => shipConfigs[type as ShipType])
    .filter((config) => config.toSell);
};

export const getMaxShipConfigValue = (field: keyof ShipConfig): number => {
  return Math.max(
    ...getSellableShipConfigs().map((config) => config[field] as number),
  );
};

export const getMaxSlotValue = (equipmentType: EquipmentType): number => {
  return Math.max(
    ...getSellableShipConfigs().map(
      (config) => config.equipmentSlots[equipmentType],
    ),
  );
};
