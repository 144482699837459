import React from 'react';
import { IonLabel, IonProgressBar } from '@ionic/react';
import { getPercentageValue } from '../../../app/ui/progressbar';
import {
  getPossibleShieldsStrength,
  getShieldStrength,
} from '../../../app/core/ship/ship.utils';
import { IShip } from '../../../app/data/types/ship/ship';

export const ShipShields: React.FC<{ ship: IShip }> = ({ ship }) => {
  const strength = getShieldStrength(ship);

  if (strength === 0) {
    return <></>;
  }

  return (
    <div>
      <IonLabel className="code">shields</IonLabel>
      <IonProgressBar
        value={getPercentageValue(
          getShieldStrength(ship),
          getPossibleShieldsStrength(ship),
        )}
        color="success"
      />
    </div>
  );
};
