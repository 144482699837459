import { getRandom } from '../../../utils';
import { IMPOSSIBLE, NORMAL } from '../../../data/constants/difficulty';
import { IShield } from '../../../data/types/ship/shield/shield';
import { IShip } from '../../../data/types/ship/ship';
import {
  QuestStatus,
  REACTOR_QUEST,
} from '../../../data/types/game/quest-status';
import { ENGINEER_SKILL } from '../../../data/types/crew-member/skill';
import { getShieldStrength } from '../../ship/ship.utils';
import { getShipConfig } from '../../../config/ship/get-ship-config';
import { getMaxHullStrength } from '../../ship/hull/hull-strength';
import { getSkillLevel } from '../../crew-member/skill/skill-level';

export function damageShield(shields: IShield[], damage: number): IShield[] {
  if (damage < 1) {
    return shields;
  }

  let remainDamage = damage;
  return shields
    .map((shield) => {
      const resultStrength = Math.max(shield.strength - remainDamage, 0);
      remainDamage = Math.max(remainDamage - shield.strength, 0);
      return {
        ...shield,
        strength: resultStrength,
      };
    })
    .filter((shield) => shield.strength > 0);
}

export const getDamageOnHull = (
  ship: IShip,
  damage: number,
  difficulty: number,
  CommanderUnderAttack: boolean,
): number => {
  // At least 2 shots on Normal level are needed to destroy the hull
  // (3 on Easy, 4 on Beginner, 1 on Hard or Impossible). For opponents,
  // it is always 2.
  const divider = CommanderUnderAttack
    ? Math.max(1, IMPOSSIBLE - difficulty)
    : 2;

  const engineerSkill = getSkillLevel(ENGINEER_SKILL)(ship, difficulty);
  const reducedDamage = Math.max(damage - getRandom(0, engineerSkill), 1);
  return Math.min(
    reducedDamage,
    Math.round(getMaxHullStrength(ship) / divider),
  );
};

export const splitDamageOnShieldsAndHull = (
  difficulty: number,
  ship: IShip,
  damage: number,
  isCommanderUnderAttack: boolean,
): [number, number] => {
  const shieldStrength = getShieldStrength(ship);
  const damageOnHull = damage > shieldStrength ? damage - shieldStrength : 0;
  const damagedHull =
    damageOnHull > 0
      ? getDamageOnHull(ship, damageOnHull, difficulty, isCommanderUnderAttack)
      : 0;

  return [damagedHull, damage - damagedHull];
};

export const getDamageBoost = (
  questStatus: QuestStatus,
  difficulty: number,
) => (CommanderUnderAttack: boolean): number => {
  // Reactor on board -- damage is boosted!
  if (
    CommanderUnderAttack &&
    questStatus[REACTOR_QUEST] > 0 &&
    questStatus[REACTOR_QUEST] < 21
  ) {
    const boostFactor = difficulty < NORMAL ? 0.25 : 0.33;
    return 1 + (difficulty + 1) * boostFactor;
  }

  return 1;
};

export const isSeriousPirateHullDamage = (
  ship: IShip,
  damage: number,
): boolean => {
  const hullWasDamaged = ship.hull < getShipConfig(ship).hullStrength;

  if (!hullWasDamaged) {
    return false;
  }

  const hullBeforeDamage = Math.min(
    ship.hull + damage,
    getShipConfig(ship).hullStrength,
  );
  return ship.hull < (hullBeforeDamage * 2) / 3;
};

export const isSeriousPoliceHullDamage = (
  ship: IShip,
  damage: number,
): boolean => {
  const hullWasDamaged = ship.hull < getShipConfig(ship).hullStrength;

  if (!hullWasDamaged) {
    return false;
  }

  const hullBeforeDamage = Math.min(
    ship.hull + damage,
    getShipConfig(ship).hullStrength,
  );
  return ship.hull < hullBeforeDamage >> 1;
};
