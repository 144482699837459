import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { pipe } from 'rxjs';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { DRAGONFLY_EVENT } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';

export const selectDragonflyAction = pipe(
  selectPoliceRecordScore,
  (policeRecordScore) => {
    const { title } = getSpecialEventConfig(DRAGONFLY_EVENT);
    if (policeRecordScore < DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record is too low',
      });
    }
    return invokeSpecialEvent(DRAGONFLY_EVENT);
  },
);
