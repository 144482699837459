import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonText,
} from '@ionic/react';
import { difficultyLevelLabel } from '../../app/data/labels/difficulty-level';
import {
  getPoliceRecordScoreLabel,
  getReputationRecordScoreLabel,
} from '../../app/ui/scores.labels';
import NumberFormat from 'react-number-format';
import ordinal from 'ordinal';
import { IShip } from '../../app/data/types/ship/ship';
import { GraphicShipCard } from '../game/Home/Encounter/GraphicShipCard';

type PlayerCardProps = {
  playerName: string;
  difficulty: number;
  day: number;
  credits: number;
  reputationScore: number;
  policeScore: number;
  ship: IShip;
};

export const PlayerCard: React.FC<PlayerCardProps> = ({
  playerName,
  difficulty,
  day,
  credits,
  reputationScore,
  policeScore,
  ship,
}) => (
  <IonCard className="ion-no-padding ion-no-margin">
    <IonCardContent>
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-align-items-center">
          <IonCol
            sizeXs="12"
            sizeMd="auto"
            className="ion-margin-bottom ion-padding-end">
            <GraphicShipCard ship={ship}>
              <div
                style={{ position: 'absolute', left: 0, top: '1rem' }}
                className="ion-text-uppercase">
                {getReputationRecordScoreLabel(reputationScore)}
              </div>
              <div
                style={{ position: 'absolute', left: 0, top: 0 }}
                className="ion-text-uppercase">
                {getPoliceRecordScoreLabel(policeScore)}
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: '1rem',
                  width: '50%',
                }}
                className="ion-text-right">
                <NumberFormat
                  value={credits}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: '2rem',
                  width: '50%',
                }}
                className="ion-text-right">
                {ship.cargo.length} cargo
              </div>
            </GraphicShipCard>
          </IonCol>
          <IonCol sizeXs="12" sizeMd="auto">
            <IonText>
              <h1>
                You are {playerName} and this is {ordinal(day)} day on{' '}
                {difficultyLevelLabel[difficulty]} level.
              </h1>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonCard>
);
