import React from 'react';
import {
  IonHeader,
  IonCardContent,
  IonPage,
  IonTitle,
  IonContent,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonButton,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMergedBalance } from '../../../store/game/balance/balance.selectors';
import { BalanceRecord } from '../../../app/data/types/balance';
import { earnCredits } from '../../../store/game/balance/balance.actions';
import { CHEAT_LOAN_REASON } from '../../../app/ui/change-balance-reasons';
import { isDebug } from '../../../is-debug-mode';
import { groupBy } from 'ramda';
import { getBalanceByDay } from '../../../store/game/balance/utils/find-highest-balance';
import { DayBalanceCard } from './DayBalanceCard';

export const BalancePage: React.FC = () => {
  const balanceRecords: BalanceRecord[] = useSelector(selectMergedBalance);
  const recordsByDay = groupBy<BalanceRecord>((record) =>
    record.day.toString(),
  )(balanceRecords);
  const dispatch = useDispatch();

  function getLoan() {
    dispatch(earnCredits({ amount: 100000, reason: CHEAT_LOAN_REASON }));
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Balance</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isDebug() && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Loan</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonButton onClick={getLoan}>Get money</IonButton>
            </IonCardContent>
          </IonCard>
        )}

        <IonGrid>
          {Object.keys(recordsByDay).map((day) => (
            <IonRow key={day}>
              <IonCol className="ion-text-right" sizeLg="4" sizeMd="12">
                <IonText color="medium">
                  <h2>Day {day}</h2>
                </IonText>
              </IonCol>
              <IonCol sizeLg="4" sizeMd="12">
                <DayBalanceCard
                  balanceRecords={recordsByDay[day] || []}
                  total={getBalanceByDay(+day)(recordsByDay)}
                />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
