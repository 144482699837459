import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { of, pipe } from 'rxjs';
import {
  map,
  withLatestFrom,
  switchMap,
  filter,
  mapTo,
  delay,
} from 'rxjs/operators';
import { GameActions, RootState } from '../..';
import { TravelActions } from './travel.reducer';
import * as actions from './travel.actions';
import { increaseDays } from '../status/status.actions';
import { repairOnTravel, spendFuel } from '../ship/ship.actions';
import { ShipActions } from '../ship/ship.reducer';
import { selectPlayerShip, selectRepairPoints } from '../ship/ship.selectors';
import { mercenaryPayment } from '../../../app/core/mercenaries/mercenary-payment';
import { addNotification } from '../notifications/notifications.actions';
import { getSystemName } from '../../../app/ui/solar-system.labels';
import { spendCredits } from '../balance/balance.actions';
import { selectNextTravelAction } from './travel.selectors';
import { mapState } from '../../operators/map-state';
import { selectSystemByIndex } from '../systems/systems.selectors';
import { hasWormholeWith } from '../../../app/core/wormholes/has-wormhole';
import { wormHoleTax } from '../../../app/core/wormholes/wormhole-tax';
import { getFuelAmountToTravel } from '../../../app/core/ship/fuel/fuel-amount-to-travel';
import { selectCurrentSystem } from '../systems/selectors/current-system.selector';
import { selectDebt, selectIsDebtHigh } from '../status/status.selectors';
import { selectIsTimeToRemindLoans } from '../../derived/remind-loans';
import { whenInState } from '../../operators/when-in-state';
import {
  MERCENARY_SALARY_REASON,
  WORMHOLE_TAX_REASON,
} from '../../../app/ui/change-balance-reasons';
import { selectCurrentSystemVisitsAmount } from '../../derived/visits-of-current-system';
import { flatten } from 'ramda';
import { TRAVEL_CLICKS } from '../../../app/data/constants';
import { addToast } from '../toasts/toasts.actions';
import { AnyAction } from 'redux';

const DELAY_BETWEEN_MOVES = 500;

const warpEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.warp)),
    map(({ payload }) => payload),
    withLatestFrom(state$),
    map(([systemIndex, state]) => {
      const warpSystem = selectSystemByIndex(systemIndex)(state);
      const currentSystem = selectCurrentSystem(state);
      const viaWormhole = hasWormholeWith(currentSystem, warpSystem);
      const spendAction = viaWormhole
        ? spendCredits({
            amount: wormHoleTax(
              currentSystem,
              warpSystem,
              selectPlayerShip(state),
            ),
            reason: WORMHOLE_TAX_REASON,
          })
        : spendFuel(getFuelAmountToTravel(currentSystem, warpSystem));
      return [
        spendAction,
        increaseDays(1),
        actions.startTravel({
          clicks: TRAVEL_CLICKS,
          systemIndex,
          viaWormhole,
        }),
      ];
    }),
    switchMap((actions) => actions),
  );

const singularityJumpEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.singularityJump)),
    map(({ payload }) => payload),
    map((systemIndex) =>
      actions.startTravel({ clicks: 0, systemIndex, viaWormhole: false }),
    ),
  );

const payTravelExpensesEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.startTravel)),
    mapState(pipe(selectPlayerShip, mercenaryPayment))(state$),
    filter((mercenaryPayment) => mercenaryPayment > 0),
    switchMap((amount) => [
      spendCredits({ amount, reason: MERCENARY_SALARY_REASON }),
    ]),
  );

const nextMoveEpic: Epic<GameActions, TravelActions> = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf([actions.move, actions.startTravel])),
    mapState<AnyAction>(selectNextTravelAction)(state$),
    switchMap((action) =>
      of(action).pipe(
        delay(DELAY_BETWEEN_MOVES),
        map((action) => action),
      ),
    ),
  );

const repairOnMoveEpic: Epic<GameActions, ShipActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.move)),
    mapState(selectRepairPoints)(state$),
    map(repairOnTravel),
  );

const firstVisitNotificationEpic: Epic<GameActions, GameActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.arrive)),
    withLatestFrom(state$.pipe(map(selectCurrentSystem))),
    withLatestFrom(state$.pipe(map(selectCurrentSystemVisitsAmount))),
    map(flatten),
    filter(([, , visits]) => visits === 1),
    map(([, warpSystem]) =>
      addToast({
        header: `Welcome to ${getSystemName(warpSystem)}`,
        message: `This is your 1st visit`,
      }),
    ),
  );

const debtAlertOnArrive: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.arrive)),
    whenInState(selectIsTimeToRemindLoans)(state$),
    mapState(selectDebt)(state$),
    map((debt) =>
      addNotification({
        title: 'Reminder: you are in debt',
        message: `Your debt is ${debt} credits`,
      }),
    ),
  );

const highDebtAlertOnArrive: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.arrive)),
    whenInState(selectIsDebtHigh)(state$),
    mapTo(
      addNotification({
        title: 'You have a large debt',
        message:
          'You pay a big interest on your debt. When you are not able to pay an interest you have to loan even more money. When debt is too high, you are not able to travel anymore.',
      }),
    ),
  );

export const travelEpics = [
  warpEpic,
  singularityJumpEpic,
  payTravelExpensesEpic,
  repairOnMoveEpic,
  nextMoveEpic,
  firstVisitNotificationEpic,
  debtAlertOnArrive,
  highDebtAlertOnArrive,
];
