import { IonRow, IonCol, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemStatus } from '../../../../app/core/solar-system/system-status';
import { System } from '../../../../app/data/types/system/system';
import { getSystemName } from '../../../../app/ui/solar-system.labels';
import { selectCurrentDay } from '../../../../store/game/status/status.selectors';
import SystemInfoCard from './Info/SystemInfoCard';
import { getPoliticConfig } from '../../../../app/config/politic/get-politic-config';
import { getSystemUniqueId } from '../../../../app/core/solar-system/system-unique-id';
import {
  singularityJump,
  warp,
} from '../../../../store/game/travel/travel.actions';

type SystemRowProps = {
  system: System;
};

const SystemRow: React.FC<SystemRowProps> = ({ system }) => {
  const dispatch = useDispatch();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const currentDay = useSelector(selectCurrentDay);

  return (
    <IonRow>
      <IonCol>{getSystemUniqueId(system)}</IonCol>
      <IonCol>{getSystemName(system)}</IonCol>
      <IonCol>{system.special}</IonCol>
      <IonCol>{getPoliticConfig(system).bribeLevel}</IonCol>
      <IonCol>{getSystemStatus(currentDay, system)}</IonCol>
      <IonCol>
        <IonButton onClick={() => dispatch(singularityJump(system.nameIndex))}>
          Jump
        </IonButton>
        <IonButton onClick={() => setIsInfoOpen(true)}>Info</IonButton>
        <SystemInfoCard
          system={system}
          isOpen={isInfoOpen}
          onClose={() => setIsInfoOpen(false)}
        />
      </IonCol>
    </IonRow>
  );
};

export default SystemRow;
