import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { ERASE_RECORD } from '../../../../app/data/types/system/special-event/special-event-type';
import { converge } from 'ramda';
import { ERASE_RECORD_PRICE } from '../../../../app/data/constants/special-events';
import { invokeSpecialEvent } from '../special-events.actions';
import { selectAvailableCredits } from '../../../derived/computational-selectors';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectEraseRecordAction = converge<
  AnyAction,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, number>]
>(
  (policeRecordScore, availableCredits) => {
    const { title } = getSpecialEventConfig(ERASE_RECORD);
    if (policeRecordScore >= DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police score is not low, nothing to erase',
      });
    }
    if (availableCredits < ERASE_RECORD_PRICE) {
      return addNotification({
        title,
        message: `You need ${ERASE_RECORD_PRICE} of available credits`,
      });
    }
    return invokeSpecialEvent(ERASE_RECORD);
  },
  [selectPoliceRecordScore, selectAvailableCredits],
);
