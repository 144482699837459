export const TechLevel = [
  'Pre-agricultural',
  'Agricultural',
  'Medieval',
  'Renaissance',
  'Early Industrial',
  'Industrial',
  'Post-industrial',
  'Hi-tech',
];
