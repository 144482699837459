import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './scores.actions';
import { IScores } from '../../../app/data/types/game/scores';
import { defaultScores } from '../../../app/data/game';
import { add } from 'ramda';
import { reduceRecords } from './scores.selectors';

export type ScoresActions = ActionType<typeof actions>;

const initialScores = {
  ...defaultScores,
};

export const scoresReducer = createReducer<IScores, ScoresActions>(
  initialScores,
)
  .handleAction(actions.initScores, (_, action) => ({ ...action.payload }))
  .handleAction(actions.increaseReputation, (scores, action) => ({
    ...scores,
    reputationRecords: [
      ...scores.reputationRecords,
      { index: scores.reputationRecords.length, ...action.payload },
    ],
  }))
  .handleAction(actions.registerPoliceScoreChange, (scores, action) => ({
    ...scores,
    policeRecords: [
      ...scores.policeRecords,
      { index: scores.policeRecords.length, ...action.payload },
    ],
  }))
  .handleAction(actions.resetPoliceScore, (scores, action) => ({
    ...scores,
    policeRecords: [
      ...scores.policeRecords,
      {
        index: scores.policeRecords.length,
        ...action.payload,
        score: Math.abs(reduceRecords(scores.policeRecords)),
      },
    ],
  }));
