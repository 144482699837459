import { merge, of, pipe } from 'rxjs';
import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { withLatestFrom, map, filter, switchMap } from 'rxjs/operators';
import { GameActions, RootState } from '../..';
import * as actions from './ship.actions';
import {
  selectBaseEquipmentPrice,
  selectCurrentShipPrice,
  selectFuelCost,
  selectPlayer,
  selectPlayerCargo,
  selectPlayerCrew,
  selectPlayerShip,
  selectRandomSkillToIncrease,
  selectShipPrice,
} from './ship.selectors';
import {
  reduceAvailableTradeItemAmount,
  updateSystem,
} from '../systems/systems.actions';
import {
  earnCredits,
  removeBalanceChange,
  spendCredits,
} from '../balance/balance.actions';
import { getHullRepairCost } from '../../../app/core/ship/hull/hull-repair-cost';
import {
  buyShip,
  changeShip,
  increaseSkill,
  returnCargo,
} from './ship.actions';
import { CreateShip } from '../../../app/core/ship/create-ship';
import { prop } from 'ramda';
import { addNotification } from '../notifications/notifications.actions';
import { SKILL_INCREASE_PRICE } from '../../../app/data/constants/special-events';
import { mapState } from '../../operators/map-state';
import { SkillType } from '../../../app/data/types/crew-member/skill';
import { selectLastVisitedSystem } from '../travel/selectors/last-visited-system.selector';
import { selectCurrentSystem } from '../systems/selectors/current-system.selector';
import {
  BUY_CARGO_REASON,
  BUY_FUEL_REASON,
  BUY_SHIP_REASON,
  INCREASE_SKILL_REASON,
  REPAIR_REASON,
  SELL_CARGO_REASON,
  SELL_SHIP_REASON,
} from '../../../app/ui/change-balance-reasons';
import { selectCurrentDay } from '../status/status.selectors';
import { selectBalance } from '../balance/balance.selectors';
import { lostCargoOpponent } from '../travel/travel.actions';
import { addToast } from '../toasts/toasts.actions';
import { formatMoney } from '../../../app/ui/format-money';

const getCrewMemberOnBoardEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.getCrewMemberOnBoard)),
    withLatestFrom(state$),
    map(
      ([action, state]) =>
        [action.payload, selectCurrentSystem(state)] as const,
    ),
    map(([person, system]) => {
      return updateSystem({
        systemIndex: system.nameIndex,
        systemData: {
          ...system,
          mercenaries: system.mercenaries.filter(
            (member) => member.name !== person.name,
          ),
        },
      });
    }),
  );

const dismissCrewMemberOnBoardEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.dismissCrewMember)),
    withLatestFrom(state$),
    map(
      ([action, state]) =>
        [action.payload, selectCurrentSystem(state)] as const,
    ),
    map(([person, system]) => {
      return updateSystem({
        systemIndex: system.nameIndex,
        systemData: {
          ...system,
          mercenaries: [...system.mercenaries, person],
        },
      });
    }),
  );

const buyFuelEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.buyFuel)),
    withLatestFrom(state$),
    map(
      ([action, state]) =>
        [action.payload, selectFuelCost(action.payload)(state)] as const,
    ),
    switchMap(([fuelAmount, price]) => {
      return of(
        actions.fillFuel(fuelAmount),
        spendCredits({ amount: price, reason: BUY_FUEL_REASON }),
      );
    }),
  );

const buyRepairEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.buyRepair)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const repairedHull = action.payload;
      const ship = selectPlayerShip(state);
      const price = getHullRepairCost(repairedHull)(ship);
      return of(
        actions.repair(repairedHull),
        spendCredits({ amount: price, reason: REPAIR_REASON }),
      );
    }),
  );

const buyCargoItemEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.buyCargo)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const purchasedItem = action.payload;
      const currentSystemIndex = selectLastVisitedSystem(state);
      const { quantity, itemPrice, type } = purchasedItem;
      const price = quantity * itemPrice;
      return of(
        reduceAvailableTradeItemAmount({
          systemIndex: currentSystemIndex,
          tradeItemType: purchasedItem.type,
          amount: purchasedItem.quantity,
        }),
        spendCredits({
          amount: price,
          reason: `${BUY_CARGO_REASON}: ${quantity} ${type} x ${formatMoney(
            itemPrice,
          )} cr.`,
        }),
      );
    }),
  );

const cancelPurchaseEpic: Epic<GameActions, GameActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.cancelPurchase)),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const day = selectCurrentDay(state);
      return [
        ...selectBalance(state)
          .filter(
            (item) => item.day === day && item.reason === BUY_CARGO_REASON,
          )
          .map(pipe(prop('id'), removeBalanceChange)),
        returnCargo(day),
      ];
    }),
  );

const buyEquipmentEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([actions.buyGadget, actions.buyWeapon, actions.buyShield]),
    ),
    withLatestFrom(state$),
    map(([action, state]) =>
      selectBaseEquipmentPrice(action.payload.type)(state),
    ),
    map((amount) => spendCredits({ amount, reason: 'buy equipment' })),
  );

const buyShipEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(buyShip)),
    map(prop('payload')),
    withLatestFrom(state$),
    map(
      ([shipType, state]) =>
        [
          shipType,
          selectCurrentShipPrice(state),
          selectShipPrice(shipType)(state),
          selectPlayerCrew(state),
        ] as const,
    ),
    switchMap(([shipType, currentShipPrice, shipPrice, crew]) => [
      spendCredits({ amount: shipPrice, reason: BUY_SHIP_REASON }),
      earnCredits({ amount: currentShipPrice, reason: SELL_SHIP_REASON }),
      changeShip({
        ...CreateShip(shipType),
        crew: [...crew],
      }),
    ]),
  );

const increaseRandomSkillEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.increaseRandomSkill)),
    mapState(selectRandomSkillToIncrease)(state$),
    filter((skill): skill is SkillType => !!skill),
    switchMap((skill) => {
      return [
        increaseSkill(skill),
        spendCredits({
          amount: SKILL_INCREASE_PRICE,
          reason: INCREASE_SKILL_REASON(skill),
        }),
        addNotification({
          title: 'Increased skill',
          message: `You got your ${skill} skill increased`,
        }),
      ];
    }),
  );

const notifyAboutNewMemberOnBoard: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getCrewMemberOnBoard)),
    map(({ payload }) => payload),
    map((crewMember) =>
      addNotification({
        title: 'New crew member',
        message: `You get ${crewMember.name} on board`,
      }),
    ),
  );

const opponentLostCargoWhenPlayerPlunder: Epic<GameActions, GameActions> = (
  action$,
) =>
  action$.pipe(
    filter(isActionOf(actions.plunderCargo)),
    map((action) => lostCargoOpponent(action.payload)),
  );

const notifyAboutPlunderCargo: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.plunderCargo)),
    map(({ payload }) =>
      addToast({
        header: 'Plunder opponent cargo',
        message: `You have got ${payload.quantity} ${payload.type} units`,
      }),
    ),
  );

export const shipEpics = [
  buyEquipmentEpic,
  buyFuelEpic,
  buyRepairEpic,
  buyCargoItemEpic,
  getCrewMemberOnBoardEpic,
  dismissCrewMemberOnBoardEpic,
  buyShipEpic,
  increaseRandomSkillEpic,
  notifyAboutNewMemberOnBoard,
  cancelPurchaseEpic,
  opponentLostCargoWhenPlayerPlunder,
  notifyAboutPlunderCargo,
];
