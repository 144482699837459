import { System } from '../../data/types/system/system';
import { politics } from '../../data/types/politic/politic-type';
import { getSeedRandom } from '../../utils';
import { getSystemUniqueId } from './system-unique-id';
import memoize from 'fast-memoize';

export const getSystemPolitics = memoize((system: System) => {
  const politicIndex = getSeedRandom(getSystemUniqueId(system))(
    0,
    politics.length - 1,
  );
  return politics[politicIndex];
});
