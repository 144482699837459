import { getRandom } from '../../../utils';
import { System } from '../../../data/types/system/system';
import { NORMAL } from '../../../data/constants/difficulty';
import { IShip } from '../../../data/types/ship/ship';
import { ShipType, MANTIS_SHIP } from '../../../data/types/ship/ship-type';
import { STRONGEST_SHIP } from '../../../data/constants/ship-types';
import {
  ActorType,
  MANTIS,
  PIRATE,
  POLICE,
  TRADER,
} from '../../../data/interaction/actor-type';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { getPoliticConfig } from '../../../config/politic/get-politic-config';
import { getTries } from './get-tries';

export const getOpponentShipType = (
  difficulty: number,
  playerCurrentWorth: number,
  policeRecordScore: number,
) => (
  opponentType: ActorType,
  warpSystem: System,
  playerShip: IShip,
): ShipType => {
  if (opponentType === MANTIS) {
    return MANTIS_SHIP;
  }

  const politicConfig = getPoliticConfig(warpSystem);
  const shipTypes = Object.keys(shipConfigs) as ShipType[];
  let i: number;
  let Redo: boolean;
  const strengthFactor = difficulty >= NORMAL ? difficulty - NORMAL : 0;
  let j = 0;
  let type = opponentType === TRADER ? shipTypes[0] : shipTypes[1];
  const tries = getTries(
    policeRecordScore,
    difficulty,
    playerCurrentWorth,
  )(opponentType, playerShip);

  while (j < tries) {
    Redo = true;
    i = 0;

    while (Redo) {
      const d = getRandom(0, 100);
      let sum = shipConfigs[shipTypes[0]].occurrence;
      i = 0;

      while (sum < d) {
        if (i >= Object.keys(shipConfigs).indexOf(STRONGEST_SHIP)) {
          break;
        }
        i += 1;
        sum += shipConfigs[shipTypes[i]].occurrence;
      }

      if (
        opponentType === POLICE &&
        (shipConfigs[shipTypes[i]].police < 0 ||
          politicConfig.strengthPolice + strengthFactor <
            shipConfigs[shipTypes[i]].police)
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (
        opponentType === PIRATE &&
        (shipConfigs[shipTypes[i]].pirates < 0 ||
          politicConfig.strengthPirates + strengthFactor <
            shipConfigs[shipTypes[i]].pirates)
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (
        opponentType === TRADER &&
        (shipConfigs[shipTypes[i]].traders < 0 ||
          politicConfig.strengthTraders + strengthFactor <
            shipConfigs[shipTypes[i]].traders)
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      Redo = false;
    }

    if (i > shipTypes.indexOf(type)) {
      type = shipTypes[i];
    }
    j += 1;
  }
  return type;
};
