import {
  IonBadge,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonNote,
} from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { parseGameId } from '../utils';
import { selectCurrentBalance } from '../../../store/game/balance/balance.selectors';
import {
  selectPoliceRecordScore,
  selectReputationScore,
} from '../../../store/game/scores/scores.selectors';
import { selectCurrentDay } from '../../../store/game/status/status.selectors';
import { PlayerCard } from '../../PlayerCard/PlayerCard';
import { selectPlayerShip } from '../../../store/game/ship/ship.selectors';
import { getShipImage } from '../../../app/ui/ship.img';
import { pipe } from 'rxjs';

const SavedGame: React.FC<{ id: string }> = ({ id }) => {
  const gameInfo = parseGameId(id);
  const currentId = localStorage.getItem('current');
  const gameState = JSON.parse(localStorage.getItem(`persist:${id}`) || '');
  const game = JSON.parse(gameState.game);
  const credits = selectCurrentBalance({ game });
  const reputationScore = selectReputationScore({ game });
  const policeScore = selectPoliceRecordScore({ game });
  const day = selectCurrentDay({ game });
  const ship = selectPlayerShip({ game });
  const history = useHistory();

  function remove() {
    localStorage.removeItem(`persist:${id}`);
    window.location.reload();
  }

  function openGame() {
    history.push(`/game/${id}`);
    window.location.reload();
  }

  return (
    <IonItemSliding>
      <IonItem
        style={{ position: 'relative' }}
        button
        detail
        onClick={openGame}>
        <PlayerCard
          playerName={gameInfo.playerName}
          day={day}
          difficulty={gameInfo.difficulty}
          credits={credits}
          reputationScore={reputationScore}
          policeScore={policeScore}
          ship={ship}
        />
        {id === currentId && (
          <IonNote style={{ position: 'absolute', top: 0, right: 0 }}>
            <IonBadge>Current</IonBadge>
          </IonNote>
        )}
      </IonItem>

      <IonItemOptions side="end">
        <IonItemOption onClick={remove}>Remove</IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default SavedGame;
