export const Activity = [
  'Absent', // 0
  'Minimal', // 1
  'Few', // 2
  'Some', // 3
  'Moderate', // 4
  'Many', // 5
  'Abundant', // 6
  'Swarms', // 7
];
