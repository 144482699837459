export const mercenaryNames = [
  'Alyssa',
  'Armatur',
  'Bentos',
  'C2U2',
  "Chi'Ti",
  'Crystal',
  'Dane',
  'Deirdre',
  'Doc',
  'Draco',
  'Iranda',
  'Jeremiah',
  'Jujubal',
  'Krydon',
  'Luis',
  'Mercedez',
  'Milete',
  'Muri-L',
  'Mystyc',
  'Nandi',
  'Orestes',
  'Pancho',
  'PS37',
  'Quarck',
  'Sosumi',
  'Uma',
  'Wesley',
  'Wonton',
  'Yorvick',
];

export const DEFAULT_PLAYER_NAME = 'Jameson';
export const PRINCESS_NAME = 'Zeethibal';

export const AMBASSADOR_JAREK = 'Ambassador Jarek';
export const JONATHAN_WILD = 'Jonathan Wild';

export const specialMembers = [AMBASSADOR_JAREK, JONATHAN_WILD];
