import { Epic } from 'redux-observable';
import { filter, mapTo, switchMapTo } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../..';
import { DALEDSYSTEM } from '../../../app/data/constants/solar-systems';
import { makeCrewMember } from '../../../app/factories/crew-member';
import { EXPERIMENT_NOT_STOPPED } from '../../../app/data/types/system/special-event/special-event-type';
import {
  getCrewMemberOnBoard,
  getPersonalEquipment,
  increaseRandomSkill,
  specialCrewMemberLeaves,
} from '../ship/ship.actions';
import { setSpecialEvent } from '../systems/systems.actions';
import { fabricRipped } from '../status/status.actions';
import * as actions from './quest-status.actions';
import {
  selectIsExperimentPerformed,
  selectIsExperimentStopped,
} from './quest-status.selectors';
import {
  DISCOUNT_COMPUTER,
  PORTABLE_SINGULARITY,
} from '../../../app/data/types/crew-member/personal-equipment';
import { whenInState } from '../../operators/when-in-state';
import { AMBASSADOR_JAREK } from '../../../app/data/labels/mercenaries';
import { earnCredits } from '../balance/balance.actions';
import { ARTIFACT_DELIVERY_REWARD } from '../../../app/data/constants/special-events';
import { ARTIFACT_DELIVERY_REASON } from '../../../app/ui/change-balance-reasons';

const deliverMedicineEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.deliverMedicine)),
    switchMapTo([increaseRandomSkill(), increaseRandomSkill()]),
  );

const getJarekEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getJarek)),
    mapTo(getCrewMemberOnBoard(makeCrewMember(AMBASSADOR_JAREK))),
  );

const deliverJarekEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.deliverJarek)),
    switchMapTo([
      getPersonalEquipment(DISCOUNT_COMPUTER),
      specialCrewMemberLeaves(AMBASSADOR_JAREK),
    ]),
  );

const performOngoingExperimentEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.experimentOngoing)),
    whenInState(selectIsExperimentPerformed)(state$),
    mapTo(actions.experimentPerformed()),
  );

const stopOngoingExperimentEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.experimentOngoing)),
    whenInState(selectIsExperimentStopped)(state$),
    mapTo(actions.experimentStopped()),
  );

const experimentPerformedEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.experimentPerformed)),
    // TODO: event is not set, overwritten by travel epic (update systems)
    switchMapTo([
      fabricRipped(),
      setSpecialEvent({
        systemIndex: DALEDSYSTEM,
        event: EXPERIMENT_NOT_STOPPED,
      }),
    ]),
  );

const experimentStoppedEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.experimentStopped)),
    mapTo(getPersonalEquipment(PORTABLE_SINGULARITY)),
  );

const artifactDeliveryEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.returnArtifact)),
    mapTo(
      earnCredits({
        amount: ARTIFACT_DELIVERY_REWARD,
        reason: ARTIFACT_DELIVERY_REASON,
      }),
    ),
  );

export const questsEpics = [
  deliverMedicineEpic,
  getJarekEpic,
  deliverJarekEpic,
  performOngoingExperimentEpic,
  stopOngoingExperimentEpic,
  experimentPerformedEpic,
  experimentStoppedEpic,
  artifactDeliveryEpic,
];
