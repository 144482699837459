import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  IonCardContent,
  IonCard,
  IonItem,
  IonText,
  IonList,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import {
  getSystemName,
  getSystemStatusInfo,
} from '../../../../app/ui/solar-system.labels';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';
import { InfoTab } from '../../debug/Systems/Info/InfoTab';
import { selectCurrentDay } from '../../../../store/game/status/status.selectors';

const CurrentSystemCard: React.FC = () => {
  const currentSystem = useSelector(selectCurrentSystem, shallowEqual);

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>This is {getSystemName(currentSystem)}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <InfoTab system={currentSystem} />
      </IonCardContent>
    </IonCard>
  );
};

export default CurrentSystemCard;
