import { useSelector } from 'react-redux';
import { ShipType } from '../../../app/data/types/ship/ship-type';
import {
  selectShipPrice,
  selectCurrentShipPrice,
  selectIsEnoughSpaceForCrew,
} from '../../../store/game/ship/ship.selectors';
import { selectIsPlayerInDebt } from '../../../store/game/status/status.selectors';
import { addNotification } from '../../../store/game/notifications/notifications.actions';
import { addConfirmation } from '../../../store/game/confirmations/confirmations.actions';
import { buyShip } from '../../../store/game/ship/ship.actions';
import { selectIsEnoughMoney } from '../../../store/derived/computational-selectors';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export const useBuyShipAlert = (shipType: ShipType) => {
  const hasDebt = useSelector(selectIsPlayerInDebt);
  const shipPrice = useTypedSelector(selectShipPrice(shipType));
  const playerShipPrice = useSelector(selectCurrentShipPrice);
  const priceDifference = shipPrice - playerShipPrice;
  const isEnoughMoney = useSelector(selectIsEnoughMoney(priceDifference));
  const isEnoughSpaceForCrew = useSelector(
    selectIsEnoughSpaceForCrew(shipType),
  );

  if (hasDebt && shipPrice - playerShipPrice > 0) {
    return addNotification({
      title: 'You are in debt',
      message: 'You can not pay for another ship while you have a debt',
    });
  }

  if (!isEnoughMoney) {
    return addNotification({
      title: 'Not enough money',
      message: `Your current balance is not enough to buy ${shipType} ship`,
    });
  }

  if (!isEnoughSpaceForCrew) {
    return addNotification({
      title: 'Not enough cabins',
      message: `Your current crew can not accomodate on ${shipType} ship`,
    });
  }

  return addConfirmation({
    title: `Buy ${shipType}`,
    message: `Do you want to buy ${shipType} and spend ${priceDifference} credits for it?`,
    action: buyShip(shipType),
  });
};
