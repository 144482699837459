import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './travel.actions';
import { StartTravelPayload } from './travel.actions';
import { Entities, lastId, newId, updateEntity } from '../../entities';
import { Encounter } from '../../../app/data/interaction/encounter';
import { SHIELDS } from '../../../app/config/equipment.configs';
import { damageShield } from '../../../app/core/encounter/fight/damage';
import {
  damageShipReducer,
  damageShipShieldReducer,
  lostCargoReducer,
  plunderCargoReducer,
} from '../ship/damageable.reducer';

export interface Travel {
  id: number;
  clicks: number;
  encounters: Entities<Encounter>;
  systemIndex: number;
  viaWormhole: boolean;
}

const initialTravelState = {};

function makeTravel(
  id: number,
  { clicks, systemIndex, viaWormhole }: StartTravelPayload,
): Travel {
  return {
    id,
    clicks,
    encounters: {},
    systemIndex,
    viaWormhole,
  };
}

export type TravelActions = ActionType<typeof actions>;

export const travelsReducer = createReducer<Entities<Travel>, TravelActions>(
  initialTravelState,
)
  .handleAction(actions.startTravel, (entities, action) => ({
    ...entities,
    [newId(entities)]: makeTravel(newId(entities), action.payload),
  }))
  .handleAction(actions.move, (entities) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      clicks: currentTravel.clicks - 1,
    })),
  )
  .handleAction(actions.arrive, (entities) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      clicks: 0,
    })),
  )

  .handleAction(actions.initEncounter, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        () => ({
          ...action.payload,
          click: currentTravel.clicks,
          isPlayerTurn: false,
          log: {},
          damageLog: {},
          accepted: false,
        }),
      ),
    })),
  )
  .handleAction(actions.acceptEncounter, (entities) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          accepted: true,
        }),
      ),
    })),
  )
  .handleAction(actions.declineEncounter, (entities) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          accepted: false,
        }),
      ),
    })),
  )
  .handleAction(actions.encounterEvent, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          log: {
            ...encounter.log,
            [newId(encounter.log)]: {
              id: newId(encounter.log),
              ...action.payload,
            },
          },
        }),
      ),
    })),
  )
  .handleAction(actions.damageEvent, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          damageLog: {
            ...encounter.damageLog,
            [newId(encounter.damageLog)]: {
              id: newId(encounter.damageLog),
              ...action.payload,
            },
          },
        }),
      ),
    })),
  )
  .handleAction(actions.changeEncounterTurn, (entities) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          isPlayerTurn: !encounter.isPlayerTurn,
        }),
      ),
    })),
  )
  .handleAction(actions.damageOpponentShipHull, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          ship: damageShipReducer(encounter.ship, action.payload),
        }),
      ),
    })),
  )
  .handleAction(actions.damageOpponentShipShields, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          ship: damageShipShieldReducer(encounter.ship, action.payload),
        }),
      ),
    })),
  )
  .handleAction(actions.lostCargoOpponent, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          ship: lostCargoReducer(encounter.ship, action.payload),
        }),
      ),
    })),
  )
  .handleAction(actions.plunderCargoOpponent, (entities, action) =>
    updateEntity(entities, lastId(entities).toString(), (currentTravel) => ({
      ...currentTravel,
      encounters: updateEntity(
        currentTravel.encounters,
        currentTravel.clicks,
        (encounter) => ({
          ...encounter,
          ship: plunderCargoReducer(encounter.ship, action.payload),
        }),
      ),
    })),
  )

  .handleAction(actions.playerRaided, (entities) => ({
    ...entities,
    [lastId(entities)]: {
      ...entities[lastId(entities)],
      raided: true,
    },
  }))
  .handleAction(actions.playerInspected, (entities) => ({
    ...entities,
    [lastId(entities)]: {
      ...entities[lastId(entities)],
      inspected: true,
    },
  }));
