import { converge } from 'ramda';
import { getBlackmailAmount } from '../../../../../app/core/encounter/values/blackmail';
import { EncounterActionType } from '../../../../../app/data/interaction/action-type';
import { selectReputationScore } from '../../../scores/scores.selectors';
import { selectPlayerShip } from '../../../ship/ship.selectors';
import { selectDifficulty } from '../../../status/status.selectors';
import { selectOpponentShip } from '../../encounter.selectors';
import { selectPlayerWorth } from '../../../../derived/computational-selectors';
import { getInitialPirateAction } from '../../../../../app/core/encounter/reactions/pirate/initial.reaction';
import { getPirateAttackReaction } from '../../../../../app/core/encounter/reactions/pirate/attack.reaction';
import { getPirateSurrenderReaction } from '../../../../../app/core/encounter/reactions/pirate/surrender.reaction';
import { selectOpponentEvents } from '../../selectors/encounter-events.selector';
import { selectDamageLog } from '../../selectors/damage-log.selectors';
import { mapEntitiesToArray } from '../../../../entities';
import { pipe } from 'rxjs';
import { RootState } from '../../../../index';

export const selectPirateSurrenderReaction: (
  state: RootState,
) => EncounterActionType = converge(getPirateSurrenderReaction, [
  selectPlayerShip,
]);

export const selectPirateAttackReaction: (
  state: RootState,
) => EncounterActionType = converge(getPirateAttackReaction, [
  selectOpponentEvents,
  pipe(selectDamageLog, mapEntitiesToArray),
  selectOpponentShip,
  selectPlayerShip,
]);

export const selectInitialPirateAction: (
  state: RootState,
) => EncounterActionType = converge(getInitialPirateAction, [
  selectDifficulty,
  selectReputationScore,
  selectOpponentShip,
  selectPlayerShip,
]);

export const selectBlackmailAmount: (
  state: RootState,
) => number = converge(getBlackmailAmount, [selectPlayerWorth]);
