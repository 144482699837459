import { BEGINNER } from '../../../data/constants/difficulty';
import {
  getRandom,
  getTotalWeaponsPower,
  getWeaponsPower,
} from '../../../utils';
import { IShip } from '../../../data/types/ship/ship';
import { SCARAB_SHIP } from '../../../data/types/ship/ship-type';
import {
  MORGAN_LASER_WEAPON,
  PULSE_LASER_WEAPON,
} from '../../../data/types/ship/weapon/weapon-type';
import {
  ENGINEER_SKILL,
  FIGHTER_SKILL,
  PILOT_SKILL,
} from '../../../data/types/crew-member/skill';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { getSkillLevel } from '../../crew-member/skill/skill-level';

// *************************************************************************
// An attack: Attacker attacks Defender
// It returns points of damage made
// *************************************************************************
export const ExecuteAttack = (
  difficulty: number,
  Attacker: IShip,
  Defender: IShip,
  isDefenderFlyingAway: boolean,
  CommanderUnderAttack: boolean,
): number => {
  // On beginner level, if you flee, you will escape unharmed.
  if (difficulty === BEGINNER && CommanderUnderAttack && isDefenderFlyingAway) {
    return 0;
  }

  // Fighter skill attacker is pitted against pilot skill defender;
  // if defender is fleeing, the attacker has a free shot, but the chance to hit is smaller
  if (
    getRandom(
      0,
      getSkillLevel(FIGHTER_SKILL)(Attacker, difficulty) +
        shipConfigs[Defender.type].size,
    ) <
    (isDefenderFlyingAway ? 2 : 1) *
      getRandom(0, 5 + (getSkillLevel(PILOT_SKILL)(Defender, difficulty) >> 1))
  ) {
    // Misses
    return 0;
  }

  // if we attack scarab, only certain weapons are suitable
  const weaponPower =
    Defender.type === SCARAB_SHIP
      ? getWeaponsPower(Attacker, [MORGAN_LASER_WEAPON, PULSE_LASER_WEAPON])
      : getTotalWeaponsPower(Attacker);

  // if attacker does not heave weapon power, he can not make any damage
  if (weaponPower <= 0) {
    return 0;
  }

  return getRandom(
    0,
    (weaponPower *
      (100 + 2 * getSkillLevel(ENGINEER_SKILL)(Attacker, difficulty))) /
      100,
  );
  // TODO: we can get rid of questStatus if we keep info about reactor inside ship itself
  // * getDamageBoost(questStatus, difficulty)(CommanderUnderAttack);
};
