import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPlayerShip } from '../../../../store/game/ship/ship.selectors';
import ActionsBoard from './ActionsBoard';
import OpponentCard from './OpponentCard';
import { move } from '../../../../store/game/travel/travel.actions';
import { GameActions } from '../../../../store';
import { selectIsPlayerDead } from '../../../../store/game/encounter/selectors/is-dead.selectors';
import {
  getLastEvent,
  isEventOfActor,
  isGameOver,
} from '../../../../app/core/encounter/interaction';
import { selectCurrentSystemName } from '../../../../store/game/systems/selectors/current-system.selector';
import { selectCurrentEncounter } from '../../../../store/game/travel/selectors/current-encounter.selector';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Encounter.css';
import {
  mapArrayToEntities,
  mapEntitiesToArray,
} from '../../../../store/entities';

type EncounterFormProps = {
  isOpen: boolean;
  onFinish: (action: GameActions) => void;
};

const EncounterForm: React.FC<EncounterFormProps> = ({ onFinish, isOpen }) => {
  const encounter = useSelector(selectCurrentEncounter);
  const systemName = useSelector(selectCurrentSystemName);
  const type = encounter.type;
  const opponentShip = encounter.ship;
  const encounterLog = encounter.log;
  const damageLog = encounter.damageLog;
  const isPlayerMove = encounter.isPlayerTurn;
  const click = encounter.click;

  const playerShip = useSelector(selectPlayerShip);
  const isPlayerDead = useSelector(selectIsPlayerDead);
  const gameOver = isGameOver(encounterLog);

  const lastOpponentEvent = getLastEvent(
    mapArrayToEntities(
      mapEntitiesToArray(encounterLog).filter(isEventOfActor(encounter.type)),
      (i) => i.id.toString(),
    ),
  ).action;

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {click} clicks from {systemName}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol size="6">
              <OpponentCard
                active={isPlayerMove}
                actor="Player"
                ship={playerShip}
              />
            </IonCol>
            <IonCol size="6">
              <OpponentCard
                active={!isPlayerMove}
                actor={type}
                ship={opponentShip}
                action={lastOpponentEvent}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="end">
            {!gameOver && isPlayerMove && <ActionsBoard />}
            {gameOver && !isPlayerDead && (
              <IonButton onClick={() => onFinish(move())}>Move</IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default EncounterForm;
