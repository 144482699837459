import { TradeItemType } from '../app/data/types/trade-item/trade-item-type';
import { useSelector } from 'react-redux';
import {
  selectFinalBuyPrice,
  selectTradeItemQuantity,
} from '../store/derived/computational-selectors';

export enum TradeItemStatus {
  AvailableToBuy = 'Available to buy',
  NoItemsToBuy = 'No items to buy',
  NotInProduction = 'Not in production',
}

export function getStatusColor(status: TradeItemStatus) {
  if (status === TradeItemStatus.AvailableToBuy) {
    return 'success';
  }
  return 'danger';
}

function getStatus(quantity: number, priceToBuy: number): TradeItemStatus {
  if (priceToBuy <= 0) {
    return TradeItemStatus.NotInProduction;
  }
  if (quantity <= 0) {
    return TradeItemStatus.NoItemsToBuy;
  }
  return TradeItemStatus.AvailableToBuy;
}

export const useTradeItemStatus = (tradeItemType: TradeItemType) => {
  const priceToBuy = useSelector(selectFinalBuyPrice(tradeItemType));
  const tradeItemQuantity = useSelector(selectTradeItemQuantity(tradeItemType));
  const isAvailable = priceToBuy > 0 && tradeItemQuantity > 0;
  const status = getStatus(tradeItemQuantity, priceToBuy);
  return [isAvailable, status] as const;
};
