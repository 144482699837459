import React from 'react';
import { IonItem, IonButton } from '@ionic/react';
import {
  ShipEquipmentItem,
  EquipmentItemType,
} from '../../../app/data/types/ship/equipment/equipment';
import useSellEquipment from '../../../hooks/useSellEquipment';

type ShipEquipmentItemProps = {
  equipmentItem: ShipEquipmentItem;
};

const ShipEquipmentItemCard: React.FC<ShipEquipmentItemProps> = ({
  equipmentItem,
}) => {
  const [price, doSell] = useSellEquipment(
    equipmentItem.type as EquipmentItemType,
    equipmentItem.id,
  );

  return (
    <IonItem>
      {equipmentItem.type}
      <IonButton onClick={doSell} slot="end">
        Sell for&nbsp;
        {price}
      </IonButton>
    </IonItem>
  );
};

export default ShipEquipmentItemCard;
