import { createAction } from 'typesafe-actions';
import * as actionTypes from './ship.action-types';
import { IShip } from '../../../app/data/types/ship/ship';
import { PurchasedItem } from '../../../app/data/types/trade-item/purchased-item';
import { TradeItemType } from '../../../app/data/types/trade-item/trade-item-type';
import { IGadget } from '../../../app/data/types/ship/gadget/gadget';
import { IWeapon } from '../../../app/data/types/ship/weapon/weapon';
import { IShield } from '../../../app/data/types/ship/shield/shield';
import { SkillType } from '../../../app/data/types/crew-member/skill';
import { CrewMember } from '../../../app/data/types/crew-member/crew-member';
import { ShipType } from '../../../app/data/types/ship/ship-type';
import { PersonalEquipment } from '../../../app/data/types/crew-member/personal-equipment';

export const initShip = createAction(actionTypes.INIT_SHIP)<IShip>();
export const changeShip = createAction(actionTypes.CHANGE_SHIP)<IShip>();
export const buyShip = createAction(actionTypes.BUY_SHIP)<ShipType>();
export const buyFuel = createAction(actionTypes.BUY_FUEL)<number>();
export const fillFuel = createAction(actionTypes.FILL_FUEL)<number>();
export const spendFuel = createAction(actionTypes.SPEND_FUEL)<number>();
export const repair = createAction(actionTypes.REPAIR_PLAYER_SHIP)<number>();
export const buyRepair = createAction(actionTypes.BUY_REPAIR)<number>();
export const repairOnTravel = createAction(
  actionTypes.REPAIR_PLAYER_SHIP_ON_TRAVEL,
)<readonly [number, number]>();

export const damageShipHull = createAction(actionTypes.DAMAGE_SHIP_HULL)<
  number
>();
export const damageShipShields = createAction(actionTypes.DAMAGE_SHIP_SHIELDS)<
  number
>();

export const buyCargo = createAction(actionTypes.BUY_CARGO)<PurchasedItem>();
export const sellCargo = createAction(actionTypes.SELL_CARGO)<TradeItemType>();
export const cancelPurchase = createAction(actionTypes.CANCEL_PURCHASE)();
export const returnCargo = createAction(actionTypes.RETURN_CARGO)<number>(); // today day in payload
export const confiscateCargo = createAction(actionTypes.CONFISCATE_CARGO)<
  TradeItemType
>();

export const lostCargo = createAction(actionTypes.LOST_CARGO)<PurchasedItem>();
export const plunderCargo = createAction(actionTypes.PLUNDER_CARGO)<
  PurchasedItem
>();

export const buyGadget = createAction(actionTypes.BUY_GADGET)<IGadget>();
export const buyWeapon = createAction(actionTypes.BUY_WEAPON)<IWeapon>();
export const buyShield = createAction(actionTypes.BUY_SHIELD)<IShield>();
export const sellGadget = createAction(actionTypes.SELL_GADGET)<string>();
export const sellWeapon = createAction(actionTypes.SELL_WEAPON)<string>();
export const sellShield = createAction(actionTypes.SELL_SHIELD)<string>();
export const increaseRandomSkill = createAction(
  actionTypes.INCREASE_RANDOM_SKILL,
)();
export const increaseSkill = createAction(actionTypes.INCREASE_SKILL)<
  SkillType
>();
export const decreaseSkill = createAction(actionTypes.DECREASE_SKILL)<
  SkillType
>();

export const getCrewMemberOnBoard = createAction(
  actionTypes.GET_CREW_MEMBER_ONBOARD,
)<CrewMember>();
export const dismissCrewMember = createAction(actionTypes.DISMISS_CREW_MEMBER)<
  CrewMember
>();
export const specialCrewMemberLeaves = createAction(
  actionTypes.SPECIAL_CREW_MEMBER_LEAVES,
)<string>();

export const getPersonalEquipment = createAction(
  actionTypes.GET_PERSONAL_EQUIPMENT,
)<PersonalEquipment>();

export const getTribble = createAction(actionTypes.GET_TRIBBLE)();
export const tribblesSold = createAction(actionTypes.TRIBBLES_SOLD)();
