import { System } from '../../../../../app/data/types/system/system';
import React from 'react';
import useDoWarp from '../../../../../hooks/useDoWarp';
import {
  IonButton,
  IonCardHeader,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { AutoIgnoreToggle } from './AutoIgnoreToggle';
import {
  PIRATE,
  POLICE,
  TRADER,
} from '../../../../../app/data/interaction/actor-type';
import { useDispatch } from 'react-redux';
import { getSystemName } from '../../../../../app/ui/solar-system.labels';

export const WarpTab: React.FC<{ system: System; onWarp: () => void }> = ({
  system,
  onWarp,
}) => {
  const dispatch = useDispatch();
  const warpAction = useDoWarp(system.nameIndex);

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>Travel to {getSystemName(system)}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <AutoIgnoreToggle actorType={POLICE} />
            </IonCol>
            <IonCol>
              <AutoIgnoreToggle actorType={PIRATE} />
            </IonCol>
            <IonCol>
              <AutoIgnoreToggle actorType={TRADER} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButton
          expand="block"
          onClick={() => {
            dispatch(warpAction);
            onWarp();
          }}>
          Warp
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};
