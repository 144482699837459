import { IShip } from '../../../data/types/ship/ship';
import { IStatus } from '../../../data/types/game/status';
import {
  DRAGONFLY_SHIP,
  SCARAB_SHIP,
  SPACE_MONSTER_SHIP,
} from '../../../data/types/ship/ship-type';
import { GADGETS, SHIELDS, WEAPONS } from '../../../config/equipment.configs';
import {
  makeGadget,
  makeShield,
  makeWeapon,
} from '../../../factories/ship-equipment';
import {
  MILITARY_LASER_WEAPON,
  PULSE_LASER_WEAPON,
} from '../../../data/types/ship/weapon/weapon-type';
import { makeCrewMember } from '../../../factories/crew-member';
import {
  ENGINEER_SKILL,
  FIGHTER_SKILL,
  PILOT_SKILL,
  TRADER_SKILL,
} from '../../../data/types/crew-member/skill';
import {
  LIGHTNING_SHIELD,
  REFLECTIVE_SHIELD,
} from '../../../data/types/ship/shield/shield-type';
import { shieldConfigs } from '../../../config/shield/shield.configs';
import {
  AUTO_REPAIR_SYSTEM,
  NAVIGATING_SYSTEM,
  TARGETING_SYSTEM,
} from '../../../data/types/ship/gadget/gadget-type';
import { MAXSKILL } from '../../../data/constants';
import { defaultOpponent } from '../../../data/ships';
import { STRONGEST_SHIP } from '../../../data/constants/ship-types';
import makeArray from '../../../utils/make-array';
import { IShield } from '../../../data/types/ship/shield/shield';
import { shipConfigs } from '../../../config/ship/ship.configs';

export const getFamousCaptainShip = (): IShip => {
  return {
    ...defaultOpponent,
    type: STRONGEST_SHIP,
    equipment: {
      [SHIELDS]: makeArray(
        shipConfigs[STRONGEST_SHIP].equipmentSlots[SHIELDS],
        () =>
          makeShield(REFLECTIVE_SHIELD, shieldConfigs[REFLECTIVE_SHIELD].power),
      ),
      [WEAPONS]: makeArray(
        shipConfigs[STRONGEST_SHIP].equipmentSlots[WEAPONS],
        () => makeWeapon(MILITARY_LASER_WEAPON),
      ),
      [GADGETS]: [makeGadget(TARGETING_SYSTEM), makeGadget(NAVIGATING_SYSTEM)],
    },
    hull: shipConfigs[STRONGEST_SHIP].hullStrength,
    commander: makeCrewMember('Captain', {
      [PILOT_SKILL]: MAXSKILL,
      [FIGHTER_SKILL]: MAXSKILL,
      [TRADER_SKILL]: MAXSKILL,
      [ENGINEER_SKILL]: MAXSKILL,
    }),
  };
};

// behaviour for all of them is the same

// if (isShipHiddenForOpponent(difficulty)(ship, opponent)) {
//   return IGNORE;
// }
// return ATTACK;

export function getSpaceMonsterShip(
  difficulty: number,
  status: IStatus,
): IShip {
  return {
    type: SPACE_MONSTER_SHIP,
    cargo: [],
    equipment: {
      [WEAPONS]: [
        makeWeapon(MILITARY_LASER_WEAPON),
        makeWeapon(MILITARY_LASER_WEAPON),
        makeWeapon(MILITARY_LASER_WEAPON),
      ],
      [SHIELDS]: [],
      [GADGETS]: [],
    },
    extra: [],
    fuel: 14,
    tribbles: 0,
    hull: status.monsterHull,
    commander: makeCrewMember('Monster commander', {
      [PILOT_SKILL]: 8 + difficulty,
      [FIGHTER_SKILL]: 8 + difficulty,
      [TRADER_SKILL]: 1,
      [ENGINEER_SKILL]: 1 + difficulty,
    }),
    crew: [],
  };
}

export function getScarabShip(difficulty: number): IShip {
  return {
    type: SCARAB_SHIP,
    cargo: [],
    equipment: {
      [WEAPONS]: [
        makeWeapon(MILITARY_LASER_WEAPON),
        makeWeapon(MILITARY_LASER_WEAPON),
      ],
      [SHIELDS]: [],
      [GADGETS]: [],
    },
    extra: [],
    fuel: 14,
    hull: 400,
    tribbles: 0,
    commander: makeCrewMember('Scarab commander', {
      [PILOT_SKILL]: 5 + difficulty,
      [FIGHTER_SKILL]: 6 + difficulty,
      [TRADER_SKILL]: 1,
      [ENGINEER_SKILL]: 6 + difficulty,
    }),
    crew: [],
  };
}

export function getDragonFlyShip(difficulty: number): IShip {
  return {
    type: DRAGONFLY_SHIP,
    cargo: [],
    equipment: {
      [WEAPONS]: [
        makeWeapon(MILITARY_LASER_WEAPON),
        makeWeapon(PULSE_LASER_WEAPON),
      ],
      [SHIELDS]: [
        makeShield(LIGHTNING_SHIELD, shieldConfigs[LIGHTNING_SHIELD].power),
        makeShield(LIGHTNING_SHIELD, shieldConfigs[LIGHTNING_SHIELD].power),
        makeShield(LIGHTNING_SHIELD, shieldConfigs[LIGHTNING_SHIELD].power),
      ],
      [GADGETS]: [makeGadget(AUTO_REPAIR_SYSTEM), makeGadget(TARGETING_SYSTEM)],
    },
    extra: [],
    fuel: 14,
    hull: 10,
    tribbles: 0,
    commander: makeCrewMember('Dragonfly commander', {
      [PILOT_SKILL]: 4 + difficulty,
      [FIGHTER_SKILL]: 6 + difficulty,
      [TRADER_SKILL]: 1,
      [ENGINEER_SKILL]: 6 + difficulty,
    }),
    crew: [],
  };
}
