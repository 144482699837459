export const INIT_SHIP = '[Player ship] init';
export const CHANGE_SHIP = '[Player ship] change';
export const BUY_SHIP = '[Player ship] buy new ship';

export const BUY_FUEL = '[Player ship] buy fuel';
export const FILL_FUEL = '[Player ship] fill fuel';
export const SPEND_FUEL = '[Player ship] spend fuel';

export const BUY_REPAIR = '[Player ship] buy repair';
export const REPAIR_PLAYER_SHIP = '[Player ship] repair';
export const REPAIR_PLAYER_SHIP_ON_TRAVEL = '[Player ship] repair on travel';

export const DAMAGE_SHIP_HULL = '[Player ship] damage hull';
export const DAMAGE_SHIP_SHIELDS = '[Player ship] damage shields';

export const BUY_CARGO = '[Player ship] buy cargo';
export const SELL_CARGO = '[Player ship] sell cargo';
export const RETURN_CARGO = '[Player ship] return cargo';
export const CANCEL_PURCHASE = '[Player ship] cancel purchase cargo';
export const CONFISCATE_CARGO = '[Player ship] confiscated cargo type';
export const LOST_CARGO = '[Player ship] lost cargo';
export const PLUNDER_CARGO = '[Player ship] plunder cargo';

export const BUY_GADGET = '[Player ship] buy gadget';
export const BUY_WEAPON = '[Player ship] buy weapon';
export const BUY_SHIELD = '[Player ship] buy shield';
export const SELL_GADGET = '[Player ship] sell gadget';
export const SELL_WEAPON = '[Player ship] sell weapon';
export const SELL_SHIELD = '[Player ship] sell shield';

export const INCREASE_RANDOM_SKILL =
  '[Player ship] increase random player skill';
export const INCREASE_SKILL = '[Player ship] increase player skill';
export const DECREASE_SKILL = '[Player ship] decrease player skill';

export const GET_CREW_MEMBER_ONBOARD = '[Player ship] get member on board';
export const DISMISS_CREW_MEMBER = '[Player ship] dismiss crew member';
export const SPECIAL_CREW_MEMBER_LEAVES =
  '[Player ship] special crew member leaves';

export const GET_PERSONAL_EQUIPMENT = '[Player ship] get personal equipment';

export const GET_TRIBBLE = '[Player ship] get tribble on board';
export const TRIBBLES_SOLD = '[Player ship] tribbles sold';
