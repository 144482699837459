import React from 'react';
import { IonToast } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirstToast } from '../../store/game/toasts/toasts.selector';
import { disposeToast } from '../../store/game/toasts/toasts.actions';

export const ToastsCenter: React.FC = () => {
  const dispatch = useDispatch();
  const toast = useSelector(selectFirstToast);
  if (toast) {
    return (
      <IonToast
        isOpen
        color="success"
        position="top"
        duration={1000}
        key={toast.id}
        header={toast.header}
        message={toast.message}
        onDidDismiss={() => dispatch(disposeToast(toast.id))}
      />
    );
  }

  return <></>;
};
