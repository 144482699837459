// Police record
export const ATTACKPOLICESCORE = -3;
export const KILLPOLICESCORE = -6;
export const ATTACKTRADERSCORE = -2;
export const KILLTRADERSCORE = -4;
export const PLUNDERTRADERSCORE = -2;
export const KILLPIRATESCORE = 1;
export const PLUNDERPIRATESCORE = -1;
export const TRAFFICKING = -1;
export const FLEEFROMINSPECTION = -2;
export const CAUGHTWITHWILDSCORE = -4;
export const TAKEMARIENARCOTICS = -4;
