import { createReducer, ActionType } from 'typesafe-actions';
import * as commonActions from './common.actions';

export interface CommonState {
  started: boolean;
}

export const initialState: CommonState = {
  started: false,
};

export type CommonActions = ActionType<typeof commonActions>;

export const commonReducer = createReducer<CommonState, CommonActions>(
  initialState,
).handleAction(commonActions.gameStarted, () => ({
  started: true,
}));
