import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonText,
} from '@ionic/react';
import React from 'react';
import { ShipType } from '../../../app/data/types/ship/ship-type';
import { useBuyShip } from '../../../hooks/useBuyShip';
import { useBuyShipAlert } from './useBuyShipAlert';
import { useDispatch } from 'react-redux';
import { getShipTypeImage } from '../../../app/ui/ship.img';
import { SlotIndicator } from './ValueIndicators/SlotIndicator';
import {
  getMaxShipConfigValue,
  getMaxSlotValue,
} from '../../../app/ui/ship-config.utils';
import {
  manOutline,
  radioButtonOnOutline,
  settingsOutline,
  shieldOutline,
} from 'ionicons/icons';
import {
  GADGETS,
  SHIELDS,
  WEAPONS,
} from '../../../app/config/equipment.configs';
import { getPercentageValue } from '../../../app/ui/progressbar';
import { shipConfigs } from '../../../app/config/ship/ship.configs';
import NumberFormat from 'react-number-format';

type ShipItemProps = {
  shipType: ShipType;
};

const ShipItem: React.FC<ShipItemProps> = ({ shipType }) => {
  const dispatch = useDispatch();
  const [price, isDisabled, status] = useBuyShip(shipType);
  const action = useBuyShipAlert(shipType);
  const config = shipConfigs[shipType];

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonAvatar slot="start">
                  <img
                    className="pixelated"
                    alt={shipType}
                    src={getShipTypeImage(shipType)}
                  />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h1>{shipType}</h1>
                  <p>{status}</p>
                </IonLabel>
              </IonItem>
            </IonCol>
            <IonCol size="auto">
              <IonText>
                <h1>
                  <NumberFormat
                    value={price}
                    displayType={'text'}
                    thousandSeparator={','}
                    suffix={' cr.'}
                  />
                </h1>
              </IonText>
            </IonCol>
          </IonRow>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonList>
          <IonItem className="ion-align-items-start">
            <SlotIndicator
              title="Crew cabines"
              value={config.crewCabines}
              max={getMaxShipConfigValue('crewCabines')}
              icon={manOutline}
            />
            <SlotIndicator
              title="Weapon slots"
              value={config.equipmentSlots.Weapons}
              max={getMaxSlotValue(WEAPONS)}
              icon={radioButtonOnOutline}
            />
            <SlotIndicator
              title="Shield slots"
              value={config.equipmentSlots.Shields}
              max={getMaxSlotValue(SHIELDS)}
              icon={shieldOutline}
            />
            <SlotIndicator
              title="Gadget slots"
              value={config.equipmentSlots.Gadgets}
              max={getMaxSlotValue(GADGETS)}
              icon={settingsOutline}
            />
          </IonItem>
          <IonItem>
            <IonCol>
              <IonText>{config.hullStrength} hull strength</IonText>
              <IonProgressBar
                value={getPercentageValue(
                  config.hullStrength,
                  getMaxShipConfigValue('hullStrength'),
                )}
              />
            </IonCol>
            <IonCol>
              <IonText>{config.cargoBays} cargo bays</IonText>
              <IonProgressBar
                value={getPercentageValue(
                  config.cargoBays,
                  getMaxShipConfigValue('cargoBays'),
                )}
              />
            </IonCol>
            <IonCol>
              <IonText>{config.fuelTanks} fuel tanks</IonText>
              <IonProgressBar
                value={getPercentageValue(
                  config.fuelTanks,
                  getMaxShipConfigValue('fuelTanks'),
                )}
              />
            </IonCol>
          </IonItem>
          <IonItem>
            <IonChip>
              <IonLabel>Fuel cost: {config.costOfFuel}</IonLabel>
            </IonChip>

            <IonChip>
              <IonLabel>Repair cost: {config.repairCosts}</IonLabel>
            </IonChip>
          </IonItem>
        </IonList>
        <IonButton
          expand={'block'}
          disabled={isDisabled}
          onClick={() => dispatch(action)}>
          Buy
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default ShipItem;
