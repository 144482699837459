export interface Entities<T> {
  [index: string]: T;
}

export function mapEntitiesToArray<T>(entities: Entities<T>): T[] {
  return Object.keys(entities)
    .sort()
    .map((id) => entities[id]);
}

export function mapEntitiesToIds<T>(entities: Entities<T>): number[] {
  return Object.keys(entities).map(Number);
}

export function mapArrayToEntities<T>(
  array: T[],
  getIndex: (item: T, index: number) => string,
): Entities<T> {
  return array.reduce(
    (entities, item, index) => ({
      ...entities,
      [getIndex(item, index)]: item,
    }),
    {},
  );
}

export function updateEntity<T>(
  entities: Entities<T>,
  id: string | number,
  updateFn: (entity: T) => T,
): Entities<T> {
  return {
    ...entities,
    [id]: { ...updateFn(entities[id]) },
  };
}

export function lastId(entities: Entities<unknown>): number {
  return Object.keys(entities).length - 1;
}

export function newId(entities: Entities<unknown>): number {
  return Object.keys(entities).length;
}
