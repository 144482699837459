import { pipe } from 'rxjs';
import { GameState } from '../..';
import { selectGame } from '../../selectors';

export const selectNotifications = pipe(
  selectGame,
  (game: GameState) => game.notifications,
);

export const selectFirstNotification = pipe(
  selectNotifications,
  (notifications) => notifications[0] || null,
);
