import React from 'react';
import {
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
} from '@ionic/react';
import { EquipmentType } from '../../../app/config/equipment.configs';
import {
  getUsedSlots,
  getTotalSlots,
} from '../../../app/core/equipment/equipment';
import { ShipEquipmentItem } from '../../../app/data/types/ship/equipment/equipment';
import ShipEquipmentItemCard from './ShipEquipmentItem';
import { IShip } from '../../../app/data/types/ship/ship';
import { useSlotsDescription } from '../../../hooks/useSlotsDescription';

type ShipEquipmentProps = {
  equipmentType: EquipmentType;
  ship: IShip;
};

const ShipEquipment: React.FC<ShipEquipmentProps> = ({
  ship,
  equipmentType,
}) => {
  const equipment = ship.equipment[equipmentType] as ShipEquipmentItem[];
  const totalSlots = getTotalSlots(ship, equipmentType);
  const usedSlots = getUsedSlots(ship, equipmentType);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{equipmentType}</IonCardTitle>
        <IonCardSubtitle className="ion-text-lowercase">
          {useSlotsDescription(totalSlots, usedSlots, `${equipmentType} slot`)}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {equipment.map((item) => (
          <ShipEquipmentItemCard key={item.id} equipmentItem={item} />
        ))}
        {Array(totalSlots - usedSlots)
          .fill(0)
          .map((value, index) => (
            <IonItem key={equipmentType + index}>
              Free {equipmentType} slot
            </IonItem>
          ))}
      </IonCardContent>
    </IonCard>
  );
};

export default ShipEquipment;
