import { pipe, prop } from 'ramda';
import { selectCurrentEncounter } from '../travel/selectors/current-encounter.selector';

export const selectEncounterType = pipe(selectCurrentEncounter, prop('type'));

export const selectOpponentShip = pipe(selectCurrentEncounter, prop('ship'));

export const selectOpponentShipCargo = pipe(selectOpponentShip, prop('cargo'));

export const selectIsPlayerMove = pipe(
  selectCurrentEncounter,
  prop('isPlayerTurn'),
);
