import { Skills } from '../../app/data/types/crew-member/skill';

export interface GameInfo {
  difficulty: number;
  playerName: string;
  playerSkills: Skills;
}

export const SAFE_SEPARATOR = '##!##';

export function makeGameId(
  difficulty: number,
  playerName: string,
  playerSkills: Skills,
): string {
  return btoa(
    [
      difficulty,
      playerName,
      JSON.stringify(playerSkills),
      new Date().toISOString(),
    ].join(SAFE_SEPARATOR),
  );
}

export function parseGameId(gameId: string): GameInfo {
  const [difficulty, playerName, playerSkillsString] = atob(gameId).split(
    SAFE_SEPARATOR,
  );

  return {
    difficulty: Number(difficulty),
    playerName,
    playerSkills: JSON.parse(playerSkillsString),
  };
}

export function getSavedGames(): string[] {
  const ids = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!!key && key.toString().startsWith('persist')) {
      ids.push(key.split(':')[1]);
    }
  }
  return ids;
}
