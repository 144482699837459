import { useSelector } from 'react-redux';
import { warp } from '../store/game/travel/travel.actions';
import { addNotification } from '../store/game/notifications/notifications.actions';
import { GameActions } from '../store';
import { selectIsCurrentSystem } from '../store/game/systems/selectors/current-system.selector';
import { selectIsDebtTooLarge } from '../store/game/status/status.selectors';
import { addConfirmation } from '../store/game/confirmations/confirmations.actions';
import { getSystemNameByIndex } from '../app/ui/solar-system.labels';
import { selectIsSystemAvailableToTravel } from '../store/derived/computational-selectors';

const useDoWarp = (systemIndex: number): GameActions => {
  const isCurrent = useSelector(selectIsCurrentSystem(systemIndex));
  const debtTooLarge = useSelector(selectIsDebtTooLarge);
  const isAvailable = useSelector(selectIsSystemAvailableToTravel(systemIndex));

  if (isCurrent) {
    return addNotification({
      title: 'Warp prevented',
      message: 'You are on this system already',
    });
  }

  if (!isAvailable) {
    return addNotification({
      title: 'Out of range',
      message: 'This system is too far to travel',
    });
  }

  if (debtTooLarge) {
    return addNotification({
      title: 'You have too large debt',
      message: 'Travel is not possible when debt is too large',
    });
  }

  return addConfirmation({
    title: 'Warp confirmation',
    message: `Do you want to warp to ${getSystemNameByIndex(
      systemIndex,
    )} system?`,
    action: warp(systemIndex),
  });
};

export default useDoWarp;
