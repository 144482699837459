export const UNEVENTFUL = 'Uneventful';
export const WAR = 'War';
export const PLAGUE = 'Plague';
export const DROUGHT = 'Drought';
export const BOREDOM = 'Boredom';
export const COLD = 'Cold';
export const CROPFAILURE = 'Crop failure';
export const LACKOFWORKERS = 'Lack of workers';

export const systemStatuses = [
  UNEVENTFUL,
  WAR,
  PLAGUE,
  DROUGHT,
  BOREDOM,
  COLD,
  CROPFAILURE,
  LACKOFWORKERS,
] as const;

export type SystemStatus = typeof systemStatuses[number];
