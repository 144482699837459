import { System } from '../data/types/system/system';
import { sqrDistance } from '../core/coordinates/coordinates';
import { SQR } from '../utils';
import { INITIAL_SHIP_TYPE } from '../data/constants/ship-types';
import { shuffle } from '../utils/shuffle-array';
import { shipConfigs } from '../config/ship/ship.configs';
import { getSystemTechLevel } from '../core/solar-system/system-tech-level';

const hasReachableSystems = (candidate: System, systems: System[]): boolean => {
  const reachableSystems = systems
    .filter((system) => system.nameIndex !== candidate.nameIndex)
    .filter(
      (system) =>
        sqrDistance(system.coordinates, candidate.coordinates) <=
        SQR(shipConfigs[INITIAL_SHIP_TYPE].fuelTanks),
    );

  return reachableSystems.length >= 3;
};

const tryCandidates = (
  candidates: System[],
  systems: System[],
): System | null => {
  if (candidates.length === 0) {
    return null;
  }

  const nextCandidate = candidates.shift();
  if (nextCandidate) {
    if (hasReachableSystems(nextCandidate, systems)) {
      return nextCandidate;
    }

    return tryCandidates(shuffle(candidates), systems);
  }

  return null;
};

export const findHomeSystemIndex = (systems: System[]): number => {
  const candidates: System[] = shuffle(
    systems
      .filter((system) => !system.special)
      .filter((system) => getSystemTechLevel(system) > 0)
      .filter((system) => getSystemTechLevel(system) < 6),
  );

  const initialSystem = tryCandidates(candidates, systems);

  if (initialSystem !== null) {
    return initialSystem.nameIndex;
  }

  return 0;
};
