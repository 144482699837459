import { converge } from 'ramda';
import {
  selectPoliceRecordScore,
  selectReputationScore,
} from '../../../scores/scores.selectors';
import { selectPlayerShip } from '../../../ship/ship.selectors';
import { selectDifficulty } from '../../../status/status.selectors';
import { selectOpponentShip } from '../../encounter.selectors';
import { EncounterActionType } from '../../../../../app/data/interaction/action-type';
import { getInitialTraderAction } from '../../../../../app/core/encounter/reactions/trader/initial.reaction';
import { getTraderAttackReaction } from '../../../../../app/core/encounter/reactions/trader/attack.reaction';
import { selectOpponentEvents } from '../../selectors/encounter-events.selector';
import { pipe } from 'rxjs';
import { selectDamageLog } from '../../selectors/damage-log.selectors';
import { mapEntitiesToArray } from '../../../../entities';
import { RootState } from '../../../../index';

export const selectInitialTraderAction = converge(getInitialTraderAction, [
  selectDifficulty,
  selectPoliceRecordScore,
  selectReputationScore,
  // chanceOfTrade,
  // questStatus,
  selectOpponentShip,
  selectPlayerShip,
]);

export const selectTraderAttackReaction: (
  state: RootState,
) => EncounterActionType = converge(getTraderAttackReaction, [
  selectOpponentEvents,
  selectOpponentShip,
  selectReputationScore,
  pipe(selectDamageLog, mapEntitiesToArray),
]);
