export const PILOT_SKILL = 'Pilot';
export const FIGHTER_SKILL = 'Fighter';
export const TRADER_SKILL = 'Trader';
export const ENGINEER_SKILL = 'Engineer';

export const skillTypes = [
  TRADER_SKILL,
  PILOT_SKILL,
  FIGHTER_SKILL,
  ENGINEER_SKILL,
] as const;

export type SkillType = typeof skillTypes[number];

export type Skills = Record<SkillType, number>;
