import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  Bar,
  ComposedChart,
} from 'recharts';
import { System } from '../../../../../app/data/types/system/system';
import { TradeItemType } from '../../../../../app/data/types/trade-item/trade-item-type';
import { getSystemName } from '../../../../../app/ui/solar-system.labels';
import { selectPricesHistory } from '../../../../../store/game/systems/systems.selectors';
import { selectCurrentSystem } from '../../../../../store/game/systems/selectors/current-system.selector';

const TradeItemProfitChart: React.FC<{
  system: System;
  tradeItemType: TradeItemType;
}> = ({ system, tradeItemType }) => {
  const currentSystem = useSelector(selectCurrentSystem);
  const currentSystemName = getSystemName(currentSystem);
  const systemName = getSystemName(system);
  const currentSystemPricesHistory = useSelector(
    selectPricesHistory(currentSystem, tradeItemType),
  );
  const systemPricesHistory = useSelector(
    selectPricesHistory(system, tradeItemType),
  );

  const comparison = currentSystemPricesHistory.map((item) => {
    const systemPrice =
      systemPricesHistory.find((systemItem) => systemItem.day === item.day)
        ?.price || 0;
    return {
      day: item.day,
      profit: systemPrice - item.price,
      [currentSystemName]: item.price,
      [systemName]: systemPrice,
    };
  });

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{tradeItemType}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <ComposedChart
          width={500}
          height={300}
          data={comparison}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis unit="day" dataKey="day" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            label="day"
            contentStyle={{
              backgroundColor: 'var(--ion-toolbar-background)',
            }}
          />
          <Legend />
          <Bar dataKey="profit" barSize={20} fill="#413ea0" />
          <Line type="linear" dataKey={currentSystemName} stroke="#2c8a9d" />
          <Line type="linear" dataKey={systemName} stroke="#82ca9d" />
        </ComposedChart>
      </IonCardContent>
    </IonCard>
  );
};

export default TradeItemProfitChart;
