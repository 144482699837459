import { KRAVATSYSTEM } from '../data/constants/solar-systems';
import { shuffle } from '../utils/shuffle-array';
import { mercenaryNames } from '../data/labels/mercenaries';
import { System } from '../data/types/system/system';
import { makeCrewMember } from '../factories/crew-member';

// eslint-disable-next-line import/prefer-default-export
export function placeMercenaries(systems: System[]): System[] {
  const shuffledNames = shuffle(mercenaryNames);

  return systems.map((system) => {
    if (system.nameIndex === KRAVATSYSTEM) {
      return system;
    }

    const name = shuffledNames.pop();

    return {
      ...system,
      mercenaries: name ? [makeCrewMember(name)] : [],
    };
  });
}
