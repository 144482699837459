import {
  ARTIFACT_DELIVERY,
  BUY_TRIBBLE,
  DRAGONFLY_DESTROYED,
  EXPERIMENT_STOPPED,
  FLY_BARATAS,
  FLY_MELINA,
  FLY_REGULAS,
  GEMULON_RESCUED,
  GET_HULL_UPGRADED,
  JAREK_GETS_OUT,
  MEDICINE_DELIVERY,
  MONSTER_KILLED,
  MOON_BOUGHT,
  REACTOR_DELIVERED,
  SCARAB_DESTROYED,
  SCARAB_STOLEN,
  SpecialEventType,
  WILD_GETS_OUT,
} from '../app/data/types/system/special-event/special-event-type';
import { useSelector } from 'react-redux';
import {
  selectIsArtifactOnBoard,
  selectIsExperimentOngoing,
  selectIsJarekOnBoard,
  selectIsMedicineOnBoard,
  selectQuestStatusState,
} from '../store/game/questStatus/quest-status.selectors';
import { selectPlayerShip } from '../store/game/ship/ship.selectors';
import { selectStatus } from '../store/game/status/status.selectors';

export const useSpecialEventVisibility = (specialEvent: SpecialEventType) => {
  const questStatus = useSelector(selectQuestStatusState);
  const ship = useSelector(selectPlayerShip);
  const status = useSelector(selectStatus);

  const isArtifactOnBoard = useSelector(selectIsArtifactOnBoard);
  const isExperimentOngoing = useSelector(selectIsExperimentOngoing);
  const isMedicineOnBoard = useSelector(selectIsMedicineOnBoard);
  const isJarekOnBoard = useSelector(selectIsJarekOnBoard);

  switch (specialEvent) {
    case BUY_TRIBBLE:
      return ship.tribbles > 0;
    case EXPERIMENT_STOPPED:
      return isExperimentOngoing;
    case ARTIFACT_DELIVERY:
      return isArtifactOnBoard;
    case MONSTER_KILLED:
      return questStatus.monster === 2; // monster is destroyed
    case FLY_BARATAS:
      return questStatus.dragonfly === 1; // go to baratas
    case FLY_MELINA:
      return questStatus.dragonfly === 2; // go to melina
    case FLY_REGULAS:
      return questStatus.dragonfly === 3; // go to regulas
    case DRAGONFLY_DESTROYED:
      return questStatus.dragonfly === 5;
    case GET_HULL_UPGRADED:
    case SCARAB_DESTROYED:
      return questStatus.scarab === 2;
    case SCARAB_STOLEN:
      return questStatus.scarab === 0;
    case MEDICINE_DELIVERY:
      return isMedicineOnBoard;
    case JAREK_GETS_OUT:
      return isJarekOnBoard;
    case WILD_GETS_OUT:
      return questStatus.wild === 1; // is wild on board
    case GEMULON_RESCUED:
      return questStatus.invasion >= 1 && questStatus.invasion <= 7; // it's not too late
    case MOON_BOUGHT:
      return status.moonBought; // we have bought a moon
    case REACTOR_DELIVERED:
      return questStatus.reactor > 0 && questStatus.reactor < 21; // it's not too late
    default:
      return true;
  }
};
