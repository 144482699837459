// earn
export const SELL_EQUIPMENT_REASON = 'sell equipment';
export const SELL_CARGO_REASON = 'sell cargo';
export const CHEAT_LOAN_REASON = 'cheat loan';
export const START_AMOUNT_REASON = 'start amount';
export const BOUNTY_REASON = 'kill pirate bounty';
export const SELL_SHIP_REASON = 'sell ship';
export const LOTTERY_WIN_REASON = 'win lottery';
export const GET_LOAN_REASON = 'bank loan';
export const ARTIFACT_DELIVERY_REASON = 'artifact delivery';

// spend
export const BUY_SHIP_REASON = 'buy ship';
export const PAY_LOAN_BACK_REASON = 'pay loan back';
export const BLACKMAIL_REASON = 'pirate blackmail';
export const BRIBE_REASON = 'police bribe';
export const ILLEGALS_FINE_REASON = 'police found illegals';
export const BUY_FUEL_REASON = 'buy fuel';
export const REPAIR_REASON = 'ship repair';
export const BUY_CARGO_REASON = 'buy cargo';
export const INCREASE_SKILL_REASON = (skill: string): string =>
  `increase ${skill} skill`;
export const PAY_INTEREST_REASON = 'pay interest';
export const PURCHASE_MOON_REASON = 'moon purchase';
export const WORMHOLE_TAX_REASON = 'wormhole tax';
export const MERCENARY_SALARY_REASON = 'mercenary salary';
export const BUY_NEWSPAPER_REASON = 'buy newspaper';
