import {
  ALIEN_ARTIFACT,
  ALIEN_INVASION,
  TRANSPORT_AMBASSADOR_JAREK,
  ARTIFACT_DELIVERY,
  BUY_TRIBBLE,
  CARGO_FOR_SALE,
  DRAGONFLY_DESTROYED,
  DRAGONFLY_EVENT,
  ERASE_RECORD,
  EXPERIMENT,
  EXPERIMENT_NOT_STOPPED,
  EXPERIMENT_STOPPED,
  FLY_BARATAS,
  FLY_MELINA,
  FLY_REGULAS,
  GEMULON_INVADED,
  GEMULON_RESCUED,
  GET_FUEL_COMPACTOR,
  GET_HULL_UPGRADED,
  GET_REACTOR,
  GET_SPECIAL_LASER,
  INSTALL_LIGHTNING_SHIELD,
  JAPORI_DISEASE,
  JAREK_GETS_OUT,
  LOTTERY_WINNER,
  MEDICINE_DELIVERY,
  MONSTER_KILLED,
  MOON_BOUGHT,
  MOON_FOR_SALE,
  REACTOR_DELIVERED,
  SCARAB_DESTROYED,
  SCARAB_STOLEN,
  SKILL_INCREASE,
  SPACE_MONSTER_EVENT,
  SpecialEventType,
  TRANSPORT_WILD,
  TRIBBLE,
  WILD_GETS_OUT,
} from '../../../app/data/types/system/special-event/special-event-type';
import { GameActions } from '../../index';
import { buyMoon } from '../status/status.actions';
import {
  deliverJarek,
  deliverMedicine,
  experimentStarted,
  experimentStopped,
  getArtifact,
  getJarek,
  getMedicine,
  returnArtifact,
} from '../questStatus/quest-status.actions';
import {
  getTribble,
  increaseRandomSkill,
  tribblesSold,
} from '../ship/ship.actions';
import { earnCredits } from '../balance/balance.actions';
import { LOTTERY_PRIZE } from '../../../app/data/constants/special-events';
import { addNotification } from '../notifications/notifications.actions';
import { endGame } from '../common/common.actions';
import { MOON } from '../../../app/data/types/end-game-status';
import { LOTTERY_WIN_REASON } from '../../../app/ui/change-balance-reasons';

const dummyEvent = addNotification({
  title: 'Dummy',
  message: 'This event is under construction',
});

export const specialEventsActionMap: Record<SpecialEventType, GameActions> = {
  [MOON_FOR_SALE]: buyMoon(),
  [MOON_BOUGHT]: endGame(MOON),

  [TRANSPORT_AMBASSADOR_JAREK]: getJarek(),
  [JAREK_GETS_OUT]: deliverJarek(),

  [TRANSPORT_WILD]: dummyEvent,
  [WILD_GETS_OUT]: dummyEvent,

  [JAPORI_DISEASE]: getMedicine(),
  [MEDICINE_DELIVERY]: deliverMedicine(),

  [ALIEN_ARTIFACT]: getArtifact(),
  [ARTIFACT_DELIVERY]: returnArtifact(),

  [EXPERIMENT]: experimentStarted(),
  [EXPERIMENT_STOPPED]: experimentStopped(),
  [EXPERIMENT_NOT_STOPPED]: dummyEvent,

  [SKILL_INCREASE]: increaseRandomSkill(),
  [CARGO_FOR_SALE]: dummyEvent,
  [ERASE_RECORD]: dummyEvent,
  [LOTTERY_WINNER]: earnCredits({
    amount: LOTTERY_PRIZE,
    reason: LOTTERY_WIN_REASON,
  }),

  [TRIBBLE]: getTribble(),
  [BUY_TRIBBLE]: tribblesSold(),

  [DRAGONFLY_EVENT]: dummyEvent,
  [FLY_BARATAS]: dummyEvent,
  [FLY_MELINA]: dummyEvent,
  [FLY_REGULAS]: dummyEvent,
  [DRAGONFLY_DESTROYED]: dummyEvent,

  [SPACE_MONSTER_EVENT]: dummyEvent,
  [MONSTER_KILLED]: dummyEvent,
  [INSTALL_LIGHTNING_SHIELD]: dummyEvent,

  [ALIEN_INVASION]: dummyEvent,
  [GEMULON_INVADED]: dummyEvent,
  [GEMULON_RESCUED]: dummyEvent,

  [GET_SPECIAL_LASER]: dummyEvent,
  [SCARAB_STOLEN]: dummyEvent,
  [SCARAB_DESTROYED]: dummyEvent,

  [GET_HULL_UPGRADED]: dummyEvent,
  [GET_REACTOR]: dummyEvent,
  [REACTOR_DELIVERED]: dummyEvent,
  [GET_FUEL_COMPACTOR]: dummyEvent,
};
