import {
  EncounterActionType,
  finiteActionTypes,
  FLEE,
  IGNORE,
  MUTUAL_IGNORE,
  SURRENDER,
} from '../../data/interaction/action-type';
import { InteractionEvent } from '../../data/interaction/interaction-event';
import { ActorType } from '../../data/interaction/actor-type';
import { Entities, lastId } from '../../../store/entities';

export const isEventOfActor = (actor: ActorType) => (item: InteractionEvent) =>
  item.actor === actor;

export const isEventOfAction = (action: EncounterActionType) => (
  item: InteractionEvent,
) => item.action === action;

export const isEventOf = (actor: ActorType, action: EncounterActionType) => (
  item: InteractionEvent,
) => isEventOfAction(action)(item) && isEventOfActor(actor)(item);

export const isEventNotOfAction = (action: EncounterActionType) => (
  item: InteractionEvent,
) => item.action !== action;

export function getLastEvent<T>(log: Entities<T>): T {
  return log[Object.keys(log).slice(-1)[0]];
}

export const hasLastActionOf = (type: EncounterActionType) => (
  log: Entities<InteractionEvent>,
): boolean => {
  return (
    (Object.keys(log).length && isEventOfAction(type)(getLastEvent(log))) ||
    false
  );
};
export const isFiniteAction = (action: EncounterActionType) =>
  finiteActionTypes.some((finite) => finite === action);

export function isGameOver(fightLog: Entities<InteractionEvent>): boolean {
  if (finiteActionTypes.some((finite) => hasLastActionOf(finite)(fightLog))) {
    return true;
  }

  if (Object.keys(fightLog).length < 2) {
    return false;
  }

  const lastPair = Object.keys(fightLog)
    .slice(-2)
    .map((id) => fightLog[id]);
  return (
    [IGNORE, FLEE, MUTUAL_IGNORE, SURRENDER].includes(lastPair[0].action) &&
    [IGNORE, FLEE, MUTUAL_IGNORE, SURRENDER].includes(lastPair[1].action)
  );
}
