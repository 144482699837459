import { v4 as uuidv4 } from 'uuid';
import { Action, ActionType, createReducer } from 'typesafe-actions';
import * as actions from './notifications.actions';

export interface Notification {
  id: string;
  title: string;
  message: string;
  action?: Action;
}

export type NotificationsActions = ActionType<typeof actions>;

export type NotificationsState = Notification[];

const initialNotificationsState: NotificationsState = [];

export const notificationsReducer = createReducer<
  NotificationsState,
  NotificationsActions
>(initialNotificationsState)
  .handleAction(actions.resetNotifications, () => [])
  .handleAction(
    actions.addNotification,
    (state, { payload: { title, message, action } }) => [
      ...state,
      {
        id: uuidv4(),
        title,
        message,
        action,
      },
    ],
  )
  .handleAction(actions.disposeNotification, (state, action) =>
    state.filter((item) => item.id !== action.payload),
  );
