import { pipe } from 'rxjs';
import { add, converge, prop } from 'ramda';
import {
  getReputationRecordScoreLabel,
  getPoliceRecordScoreLabel,
} from '../../../app/ui/scores.labels';
import { ScoreRecord } from '../../../app/data/types/score-record';
import { selectGame } from '../../selectors';
import { RootState } from '../../index';

export const reduceRecords = (records: ScoreRecord[]) =>
  records.map(prop('score')).reduce(add, 0);

const getPreviousScore = (records: ScoreRecord[]) =>
  records.length ? reduceRecords(records.slice(0, -1)) : 0;

export const selectScoreState = pipe(selectGame, prop('scores'));

export const selectReputationRecords = pipe(
  selectScoreState,
  prop('reputationRecords'),
);

export const selectReputationScore = pipe(
  selectReputationRecords,
  reduceRecords,
);

export const selectPoliceRecords = pipe(
  selectScoreState,
  prop('policeRecords'),
);

export const selectPoliceRecordScore = pipe(selectPoliceRecords, reduceRecords);

export const selectPreviousPoliceRecordScore = pipe(
  selectPoliceRecords,
  getPreviousScore,
);

export const selectPreviousReputationScore = pipe(
  selectReputationRecords,
  getPreviousScore,
);

export const selectReputationLabel = pipe(
  selectReputationScore,
  getReputationRecordScoreLabel,
);

export const selectPoliceRecordLabel = pipe(
  selectPoliceRecordScore,
  getPoliceRecordScoreLabel,
);

export const selectPreviousPoliceRecordLabel = pipe(
  selectPreviousPoliceRecordScore,
  getPoliceRecordScoreLabel,
);

export const selectPreviousReputationLabel = pipe(
  selectPreviousReputationScore,
  getReputationRecordScoreLabel,
);

export const selectIsPoliceRecordLabelChanged: (state: RootState) => boolean =
  converge(
    (prevLabel: string, currentLabel: string) => prevLabel !== currentLabel,
    [selectPreviousPoliceRecordLabel, selectPoliceRecordLabel],
  );

export const selectIsReputationScoreLabelChanged: (
  state: RootState,
) => boolean = converge(
  (prevLabel: string, currentLabel: string) => prevLabel !== currentLabel,
  [selectPreviousReputationLabel, selectReputationLabel],
);
