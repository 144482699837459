export const cancelButton = {
  text: 'Cancel',
  role: 'cancel',
  cssClass: 'secondary',
};

export const confirmButton = (handler: () => void) => ({
  text: 'Okay',
  handler,
});
