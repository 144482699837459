// Special events
// TODO: cover all expenses and rewards
export const MOON_COST = 500000;
export const ERASE_RECORD_PRICE = 5000;

export const CARGO_FOR_SALE_PRICE = 1000;
export const CARGO_FOR_SALE_AMOUNT = 4;

export const TRIBBLE_PRICE = 1000;
export const SKILL_INCREASE_PRICE = 3000;

export const LOTTERY_PRIZE = 1000;
export const ARTIFACT_DELIVERY_REWARD = 20000;
export const MONSTER_KILLED_BOUNTY = 15000;

export const REACTOR_CARGO_SIZE = 15;

export const EXPERIMENT_NOT_STARTED_CODE = 0;
export const EXPERIMENT_LAST_DAY = 12;
export const EXPERIMENT_STOPPED_CODE = 13;

export const ARTIFACT_NOT_TAKEN_CODE = 0;
export const ARTIFACT_ON_BOARD_CODE = 1;
export const ARTIFACT_DELIVERED_CODE = 2;

export const MEDICINE_NOT_TAKEN_CODE = 0;
export const MEDICINE_ON_BOARD_CODE = 1;
export const MEDICINE_DELIVERED_CODE = 2;
export const MEDICINE_AMOUNT = 10;

export const JAREK_NOT_TAKEN_CODE = 0;
export const JAREK_ON_BOARD_CODE = 1;
export const JAREK_DELIVERED_CODE = 2;
