import { IonPage, IonContent } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router';
import { startGame } from '../../store/game/common/common.actions';
import { selectGameStarted } from '../../store/selectors';
import { parseGameId } from '../main-menu/utils';

const Loader: React.FC = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const id = localStorage.getItem('current');
  const gameStarted = useSelector(selectGameStarted);

  if (gameStarted) {
    return <Redirect to={`${match.url}/game`} />;
  }

  if (id) {
    dispatch(startGame(parseGameId(id)));
  }

  return (
    <IonPage>
      <IonContent>
        <p>Loading...</p>
      </IonContent>
    </IonPage>
  );
};

export default Loader;
