import { shipTypes } from '../../../../data/types/ship/ship-type';
import { ELITESCORE } from '../../../../data/constants/reputation';
import { getRandom } from '../../../../utils';
import { isShipBetterThanOther } from '../../../ship/compare';
import {
  EncounterActionType,
  ATTACK,
  FLEE,
  IGNORE,
} from '../../../../data/interaction/action-type';
import { IShip } from '../../../../data/types/ship/ship';
import { isShipHiddenForOpponent } from '../../../ship/is-hidden-for-opponent';

export const getInitialPirateAction = (
  difficulty: number,
  playerReputationScore: number,
  pirateShip: IShip,
  playerShip: IShip,
): EncounterActionType => {
  const isPlayerHidden = isShipHiddenForOpponent(difficulty)(
    playerShip,
    pirateShip,
  );
  const pirateShipType = shipTypes.indexOf(pirateShip.type);
  const isOpponentOnBetterShip = isShipBetterThanOther(pirateShip, playerShip);
  const isOpponentStrong = pirateShipType >= 7;
  const isPlayerWithoutReputation =
    getRandom(0, ELITESCORE) >
    (playerReputationScore * 4) / (1 + pirateShipType);

  if (isPlayerHidden) {
    return IGNORE;
  }

  if (isOpponentStrong || isOpponentOnBetterShip || isPlayerWithoutReputation) {
    return ATTACK;
  }

  return FLEE;
};
