import { useSelector } from 'react-redux';
import { ShipType } from '../app/data/types/ship/ship-type';
import {
  selectPlayerShip,
  selectShipPrice,
} from '../store/game/ship/ship.selectors';
import { isShipProducible } from '../app/core/ship/is-producible';
import { selectCurrentSystem } from '../store/game/systems/selectors/current-system.selector';
import { useTypedSelector } from './useTypedSelector';

function getBuyLabel(isSameWithPlayer: boolean, isAvailable: boolean) {
  if (isSameWithPlayer) {
    return 'You have same ship';
  }
  if (!isAvailable) {
    return 'Not available';
  }

  return `Available`;
}
export const useBuyShip = (shipType: ShipType) => {
  const currentSystem = useSelector(selectCurrentSystem);
  const playerShip = useSelector(selectPlayerShip);

  const shipPrice = useTypedSelector(selectShipPrice(shipType));
  const isShipAvailable = isShipProducible(currentSystem)(shipType);

  const isSameWithPlayerShip = playerShip.type === shipType;
  const canBuy = isShipAvailable && !isSameWithPlayerShip;
  const isDisabled = !canBuy;
  const status = getBuyLabel(isSameWithPlayerShip, isShipAvailable);

  return [shipPrice, isDisabled, status] as const;
};
