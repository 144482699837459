import React from 'react';
import { useSelector } from 'react-redux';
import { pipe } from 'rxjs';
import { Entities, mapEntitiesToArray } from '../../../../store/entities';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { IonText } from '@ionic/react';
import './Encounter.css';
import { RootState } from '../../../../store';
import { DamageEvent } from '../../../../app/data/interaction/damage-event';
import { prop, sortBy } from 'ramda';

export const DamageAnimation: React.FC<{
  logSelector: (state: RootState) => Entities<DamageEvent>;
}> = ({ logSelector }) => {
  const damage = useSelector(
    pipe(logSelector, mapEntitiesToArray, sortBy(prop('id')), (log) => {
      return log.slice(-1)[0];
    }),
  );

  if (!damage) {
    return <></>;
  }

  const label = damage.damage === 0 ? 'Missed' : `-${damage.damage} pt`;

  return (
    <div className="damage-block">
      <SwitchTransition>
        <CSSTransition
          appear
          key={damage.id}
          classNames="fadeout"
          exit={false}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}>
          <div className="label ion-text-right">
            <IonText color="danger" className="code ion-text-uppercase">
              {label}
            </IonText>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
