import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { buyCargo } from '../store/game/ship/ship.actions';
import { selectPlayerAvailableCargoBaysAmount } from '../store/game/ship/ship.selectors';
import {
  selectCurrentDay,
  selectIsDebtTooLarge,
} from '../store/game/status/status.selectors';
import { TradeItemType } from '../app/data/types/trade-item/trade-item-type';
import { addNotification } from '../store/game/notifications/notifications.actions';
import {
  selectAvailableCredits,
  selectFinalBuyPrice,
} from '../store/derived/computational-selectors';

export const useBuyItem = (
  tradeItemType: TradeItemType,
  selectedQuantity: number,
) => {
  const isDebtTooLarge = useSelector(selectIsDebtTooLarge);
  const availableCredits = useSelector(selectAvailableCredits);
  const priceToBuy = useSelector(selectFinalBuyPrice(tradeItemType));
  const availableCargoBays = useSelector(selectPlayerAvailableCargoBaysAmount);
  const day = useSelector(selectCurrentDay);
  const totalCost = priceToBuy * selectedQuantity;

  return useCallback(() => {
    if (isDebtTooLarge) {
      return addNotification({
        title: 'You are in debt',
        message: 'Your debt is too large',
      });
    }
    if (availableCredits < totalCost) {
      return addNotification({
        title: 'Not enough money',
        message: 'You can not afford this item',
      });
    }
    if (availableCargoBays === 0) {
      return addNotification({
        title: 'No available cargo bays',
        message: 'You do not have available cargo bays to store trade items',
      });
    }
    return buyCargo({
      type: tradeItemType,
      quantity: selectedQuantity,
      itemPrice: priceToBuy,
      day,
    });
  }, [
    tradeItemType,
    priceToBuy,
    availableCredits,
    isDebtTooLarge,
    availableCargoBays,
    selectedQuantity,
    totalCost,
    day,
  ]);
};
