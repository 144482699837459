import { converge, pipe } from 'ramda';
import { selectOpponentsToIgnore } from '../../settings/settings.selectors';
import { selectOpponentEvents } from './encounter-events.selector';
import { InteractionEvent } from '../../../../app/data/interaction/interaction-event';
import { ActorType } from '../../../../app/data/interaction/actor-type';
import {
  EncounterActionType,
  FLEE,
  IGNORE,
} from '../../../../app/data/interaction/action-type';
import { acceptEncounter, declineEncounter } from '../../travel/travel.actions';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';
import { mapEntitiesToArray } from '../../../entities';

const actionsSafeToIgnore: EncounterActionType[] = [IGNORE, FLEE];

const acceptOrDeclineEncounter = (
  opponentLog: InteractionEvent[],
  actorsToIgnore: ActorType[],
) => {
  if (
    actorsToIgnore.includes(opponentLog[0].actor) &&
    actionsSafeToIgnore.includes(opponentLog[0].action)
  ) {
    return declineEncounter();
  }
  return acceptEncounter();
};

export const selectIncomingEncounterDecision = converge<
  AnyAction,
  [(state: RootState) => InteractionEvent[], (state: RootState) => ActorType[]]
>(acceptOrDeclineEncounter, [
  pipe(selectOpponentEvents, mapEntitiesToArray),
  selectOpponentsToIgnore,
]);
