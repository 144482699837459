import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './settings.actions';
import { defaultSettings } from '../../../app/data/game';
import { Settings } from '../../../app/data/types/game/settings';
import { complement, equals , any } from 'ramda';


export type SettingsActions = ActionType<typeof actions>;

const initialSettings = {
  ...defaultSettings,
};

export const settingsReducer = createReducer<Settings, SettingsActions>(
  initialSettings,
)
  .handleAction(actions.initSettings, (_, action) => ({
    ...action.payload,
  }))
  .handleAction(
    actions.toggleAutoIgnore,
    (settings, { payload: actorType }) => ({
      ...settings,
      autoIgnore: any(equals(actorType))(settings.autoIgnore)
        ? settings.autoIgnore.filter(complement(equals(actorType)))
        : [...settings.autoIgnore, actorType],
    }),
  );
