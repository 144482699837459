import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
} from '@ionic/react';
import { HighScoreRecord } from '../../../app/data/types/high-score-record';
import { loadHighScores } from './high-scores.utils';
import {
  getScore,
  getScorePercentage,
} from '../../../app/core/high-scores/get-score';
import { difficultyLevelLabel } from '../../../app/data/labels/difficulty-level';

export const HighScores: React.FC = () => {
  const highScores: HighScoreRecord[] = loadHighScores();

  return (
    <>
      {highScores.map((highScore, index) => (
        <IonCard key={index}>
          <IonCardHeader>
            <IonCardTitle>{highScore.name}</IonCardTitle>
            <IonCardSubtitle>
              {getScorePercentage(
                getScore(highScore.status)(
                  highScore.days,
                  highScore.worth,
                  highScore.difficulty,
                ),
              )}
              %
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText>
              <p>
                {highScore.status} in {highScore.days} day, worth{' '}
                {highScore.worth} credits on{' '}
                {difficultyLevelLabel[highScore.difficulty]} level
              </p>
            </IonText>
          </IonCardContent>
        </IonCard>
      ))}
    </>
  );
};
