import { pipe } from 'rxjs';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { ALIEN_ARTIFACT } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';

export const selectAlienArtifactAction = pipe(
  selectPoliceRecordScore,
  (policeRecordScore) => {
    const { title } = getSpecialEventConfig(ALIEN_ARTIFACT);
    if (policeRecordScore < DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record is too low to take this quest',
      });
    }
    return invokeSpecialEvent(ALIEN_ARTIFACT);
  },
);
