export const FLEA = 'Flea';
export const GNAT = 'Gnat';
export const FIREFLY = 'Firefly';
export const MOSQUITO = 'Mosquito';
export const BUMBLEBEE = 'Bumblebee';
export const BEETLE = 'Beetle';
export const HORNET = 'Hornet';
export const GRASSHOPPER = 'Grasshopper';
export const TERMITE = 'Termite';
export const WASP = 'Wasp';
export const SPACE_MONSTER_SHIP = 'Space monster ship';
export const DRAGONFLY_SHIP = 'Dragonfly ship';
export const MANTIS_SHIP = 'Mantis ship';
export const SCARAB_SHIP = 'Scarab ship';
export const BOTTLE = 'Bottle';

// ships are ordered from weakest to strongest
export const shipTypes = [
  BOTTLE,
  FLEA,
  GNAT,
  FIREFLY,
  MOSQUITO,
  BUMBLEBEE,
  BEETLE,
  HORNET,
  GRASSHOPPER,
  TERMITE,
  WASP,
  SPACE_MONSTER_SHIP,
  DRAGONFLY_SHIP,
  MANTIS_SHIP,
  SCARAB_SHIP,
] as const;

export type ShipType = typeof shipTypes[number];
