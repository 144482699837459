import { IShip } from '../../../../data/types/ship/ship';
import {
  EncounterActionType,
  BLACKMAIL,
  PLUNDER,
} from '../../../../data/interaction/action-type';

export const getPirateSurrenderReaction = (
  playerShip: IShip,
): EncounterActionType => {
  if (!playerShip.cargo.length) {
    return BLACKMAIL;
  }

  return PLUNDER;
};
