import { pipe, prop } from 'ramda';
import { getLastEvent } from '../../../../app/core/encounter/interaction';
import { selectCurrentEncounter } from '../../travel/selectors/current-encounter.selector';
import { mapArrayToEntities, mapEntitiesToArray } from '../../../entities';
import { DamageEvent } from '../../../../app/data/interaction/damage-event';

export const selectDamageLog = pipe(selectCurrentEncounter, prop('damageLog'));
export const selectOpponentDamageLog = pipe(
  selectDamageLog,
  mapEntitiesToArray,
  (damageLog) =>
    mapArrayToEntities<DamageEvent>(
      damageLog.filter((item) => item.actor !== 'Player'),
      (item) => item.id.toString(),
    ),
);
export const selectPlayerDamageLog = pipe(
  selectDamageLog,
  mapEntitiesToArray,
  (damageLog) =>
    mapArrayToEntities<DamageEvent>(
      damageLog.filter((item) => item.actor === 'Player'),
      (item) => item.id.toString(),
    ),
);
export const selectLastOpponentDamage = pipe(
  selectOpponentDamageLog,
  getLastEvent,
  prop('damage'),
);
export const selectLastPlayerDamage = pipe(
  selectPlayerDamageLog,
  getLastEvent,
  prop('damage'),
);
