import React from 'react';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardContent,
  IonCard,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { selectGalaxy } from '../../../../store/game/systems/systems.selectors';
import SystemRow from './SystemRow';

const Systems: React.FC = () => {
  const galaxy = useSelector(selectGalaxy);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Solar systems</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>index</IonCol>
                <IonCol>name</IonCol>
                <IonCol>event</IonCol>
                <IonCol>bribe level</IonCol>
                <IonCol>status</IonCol>
                <IonCol>actions</IonCol>
              </IonRow>
              {galaxy.map((system) => (
                <SystemRow system={system} key={system.nameIndex} />
              ))}
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Systems;
