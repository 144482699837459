import React, { useEffect } from 'react';
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonButton,
  IonIcon,
} from '@ionic/react';
import './Navigator.css';
import { SystemPoint } from './SystemPoint';
import { useScroll } from './useScroll';
import { navigateOutline } from 'ionicons/icons';
import { MAXSOLARSYSTEM } from '../../../../app/data/constants/solar-systems';
import { AvailableDistance } from './AvailableDistance';
import { selectCurrentSystemIndex } from '../../../../store/game/systems/selectors/current-system.selector';
import { useSelector } from 'react-redux';

const systemIndexes = Array(MAXSOLARSYSTEM)
  .fill(0)
  .map((_, index) => index);

const Navigator: React.FC = () => {
  const scrollRef = React.createRef<HTMLIonContentElement>();
  const currentSystemIndex = useSelector(selectCurrentSystemIndex);
  const scrollToMe = useScroll(scrollRef);

  useEffect(() => {
    scrollToMe();
  }, [scrollToMe, currentSystemIndex]);

  return (
    <IonCard className="ion-no-margin">
      <IonCardContent>
        <IonButton size="small" className="actions" onClick={scrollToMe}>
          <IonIcon slot="icon-only" icon={navigateOutline} />
        </IonButton>
        <IonContent
          style={{ border: '1px solid white' }}
          className="space"
          ref={scrollRef}
          scrollX={false}
          scrollY={false}>
          <div className="map">
            <AvailableDistance />
            {systemIndexes.map((systemIndex) => (
              <SystemPoint key={systemIndex} systemIndex={systemIndex} />
            ))}
          </div>
        </IonContent>
      </IonCardContent>
    </IonCard>
  );
};

export default Navigator;
