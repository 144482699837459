import { shallowEqual, useSelector } from 'react-redux';
import React from 'react';
import { PlayerAction } from './PlayerAction';
import {
  selectActionsForBoard,
  selectCanPlunder,
} from '../../../../store/game/encounter/selectors/encounter-events.selector';
import { CargoModal } from '../../Cargo/CargoModal';
import { OpponentCargoContainer } from '../../Cargo/OpponentCargoContainer';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { PlayerCargoContainer } from '../../Cargo/PlayerCargoContainer';

const ActionsBoard: React.FC = () => {
  const availableActions = useSelector(selectActionsForBoard, shallowEqual);
  const canPlunder = useSelector(selectCanPlunder);

  return (
    <>
      {availableActions.map((action) => (
        <PlayerAction key={action} action={action} />
      ))}

      {canPlunder && (
        <CargoModal title="Plunder">
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <h1>Plunder opponent</h1>
                <OpponentCargoContainer />
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <h1>Your cargo</h1>
                <PlayerCargoContainer />
              </IonCol>
            </IonRow>
          </IonGrid>
        </CargoModal>
      )}
    </>
  );
};

export default ActionsBoard;
