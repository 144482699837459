import React from 'react';
import { IonGrid, IonRow } from '@ionic/react';
import { selectTravelEncountersIds } from '../../store/game/travel/selectors/encounters.selectors';
import { useSelector } from 'react-redux';
import { EncounterBadge } from './EncounterBadge';
import { eqByLength } from '../../app/utils';

export const TravelEncounters: React.FC<{ travelId: string }> = ({
  travelId,
}) => {
  const encounterIds = useSelector(
    selectTravelEncountersIds(travelId),
    eqByLength,
  );

  return (
    <IonGrid>
      <IonRow>
        {encounterIds
          .sort((a, b) => a - b)
          .reverse()
          .map((encounterId) => (
            <EncounterBadge
              key={encounterId}
              travelId={travelId}
              encounterId={encounterId.toString()}
            />
          ))}
      </IonRow>
    </IonGrid>
  );
};
