import {
  TradeItemType,
  tradeItemTypes,
} from '../../../../app/data/types/trade-item/trade-item-type';
import { ModalProps } from '../../../props/ModalProps';
import React, { useCallback, useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonModal,
  IonToolbar,
  IonButton,
  IonRange,
  IonLabel,
  IonCol,
  IonRow,
  IonGrid,
  IonText,
  IonCard,
  IonCardContent,
  IonButtons,
} from '@ionic/react';
import { selectCurrentSystemName } from '../../../../store/game/systems/selectors/current-system.selector';
import { useDispatch, useSelector } from 'react-redux';
import { useBuyItem } from '../../../../hooks/useBuyItem';
import {
  selectAvailableCredits,
  selectFinalBuyPrice,
  selectPossibleTradeItemQuantityToPurchase,
  selectPriceToBuy,
} from '../../../../store/derived/computational-selectors';
import NumberFormat from 'react-number-format';
import { ShipCargoIndicator } from '../../../Ship/ShipCargoIndicator/ShipCargoIndicator';
import {
  selectPlayerFilledCargoBays,
  selectPlayerTotalCargoBays,
  selectSkillLevel,
} from '../../../../store/game/ship/ship.selectors';
import { TRADER_SKILL } from '../../../../app/data/types/crew-member/skill';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

export const BuyTradeItem: React.FC<
  { tradeItemType: TradeItemType } & ModalProps
> = ({ tradeItemType, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const systemName = useSelector(selectCurrentSystemName);
  const traderSkill = useTypedSelector(selectSkillLevel(TRADER_SKILL));
  const possibleQuantity = useTypedSelector(
    selectPossibleTradeItemQuantityToPurchase(tradeItemType),
  );
  const standardPrice = useSelector(selectPriceToBuy(tradeItemType));
  const finalPrice = useSelector(selectFinalBuyPrice(tradeItemType));
  const availableCredits = useSelector(selectAvailableCredits);
  const [selectedQuantity, setSelectedQuantity] = useState(possibleQuantity);
  const buyAction = useBuyItem(tradeItemType, selectedQuantity);
  const total = useTypedSelector(selectPlayerTotalCargoBays);
  const filled = useSelector(selectPlayerFilledCargoBays);

  const buy = useCallback(() => {
    dispatch(buyAction());
    onClose();
  }, [dispatch, onClose, buyAction]);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <IonText>
              <h2>
                {tradeItemType} on {systemName} will cost you{' '}
                <NumberFormat
                  value={finalPrice}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' credits'}
                />{' '}
                for an item thanks to your {traderSkill} points of trading.
              </h2>
              <div className="ion-margin-top">
                <h2>
                  Standard price of {tradeItemType} here is{' '}
                  <NumberFormat
                    value={standardPrice}
                    displayType={'text'}
                    thousandSeparator={','}
                  />
                  &nbsp;credits though.
                </h2>
              </div>
              <div className="ion-margin-top">
                <h2>
                  You have{' '}
                  <NumberFormat
                    value={availableCredits}
                    displayType={'text'}
                    thousandSeparator={','}
                  />{' '}
                  credits now. You are going to buy {selectedQuantity} items. It
                  will cost you{' '}
                  <NumberFormat
                    value={finalPrice * selectedQuantity}
                    displayType={'text'}
                    thousandSeparator={','}
                  />{' '}
                  credits.
                </h2>
              </div>
            </IonText>
            {possibleQuantity > 1 && (
              <IonRange
                pin={true}
                min={1}
                max={possibleQuantity}
                step={1}
                value={selectedQuantity}
                snaps={true}
                onIonChange={(e) =>
                  setSelectedQuantity(e.detail.value as number)
                }
              >
                <IonLabel slot="start">1</IonLabel>
                <IonLabel slot="end">{possibleQuantity}</IonLabel>
              </IonRange>
            )}

            <IonText>
              <p>Ship cargo</p>
            </IonText>
            <ShipCargoIndicator
              total={total}
              filled={filled}
              selected={selectedQuantity}
            />
          </IonCardContent>
        </IonCard>
        <IonGrid>
          {possibleQuantity > 0 && (
            <IonRow>
              <IonCol>
                <IonButton expand="block" onClick={buy}>
                  Buy
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};
