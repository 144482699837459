import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectFuelAmountToPurchase,
  selectHullToRepair,
} from '../../../../store/derived/computational-selectors';
import { useBuyFuel } from '../../../../hooks/useBuyFuel';
import { useRepairHull } from '../../../../hooks/useRepairHull';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

export const useShipHealthCheck = () => {
  const fuelAmountToPurchase = useTypedSelector(selectFuelAmountToPurchase);
  const hullToRepair = useTypedSelector(selectHullToRepair);
  const [buyFuel] = useBuyFuel();
  const [repair] = useRepairHull();
  const refuelBtn = <a onClick={buyFuel}>Refuel</a>;
  const repairBtn = <a onClick={repair}>Repair</a>;

  if (fuelAmountToPurchase > 0 && hullToRepair > 0) {
    return (
      <>
        {refuelBtn}&nbsp; and
        {repairBtn}&nbsp; your ship before travel
      </>
    );
  }

  if (fuelAmountToPurchase > 0) {
    return <>{refuelBtn}&nbsp; your ship before travel</>;
  }

  if (hullToRepair) {
    return <>{repairBtn}&nbsp; your ship before travel</>;
  }

  return <>Your ship is ready to travel</>;
};
