import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonNote,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectReputationRecords,
  selectReputationScore,
} from '../../../store/game/scores/scores.selectors';

const ReputationRecords: React.FC = () => {
  const reputationRecords = useSelector(selectReputationRecords);
  const reputationRecordScore = useSelector(selectReputationScore);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Reputation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {reputationRecords.map((record) => (
              <IonItem key={record.index}>
                <IonLabel>{record.reason}</IonLabel>
                <IonNote color="green" slot="end">
                  {record.score}
                </IonNote>
              </IonItem>
            ))}
            <IonItem>
              <IonLabel>Current score</IonLabel>
              <IonNote slot="end">{reputationRecordScore}</IonNote>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ReputationRecords;
