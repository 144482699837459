// Tradeitems
export const WATER = 'Water';
export const FURS = 'Furs';
export const FOOD = 'Food';
export const ORE = 'Ore';
export const GAMES = 'Games';
export const FIREARMS = 'Firearms';
export const MEDICINE = 'Medicine';
export const MACHINES = 'Machines';
export const NARCOTICS = 'Narcotics';
export const ROBOTS = 'Robots';

export const tradeItemTypes = [
  WATER,
  FURS,
  FOOD,
  ORE,
  GAMES,
  FIREARMS,
  MEDICINE,
  MACHINES,
  NARCOTICS,
  ROBOTS,
] as const;

export type TradeItemType = typeof tradeItemTypes[number];
