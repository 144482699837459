import {
  ATTACK,
  FLEE,
  IGNORE,
  MUTUAL_IGNORE,
  PLUNDER,
  SURRENDER,
} from './action-type';

export const possiblePirateActions = [ATTACK, FLEE, IGNORE, SURRENDER] as const;

export type PossiblePirateAction = typeof possiblePirateActions[number];

export const possiblePlayerReactionToPirateAttack = [
  ATTACK,
  FLEE,
  SURRENDER,
] as const;
export const possiblePlayerReactionToPirateSurrender = [
  ATTACK,
  FLEE,
  PLUNDER,
] as const;

export const possiblePlayerReactionToPirateIgnore = [
  ATTACK,
  MUTUAL_IGNORE,
] as const;

export type PossibleReactionToPirate =
  | typeof possiblePlayerReactionToPirateAttack[number]
  | typeof possiblePlayerReactionToPirateIgnore[number]
  | typeof possiblePlayerReactionToPirateSurrender[number];

export const possibleReactionsToPirate: Record<
  PossiblePirateAction,
  readonly PossibleReactionToPirate[]
> = {
  [ATTACK]: possiblePlayerReactionToPirateAttack,
  [IGNORE]: possiblePlayerReactionToPirateIgnore,
  [FLEE]: possiblePlayerReactionToPirateIgnore,
  [SURRENDER]: possiblePlayerReactionToPirateSurrender,
};
