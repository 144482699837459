import { converge } from 'ramda';
import { getOpponentShip } from '../../../../app/core/encounter/opponent-ship/get-opponent-ship';
import { selectDifficulty, selectStatus } from '../../status/status.selectors';
import { selectPlayerShip } from '../../ship/ship.selectors';
import { selectPlayerWorth } from '../../../derived/computational-selectors';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { selectCurrentSystem } from '../../systems/selectors/current-system.selector';
import { RootState } from '../../../index';
import { ActorType } from '../../../../app/data/interaction/actor-type';
import { IShip } from '../../../../app/data/types/ship/ship';

export const selectGetOpponentShip: (
  state: RootState,
) => (type: ActorType) => IShip = converge(getOpponentShip, [
  selectDifficulty,
  selectPlayerShip,
  selectPlayerWorth,
  selectPoliceRecordScore,
  selectCurrentSystem,
  selectStatus,
]);
