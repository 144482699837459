import { IShip } from '../../../data/types/ship/ship';
import { EnemyShipPrice } from '../../ship/ship.prices';
import { SPACE_MONSTER_SHIP } from '../../../data/types/ship/ship-type';
import { MONSTER_KILLED_BOUNTY } from '../../../data/constants/special-events';

export const getBounty = (difficulty: number, destroyedShip: IShip): number => {
  if (destroyedShip.type === SPACE_MONSTER_SHIP) {
    return MONSTER_KILLED_BOUNTY;
  }

  const bounty = EnemyShipPrice(difficulty)(destroyedShip) * 0.005;

  return Math.floor(Math.min(Math.max(bounty, 25), 2500));
};
