import { ActionType, createReducer } from 'typesafe-actions';
import { BalanceRecord } from '../../../app/data/types/balance';
import * as actions from './balance.actions';
import { v4 as uuidv4 } from 'uuid';

export type BalanceActions = ActionType<typeof actions>;

const initialState: BalanceRecord[] = [];

export const balanceReducer = createReducer<BalanceRecord[], BalanceActions>(
  initialState,
)
  .handleAction(actions.registerBalanceChange, (state, action) => [
    ...state,
    { id: uuidv4(), ...action.payload },
  ])
  .handleAction(actions.removeBalanceChange, (state, { payload }) =>
    state.filter((record) => record.id !== payload),
  );
