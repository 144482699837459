import { createAction } from 'typesafe-actions';
import * as actionTypes from './status.action-type';
import { IStatus } from '../../../app/data/types/game/status';

export const initStatus = createAction(actionTypes.INIT_STATUS)<IStatus>();

export const getLoan = createAction(actionTypes.LOAN_MONEY)<number>();

export const payLoanBack = createAction(actionTypes.PAY_BACK)<number>();

export const increaseDays = createAction(actionTypes.INCREASE_DAYS)<number>();

export const fabricRipped = createAction(actionTypes.FABRIC_RIPPED)();
export const decreaseFabricRipProbability = createAction(
  actionTypes.DECREASE_FABRIC_RIP_PROBABILITY,
)();

export const buyMoon = createAction(actionTypes.BUY_MOON)();

export const buyNewspaper = createAction(actionTypes.BUY_NEWSPAPER)();
export const registerNewspaperPurchase = createAction(
  actionTypes.REGISTER_NEWSPAPER_PURCHASE,
)<number>();
