import { converge } from 'ramda';
import { selectPlayerAvailableCargoBaysAmount } from '../../ship/ship.selectors';
import {
  selectPoliceRecordScore,
  selectReputationScore,
} from '../../scores/scores.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { AVERAGESCORE } from '../../../../app/data/constants/reputation';
import { GET_REACTOR } from '../../../../app/data/types/system/special-event/special-event-type';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { REACTOR_CARGO_SIZE } from '../../../../app/data/constants/special-events';
import { invokeSpecialEvent } from '../special-events.actions';
import { AnyAction } from 'redux';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';

export const selectGetReactorAction = converge<
  AnyAction,
  [
    UnaryFunction<RootState, number>,
    UnaryFunction<RootState, number>,
    UnaryFunction<RootState, number>,
  ]
>(
  (availableCargoBays, policeRecordScore, reputationScore) => {
    const { title } = getSpecialEventConfig(GET_REACTOR);
    if (availableCargoBays < REACTOR_CARGO_SIZE) {
      return addNotification({
        title,
        message: `You need free ${REACTOR_CARGO_SIZE} cargo bays`,
      });
    }

    if (policeRecordScore >= DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record score is too high',
      });
    }

    if (reputationScore < AVERAGESCORE) {
      return addNotification({
        title,
        message: 'Your reputation is too low',
      });
    }
    return invokeSpecialEvent(GET_REACTOR);
  },
  [
    selectPlayerAvailableCargoBaysAmount,
    selectPoliceRecordScore,
    selectReputationScore,
  ],
);
