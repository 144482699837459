import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentSystemCoordinates } from '../../../../store/game/systems/selectors/current-system.selector';
import { selectCurrentFuel } from '../../../../store/game/ship/ship.selectors';
import { VISUAL_MULTIPLIER } from './visual-mulitplier';
import React from 'react';

export const AvailableDistance: React.FC = () => {
  const currentSystemCoordinates = useSelector(
    selectCurrentSystemCoordinates,
    shallowEqual,
  );
  const fuel = useSelector(selectCurrentFuel);
  return (
    <span
      className="available-distance"
      style={{
        width: `${fuel * VISUAL_MULTIPLIER * 2}px`,
        height: `${fuel * VISUAL_MULTIPLIER * 2}px`,
        top: `${
          currentSystemCoordinates.Y * VISUAL_MULTIPLIER -
          fuel * VISUAL_MULTIPLIER
        }px`,
        left: `${
          currentSystemCoordinates.X * VISUAL_MULTIPLIER -
          fuel * VISUAL_MULTIPLIER
        }px`,
      }}>
      {' '}
    </span>
  );
};
