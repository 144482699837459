import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TechLevel } from '../../../../app/data/labels/solar-system/technical-levels';
import { System } from '../../../../app/data/types/system/system';
import { getSystemNameByIndex } from '../../../../app/ui/solar-system.labels';
import useDoWarp from '../../../../hooks/useDoWarp';
import './SystemPoint.css';
import { getSystemUniqueId } from '../../../../app/core/solar-system/system-unique-id';
import { getSystemTechLevel } from '../../../../app/core/solar-system/system-tech-level';
import { hasWormhole } from '../../../../app/core/wormholes/has-wormhole';
import { selectIsCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';
import { selectIsVisited } from '../../../../store/game/travel/travel.selectors';
import { VISUAL_MULTIPLIER } from './visual-mulitplier';
import { selectSystemByIndex } from '../../../../store/game/systems/systems.selectors';

function getSystemColor(isCurrent: boolean, isVisited: boolean) {
  if (isCurrent) {
    return 'orange';
  }
  if (isVisited) {
    return 'white';
  }
  return 'grey';
}

function getSystemBorderColor(system: System, isVisited: boolean) {
  if (isVisited) {
    return `rgb(${
      (255 / TechLevel.length) * (getSystemTechLevel(system) + 1)
    }, 0, 0)`;
  }
  return 'transparent';
}

export const SystemPoint: React.FC<{ systemIndex: number }> = ({
  systemIndex,
}) => {
  const dispatch = useDispatch();
  const system = useSelector(selectSystemByIndex(systemIndex), shallowEqual);
  const isVisited = useSelector(selectIsVisited(systemIndex));
  const isCurrent = useSelector(selectIsCurrentSystem(systemIndex));
  const systemName = getSystemNameByIndex(systemIndex);
  const [showPopover, setShowPopover] = useState(false);
  const warpAction = useDoWarp(systemIndex);

  return (
    <button
      onMouseEnter={() => setShowPopover(true)}
      onMouseLeave={() => setShowPopover(false)}
      type="button"
      onClick={() => dispatch(warpAction)}
      className="system"
      style={{
        top: `${system.coordinates.Y * VISUAL_MULTIPLIER - 10}px`,
        left: `${system.coordinates.X * VISUAL_MULTIPLIER - 35}px`,
        display: 'inline-block',
        borderStyle: 'solid',
        borderRadius: '100%',
        borderWidth: '1px',
        borderColor: getSystemBorderColor(system, isVisited),
        color: getSystemColor(isCurrent, isVisited),
      }}>
      {systemName}

      {hasWormhole(system) && <span>*</span>}

      <div
        className="tooltip"
        style={{ display: showPopover ? 'block' : 'none' }}>
        <p>Coordinates: {getSystemUniqueId(system)}</p>
        {isVisited && !isCurrent && <p>Visited system</p>}
        {isCurrent && <p>Current system</p>}
      </div>
    </button>
  );
};
