import { TradeItemType } from '../../../../app/data/types/trade-item/trade-item-type';
import { System } from '../../../../app/data/types/system/system';
import React from 'react';
import { IonCol, IonRow, IonText } from '@ionic/react';
import { getSystemName } from '../../../../app/ui/solar-system.labels';
import {
  getPriceDifferenceColor,
  getPriceDifferenceLabel,
} from '../../../../app/ui/price.labels';
import {
  selectCurrentDay,
  selectDifficulty,
} from '../../../../store/game/status/status.selectors';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';
import { useSelector } from 'react-redux';

export const TradeItemProfit: React.FC<{
  tradeItemType: TradeItemType;
  system: System;
}> = ({ tradeItemType, system }) => {
  const currentDay = useSelector(selectCurrentDay);
  const difficulty = useSelector(selectDifficulty);
  const currentSystem = useSelector(selectCurrentSystem);
  const difference = getPriceDifferenceLabel(
    difficulty,
    currentDay,
    currentSystem,
    system,
    tradeItemType,
  );
  const color = getPriceDifferenceColor(difference);

  return (
    <IonRow>
      <IonCol>{getSystemName(system)}</IonCol>
      <IonCol>
        <IonText color={color}>{difference}</IonText>
      </IonCol>
    </IonRow>
  );
};
