import { RootState } from '../../../index';
import { System } from '../../../../app/data/types/system/system';
import { converge, equals, prop } from 'ramda';
import { Entities } from '../../../entities';
import { selectLastVisitedSystem } from '../../travel/selectors/last-visited-system.selector';
import { selectSystemsState } from '../systems.selectors';
import { pipe } from 'rxjs';
import { tradeItemTypes } from '../../../../app/data/types/trade-item/trade-item-type';
import { isTradeItemInConsumption } from '../../../../app/core/trade-items/trade-items.utils';
import { getSystemName } from '../../../../app/ui/solar-system.labels';

export const selectCurrentSystem: (state: RootState) => System = converge(
  (entities: Entities<System>, id: number) => entities[id],
  [selectSystemsState, selectLastVisitedSystem],
);

export const selectCurrentSystemName = pipe(selectCurrentSystem, getSystemName);

export const selectCurrentSystemIndex = pipe(
  selectCurrentSystem,
  prop('nameIndex'),
);

export const selectCurrentSystemSpecial = pipe(
  selectCurrentSystem,
  prop('special'),
);

export const selectCurrentSystemCoordinates = pipe(
  selectCurrentSystem,
  prop('coordinates'),
);

export const selectCurrentSystemTradeItemsInConsumption = pipe(
  selectCurrentSystem,
  (system) =>
    tradeItemTypes.filter((type) => isTradeItemInConsumption(type, system)),
);

export const selectIsCurrentSystem = (systemIndex: number) =>
  pipe(selectCurrentSystem, pipe(prop('nameIndex'), equals(systemIndex)));
