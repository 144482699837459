import { converge, prop } from 'ramda';
import { selectClicks } from './clicks.selector';
import { selectCurrentTravelEncounters } from './current-travel-encounters.selector';
import { Encounter } from '../../../../app/data/interaction/encounter';
import { RootState } from '../../../index';
import { pipe } from 'rxjs';
import { Entities } from '../../../entities';

export const selectCurrentEncounter: (state: RootState) => Encounter = converge(
  (clicks: number, encounters: Entities<Encounter>) => {
    return encounters[clicks];
  },
  [selectClicks, selectCurrentTravelEncounters],
);

export const selectCurrentEncounterId = pipe(
  selectCurrentEncounter,
  (encounter) => (encounter ? encounter.click : null),
);

export const selectIsCurrentEncounterAccepted = pipe(
  selectCurrentEncounter,
  (encounter) => (encounter ? encounter.accepted : false),
);

export const selectCurrentEncounterType = pipe(
  selectCurrentEncounter,
  prop('type'),
);
