import { IShip } from '../data/types/ship/ship';
import { INITIAL_SHIP_TYPE } from '../data/constants/ship-types';
import { PULSE_LASER_WEAPON } from '../data/types/ship/weapon/weapon-type';
import { WEAPONS, SHIELDS, GADGETS } from '../config/equipment.configs';
import { makeWeapon } from '../factories/ship-equipment';
import { shipConfigs } from '../config/ship/ship.configs';

export const initShip = (): IShip => ({
  type: INITIAL_SHIP_TYPE,
  cargo: [],
  equipment: {
    [WEAPONS]: [makeWeapon(PULSE_LASER_WEAPON)],
    [SHIELDS]: [],
    [GADGETS]: [],
  },
  extra: [],
  commander: null,
  crew: [],
  fuel: shipConfigs[INITIAL_SHIP_TYPE].fuelTanks,
  hull: shipConfigs[INITIAL_SHIP_TYPE].hullStrength,
  tribbles: 0,
});
