import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import { difficultyLevels, EASY } from '../../../app/data/constants/difficulty';
import { difficultyLevelLabel } from '../../../app/data/labels/difficulty-level';
import { makeGameId } from '../utils';
import React, { useState } from 'react';
import { DEFAULT_PLAYER_NAME } from '../../../app/data/labels/mercenaries';
import { useHistory } from 'react-router';
import {
  ENGINEER_SKILL,
  FIGHTER_SKILL,
  PILOT_SKILL,
  Skills,
  SkillType,
  skillTypes,
  TRADER_SKILL,
} from '../../../app/data/types/crew-member/skill';
import { MAXSKILL } from '../../../app/data/constants';

export const NewGame: React.FC = () => {
  const [difficulty, setDifficulty] = useState(EASY);
  const [playerName, setPlayerName] = useState(DEFAULT_PLAYER_NAME);
  const [skills, setSkills] = useState<Skills>({
    [PILOT_SKILL]: 1,
    [TRADER_SKILL]: 1,
    [FIGHTER_SKILL]: 1,
    [ENGINEER_SKILL]: 1,
  });
  const remainingSkillPoints =
    2 * MAXSKILL -
    skills[PILOT_SKILL] -
    skills[TRADER_SKILL] -
    skills[FIGHTER_SKILL] -
    skills[ENGINEER_SKILL];
  const history = useHistory();

  function start() {
    history.push(`/game/${makeGameId(difficulty, playerName, skills)}`);
    window.location.reload();
  }

  function increaseSkill(skillType: SkillType) {
    setSkills({
      ...skills,
      [skillType]: skills[skillType] + 1,
    });
  }

  function decreaseSkill(skillType: SkillType) {
    setSkills({
      ...skills,
      [skillType]: skills[skillType] - 1,
    });
  }

  return (
    <IonRow className="ion-justify-content-center">
      <IonCol sizeLg="4" size="12">
        <IonText>
          <h3>Your commander</h3>
        </IonText>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Difficulty</IonLabel>
            <IonSelect
              value={difficulty}
              okText="Okay"
              cancelText="Dismiss"
              onIonChange={(e) => setDifficulty(e.detail.value)}>
              {difficultyLevels.map((difficultyLevel) => (
                <IonSelectOption key={difficultyLevel} value={difficultyLevel}>
                  {difficultyLevelLabel[difficultyLevel]}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Commander name</IonLabel>
            <IonInput
              value={playerName}
              placeholder="Name is required"
              onIonChange={(e) => setPlayerName(e.detail.value || '')}
            />
          </IonItem>

          <IonRow>
            {skillTypes.map((skillType) => (
              <IonCol key={skillType}>
                <IonItem>
                  <IonLabel position="stacked">{skillType} skill</IonLabel>
                  <IonRow className="ion-align-items-center">
                    <IonButton
                      onClick={() => decreaseSkill(skillType)}
                      disabled={skills[skillType] === 1}>
                      -
                    </IonButton>
                    <IonText>
                      <p>{skills[skillType]}</p>
                    </IonText>
                    <IonButton
                      onClick={() => increaseSkill(skillType)}
                      disabled={
                        skills[skillType] === MAXSKILL ||
                        remainingSkillPoints === 0
                      }>
                      +
                    </IonButton>
                  </IonRow>
                </IonItem>
              </IonCol>
            ))}
          </IonRow>

          <IonItem>
            <IonLabel>Remaining skill points: {remainingSkillPoints}</IonLabel>
          </IonItem>
        </IonList>
        <IonButton
          disabled={remainingSkillPoints > 0}
          className="ion-margin"
          onClick={start}>
          Start new game
        </IonButton>
      </IonCol>
    </IonRow>
  );
};
