import { pipe } from 'rxjs';
import { GameState } from '../..';
import {
  ARTIFACT_DELIVERED_CODE,
  ARTIFACT_NOT_TAKEN_CODE,
  ARTIFACT_ON_BOARD_CODE,
  EXPERIMENT_LAST_DAY,
  EXPERIMENT_NOT_STARTED_CODE,
  EXPERIMENT_STOPPED_CODE,
  JAREK_DELIVERED_CODE,
  JAREK_NOT_TAKEN_CODE,
  JAREK_ON_BOARD_CODE,
  MEDICINE_DELIVERED_CODE,
  MEDICINE_NOT_TAKEN_CODE,
  MEDICINE_ON_BOARD_CODE,
} from '../../../app/data/constants/special-events';
import {
  ALIEN_ARTIFACT_QUEST,
  EXPERIMENT_QUEST,
  JAPORI_DESEASE_QUEST,
  JAREK_QUEST,
  QuestType,
  REACTOR_QUEST,
} from '../../../app/data/types/game/quest-status';
import { selectGame } from '../../selectors';

export const selectQuestStatusState = pipe(
  selectGame,
  (game: GameState) => game.questStatus,
);

export const selectQuestStatus = (type: QuestType) =>
  pipe(selectQuestStatusState, (state) => state[type]);

export const selectIsExperimentNotStarted = pipe(
  selectQuestStatus(EXPERIMENT_QUEST),
  (status) => status === EXPERIMENT_NOT_STARTED_CODE,
);

export const selectIsArtifactNotTaken = pipe(
  selectQuestStatus(ALIEN_ARTIFACT_QUEST),
  (status) => status === ARTIFACT_NOT_TAKEN_CODE,
);

export const selectIsArtifactOnBoard = pipe(
  selectQuestStatus(ALIEN_ARTIFACT_QUEST),
  (status) => status === ARTIFACT_ON_BOARD_CODE,
);

export const selectIsArtifactDelivered = pipe(
  selectQuestStatus(ALIEN_ARTIFACT_QUEST),
  (status) => status === ARTIFACT_DELIVERED_CODE,
);

export const selectIsJarekNotTaken = pipe(
  selectQuestStatus(JAREK_QUEST),
  (status) => status === JAREK_NOT_TAKEN_CODE,
);

export const selectIsJarekOnBoard = pipe(
  selectQuestStatus(JAREK_QUEST),
  (status) => status === JAREK_ON_BOARD_CODE,
);

export const selectIsJarekDelivered = pipe(
  selectQuestStatus(JAREK_QUEST),
  (status) => status === JAREK_DELIVERED_CODE,
);

export const selectIsMedicineNotTaken = pipe(
  selectQuestStatus(JAPORI_DESEASE_QUEST),
  (status) => status === MEDICINE_NOT_TAKEN_CODE,
);

export const selectIsMedicineOnBoard = pipe(
  selectQuestStatus(JAPORI_DESEASE_QUEST),
  (status) => status === MEDICINE_ON_BOARD_CODE,
);

export const selectIsMedicineDelivered = pipe(
  selectQuestStatus(JAPORI_DESEASE_QUEST),
  (status) => status === MEDICINE_DELIVERED_CODE,
);

export const selectIsExperimentOngoing = pipe(
  selectQuestStatus(EXPERIMENT_QUEST),
  (status) => status > 0 && status < EXPERIMENT_LAST_DAY,
);

export const selectIsExperimentPerformed = pipe(
  selectQuestStatus(EXPERIMENT_QUEST),
  (status) => status === EXPERIMENT_LAST_DAY,
);

export const selectIsExperimentStopped = pipe(
  selectQuestStatus(EXPERIMENT_QUEST),
  (status) => status === EXPERIMENT_STOPPED_CODE,
);

export const selectIsReactOnBoard = pipe(
  selectQuestStatus(REACTOR_QUEST),
  (status) => status > 0 && status < 21,
);
