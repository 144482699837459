import {
  ActorType,
  CAPTAIN,
  MANTIS,
} from '../../../data/interaction/actor-type';
import { ShipType } from '../../../data/types/ship/ship-type';
import { IShield } from '../../../data/types/ship/shield/shield';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { getRandom } from '../../../utils';

export const getHull = (
  opponentType: ActorType,
  opponentShipType: ShipType,
  shields: IShield[],
): number => {
  if (opponentType === MANTIS || opponentType === CAPTAIN) {
    return shipConfigs[opponentShipType].hullStrength;
  }

  // If there are shields, the hull will probably be stronger
  if (shields.length > 0 && getRandom(0, 10) <= 7) {
    return shipConfigs[opponentShipType].hullStrength;
  }

  return Math.max(
    ...Array(5)
      .fill(0)
      .map(() => 1 + getRandom(0, shipConfigs[opponentShipType].hullStrength)),
  );
};
