import { getRandom } from '../../../utils';
import { makeCrewMember } from '../../../factories/crew-member';
import { System } from '../../../data/types/system/system';
import { MAXSKILL } from '../../../data/constants';
import { KRAVATSYSTEM } from '../../../data/constants/solar-systems';
import { CrewMember } from '../../../data/types/crew-member/crew-member';
import { ShipType } from '../../../data/types/ship/ship-type';
import {
  PILOT_SKILL,
  FIGHTER_SKILL,
  TRADER_SKILL,
  ENGINEER_SKILL,
} from '../../../data/types/crew-member/skill';
import { IShip } from '../../../data/types/ship/ship';
import { isWildOnBoard } from '../../ship/crew/is-on-board';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { mercenaryNames } from '../../../data/labels/mercenaries';
import { shuffle } from '../../../utils/shuffle-array';
import { HARD } from '../../../data/constants/difficulty';

function getSkillLevel() {
  return 1 + getRandom(0, MAXSKILL);
}

function getEngineerSkill(
  warpSystem: System,
  difficulty: number,
  playerShip: IShip,
): number {
  // when we fly to KRAVAT with wild on board we think that it's a wild quest
  // KRAVAT is a destination point, for some reason we meet a lot of engineers there
  if (
    warpSystem.nameIndex === KRAVATSYSTEM &&
    isWildOnBoard(playerShip) &&
    getRandom(0, 10) < difficulty + 1
  ) {
    return MAXSKILL;
  }

  return getSkillLevel();
}

export const getOpponentCommander = (
  warpSystem: System,
  difficulty: number,
  playerShip: IShip,
) => {
  return makeCrewMember(shuffle(mercenaryNames)[0], {
    [PILOT_SKILL]: getSkillLevel(),
    [FIGHTER_SKILL]: getSkillLevel(),
    [TRADER_SKILL]: getSkillLevel(),
    [ENGINEER_SKILL]: getEngineerSkill(warpSystem, difficulty, playerShip),
  });
};

export const getOpponentCrewMembers = (
  opponentType: ShipType,
  difficulty: number,
): CrewMember[] => {
  const crewCabines = shipConfigs[opponentType].crewCabines;
  let crewLength = getRandom(0, crewCabines);

  if (difficulty >= HARD && crewLength < crewCabines) {
    crewLength += 1;
  }

  return Array(crewLength)
    .fill(0)
    .map(() => makeCrewMember());
};
