import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './systems.actions';
import * as actionTypes from './systems.action-types';
import { Entities, mapArrayToEntities, updateEntity } from '../../entities';
import { System } from '../../../app/data/types/system/system';

function mapSystemsToEntities(systems: System[]): Entities<System> {
  return mapArrayToEntities<System>(systems, (system) =>
    system.nameIndex.toString(),
  );
}

export const initialSystemsState: Entities<System> = {};

export type SystemsActions = ActionType<typeof actions>;

export const systemsReducer = createReducer<Entities<System>, SystemsActions>(
  initialSystemsState,
)
  .handleAction(actions.initSystems, (_, action) =>
    mapSystemsToEntities(action.payload),
  )
  .handleAction(actions.setSystemsTradeItemQuantities, (_, action) =>
    mapSystemsToEntities(action.payload),
  )
  .handleAction(
    actions.updateSystem,
    (state, { payload: { systemIndex, systemData } }) =>
      updateEntity(state, systemIndex, (system) => ({
        ...system,
        ...systemData,
      })),
  )
  .handleAction(
    actions.setSpecialEvent,
    (state, { payload: { systemIndex, event } }) =>
      updateEntity(state, systemIndex, (system) => ({
        ...system,
        special: event,
      })),
  )
  .handleAction(
    actions.disposeSpecialEvent,
    (state, { payload: systemIndex }) =>
      updateEntity(state, systemIndex, (system) => ({
        ...system,
        special: null,
      })),
  )
  .handleAction(
    [
      actions.increaseAvailableTradeItemAmount,
      actions.reduceAvailableTradeItemAmount,
    ],
    (state, { type, payload: { systemIndex, tradeItemType, amount } }) =>
      updateEntity(state, systemIndex, (system) => ({
        ...system,
        tradeItems: {
          ...system.tradeItems,
          [tradeItemType]:
            type === actionTypes.INCREASE_TRADE_ITEM_AMOUNT
              ? system.tradeItems[tradeItemType] + amount
              : system.tradeItems[tradeItemType] - amount,
        },
      })),
  );
