import { System } from '../../data/types/system/system';
import { getRandom } from '../../utils';
import { MAXWORMHOLE } from '../../data/constants';
import { isOnAcceptableDistanceForQuest } from '../quests/quests.utils';
import { shuffle } from '../../utils/shuffle-array';

export function setWormHoles(systems: System[]): System[] {
  const firstSystemIndex = getRandom(0, systems.length - 1);
  const firstSystem = systems.find(
    (system) => system.nameIndex === firstSystemIndex,
  );
  if (!firstSystem) {
    return systems;
  }

  let currentSystem = firstSystem;

  let i = 0;
  while (i < MAXWORMHOLE - 1) {
    const candidates = systems.filter(
      isOnAcceptableDistanceForQuest(currentSystem),
    );
    const randomCandidateNameIndex = shuffle(
      candidates.map((candidate) => candidate.nameIndex),
    )[0];
    currentSystem.wormHoleNameIndex = randomCandidateNameIndex;

    const nextSystem = systems.find(
      (system) => system.nameIndex === randomCandidateNameIndex,
    );
    if (nextSystem) {
      currentSystem = nextSystem;
    }
    // eslint-disable-next-line no-plusplus
    ++i;
  }

  currentSystem.wormHoleNameIndex = firstSystem.nameIndex;

  return systems;
}
