import { ActorType } from '../../../app/data/interaction/actor-type';
import React from 'react';
import { useSelector } from 'react-redux';
import { IonItem, IonLabel, IonNote } from '@ionic/react';
import { selectKilled } from '../../../store/game/encounter/selectors/killed.selectors';
import { pipe } from 'rxjs';
import { prop } from 'ramda';

export const KillCounter: React.FC<{ killType: ActorType }> = ({
  killType,
}) => {
  const kills = useSelector(pipe(selectKilled(killType), prop('length')));
  return (
    <IonItem>
      <IonLabel>{killType}</IonLabel>
      <IonNote slot="end">{kills}</IonNote>
    </IonItem>
  );
};
