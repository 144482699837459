import { SkillType } from '../../../data/types/crew-member/skill';
import { CrewMember } from '../../../data/types/crew-member/crew-member';

export const increaseCrewMemberSkill = (skillType: SkillType) => (
  member: CrewMember,
): CrewMember => {
  return {
    ...member,
    skills: {
      ...member.skills,
      [skillType]: member.skills[skillType] + 1,
    },
  };
};

export const decreaseCrewMemberSkill = (skillType: SkillType) => (
  member: CrewMember,
): CrewMember => {
  return {
    ...member,
    skills: {
      ...member.skills,
      [skillType]: member.skills[skillType] - 1,
    },
  };
};
