import React from 'react';
import { ShipCargoIndicator } from '../../Ship/ShipCargoIndicator/ShipCargoIndicator';
import { IonCol, IonGrid, IonRow } from '@ionic/react';

export const CargoContent: React.FC<{
  total: number;
  filled: number;
}> = ({ total, filled, children }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <ShipCargoIndicator total={total} filled={filled} />
        </IonCol>
      </IonRow>
      {children}
    </IonGrid>
  );
};
