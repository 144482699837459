import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { mapEntitiesToIds } from '../../../../store/entities';
import { selectTravelsState } from '../../../../store/game/travel/selectors/travels-state.selector';
import { TravelCard } from './TravelCard';

const TravelHistory: React.FC = () => {
  const travelLog = useSelector(selectTravelsState);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Travel history</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {mapEntitiesToIds(travelLog)
          .reverse()
          .map((id) => (
            <TravelCard key={id} travel={travelLog[id]} />
          ))}
      </IonContent>
    </IonPage>
  );
};

export default TravelHistory;
