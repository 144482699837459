import { SKILL_INCREASE_PRICE } from '../../../../app/data/constants/special-events';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { SKILL_INCREASE } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';
import { converge } from 'ramda';
import { selectRandomSkillToIncrease } from '../../ship/ship.selectors';
import { selectAvailableCredits } from '../../../derived/computational-selectors';
import { isNone } from 'fp-ts/Option';
import { SkillType } from '../../../../app/data/types/crew-member/skill';

export const selectSkillIncreaseAction = converge(
  (credits: number, randomSkill: SkillType | null) => {
    const { title } = getSpecialEventConfig(SKILL_INCREASE);
    if (isNone(randomSkill as any)) {
      return addNotification({
        title,
        message: `You have all skills maximum high!`,
      });
    }
    if (credits < SKILL_INCREASE_PRICE) {
      return addNotification({
        title,
        message: `You need ${SKILL_INCREASE_PRICE} credits to get it`,
      });
    }
    return invokeSpecialEvent(SKILL_INCREASE);
  },
  [selectAvailableCredits, selectRandomSkillToIncrease],
);
