import { NORMAL } from '../../../../app/data/constants/difficulty';
import { FLEEFROMINSPECTION } from '../../../../app/data/constants/police-record';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';

export const getFleeFromInspectionChangeScore = (
  policeRecordScore: number,
  difficulty: number,
) => {
  if (policeRecordScore > DUBIOUSSCORE) {
    return DUBIOUSSCORE - (policeRecordScore + (difficulty < NORMAL ? 0 : 1));
  }

  return FLEEFROMINSPECTION;
};
