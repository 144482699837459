import { IMPOSSIBLE } from '../../../data/constants/difficulty';

export const getIllegalsFineAmount = (
  currentWorth: number,
  difficulty: number,
) => {
  let Fine = currentWorth / ((IMPOSSIBLE + 2 - difficulty) * 10);
  if (Fine % 50 !== 0) {
    Fine += 50 - (Fine % 50);
  }
  return Math.max(100, Math.min(Fine, 10000));
};
