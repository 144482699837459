import {
  CLOSEDISTANCE,
  GALAXYWIDTH,
  GALAXYHEIGHT,
  MINDISTANCE,
} from '../../data/constants';
import { MAXSOLARSYSTEM } from '../../data/constants/solar-systems';
import { getRandom, SQR } from '../../utils';
import { Coordinates } from '../../data/types/system/coordinates';

export function sqrDistance(a: Coordinates, b: Coordinates): number {
  return SQR(a.X - b.X) + SQR(a.Y - b.Y);
}

export function realDistance(a: Coordinates, b: Coordinates): number {
  return Math.sqrt(sqrDistance(a, b));
}

export function roundRealDistance(a: Coordinates, b: Coordinates): number {
  return Math.round(realDistance(a, b));
}

// TODO: do we need to place systems in the center?
// function getCenteredX(i: number): number {
//   return (
//     (CLOSEDISTANCE >> 1) -
//     getRandom(0, CLOSEDISTANCE) +
//     (GALAXYWIDTH * (1 + 2 * (i % 3))) / 6
//   );
// }

// function getCenteredY(i: number): number {
//   return (
//     (CLOSEDISTANCE >> 1) -
//     getRandom(0, CLOSEDISTANCE) +
//     (GALAXYHEIGHT * (i < 3 ? 1 : 3)) / 4
//   );
// }

export function isTooClose(a: Coordinates, b: Coordinates): boolean {
  return sqrDistance(a, b) <= SQR(MINDISTANCE + 1);
}

export function isTooFar(a: Coordinates, b: Coordinates): boolean {
  return sqrDistance(a, b) > SQR(CLOSEDISTANCE);
}

export function isInAcceptableDistance(
  a: Coordinates,
  b: Coordinates,
): boolean {
  const distance = sqrDistance(a, b);
  return distance > SQR(MINDISTANCE + 1) && distance < SQR(CLOSEDISTANCE);
}

export function isNotSame(a: Coordinates, b: Coordinates): boolean {
  return a.X !== b.X && a.Y !== b.Y;
}

export function getNextCoordinates(
  existingCoordinates: Coordinates[],
): Coordinates {
  const nextCoordinates = {
    X: getRandom(1, GALAXYWIDTH - 1),
    Y: getRandom(1, GALAXYHEIGHT - 1),
  };
  const isTooCloseToExistingCoordinates = existingCoordinates.some(
    (coordinates) => isTooClose(coordinates, nextCoordinates),
  );

  // can't make it work yet, maximum call stack size exceeded
  // const hasCoordinatesInAcceptableDistance = existingCoordinates.some(coordinates => isInAcceptableDistance(coordinates, nextCoordinates));
  return isTooCloseToExistingCoordinates
    ? getNextCoordinates(existingCoordinates)
    : nextCoordinates;
}

export function getSystemsCoordinates(): Coordinates[] {
  const coordinatesMap: Coordinates[] = [];

  while (coordinatesMap.length < MAXSOLARSYSTEM) {
    coordinatesMap.push(getNextCoordinates(coordinatesMap));
  }

  return coordinatesMap;
}
