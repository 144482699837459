import React from 'react';
import { TradeItemType } from '../../../../app/data/types/trade-item/trade-item-type';
import { System } from '../../../../app/data/types/system/system';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import {
  getSystemPoliticsName,
  getSystemResourcesName,
  getSystemSizeName,
  getSystemTechLevelName,
  getSystemTradersActivity,
} from '../../../../app/ui/solar-system.labels';
import {
  getBasePrice,
  getCheapResourceFactor,
  getDemandedFactor,
  getExpensiveResourceFactor,
  getPriceDeviation,
  getSystemSizeFactor,
  getTradersStrengthFactor,
  getWantedFactor,
} from '../../../../app/core/trade-items/trade-items.prices';
import { selectCurrentDay } from '../../../../store/game/status/status.selectors';
import { useSelector } from 'react-redux';
import { getSystemStatus } from '../../../../app/core/solar-system/system-status';

export const PriceDefinition: React.FC<{
  tradeItemType: TradeItemType;
  system: System;
}> = ({ tradeItemType, system }) => {
  const day = useSelector(selectCurrentDay);

  const basePrice = getBasePrice(tradeItemType, system);
  const wantedFactor = getWantedFactor(tradeItemType, system);
  const tradersStrengthFactor = getTradersStrengthFactor(system);
  const systemSizeFactor = getSystemSizeFactor(system);
  const cheapResourceFactor = getCheapResourceFactor(tradeItemType, system);
  const expensiveResourceFactor = getExpensiveResourceFactor(
    tradeItemType,
    system,
  );
  const demandedFactor = getDemandedFactor(day, system, tradeItemType);
  const priceDeviation = getPriceDeviation(tradeItemType, system, day);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>Base price</IonCol>
        <IonCol>{basePrice}</IonCol>
        <IonCol className="ion-text-lowercase">
          ({getSystemTechLevelName(system)} tech level)
        </IonCol>
      </IonRow>
      {wantedFactor !== 1 && (
        <IonRow>
          <IonCol>Wanted factor</IonCol>
          <IonCol>{wantedFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            (wanted on {getSystemPoliticsName(system)})
          </IonCol>
        </IonRow>
      )}
      {tradersStrengthFactor !== 1 && (
        <IonRow>
          <IonCol>Traders strength factor</IonCol>
          <IonCol>{tradersStrengthFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            ({getSystemTradersActivity(system)} traders)
          </IonCol>
        </IonRow>
      )}
      {systemSizeFactor !== 1 && (
        <IonRow>
          <IonCol>System size factor</IonCol>
          <IonCol>{systemSizeFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            ({getSystemSizeName(system)} system size)
          </IonCol>
        </IonRow>
      )}
      {cheapResourceFactor !== 1 && (
        <IonRow>
          <IonCol>Cheap resource factor</IonCol>
          <IonCol>{cheapResourceFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            (cheap when {getSystemResourcesName(system)})
          </IonCol>
        </IonRow>
      )}
      {expensiveResourceFactor !== 1 && (
        <IonRow>
          <IonCol>Expensive resource factor</IonCol>
          <IonCol>{expensiveResourceFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            (expensive when {getSystemResourcesName(system)})
          </IonCol>
        </IonRow>
      )}
      {demandedFactor !== 1 && (
        <IonRow>
          <IonCol>Demanded resource factor</IonCol>
          <IonCol>{demandedFactor}</IonCol>
          <IonCol className="ion-text-lowercase">
            (demanded on {getSystemStatus(day, system)})
          </IonCol>
        </IonRow>
      )}
      {priceDeviation !== 0 && (
        <IonRow>
          <IonCol>Price deviation</IonCol>
          <IonCol>
            {priceDeviation > 0 ? '+' : ''}
            {priceDeviation}
          </IonCol>
          <IonCol>(daily deviation)</IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};
