// Other special events (Encounters)
// First is probability in 1000 that one could happen at all:
export const CHANCEOFVERYRAREENCOUNTER = 5;
export const MAXVERYRAREENCOUNTER = 6;
export const MARIECELESTE = 0;
export const CAPTAINAHAB = 1;
export const CAPTAINCONRAD = 2;
export const CAPTAINHUIE = 3;
export const BOTTLEOLD = 4;
export const BOTTLEGOOD = 5;
// Already done this encounter?
export const ALREADYMARIE = 1;
export const ALREADYAHAB = 2;
export const ALREADYCONRAD = 4;
export const ALREADYHUIE = 8;
export const ALREADYBOTTLEOLD = 16;
export const ALREADYBOTTLEGOOD = 32;
