import { IShip } from '../../../app/data/types/ship/ship';
import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
  IonText,
} from '@ionic/react';
import { getShipConfig } from '../../../app/config/ship/get-ship-config';
import CabineCard from '../../game/Ship/CabineCard';
import ordinal from 'ordinal';
import { useSlotsDescription } from '../../../hooks/useSlotsDescription';

export const ShipCrew: React.FC<{ ship: IShip }> = ({ ship }) => {
  const crewLength = ship.crew.length;
  const availableCabinesLength = getShipConfig(ship).crewCabines;

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Ship crew</IonCardTitle>
        <IonCardSubtitle className="ion-text-lowercase">
          {useSlotsDescription(
            availableCabinesLength,
            crewLength,
            'crew cabine',
          )}
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          {new Array(getShipConfig(ship).crewCabines)
            .fill(0)
            .map((cabine, index) => (
              <IonCol size="12" key={index}>
                <IonText>
                  <CabineCard index={index} person={ship.crew[index]} />
                </IonText>
              </IonCol>
            ))}
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};
