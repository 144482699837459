import {
  ATTACK,
  BRIBE,
  FLEE,
  IGNORE,
  INSPECT,
  MUTUAL_IGNORE,
  SUBMIT,
  SURRENDER,
} from './action-type';

export const possiblePoliceActions = [ATTACK, FLEE, IGNORE, INSPECT] as const;

export type PossiblePoliceAction = typeof possiblePoliceActions[number];

export const possiblePlayerReactionToPoliceAttack = [
  ATTACK,
  FLEE,
  SURRENDER,
] as const;
export const possiblePlayerReactionToPoliceIgnore = [
  ATTACK,
  MUTUAL_IGNORE,
] as const;
export const possiblePlayerReactionToPoliceInspect = [
  ATTACK,
  FLEE,
  SUBMIT,
  BRIBE,
] as const;

export type PossibleReactionToPolice =
  | typeof possiblePlayerReactionToPoliceAttack[number]
  | typeof possiblePlayerReactionToPoliceIgnore[number]
  | typeof possiblePlayerReactionToPoliceInspect[number];

export const possibleReactionsToPolice: Record<
  PossiblePoliceAction,
  readonly PossibleReactionToPolice[]
> = {
  [ATTACK]: possiblePlayerReactionToPoliceAttack,
  [FLEE]: possiblePlayerReactionToPoliceIgnore,
  [IGNORE]: possiblePlayerReactionToPoliceIgnore,
  [INSPECT]: possiblePlayerReactionToPoliceInspect,
};
