import { createAction } from 'typesafe-actions';
import { System } from '../../../app/data/types/system/system';
import * as actionTypes from './systems.action-types';
import { TradeItemType } from '../../../app/data/types/trade-item/trade-item-type';
import { SpecialEventType } from '../../../app/data/types/system/special-event/special-event-type';

export const initSystems = createAction(actionTypes.INIT_SOLAR_SYSTEMS)<
  System[]
>();

export const setSystemsTradeItemQuantities = createAction(
  actionTypes.UPDATE_TRADE_QUANTITIES,
)<System[]>();

export const updateSystem = createAction(actionTypes.UPDATE_SYSTEM)<{
  systemIndex: number;
  systemData: Partial<System>;
}>();

export const setSpecialEvent = createAction(actionTypes.SET_SPECIAL_EVENT)<{
  systemIndex: number;
  event: SpecialEventType;
}>();
export const disposeSpecialEvent = createAction(
  actionTypes.DISPOSE_SPECIAL_EVENT,
)<number>();

export const increaseAvailableTradeItemAmount = createAction(
  actionTypes.INCREASE_TRADE_ITEM_AMOUNT,
)<{ systemIndex: number; tradeItemType: TradeItemType; amount: number }>();

export const reduceAvailableTradeItemAmount = createAction(
  actionTypes.REDUCE_TRADE_ITEM_AMOUNT,
)<{ systemIndex: number; tradeItemType: TradeItemType; amount: number }>();
