import { converge } from 'ramda';
import { selectEncounterType } from '../encounter.selectors';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import {
  ActorType,
  PLAYER,
  POLICE,
} from '../../../../app/data/interaction/actor-type';
import { PSYCHOPATHSCORE } from '../../../../app/data/constants/police-record-score';
import { SURRENDER } from '../../../../app/data/interaction/action-type';
import { addNotification } from '../../notifications/notifications.actions';
import { makeActionEvent } from '../make-action-event';

export const selectSurrenderAction = converge(
  (encounterType: ActorType, policeRecordScore: number) => {
    if (encounterType === POLICE && policeRecordScore < PSYCHOPATHSCORE) {
      return addNotification({
        title: 'No mercy for psychopath',
        message:
          'Police does not accept surrender from pilots with such a low police record score',
      });
    }
    return makeActionEvent(PLAYER)(SURRENDER);
  },
  [selectEncounterType, selectPoliceRecordScore],
);
