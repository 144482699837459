export const TRAVEL_CLICKS = 21;

// Tradeable items
export const MAXTRADEITEM = 10; // Number of items traded

// Crewmembers. The commander is always the crewmember with index 0.
// Zeethibal is always the last
export const MAXCREWMEMBER = 31;

export const MAXSKILL = 10;

// Skills
export const MAXSKILLTYPE = 4;
export const SKILLBONUS = 3;
export const CLOAKBONUS = 2;

// The commander's ship
export const MAXTRIBBLES = 100000;

// Max Number of Tribble Buttons
export const TRIBBLESONSCREEN = 31;

// Propability in 1000 that a trader will make offer while in orbit
export const CHANCEOFTRADEINORBIT = 100;

// Cargo Dumping Codes. These identify the operation so we can reuse
// some of the Sell Cargo code.
// SELL is obvious, Dump is when in dock, Jettison is when in space.
export const SELLCARGO = 1;
export const DUMPCARGO = 2;
export const JETTISONCARGO = 3;

// System sizes (influences the number of goods available)
export const MAXSIZE = 5;

// Wormholes
export const MAXWORMHOLE = 6;

export const GALAXYWIDTH = 150;
export const GALAXYHEIGHT = 110;
export const SHORTRANGEWIDTH = 140;
export const SHORTRANGEHEIGHT = 140;
export const SHORTRANGEBOUNDSX = 10;
export const BOUNDSX = 5;
export const BOUNDSY = 20;
export const MINDISTANCE = 6;
export const CLOSEDISTANCE = 13;
export const WORMHOLEDISTANCE = 3;
export const EXTRAERASE = 3;

export const FABRICRIPINITIALPROBABILITY = 25;
