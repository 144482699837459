import { shuffle } from '../../utils/shuffle-array';
import makeArray from '../../utils/make-array';
import { specialEventConfigs } from '../../config/special-event/special-event.configs';
import { SpecialEventType } from '../../data/types/system/special-event/special-event-type';

export const specialEventsToSamples = (events: SpecialEventType[]) => {
  // we prepare simple number array with quest indexes occuring as often as it's specified in special event occurence counter
  const eventSamples = events.flatMap((eventType) =>
    makeArray<SpecialEventType>(
      specialEventConfigs[eventType].occurrence,
      eventType,
    ),
  );

  return shuffle(eventSamples);
};
