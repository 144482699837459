import { System } from '../../data/types/system/system';
import {
  BOREDOM,
  COLD,
  CROPFAILURE,
  DROUGHT,
  LACKOFWORKERS,
  PLAGUE,
  SystemStatus,
  UNEVENTFUL,
  WAR,
} from '../../data/types/system/system-status';
import { getSystemName } from '../../ui/solar-system.labels';
import { shuffle } from '../../utils/shuffle-array';
import { getSystemStatus } from '../solar-system/system-status';

const headlines = [
  'Reports of',
  'News of',
  'New Rumors of',
  'Sources say',
  'Notice:',
  'Evidence Suggests',
];
const statusDescription: Record<SystemStatus, string> = {
  [UNEVENTFUL]: 'nothing',
  [WAR]: 'Strife and War',
  [PLAGUE]: 'Plague Outbreaks',
  [DROUGHT]: 'Severe Drought',
  [BOREDOM]: 'Terrible Boredom',
  [COLD]: 'Cold Weather',
  [CROPFAILURE]: 'Crop Failures',
  [LACKOFWORKERS]: 'Labor Shortages',
};

export const getSystemStatusNewsLine = (currentDay: number, system: System) => {
  return `
    ${shuffle(headlines)[0]}
    ${statusDescription[getSystemStatus(currentDay, system)]}
    in the
    ${getSystemName(system)} System
  `;
};
