import { getRandom } from '../utils';
import { makeSkills } from './skills';
import { CrewMember } from '../data/types/crew-member/crew-member';
import { mercenaryNames } from '../data/labels/mercenaries';

export function makeCrewMember(
  name = mercenaryNames[getRandom(0, mercenaryNames.length - 1)],
  skills = makeSkills(),
): CrewMember {
  return {
    name,
    skills,
    equipment: [],
  };
}
