import { complement } from 'ramda';
import { PurchasedItem } from '../../../data/types/trade-item/purchased-item';

export const isPurchasedGroupEqual = (a: PurchasedItem) => (
  b: PurchasedItem,
): boolean => {
  return a.type === b.type && a.itemPrice === b.itemPrice;
};

export function getGroupedCargo(cargo: PurchasedItem[]): PurchasedItem[] {
  return cargo.reduce<PurchasedItem[]>((groupedCargo, item) => {
    const existingGroup = groupedCargo.find(isPurchasedGroupEqual(item));
    if (!existingGroup) {
      return [...groupedCargo, item];
    }
    return [
      {
        ...existingGroup,
        quantity: existingGroup.quantity + item.quantity,
      },
      ...groupedCargo.filter(complement(isPurchasedGroupEqual(item))),
    ];
  }, []);
}
