import { ShipType } from '../../../app/data/types/ship/ship-type';
import { getShipTypeImage } from '../../../app/ui/ship.img';
import { IonAvatar } from '@ionic/react';
import React from 'react';

export const ShipAvatar: React.FC<{ shipType: ShipType }> = ({ shipType }) => (
  <IonAvatar>
    <img
      className="pixelated"
      alt={shipType}
      src={getShipTypeImage(shipType)}
    />
  </IonAvatar>
);
