export const WARP = '[Travel] warp';
export const SINGULARITY_JUMP = '[Travel] jump';
export const START_TRAVEL = '[Travel] start';
export const MOVE = '[Travel] move';

export const ENCOUNTER = '[Travel] encounter';
export const INIT_ENCOUNTER = '[Encounter] init';
export const ACCEPT_ENCOUNTER = '[Encounter] accept';
export const DECLINE_ENCOUNTER = '[Encounter] decline';

export const ENCOUNTER_EVENT = '[Encounter] event';
export const DAMAGE_EVENT = '[Encounter] damage';
export const CHANGE_TURN = '[Encounter] change turn';

// 4 similar actions with [Player ship] - to be DRY?
export const DAMAGE_OPPONENT_SHIP_HULL = '[Opponent ship] damage hull';
export const DAMAGE_OPPONENT_SHIP_SHIELDS = '[Opponent ship] damage shields';
export const LOST_CARGO_OPPONENT = '[Opponent ship] lost cargo';
export const PLUNDER_CARGO_OPPONENT = '[Opponent ship] plunder cargo';

export const ARRIVE = '[Travel] arrive';

export const RAIDED = '[Travel] player raided';
export const INSPECTED = '[Traver] player inspected';
