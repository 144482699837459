import { pipe } from 'rxjs';
import { GameState } from '../..';
import { selectGame } from '../../selectors';

export const selectConfirmations = pipe(
  selectGame,
  (game: GameState) => game.confirmations,
);

export const selectFirstConfirmation = pipe(
  selectConfirmations,
  (confirmations) => confirmations[0] || null,
);

export const selectConfirmationById = (id: string) =>
  pipe(selectConfirmations, (confirmations) =>
    confirmations.find((confirmation) => confirmation.id === id),
  );
