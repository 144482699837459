import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentBalance } from '../../../store/game/balance/balance.selectors';
import NumberFormat from 'react-number-format';

export const CurrentBalanceMenuLabel: React.FC = () => {
  const currentBalance = useSelector(selectCurrentBalance);
  return (
    <IonNote slot="end" className="ion-text-uppercase">
      <NumberFormat
        value={currentBalance}
        displayType={'text'}
        thousandSeparator={','}
        suffix={' cr.'}
      />
    </IonNote>
  );
};
