import { useDispatch } from 'react-redux';
import { buyFuel } from '../store/game/ship/ship.actions';
import { selectFuelAmountToPurchase } from '../store/derived/computational-selectors';
import { useTypedSelector } from './useTypedSelector';

export const useBuyFuel = () => {
  const dispatch = useDispatch();

  const fuelAmountToPurchase = useTypedSelector(selectFuelAmountToPurchase);
  const isDisabled = fuelAmountToPurchase < 1;

  const purchaseFuel = () => {
    dispatch(buyFuel(fuelAmountToPurchase));
  };

  return [purchaseFuel, fuelAmountToPurchase, isDisabled] as const;
};
