import {
  Resource,
  NO_SPECIAL_RESOURCES,
  MINERAL_RICH,
  MINERAL_POOR,
  DESERT,
  LOTS_OF_WATER,
  RICH_SOIL,
  POOR_SOIL,
  LIFELESS,
  WEIRD_MUSHROOMS,
  LOTS_OF_HERBS,
  ARTISTIC,
  WARLIKE,
  RICH_FAUNA,
} from '../../types/system/special-resource';

export const SpecialResourcesLabels: Record<Resource, string> = {
  [NO_SPECIAL_RESOURCES]: 'Nothing special',
  [MINERAL_RICH]: 'Mineral rich',
  [MINERAL_POOR]: 'Mineral poor',
  [DESERT]: 'Desert',
  [LOTS_OF_WATER]: 'Sweetwater oceans',
  [RICH_SOIL]: 'Rich soil',
  [POOR_SOIL]: 'Poor soil',
  [RICH_FAUNA]: 'Rich fauna',
  [LIFELESS]: 'Lifeless',
  [WEIRD_MUSHROOMS]: 'Weird mushrooms',
  [LOTS_OF_HERBS]: 'Special herbs',
  [ARTISTIC]: 'Artistic populace',
  [WARLIKE]: 'Warlike populace',
};
