import { shipTypes } from '../../../../data/types/ship/ship-type';
import { ELITESCORE } from '../../../../data/constants/reputation';
import { getRandom, getTotalWeaponsPower } from '../../../../utils';
import {
  EncounterActionType,
  ATTACK,
  FLEE,
  SURRENDER,
} from '../../../../data/interaction/action-type';
import { IShip } from '../../../../data/types/ship/ship';
import { InteractionEvent } from '../../../../data/interaction/interaction-event';
import { getLastEvent } from '../../interaction';
import { Entities } from '../../../../../store/entities';
import { TRADER } from '../../../../data/interaction/actor-type';
import { DamageEvent } from '../../../../data/interaction/damage-event';

export const getTraderAttackReaction = (
  traderActions: Entities<InteractionEvent>,
  traderShip: IShip,
  playerReputationScore: number,
  damageLog: DamageEvent[],
): EncounterActionType => {
  const lastTraderAction = getLastEvent(traderActions).action;
  const opponentDamages = damageLog.filter((item) => item.actor === TRADER);
  const lastDamage = opponentDamages[opponentDamages.length - 1].damage;
  const hullBeforeDamage = traderShip.hull + lastDamage;
  if (traderShip.hull < (hullBeforeDamage * 2) / 3) {
    return getRandom(0, 10) > 3 ? SURRENDER : FLEE;
  }

  if (lastTraderAction !== FLEE) {
    if (getTotalWeaponsPower(traderShip) <= 0) return FLEE;

    if (
      getRandom(0, ELITESCORE) <=
      (playerReputationScore * 10) / (1 + shipTypes.indexOf(traderShip.type))
    )
      return FLEE;

    return ATTACK;
  }

  return FLEE;
};
