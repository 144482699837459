import { SpecialEventType } from '../../../app/data/types/system/special-event/special-event-type';
import { getSpecialEventConfig } from '../../../app/config/special-event/get-special-event-conifg';
import { addNotification } from '../notifications/notifications.actions';
import { addConfirmation } from '../confirmations/confirmations.actions';
import { validateSpecialEvent } from './special-events.actions';
import { GameActions } from '../../index';

export const makeSpecialEventAlert = (
  specialEvent: SpecialEventType,
): GameActions => {
  const { title, description: message, justAMessage } = getSpecialEventConfig(
    specialEvent,
  );
  const alertAction = justAMessage ? addNotification : addConfirmation;

  return alertAction({
    title,
    message,
    action: validateSpecialEvent(specialEvent),
  });
};
