import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './ship.actions';
import { defaultShip } from '../../../app/data/ships';
import { IShip } from '../../../app/data/types/ship/ship';
import {
  GADGETS,
  WEAPONS,
  SHIELDS,
} from '../../../app/config/equipment.configs';
import { doShieldRepairs } from '../../../app/core/travel/do-repairs';
import { shieldConfigs } from '../../../app/config/shield/shield.configs';
import { damageShield } from '../../../app/core/encounter/fight/damage';
import { getGroupedCargo } from '../../../app/core/ship/cargo/grouped-cargo';
import { fromNullable, map, toNullable } from 'fp-ts/Option';
import {
  decreaseCrewMemberSkill,
  increaseCrewMemberSkill,
} from '../../../app/core/crew-member/skill/change-crew-member-skill';
import { assignPersonalEquipment } from '../../../app/core/crew-member/personal-equipment';
import { pipe } from 'fp-ts/function';
import {
  damageShipReducer,
  damageShipShieldReducer,
  lostCargoReducer,
  plunderCargoReducer,
} from './damageable.reducer';

export const initialShipState: IShip = {
  ...defaultShip,
};

export type ShipActions = ActionType<typeof actions>;

export const shipReducer = createReducer<IShip, ShipActions>(initialShipState)
  .handleAction(actions.initShip, (_, action) => ({
    ...action.payload,
  }))
  .handleAction(actions.changeShip, ({ commander }, { payload }) => ({
    ...payload,
    commander,
  }))
  .handleAction(actions.spendFuel, (ship, action) => ({
    ...ship,
    fuel: ship.fuel - action.payload,
  }))
  .handleAction(actions.fillFuel, (ship, action) => ({
    ...ship,
    fuel: ship.fuel + action.payload,
  }))
  .handleAction(actions.repair, (ship, action) => ({
    ...ship,
    hull: ship.hull + action.payload,
  }))
  .handleAction(actions.repairOnTravel, (ship, action) => ({
    ...ship,
    hull: ship.hull + action.payload[0],
    equipment: {
      ...ship.equipment,
      [SHIELDS]: doShieldRepairs(shieldConfigs)(
        ship.equipment[SHIELDS],
        action.payload[1],
      ),
    },
  }))
  .handleAction(actions.damageShipHull, (ship, action) =>
    damageShipReducer(ship, action.payload),
  )
  .handleAction(actions.damageShipShields, (ship, action) =>
    damageShipShieldReducer(ship, action.payload),
  )
  .handleAction(actions.buyCargo, (ship, action) => ({
    ...ship,
    cargo: getGroupedCargo([...ship.cargo, action.payload]),
  }))
  .handleAction(actions.sellCargo, (ship, action) => ({
    ...ship,
    cargo: [...ship.cargo.filter((item) => item.type !== action.payload)],
  }))
  .handleAction(actions.returnCargo, (ship, { payload }) => ({
    ...ship,
    cargo: ship.cargo.filter((item) => item.day !== payload),
  }))
  .handleAction(actions.lostCargo, (ship, action) =>
    lostCargoReducer(ship, action.payload),
  )
  .handleAction(actions.plunderCargo, (ship, action) =>
    plunderCargoReducer(ship, action.payload),
  )
  .handleAction(actions.confiscateCargo, (ship, action) => ({
    ...ship,
    cargo: ship.cargo.filter((item) => item.type !== action.payload),
  }))
  .handleAction(actions.buyGadget, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [GADGETS]: [...ship.equipment[GADGETS], { ...action.payload }],
    },
  }))
  .handleAction(actions.buyWeapon, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [WEAPONS]: [...ship.equipment[WEAPONS], { ...action.payload }],
    },
  }))
  .handleAction(actions.buyShield, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [SHIELDS]: [...ship.equipment[SHIELDS], { ...action.payload }],
    },
  }))
  .handleAction(actions.sellGadget, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [GADGETS]: ship.equipment[GADGETS].filter(
        (gadget) => gadget.id !== action.payload,
      ),
    },
  }))
  .handleAction(actions.sellWeapon, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [WEAPONS]: ship.equipment[WEAPONS].filter(
        (weapon) => weapon.id !== action.payload,
      ),
    },
  }))
  .handleAction(actions.sellShield, (ship, action) => ({
    ...ship,
    equipment: {
      ...ship.equipment,
      [SHIELDS]: ship.equipment[SHIELDS].filter(
        (shield) => shield.id !== action.payload,
      ),
    },
  }))
  .handleAction(actions.getCrewMemberOnBoard, (ship, action) => ({
    ...ship,
    crew: [...ship.crew, action.payload],
  }))
  .handleAction(actions.dismissCrewMember, (ship, action) => ({
    ...ship,
    crew: ship.crew.filter((member) => member.name !== action.payload.name),
  }))
  .handleAction(actions.specialCrewMemberLeaves, (ship, action) => ({
    ...ship,
    crew: ship.crew.filter((member) => member.name !== action.payload),
  }))
  .handleAction(actions.increaseSkill, (ship, action) => ({
    ...ship,
    commander: pipe(
      fromNullable(ship.commander),
      map(increaseCrewMemberSkill(action.payload)),
      toNullable,
    ),
  }))
  .handleAction(actions.decreaseSkill, (ship, action) => ({
    ...ship,
    commander: pipe(
      fromNullable(ship.commander),
      map(decreaseCrewMemberSkill(action.payload)),
      toNullable,
    ),
  }))
  .handleAction(actions.getPersonalEquipment, (ship, action) => ({
    ...ship,
    commander: pipe(
      fromNullable(ship.commander),
      map(assignPersonalEquipment(action.payload)),
      toNullable,
    ),
  }))
  .handleAction(actions.getTribble, (ship) => ({
    ...ship,
    tribbles: 1,
  }))
  .handleAction(actions.tribblesSold, (ship) => ({
    ...ship,
    tribbles: 0,
  }));
