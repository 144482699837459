import { converge } from 'ramda';
import {
  selectCurrentDay,
  selectIsPlayerInDebt,
} from '../game/status/status.selectors';
import { selectRemindLoans } from '../game/settings/settings.selectors';

export const selectIsTimeToRemindLoans = converge(
  (inDebt: boolean, remind: boolean, day: number) => {
    return inDebt && remind && day % 5 === 0;
  },
  [selectIsPlayerInDebt, selectRemindLoans, selectCurrentDay],
);
