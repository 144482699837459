import { IonText } from '@ionic/react';
import React from 'react';
import { CrewMember } from '../../../../app/data/types/crew-member/crew-member';

export const SkillsCard: React.FC<{ commander: CrewMember }> = ({
  commander,
}) => {
  const getNumber = (num: number) => num.toString().padStart(2, ' ');

  return (
    <IonText style={{ fontSize: '1rem' }} color="light" className="code">
      <div>T{getNumber(commander.skills.Trader)}</div>
      <div>P{getNumber(commander.skills.Pilot)}</div>
      <div>F{getNumber(commander.skills.Fighter)}</div>
      <div>E{getNumber(commander.skills.Engineer)}</div>
    </IonText>
  );
};
