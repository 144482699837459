import { ShipType } from '../../data/types/ship/ship-type';
import { IShip } from '../../data/types/ship/ship';
import { GADGETS, SHIELDS, WEAPONS } from '../../config/equipment.configs';
import { shipConfigs } from '../../config/ship/ship.configs';

export const CreateShip = (type: ShipType): IShip => ({
  type,
  equipment: {
    [WEAPONS]: [],
    [SHIELDS]: [],
    [GADGETS]: [],
  },
  extra: [],
  cargo: [],
  fuel: shipConfigs[type].fuelTanks,
  hull: shipConfigs[type].hullStrength,
  commander: null,
  crew: [],
  tribbles: 0,
});
