import React, { useState } from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
} from 'recharts';
import { shallowEqual, useSelector } from 'react-redux';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonCardContent,
  IonCard,
} from '@ionic/react';
import { selectPricesHistory } from '../../../../store/game/systems/systems.selectors';
import { TradeItemModalProps } from '../../../props/TradeItemModalProps';
import { getSystemName } from '../../../../app/ui/solar-system.labels';
import { PriceDefinition } from './PriceDefinition';
import { QuantityDefinition } from './QuantityDefinition';
import { Profit } from './Profit';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';

const TradeItemInfo: React.FC<TradeItemModalProps> = ({
  tradeItemType,
  isOpen,
  onClose,
}) => {
  const system = useSelector(selectCurrentSystem, shallowEqual);
  const [mode, setMode] = useState('profit');
  const history = useSelector(selectPricesHistory(system, tradeItemType));
  const onTraderItemInfoClose = onClose;

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {tradeItemType} on {getSystemName(system)}
          </IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={mode}
            onIonChange={(e) => setMode(e.detail.value as string)}
          >
            <IonSegmentButton value="profit">Profit</IonSegmentButton>
            <IonSegmentButton value="price">Price</IonSegmentButton>
            <IonSegmentButton value="quantity">Quantity</IonSegmentButton>
          </IonSegment>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {mode === 'profit' && (
              <Profit
                tradeItemType={tradeItemType}
                onClose={onTraderItemInfoClose}
              />
            )}
            {mode === 'price' && (
              <>
                <LineChart
                  width={500}
                  height={300}
                  data={history}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <XAxis unit="day" dataKey="day" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    label="day"
                    contentStyle={{
                      backgroundColor: 'var(--ion-toolbar-background)',
                    }}
                  />
                  <Legend />
                  <Line type="linear" dataKey={mode} stroke="#82ca9d" />
                </LineChart>
                <PriceDefinition
                  tradeItemType={tradeItemType}
                  system={system}
                />
              </>
            )}
            {mode === 'quantity' && (
              <QuantityDefinition
                tradeItemType={tradeItemType}
                system={system}
              />
            )}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default TradeItemInfo;
