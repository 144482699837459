import {
  isTradeItemDemanded,
  isTradeItemInConsumption,
  isTradeItemInProduction,
} from './trade-items.utils';
import { IShip } from '../../data/types/ship/ship';
import { System } from '../../data/types/system/system';
import { TradeItemType } from '../../data/types/trade-item/trade-item-type';
import { MAXSKILL } from '../../data/constants';
import { DUBIOUSSCORE } from '../../data/constants/police-record-score';
import { TRADER_SKILL } from '../../data/types/crew-member/skill';
import { getPoliticConfig } from '../../config/politic/get-politic-config';
import { getSystemUniqueId } from '../solar-system/system-unique-id';
import { getSystemTechLevel } from '../solar-system/system-tech-level';
import { getTradeItemConfig } from '../../config/trade-item/get-trade-item-config';
import { getSystemResource } from '../solar-system/system-resource';
import { getSystemSize } from '../solar-system/system-size';
import { getSkillLevel } from '../crew-member/skill/skill-level';
import { getSeedRandom } from '../../utils';
import { pipe } from 'fp-ts/lib/function';
import { add, max, multiply } from 'ramda';
import { round } from 'ramda-adjunct';

// Determine base price on tech level of system
export const getBasePrice = (tradeItemType: TradeItemType, system: System) => {
  const tradeItemConfig = getTradeItemConfig(tradeItemType);
  return (
    tradeItemConfig.priceLowTech +
    getSystemTechLevel(system) * tradeItemConfig.priceInc
  );
};

// If a good is highly requested, increase the price
export const getWantedFactor = (
  tradeItemType: TradeItemType,
  system: System,
) => {
  return getPoliticConfig(system).wanted.includes(tradeItemType) ? 4 / 3 : 1;
};

// High trader activity decreases prices
export const getTradersStrengthFactor = (system: System) => {
  return (100 - 2 * getPoliticConfig(system).strengthTraders) / 100;
};

// Large system = high production decreases prices
export const getSystemSizeFactor = (system: System) => {
  return (100 - getSystemSize(system)) / 100;
};

// Cheap special resources price adaptation
export const getCheapResourceFactor = (
  tradeItemType: TradeItemType,
  system: System,
) => {
  return getTradeItemConfig(tradeItemType).cheapResource.includes(
    getSystemResource(system),
  )
    ? 0.75
    : 1;
};

// Expensive special resources price adaptation
export const getExpensiveResourceFactor = (
  tradeItemType: TradeItemType,
  system: System,
) => {
  return getTradeItemConfig(tradeItemType).expensiveResource.includes(
    getSystemResource(system),
  )
    ? 4 / 3
    : 1;
};

// In case of a special status, adapt price accordingly
export const getDemandedFactor = (
  currentDay: number,
  system: System,
  tradeItemType: TradeItemType,
): number => {
  return isTradeItemDemanded(currentDay, system, tradeItemType) ? 3 / 2 : 1;
};

export const getPriceDeviation = (
  tradeItemType: TradeItemType,
  system: System,
  currentDay: number,
): number => {
  const seed = `${tradeItemType}${getSystemUniqueId(system)}${currentDay}`;
  const tradeItemConfig = getTradeItemConfig(tradeItemType);
  return (
    getSeedRandom(`inc${seed}`)(0, tradeItemConfig.variance) -
    getSeedRandom(`dec${seed}`)(0, tradeItemConfig.variance)
  );
};

export const getStandardPrice = (
  tradeItemType: TradeItemType,
  system: System,
  currentDay: number,
) => {
  return pipe(
    getBasePrice(tradeItemType, system),
    multiply(getWantedFactor(tradeItemType, system)),
    multiply(getTradersStrengthFactor(system)),
    multiply(getSystemSizeFactor(system)),
    multiply(getCheapResourceFactor(tradeItemType, system)),
    multiply(getExpensiveResourceFactor(tradeItemType, system)),
    multiply(getDemandedFactor(currentDay, system, tradeItemType)),
    add(getPriceDeviation(tradeItemType, system, currentDay)),
    max<number>(0),
    round,
  );
};

//TODO: when player trader skill is changed, history is alternated
export const getPriceToBuy = (tradeItemType: TradeItemType) => (
  difficulty: number,
  currentDay: number,
  system: System,
) => {
  // If a system can't use something, its selling price is zero.
  if (!isTradeItemInProduction(tradeItemType, system)) {
    return 0;
  }
  return getStandardPrice(tradeItemType, system, currentDay);
};

export const applyTraderSkillToPrice = (
  price: number,
  difficulty: number,
  ship: IShip,
) => {
  return pipe(
    price,
    multiply(
      (103 + (MAXSKILL - getSkillLevel(TRADER_SKILL)(ship, difficulty))) / 100,
    ),
    round,
  );
};

export const getPriceToSell = (tradeItemType: TradeItemType) => (
  difficulty: number,
  currentDay: number,
  policeRecordScore: number,
  system: System,
): number => {
  if (!isTradeItemInConsumption(tradeItemType, system)) {
    return 0;
  }
  return getStandardPrice(tradeItemType, system, currentDay);
};

export const getMiddlemanSellPrice = (
  price: number,
  policeRecordScore: number,
) => {
  return policeRecordScore < DUBIOUSSCORE ? Math.round(price * 0.1) : 0;
};
