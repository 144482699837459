import { WeaponType, isWeaponType } from '../weapon/weapon-type';
import { GadgetType } from '../gadget/gadget-type';
import { ShieldType, isShieldType } from '../shield/shield-type';
import {
  EquipmentType,
  WEAPONS,
  SHIELDS,
  GADGETS,
} from '../../../../config/equipment.configs';

export type EquipmentItemType = WeaponType | GadgetType | ShieldType;

export interface ShipEquipmentItem {
  id: string;
  type: string;
}

export function getEquipmentType(
  equipmentItemType: EquipmentItemType,
): EquipmentType {
  if (isWeaponType(equipmentItemType)) {
    return WEAPONS;
  }

  if (isShieldType(equipmentItemType)) {
    return SHIELDS;
  }

  return GADGETS;
}
