import { getRandom } from '../../../utils';
import { NORMAL } from '../../../data/constants/difficulty';
import { PurchasedItem } from '../../../data/types/trade-item/purchased-item';
import { TradeItemType } from '../../../data/types/trade-item/trade-item-type';
import { ShipType } from '../../../data/types/ship/ship-type';
import { IGadget } from '../../../data/types/ship/gadget/gadget';
import { EXTRA_BAYS } from '../../../data/types/ship/gadget/gadget-type';
import {
  ActorType,
  PIRATE,
  POLICE,
} from '../../../data/interaction/actor-type';
import { tradeItemConfigs } from '../../../config/trade-item/trade-item.configs';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { ShipTotalCargoBays } from '../../ship/cargo/total-cargo-bays';

export const getOpponentCargo = (
  opponentType: ActorType,
  opponentShipType: ShipType,
  gadgets: IGadget[],
  difficulty: number,
): PurchasedItem[] => {
  const tradeItemTypes: TradeItemType[] = Object.keys(
    tradeItemConfigs,
  ) as TradeItemType[];
  const totalCargoBays = ShipTotalCargoBays(opponentShipType, gadgets);

  // Fill the cargo bays
  const cargo: PurchasedItem[] = [];

  if (totalCargoBays > 5) {
    let cargoBaysToFill =
      difficulty >= NORMAL
        ? Math.min(3 + getRandom(0, totalCargoBays - 5), 15)
        : totalCargoBays;

    if (opponentType === POLICE) {
      cargoBaysToFill = 0;
    }

    if (opponentType === PIRATE) {
      if (difficulty < NORMAL) {
        cargoBaysToFill = (cargoBaysToFill * 4) / 5;
      } else {
        cargoBaysToFill /= difficulty;
      }
    }
    if (cargoBaysToFill < 1) {
      cargoBaysToFill = 1;
    }
    cargoBaysToFill = Math.floor(cargoBaysToFill);

    let filled = 0;
    while (filled < cargoBaysToFill) {
      const tradeItemTypeIndex = getRandom(0, tradeItemTypes.length - 1);
      let quantity = 1 + getRandom(0, 10 - tradeItemTypeIndex);
      if (filled + quantity > cargoBaysToFill) {
        quantity = cargoBaysToFill - filled;
      }
      cargo.push({
        type: tradeItemTypes[tradeItemTypeIndex],
        quantity: quantity,
        itemPrice: 1, // opponent bought resource for unknown price, we could use price of warp system
        day: 0,
      });
      filled += quantity;
    }
  }
  return cargo;
};
