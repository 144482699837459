import React from 'react';
import { useSelector } from 'react-redux';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonCardContent,
  IonCard,
  IonAvatar,
  IonText,
  IonLabel,
  IonProgressBar,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import {
  selectPlayerFilledCargoBays,
  selectPlayerShip,
  selectPlayerTotalCargoBays,
} from '../../../store/game/ship/ship.selectors';
import { ShipHull } from '../../Ship/ShipHull/ShipHull';
import { ShipShields } from '../../Ship/ShipShields/ShipShields';
import { getShipImage } from '../../../app/ui/ship.img';
import { getPercentageValue } from '../../../app/ui/progressbar';
import { getShipConfig } from '../../../app/config/ship/get-ship-config';
import ShipEquipment from '../../Ship/ShipEquipment/ShipEquipment';
import {
  GADGETS,
  SHIELDS,
  WEAPONS,
} from '../../../app/config/equipment.configs';
import { ShipCrew } from '../../Ship/ShipCrew/ShipCrew';
import { ShipCargoIndicator } from '../../Ship/ShipCargoIndicator/ShipCargoIndicator';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const PlayerShipPage: React.FC = () => {
  const ship = useSelector(selectPlayerShip);
  const total = useTypedSelector(selectPlayerTotalCargoBays);
  const filled = useSelector(selectPlayerFilledCargoBays);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ship</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardContent>
                  <IonRow>
                    <IonCol size="auto" className="ion-margin-end">
                      <IonAvatar>
                        <img
                          alt=""
                          className="pixelated"
                          src={getShipImage(ship)}
                        />
                      </IonAvatar>
                    </IonCol>
                    <IonCol>
                      <IonText color="dark">
                        <h1>{ship.type}</h1>
                      </IonText>
                      <ShipShields ship={ship} />
                      <ShipHull ship={ship} />
                      <div>
                        <IonLabel>Fuel</IonLabel>
                        <IonProgressBar
                          className="progressbar"
                          value={getPercentageValue(
                            ship.fuel,
                            getShipConfig(ship).fuelTanks,
                          )}
                          color="primary"
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Ship cargo</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <ShipCargoIndicator total={total} filled={filled} />
                </IonCardContent>
              </IonCard>

              <ShipCrew ship={ship} />
            </IonCol>
            <IonCol>
              <ShipEquipment ship={ship} equipmentType={WEAPONS} />
              <ShipEquipment ship={ship} equipmentType={SHIELDS} />
              <ShipEquipment ship={ship} equipmentType={GADGETS} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default PlayerShipPage;
