import { createAction } from 'typesafe-actions';
import * as actionTypes from './quest-status.action-type';
import {
  QuestStatus,
  QuestType,
} from '../../../app/data/types/game/quest-status';

export const initQuestStatues = createAction(actionTypes.INIT_QUEST_STATUSES)<
  QuestStatus
>();
export const updateQuestStatus = createAction(actionTypes.UPDATE_QUEST_STATUS)<{
  type: QuestType;
  amount: number;
}>();

export const getArtifact = createAction(actionTypes.GET_ARTIFACT)();
export const returnArtifact = createAction(actionTypes.RETURN_ARTIFACT)();

export const getMedicine = createAction(actionTypes.GET_MEDICINE)();
export const deliverMedicine = createAction(actionTypes.DELIVER_MEDICINE)();

export const getJarek = createAction(actionTypes.GET_JAREK_ON_BOARD)();
export const deliverJarek = createAction(actionTypes.DELIVER_JAREK)();

export const experimentStarted = createAction(actionTypes.EXPERIMENT_STARTED)();
export const experimentOngoing = createAction(actionTypes.EXPERIMENT_ONGOING)<
  number
>();
export const experimentStopped = createAction(actionTypes.EXPERIMENT_STOPPED)();
export const experimentPerformed = createAction(
  actionTypes.EXPERIMENT_PERFORMED,
)();
