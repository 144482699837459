import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentDay } from '../../../store/game/status/status.selectors';

export const CurrentDayMenuLabel: React.FC = () => {
  const day = useSelector(selectCurrentDay);
  return (
    <IonNote slot="end" className="ion-text-uppercase">
      Day {day}
    </IonNote>
  );
};
