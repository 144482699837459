import { addNotification } from '../../notifications/notifications.actions';
import { selectPlayerHasWeaponPower } from '../../ship/ship.selectors';
import { PLAYER } from '../../../../app/data/interaction/actor-type';
import { ATTACK } from '../../../../app/data/interaction/action-type';
import { pipe } from 'rxjs';
import { makeActionEvent } from '../make-action-event';

export const selectAttackAction = pipe(
  selectPlayerHasWeaponPower,
  (hasWeaponPower) => {
    if (!hasWeaponPower) {
      return addNotification({
        title: 'No weapons on your ship',
        message: 'You do not have any weapons to attack',
      });
    }
    return makeActionEvent(PLAYER)(ATTACK);
  },
);
