import React from 'react';
import { IonAlert } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { disposeNotification } from '../../store/game/notifications/notifications.actions';
import { selectFirstNotification } from '../../store/game/notifications/notifications.selectors';
import { confirmButton } from './buttons';
import { Notification } from '../../store/game/notifications/notifications.reducer';
import { Action } from 'typesafe-actions';

const NotificationsCenter: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector(selectFirstNotification);

  if (notification) {
    const button = notification.action
      ? confirmButton(() => dispatch(notification.action as Action<string>))
      : 'OK';

    return (
      <IonAlert
        key={notification.id}
        isOpen
        header={notification.title}
        message={notification.message}
        onDidDismiss={() => dispatch(disposeNotification(notification.id))}
        buttons={[button]}
      />
    );
  }

  return <></>;
};

export default NotificationsCenter;
