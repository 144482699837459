import { IShip } from '../../../../app/data/types/ship/ship';
import { IonImg } from '@ionic/react';
import { getShipImage } from '../../../../app/ui/ship.img';
import { SkillsCard } from './SkillsCard';
import { ShipHull } from '../../../Ship/ShipHull/ShipHull';
import { ShipShields } from '../../../Ship/ShipShields/ShipShields';
import React from 'react';

export const GraphicShipCard: React.FC<{ ship: IShip }> = ({
  ship,
  children,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <IonImg
        style={{ minWidth: '300px' }}
        className="pixelated"
        src={getShipImage(ship)}
      />
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}>
        {ship.commander && <SkillsCard commander={ship.commander} />}
      </div>
      <div
        style={{
          position: 'absolute',
          width: '50%',
          left: 0,
          bottom: 0,
        }}>
        <ShipHull ship={ship} />
      </div>
      <div
        style={{
          position: 'absolute',
          width: '50%',
          left: '50%',
          bottom: 0,
        }}>
        <ShipShields ship={ship} />
      </div>
      {children}
    </div>
  );
};
