import { Epic } from 'redux-observable';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../../..';
import {
  KILLPIRATESCORE,
  KILLPOLICESCORE,
  KILLTRADERSCORE,
} from '../../../../app/data/constants/police-record';
import {
  increaseReputation,
  registerPoliceScoreChange,
} from '../../scores/scores.actions';
import {
  onEncounterEvent,
  whenEncounterType,
  whenOpponentDid,
} from '../opponents/encounter.pipe-operators';
import {
  selectBounty,
  selectFleeFromInspectionChangeScore,
  selectPoliceAttackChangeScore,
  selectReputationScoreChange,
  selectTraderAttackChangeScore,
} from './karma.selectors';
import { mapState } from '../../../operators/map-state';
import {
  ATTACK,
  BUY,
  DEAD,
  ESCAPE,
  IGNORE,
  INSPECT,
  SELL,
} from '../../../../app/data/interaction/action-type';
import { earnCredits } from '../../balance/balance.actions';
import {
  pirateAlike,
  PLAYER,
  POLICE,
  TRADER,
} from '../../../../app/data/interaction/actor-type';
import { addNotification } from '../../notifications/notifications.actions';
import { encounterEvent } from '../../travel/travel.actions';
import { selectLastOpponentAction } from '../selectors/encounter-events.selector';
import { BOUNTY_REASON } from '../../../../app/ui/change-balance-reasons';

const attackPoliceEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PLAYER, ATTACK),
    whenEncounterType(POLICE)(state$),
    mapState(selectPoliceAttackChangeScore)(state$),
    map((score) =>
      registerPoliceScoreChange({
        reason: 'attack police',
        score,
      }),
    ),
  );

const attackTraderEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PLAYER, ATTACK),
    whenEncounterType(TRADER)(state$),
    mapState(selectLastOpponentAction)(state$),
    filter((action) => [IGNORE, BUY, SELL].includes(action)),
    mapState(selectTraderAttackChangeScore)(state$),
    map((score) =>
      registerPoliceScoreChange({
        reason: 'attack trader',
        score,
      }),
    ),
  );

const fleeFromPoliceInspectionEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onEncounterEvent(PLAYER, ESCAPE),
    whenEncounterType(POLICE)(state$),
    whenOpponentDid(INSPECT)(state$),
    mapState(selectFleeFromInspectionChangeScore)(state$),
    map((score) =>
      registerPoliceScoreChange({
        reason: 'flee from inspection',
        score,
      }),
    ),
  );

const killPirateEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    filter((action) =>
      pirateAlike.some((pirate) => pirate === action.payload.actor),
    ),
    filter((action) => action.payload.action === DEAD),
    mapTo(
      registerPoliceScoreChange({
        reason: 'kill pirate',
        score: KILLPIRATESCORE,
      }),
    ),
  );

const earnPirateBountyEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    filter((action) =>
      pirateAlike.some((pirate) => pirate === action.payload.actor),
    ),
    filter((action) => action.payload.action === DEAD),
    mapState(selectBounty)(state$),
    switchMap((amount) => [
      addNotification({
        title: 'Mmm... bounty!',
        message: `You got a reward of ${amount} credits for killing a pirate`,
      }),
      earnCredits({
        reason: BOUNTY_REASON,
        amount,
      }),
    ]),
  );

const killPoliceEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    onEncounterEvent(POLICE, DEAD),
    mapTo(
      registerPoliceScoreChange({
        reason: 'kill police',
        score: KILLPOLICESCORE,
      }),
    ),
  );

const killTraderEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    onEncounterEvent(TRADER, DEAD),
    mapTo(
      registerPoliceScoreChange({
        reason: 'kill trader',
        score: KILLTRADERSCORE,
      }),
    ),
  );

const earnReputationEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    filter((action) => action.payload.actor !== PLAYER),
    filter((action) => action.payload.action === DEAD),
    mapState(selectReputationScoreChange)(state$),
    map(([type, score]) =>
      increaseReputation({
        reason: `kill ${type}`,
        score,
      }),
    ),
  );

export const karmaEpics = [
  killPirateEpic,
  earnPirateBountyEpic,

  attackTraderEpic,
  killTraderEpic,

  attackPoliceEpic,
  fleeFromPoliceInspectionEpic,
  killPoliceEpic,

  earnReputationEpic,
];
