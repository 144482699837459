import {
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useState } from 'react';
import { getSavedGames } from './utils';
import { NewGame } from './new-game/NewGame';
import { LoadGame } from './load-game/LoadGame';
import { HighScores } from './high-scores/HighScores';

const MainMenu: React.FC = () => {
  const ids = getSavedGames();
  const [mode, setMode] = useState(ids.length ? 'load' : 'new');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Space trader</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={mode}
            onIonChange={(e) => setMode(e.detail.value as string)}
          >
            <IonSegmentButton value="new">New game</IonSegmentButton>
            <IonSegmentButton value="load">Load game</IonSegmentButton>
            <IonSegmentButton value="scores">High scores</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {mode === 'new' && <NewGame />}
        {mode === 'load' && <LoadGame ids={ids} />}
        {mode === 'scores' && <HighScores />}
      </IonContent>
    </IonPage>
  );
};

export default MainMenu;
