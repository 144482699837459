import { useDispatch } from 'react-redux';
import { buyRepair } from '../store/game/ship/ship.actions';
import { selectHullToRepair } from '../store/derived/computational-selectors';
import { useTypedSelector } from './useTypedSelector';

export const useRepairHull = () => {
  const dispatch = useDispatch();
  const hullToRepair = useTypedSelector(selectHullToRepair);
  const isDisabled = hullToRepair < 1;

  const repairHull = () => {
    dispatch(buyRepair(hullToRepair));
  };

  return [repairHull, isDisabled] as const;
};
