import { pipe } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EncounterActionType } from '../../../../app/data/interaction/action-type';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import { whenInState } from '../../../operators/when-in-state';
import { selectPlayerHasIllegalCargo } from '../../ship/ship.selectors';
import {
  selectEncounterType,
  selectIsPlayerMove,
} from '../encounter.selectors';
import { StateObservable } from 'redux-observable';
import { complement, equals, prop } from 'ramda';
import {
  changeEncounterTurn,
  damageEvent,
  encounterEvent,
  initEncounter,
} from '../../travel/travel.actions';
import { selectIsTravelling } from '../../travel/selectors/is-travelling.selector';
import { selectIsOpponentDoing } from '../selectors/is-opponent-doing.selector';
import {
  selectIsOpponentDead,
  selectIsPlayerDead,
} from '../selectors/is-dead.selectors';
import {
  selectIsGameOver,
  selectIsOpponentDid,
  selectIsPlayerDoing,
} from '../selectors/encounter-events.selector';
import { RootState } from '../../../index';

// format
// on<SomeAction> - should be first - the entry pipe that filters the action
// when<SomeState> - shouldn't be first - the pipe that indicates if predicate applied to state is true

export const onInitEncounter = (actor: ActorType) =>
  pipe(
    filter(isActionOf(initEncounter)),
    pluck('payload'),
    pluck('type'),
    filter(equals(actor)),
  );

export const onEncounterEvent = (
  actor: ActorType,
  actorAction: EncounterActionType,
) =>
  pipe(
    filter(isActionOf(encounterEvent)),
    pluck('payload'),
    filter(pipe(prop('actor'), equals(actor))),
    filter(pipe(prop('action'), equals(actorAction))),
  );

export const onOpponentEvent = (actorAction: EncounterActionType) =>
  pipe(
    filter(isActionOf(encounterEvent)),
    pluck('payload'),
    filter(pipe(prop('actor'), complement(equals(PLAYER)))),
    filter(pipe(prop('action'), equals(actorAction))),
  );

export const onDamageEvent = (actor: ActorType) =>
  pipe(
    filter(isActionOf(damageEvent)),
    filter((action) => action.payload.actor === actor),
  );

export const whenEncounterType = (type: ActorType) =>
  whenInState((state) => selectEncounterType(state) === type);

export const whenOpponentDid = (action: EncounterActionType) =>
  whenInState(selectIsOpponentDid(action));

export const whenOpponentDoing = (action: EncounterActionType) =>
  whenInState(selectIsOpponentDoing(action));

export const whenPlayerDoing = (action: EncounterActionType) =>
  whenInState(selectIsPlayerDoing(action));

export const whenPlayerMove = whenInState(selectIsPlayerMove);

export const whenOpponentMove = whenInState(
  (state) => !selectIsPlayerMove(state),
);

export const whenOpponentAlive = whenInState(
  (state) => !selectIsOpponentDead(state),
);

export const whenTravelling = whenInState(selectIsTravelling);

export const whenOpponentDead = whenInState(selectIsOpponentDead);

export const whenPlayerAlive = whenInState(
  (state) => !selectIsPlayerDead(state),
);

export const whenPlayerDead = whenInState(selectIsPlayerDead);

export const whenPlayerHasIllegals = whenInState(selectPlayerHasIllegalCargo);

export const whenPlayerClean = whenInState(
  complement(selectPlayerHasIllegalCargo),
);

export const whenEncounterIsOver = whenInState(selectIsGameOver);

export const onOpponentTurn = (opponentType: ActorType) => (
  state$: StateObservable<RootState>,
) =>
  pipe(
    filter(isActionOf(changeEncounterTurn)),
    whenEncounterType(opponentType)(state$),
    whenOpponentMove(state$),
    whenOpponentAlive(state$),
  );
