import {
  selectPlayerFilledCargoBays,
  selectPlayerTotalCargoBays,
} from '../../../store/game/ship/ship.selectors';
import { useSelector } from 'react-redux';
import React from 'react';
import './ShipCargoIndicator.css';
import { IonGrid, IonRow } from '@ionic/react';

export const ShipCargoIndicator: React.FC<{
  total: number;
  filled: number;
  selected?: number;
}> = ({ total, filled, selected = 0 }) => {
  const remain = Math.max(0, total - filled - selected);
  return (
    <IonGrid>
      <IonRow>
        <div className="segmented-progressbar">
          {Array(filled)
            .fill(0)
            .map((v, i) => (
              <div key={i} className="item filled" />
            ))}
          {Array(selected)
            .fill(0)
            .map((v, i) => (
              <div key={i} className="item selected" />
            ))}
          {remain > 0 &&
            Array(remain)
              .fill(0)
              .map((v, i) => <div key={i} className="item" />)}
        </div>
      </IonRow>
    </IonGrid>
  );
};
