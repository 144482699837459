// ignore actions - opponent incoming ignore and player's responding ignore
export const IGNORE = 'ignore';
export const MUTUAL_IGNORE = 'mutual ignore';

// general combat actions
export const ATTACK = 'attack';
export const FLEE = 'flee';
export const SURRENDER = 'surrender';

// traders
export const BUY = 'buy';
export const SELL = 'sell';
export const TRADE = 'trade'; // response to sell or buy request

// police inspection
export const INSPECT = 'inspection';
export const SUBMIT = 'submit';
export const BRIBE = 'bribe';

// special encounters - meet captain, drink bottle, board on abandoned ship
export const MEET = 'meet';
export const DRINK = 'drink';
export const BOARD = 'board';

// actions that finishes the encounter
export const LET_GO = 'let go'; // police let player go after bribe
export const ARREST = 'arrest'; // police arrest player after submit inspection or attack
export const SUBMIT_FAILED = 'inspection submit failed'; // police confiscate illegals and requests a fine after submitting illegals
export const SUBMIT_APPROVED = 'inspection submit approved'; // police let player go after clean submitting
export const BLACKMAIL = 'blackmail'; // pirate blackmails player after surrender
export const PLUNDER = 'plunder'; // winner plunders surrenderer
export const ESCAPE = 'escape'; // attacked ship escaped
export const DEAD = 'dead'; // attacked ship dead

export const finiteActionTypes = [
  // player ignores opponent that ignores player
  MUTUAL_IGNORE,

  // police inspection output
  SUBMIT_FAILED,
  SUBMIT_APPROVED,
  LET_GO,
  ARREST,

  // when pirate wins
  BLACKMAIL,
  PLUNDER,

  ESCAPE,
  DEAD,
] as const;

export const actionTypes = [
  // entry actions
  IGNORE,
  MEET, // special captain
  DRINK, // drink bottle
  BOARD, // board on marie celeste

  // traders
  TRADE,
  BUY,
  SELL,

  // combat
  ATTACK,
  FLEE,
  SURRENDER,

  // police inspection
  INSPECT,
  SUBMIT,
  BRIBE,
  ...finiteActionTypes,
] as const;

export type EncounterActionType = typeof actionTypes[number];
