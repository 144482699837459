import {
  BalanceChangeType,
  BalanceRecord,
} from '../../../app/data/types/balance';
import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonNote,
  IonText,
} from '@ionic/react';
import NumberFormat from 'react-number-format';
import { reduceBalance } from '../../../store/game/balance/utils/find-highest-balance';
import { groupBy } from 'ramda';

export const DayBalanceCard: React.FC<{
  balanceRecords: BalanceRecord[];
  total: number;
}> = ({ balanceRecords, total }) => {
  const balanceRecordsByType = groupBy<BalanceRecord>((record) => record.type)(
    balanceRecords,
  );
  const incomeRecords = balanceRecordsByType['income'];
  const outcomeRecords = balanceRecordsByType['outcome'];
  const totalIncome = incomeRecords ? reduceBalance(incomeRecords) : 0;
  const totalOutcome = outcomeRecords ? reduceBalance(outcomeRecords) : 0;

  return (
    <IonCard>
      <IonCardContent>
        {incomeRecords &&
          incomeRecords.map((record) => (
            <IonItem key={record.id}>
              <IonLabel color="medium">{record.reason}</IonLabel>
              <IonNote slot="end" color="success">
                <NumberFormat
                  value={record.amount}
                  prefix={'+'}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </IonNote>
            </IonItem>
          ))}
        <IonItem>
          <IonLabel>Income</IonLabel>
          <IonNote slot="end">
            <IonText color="success">
              <h1>
                <NumberFormat
                  value={totalIncome}
                  prefix={'+'}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </h1>
            </IonText>
          </IonNote>
        </IonItem>

        {outcomeRecords &&
          outcomeRecords.map((record) => (
            <IonItem key={record.id}>
              <IonLabel color="medium">{record.reason}</IonLabel>
              <IonNote slot="end" color="danger">
                <NumberFormat
                  value={record.amount}
                  prefix={'-'}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </IonNote>
            </IonItem>
          ))}
        <IonItem>
          <IonLabel>Expenses</IonLabel>
          <IonNote slot="end">
            <IonText color="danger">
              <h1>
                <NumberFormat
                  value={totalOutcome}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </h1>
            </IonText>
          </IonNote>
        </IonItem>

        <IonItem>
          <IonLabel>Total</IonLabel>
          <IonNote slot="end">
            <IonText color="dark">
              <h1>
                <NumberFormat
                  value={total}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </h1>
            </IonText>
          </IonNote>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};
