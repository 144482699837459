import { useSelector } from 'react-redux';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  IonMenuButton,
  IonButtons,
  IonFooter,
} from '@ionic/react';
import React from 'react';
import SystemsToTravel from './SystemsToTravel/SystemsToTravel';
import CurrentSystemCard from './CurrentSystemCard/CurrentSystemCard';
import TravelStatus from './Travel/TravelStatus';
import TradeCard from './Trade/Trade';
import SpecialEventCard from './SpecialEvent/SpecialEventCard';
import Navigator from './Navigator/Navigator';
import MercenariesList from './Mercenaries/Mercenaries';
import { selectIsTravelling } from '../../../store/game/travel/selectors/is-travelling.selector';
import { PlayerCardContainer } from '../../PlayerCard/PlayerCardContainer';
import { selectCurrentSystemSpecial } from '../../../store/game/systems/selectors/current-system.selector';
import { Planner } from './Planner/Planner';
import NewsPaper from './NewsPaper/NewsPaper';

const Home: React.FC = () => {
  const isTravelling = useSelector(selectIsTravelling);
  const currentSystemSpecial = useSelector(selectCurrentSystemSpecial);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Game</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <PlayerCardContainer />
            </IonCol>
          </IonRow>
        </IonGrid>
        {!isTravelling && (
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <Planner />
                <CurrentSystemCard />
                <NewsPaper />
                <MercenariesList />
                {!!currentSystemSpecial && (
                  <SpecialEventCard specialEvent={currentSystemSpecial} />
                )}
                <Navigator />
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <TradeCard />
                <SystemsToTravel />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {isTravelling && (
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <CurrentSystemCard />
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <Navigator />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      {isTravelling && (
        <IonFooter className="ion-no-border">
          <TravelStatus />
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Home;
