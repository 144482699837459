import React from 'react';
import { useLocation, useHistory } from 'react-router';

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';
import {
  planet,
  cogOutline,
  rocket,
  personCircle,
  planetOutline,
  listCircleOutline,
  gameControllerOutline,
  star,
  warning,
  arrowUp,
  cash,
  radioButtonOnOutline,
  shieldOutline,
  settingsOutline,
} from 'ionicons/icons';

import './Menu.css';
import { shallowEqual, useSelector } from 'react-redux';
import { selectCurrentSystem } from '../../../store/game/systems/selectors/current-system.selector';
import { getSystemName } from '../../../app/ui/solar-system.labels';
import {
  getAvailableShips,
  sellableShipTypes,
} from '../../../app/core/ship/sellable-ship-types';
import { weapons } from '../../../app/data/types/ship/weapon/weapon-type';
import {
  isEquipmentAvailable,
  isEquipmentToSell,
} from '../../../app/core/equipment/equipment';
import { weaponConfigs } from '../../../app/config/weapon/weapon.configs';
import { shields } from '../../../app/data/types/ship/shield/shield-type';
import { shieldConfigs } from '../../../app/config/shield/shield.configs';
import { gadgets } from '../../../app/data/types/ship/gadget/gadget-type';
import { gadgetConfigs } from '../../../app/config/gadget/gadget.configs';
import { isDebug } from '../../../is-debug-mode';
import { CurrentDayMenuLabel } from './CurrentDayMenuLabel';
import { PlayerNameMenuLabel } from './PlayerNameMenuLabel';
import { PlayerShipTypeMenuLabel } from './PlayerShipTypeMenuLabel';
import { CargoBaysMenuLabel } from './CargoBaysMenuLabel';
import { DebtMenuLabel } from './DebtMenuLabel';
import { CurrentBalanceMenuLabel } from './CurrentBalanceMenuLabel';
import { ReputationMenuLabel } from './ReputationMenuLabel';
import { PoliceRecordMenuLabel } from './PoliceRecordLabel';

interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
  children?: JSX.Element;
}

interface MenuProps {
  baseUrl: string;
}

const Menu: React.FC<MenuProps> = ({ baseUrl }) => {
  const location = useLocation();
  const history = useHistory();

  const currentSystem = useSelector(selectCurrentSystem, shallowEqual);
  const availableShipsAmount = currentSystem
    ? getAvailableShips(currentSystem).length
    : 0;
  const availableWeaponsAmount = currentSystem
    ? weapons.filter((type) =>
        isEquipmentAvailable(weaponConfigs)(currentSystem, type),
      ).length
    : 0;
  const sellableWeaponsAmount = currentSystem
    ? weapons.filter(isEquipmentToSell(weaponConfigs)).length
    : 0;
  const availableShieldsAmount = currentSystem
    ? shields.filter((type) =>
        isEquipmentAvailable(shieldConfigs)(currentSystem, type),
      ).length
    : 0;
  const sellableShieldsAmount = currentSystem
    ? shields.filter(isEquipmentToSell(shieldConfigs)).length
    : 0;
  const availableGadgetsAmount = currentSystem
    ? gadgets.filter((type) =>
        isEquipmentAvailable(gadgetConfigs)(currentSystem, type),
      ).length
    : 0;
  const sellableGadgetsAmount = currentSystem
    ? gadgets.filter(isEquipmentToSell(gadgetConfigs)).length
    : 0;

  const routes = {
    appPages: [
      {
        title: 'Game',
        path: '/game',
        icon: planet,
        children: <CurrentDayMenuLabel />,
      },
      {
        title: 'Commander',
        path: '/commander',
        icon: personCircle,
        children: <PlayerNameMenuLabel />,
      },
      {
        title: 'Ship',
        path: '/ship',
        icon: cogOutline,
        children: <PlayerShipTypeMenuLabel />,
      },
      {
        title: 'Cargo',
        path: '/cargo',
        icon: cogOutline,
        children: <CargoBaysMenuLabel />,
      },
      {
        title: 'Bank',
        path: '/bank',
        icon: cash,
        children: <DebtMenuLabel />,
      },
    ],
    statPages: [
      {
        title: 'Balance',
        path: '/balance',
        icon: arrowUp,
        children: <CurrentBalanceMenuLabel />,
      },
      {
        title: 'Reputation',
        path: '/reputation',
        icon: star,
        children: <ReputationMenuLabel />,
      },
      {
        title: 'Police records',
        path: '/police-score',
        icon: warning,
        children: <PoliceRecordMenuLabel />,
      },
      {
        title: 'Travel History',
        path: '/history',
        icon: listCircleOutline,
        children: (
          <IonNote slot="end" className="ion-text-uppercase">
            {currentSystem ? getSystemName(currentSystem) : ''}
          </IonNote>
        ),
      },
    ],
    purchasePages: [
      {
        title: 'Weapons',
        path: '/buy-weapons',
        icon: radioButtonOnOutline,
        children: (
          <IonNote slot="end" className="ion-text-uppercase">
            {availableWeaponsAmount}/{sellableWeaponsAmount}
          </IonNote>
        ),
      },
      {
        title: 'Shields',
        path: '/buy-shields',
        icon: shieldOutline,
        children: (
          <IonNote slot="end" className="ion-text-uppercase">
            {availableShieldsAmount}/{sellableShieldsAmount}
          </IonNote>
        ),
      },
      {
        title: 'Gadgets',
        path: '/buy-gadgets',
        icon: settingsOutline,
        children: (
          <IonNote slot="end" className="ion-text-uppercase">
            {availableGadgetsAmount}/{sellableGadgetsAmount}
          </IonNote>
        ),
      },
      {
        title: 'Ships',
        path: '/buy-ships',
        icon: rocket,
        children: (
          <IonNote slot="end" className="ion-text-uppercase">
            {availableShipsAmount}/{sellableShipTypes.length}
          </IonNote>
        ),
      },
    ],
    debugPages: [
      { title: 'Galaxy', path: '/galaxy', icon: planetOutline },
      { title: 'Quest status', path: '/quests', icon: gameControllerOutline },
      { title: 'Settings', path: '/settings', icon: cogOutline },
    ],
  };

  function reset() {
    history.push('/');
    window.location.reload();
  }

  function renderlistItems(list: Pages[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={baseUrl + p.path}
            routerDirection="forward"
            className={
              location.pathname.endsWith(p.path) ? 'selected' : undefined
            }>
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
            {p.children}
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>Space Trader</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Statistic</IonListHeader>
          {renderlistItems(routes.statPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Purchases</IonListHeader>
          {renderlistItems(routes.purchasePages)}
        </IonList>
        {isDebug() && (
          <IonList lines="none">
            <IonListHeader>Debug</IonListHeader>
            {renderlistItems(routes.debugPages)}
          </IonList>
        )}
        <IonList lines="none">
          <IonListHeader>General</IonListHeader>
          <IonItem detail onClick={reset}>
            <IonLabel>Main menu</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
