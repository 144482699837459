import { addConfirmation } from '../../confirmations/confirmations.actions';
import { selectPlayerHasIllegalCargo } from '../../ship/ship.selectors';
import { PLAYER } from '../../../../app/data/interaction/actor-type';
import { SUBMIT } from '../../../../app/data/interaction/action-type';
import { pipe } from 'rxjs';
import { makeActionEvent } from '../make-action-event';

export const selectSubmitAction = pipe(
  selectPlayerHasIllegalCargo,
  (hasIllegalCargo) => {
    const submitAction = makeActionEvent(PLAYER)(SUBMIT);
    if (hasIllegalCargo) {
      return addConfirmation({
        title: 'Better find a good excuse for an officer',
        message: `
          If you submit your cargo for police inspection, police will request a fine and confiscate your illegals.
          Do you want to submit?
        `,
        action: submitAction,
      });
    }

    return submitAction;
  },
);
