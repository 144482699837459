// Reputation (depends on number of kills)
export const HARMLESSREP = 0;
export const MOSTLYHARMLESSREP = 10;
export const POORREP = 20;
export const AVERAGESCORE = 40;
export const ABOVEAVERAGESCORE = 80;
export const COMPETENTREP = 150;
export const DANGEROUSREP = 300;
export const DEADLYREP = 600;
export const ELITESCORE = 1500;
