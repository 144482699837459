import React from 'react';
import { useDispatch } from 'react-redux';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonNote,
} from '@ionic/react';
import { skillTypes } from '../../../../app/data/types/crew-member/skill';
import { CrewMember } from '../../../../app/data/types/crew-member/crew-member';
import { mercenaryHirePrice } from '../../../../app/core/mercenaries/mercenary-hire-price';
import { useMercenaryAlert } from './useMercenaryAlert';
import { SkillProfit } from './SkillProfit';

type MercenaryCardProps = {
  person: CrewMember;
};

const MercenaryCard: React.FC<MercenaryCardProps> = ({ person }) => {
  const dispatch = useDispatch();
  const mercenaryAction = useMercenaryAlert(person);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{person.name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {skillTypes.map((skillType) => (
          <SkillProfit key={skillType} skillType={skillType} person={person} />
        ))}
        <IonButton expand="block" onClick={() => dispatch(mercenaryAction)}>
          Hire for {mercenaryHirePrice(person)}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default MercenaryCard;
