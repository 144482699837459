import { realDistance } from '../coordinates/coordinates';
import { System } from '../../data/types/system/system';
import { GEMULONSYSTEM, DALEDSYSTEM } from '../../data/constants/solar-systems';
import { shuffle } from '../../utils/shuffle-array';
import { SpecialEventType } from '../../data/types/system/special-event/special-event-type';

export const isOnAcceptableDistanceForQuest = (systemOfDestination: System) => (
  system: System,
) => realDistance(system.coordinates, systemOfDestination.coordinates) >= 70;

// GEMULON and DALED are used for other quests
export const isAcceptableSystemForQuest = (system: System) =>
  ![GEMULONSYSTEM, DALEDSYSTEM].includes(system.nameIndex) && !system.special;

export const sortByDistance = (systemOfDestination: System) => (
  a: System,
  b: System,
) => {
  const distanceWithA = realDistance(
    a.coordinates,
    systemOfDestination.coordinates,
  );
  const distanceWithB = realDistance(
    b.coordinates,
    systemOfDestination.coordinates,
  );
  return distanceWithA - distanceWithB;
};

export function getCandidateForQuest(
  systems: System[],
  destinationSystem: System,
): number {
  const foundSystem = shuffle(
    systems
      .filter(isAcceptableSystemForQuest)
      .filter(isOnAcceptableDistanceForQuest(destinationSystem)),
  ).shift();

  if (foundSystem) {
    return systems.findIndex(
      (system) => foundSystem.nameIndex === system.nameIndex,
    );
  }

  return 0;
}

function isDestination(system: System, destinationIndex: number): boolean {
  return system.nameIndex === destinationIndex;
}

function isSecondPiece(system: System, candidateIndex: number): boolean {
  return system.nameIndex === candidateIndex;
}

export function tryToSetInitialEvent(
  system: System,
  candidateIndex: number,
  initialEvent: SpecialEventType,
): SpecialEventType | false {
  if (isSecondPiece(system, candidateIndex)) {
    return initialEvent;
  }

  return false;
}

export function tryToSetDestinationEvent(
  system: System,
  destinationIndex: number,
  destinationEvent: SpecialEventType,
): SpecialEventType | false {
  if (isDestination(system, destinationIndex)) {
    return destinationEvent;
  }

  return false;
}
