// return hull units repaired
import { IShip } from '../../../data/types/ship/ship';
import { getHullToRepair } from './hull-to-repair';
import { getPossibleHullToRepair } from './possible-amount-to-repair';

export const getRepairedHull = (
  ship: IShip,
  availableCredits: number,
): number => {
  const maxHullRepairRequired = getHullToRepair(ship);
  const possibleHullToRepair = getPossibleHullToRepair(ship, availableCredits);
  return Math.min(maxHullRepairRequired, possibleHullToRepair);
};
