export const PLAYER = 'Player';
export const POLICE = 'Police';
export const PIRATE = 'Pirate';
export const TRADER = 'Trader';
export const CAPTAIN = 'Famous Captain';
export const MANTIS = 'Mantis';
export const SPACE_MONSTER = 'Space monster';
export const SCARAB = 'Scarab';
export const DRAGONFLY = 'Dragonfly';
export const BOTTLE = 'Bottle';

export const countableKills = [POLICE, TRADER, PIRATE] as const;

export const pirateAlike = [PIRATE, SCARAB, SPACE_MONSTER, DRAGONFLY] as const;

export const actorTypes = [
  PLAYER,
  POLICE,
  PIRATE,
  TRADER,
  CAPTAIN,
  MANTIS,
  SPACE_MONSTER,
  SCARAB,
  DRAGONFLY,
  BOTTLE,
] as const;

export type ActorType = typeof actorTypes[number];
