import { SkillType } from '../../../data/types/crew-member/skill';
import { IShip } from '../../../data/types/ship/ship';
import { getSkillValue } from './skill-value';
import { allShipMembers } from '../../ship/crew/all-members';

export const findMaxCrewSkill =
  (skillType: SkillType) =>
  (ship: IShip): number =>
    // Math.max(...allShipMembers(ship).map<number>(getSkillValue(skillType)));
    Math.max(...allShipMembers(ship).map((member) => member.skills[skillType]));
