export const BEGINNER = 0;
export const EASY = 1;
export const NORMAL = 2;
export const HARD = 3;
export const IMPOSSIBLE = 4;

export const difficultyLevels = [
  BEGINNER,
  EASY,
  NORMAL,
  HARD,
  IMPOSSIBLE,
] as const;
