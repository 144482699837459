import { SpecialEventConfigs } from '../../data/types/system/special-event/special-event-config';
import {
  DRAGONFLY_DESTROYED,
  FLY_BARATAS,
  FLY_MELINA,
  FLY_REGULAS,
  MONSTER_KILLED,
  MEDICINE_DELIVERY,
  MOON_BOUGHT,
  MOON_FOR_SALE,
  SKILL_INCREASE,
  TRIBBLE,
  ERASE_RECORD,
  BUY_TRIBBLE,
  CARGO_FOR_SALE,
  INSTALL_LIGHTNING_SHIELD,
  JAPORI_DISEASE,
  LOTTERY_WINNER,
  ARTIFACT_DELIVERY,
  ALIEN_ARTIFACT,
  TRANSPORT_AMBASSADOR_JAREK,
  ALIEN_INVASION,
  GEMULON_INVADED,
  GET_FUEL_COMPACTOR,
  EXPERIMENT,
  TRANSPORT_WILD,
  GET_REACTOR,
  GET_SPECIAL_LASER,
  GET_HULL_UPGRADED,
  SCARAB_DESTROYED,
  REACTOR_DELIVERED,
  JAREK_GETS_OUT,
  GEMULON_RESCUED,
  EXPERIMENT_STOPPED,
  EXPERIMENT_NOT_STOPPED,
  WILD_GETS_OUT,
  SPACE_MONSTER_EVENT,
  DRAGONFLY_EVENT,
  SCARAB_STOLEN,
} from '../../data/types/system/special-event/special-event-type';
import {
  ARTIFACT_DELIVERY_REWARD,
  MEDICINE_AMOUNT,
  TRIBBLE_PRICE,
} from '../../data/constants/special-events';

export const specialEventConfigs: SpecialEventConfigs = {
  /**
   * Generic random events
   */
  [CARGO_FOR_SALE]: {
    title: 'Cargo For Sale',
    description: 'There is a cargo for sale, wanna buy?',
    occurrence: 3,
    justAMessage: false,
    randomPosition: true,
  },
  [ERASE_RECORD]: {
    title: 'Erase Record',
    description: 'You can erase your police record for a price',
    occurrence: 3,
    justAMessage: false,
    randomPosition: true,
  },
  [LOTTERY_WINNER]: {
    title: 'Lottery Winner',
    description: 'You won the lottery',
    occurrence: 0,
    justAMessage: true,
    randomPosition: true,
  },
  [SKILL_INCREASE]: {
    title: 'Skill Increase',
    description: 'Do you want to increase your random skill?',
    occurrence: 3,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Main quest
   */
  [MOON_FOR_SALE]: {
    title: 'Moon For Sale',
    description: `
      There is a small but habitable moon for sale in the Utopia system,
      for the very reasonable sum of half a million credits. If you accept it,
      you can retire to it and live a peaceful, happy, and wealthy life. Do you wish to buy it?
    `,
    occurrence: 4,
    justAMessage: false,
    randomPosition: true,
  },
  [MOON_BOUGHT]: {
    title: 'Retirement',
    description: `
      Welcome to the Utopia system. Your own moon is available for you to retire to it,
      if you feel inclined to do that. Are you ready to retire
      and lead a happy, peaceful, and wealthy life?
    `,
    occurrence: 0,
    justAMessage: false,
    randomPosition: false,
  },

  /**
   * Quest-joke
   */
  [TRIBBLE]: {
    title: 'Merchant Prince',
    description: `
      A merchant prince offers you a very special and wondrous item for the sum of ${TRIBBLE_PRICE} credits.
      Do you accept?
    `,
    occurrence: 1,
    justAMessage: false,
    randomPosition: true,
  },
  [BUY_TRIBBLE]: {
    title: 'Tribble Buyer',
    description: '',
    occurrence: 3,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Dragonfly
   **/
  [DRAGONFLY_EVENT]: {
    title: 'Dragonfly',
    description: `
      This is colonel Jackson of the Space Corps.
      An experimental ship, code-named "Dragonfly", has been stolen.
      It is equipped with very special, almost indestructible shields.
      It shouldn't fall into the wrong hands and we will reward you if you destroy it.
      It has been last seen in the Baratas system.
    `,
    occurrence: 1,
    justAMessage: true,
    randomPosition: true,
  },
  [FLY_BARATAS]: {
    title: 'Weird Ship',
    description: `
      A small ship of a weird design docked here recently for repairs.
      The engineer who worked on it said that it had a weak hull, but incredibly strong shields.
      I heard it took off in the direction of the Melina system.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [FLY_MELINA]: {
    title: 'Lightning Ship',
    description: `
      A ship with shields that seemed to be like lightning
      recently fought many other ships in our system.
      I have never seen anything like it before.
      After it left, I heard it went to the Regulas system.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [FLY_REGULAS]: {
    title: 'Strange Ship',
    description: `
      A small ship with shields like I have never seen before was here a few days ago.
      It destroyed at least ten police ships! Last thing I heard was that it went to the Zalkon system.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [DRAGONFLY_DESTROYED]: {
    title: 'Dragonfly Destroyed',
    description: '',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [INSTALL_LIGHTNING_SHIELD]: {
    title: 'Lightning Shield',
    description: 'Wanna install lightning shield?',
    occurrence: 0,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Space monster
   */
  [SPACE_MONSTER_EVENT]: {
    title: 'Space Monster',
    description: 'There is a space monster, go kill it',
    occurrence: 1,
    justAMessage: true,
    randomPosition: true,
  },
  [MONSTER_KILLED]: {
    title: 'Monster Killed',
    description: '',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },

  /**
   * Scarab
   */
  [SCARAB_STOLEN]: {
    title: 'Scarab Stolen',
    description: 'Scarab is stolen, go to rescue',
    occurrence: 1,
    justAMessage: true,
    randomPosition: true,
  },
  [SCARAB_DESTROYED]: {
    title: 'Scarab Destroyed',
    description: 'Scarab is destroyed, yeee',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [GET_HULL_UPGRADED]: {
    title: 'Upgrade Hull',
    description: 'Wanna upgrade hull?',
    occurrence: 0,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Medicine for Japori
   */
  [JAPORI_DISEASE]: {
    title: 'Japori Disease',
    description: `
      A strange disease has invaded the Japori system.
      We would like you to deliver these ten canisters of special antidote to Japori.
      Note that, if you accept, ${MEDICINE_AMOUNT} of your cargo bays will remain in use on your way to Japori.
      Do you accept this mission?
    `,
    occurrence: 1,
    justAMessage: false,
    randomPosition: true,
  },
  [MEDICINE_DELIVERY]: {
    title: 'Medicine Delivery',
    description: `
      Thank you for delivering the medicine for us.
      We don't have any money to reward you, but we do have an alien fast-learning machine
      with which we will increase your skills.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },

  /**
   * Alien artifact delivery
   */
  [ALIEN_ARTIFACT]: {
    title: 'Alien Artifact',
    description: `
      This alien artifact should be delivered to professor Berger, who is currently travelling.
      You can probably find him at a hi-tech solar system. The alien race which produced this artifact
      seems keen on getting it back, however, and may hinder the carrier. Are you, for a price,
      willing to deliver it?
    `,
    occurrence: 1,
    justAMessage: false,
    randomPosition: true,
  },
  [ARTIFACT_DELIVERY]: {
    title: 'Artifact Delivery',
    description: `
      This is professor Berger. I thank you for delivering the alien artifact to me.
      I hope the aliens weren't too much of a nuisance. I have transferred ${ARTIFACT_DELIVERY_REWARD} credits
      to your account, which I assume compensates for your troubles.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: true,
  },

  /**
   * Ambassador Jarek
   */
  [TRANSPORT_AMBASSADOR_JAREK]: {
    title: 'Ambassador Jarek',
    description: `
      A recent change in the political climate of this solar system
      has forced ambassador Jarek to flee back to his home system, Devidia.
      Would you be willing to give him a lift?
    `,
    occurrence: 1,
    justAMessage: false,
    randomPosition: true,
  },
  [JAREK_GETS_OUT]: {
    title: 'Jarek Gets Out',
    description: `
      Ambassador Jarek is very grateful to you for delivering him back to Devidia.
      As a reward, he gives you an experimental handheld haggling computer,
      which allows you to gain larger discounts when purchasing goods and equipment.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },

  /**
   * Jonathan Wild
   */
  [TRANSPORT_WILD]: {
    title: 'Jonathan Wild',
    description: `
      You need to transport Wild
    `,
    occurrence: 1,
    justAMessage: false,
    randomPosition: true,
  },
  [WILD_GETS_OUT]: {
    title: 'Wild Gets Out',
    description: 'Wild gets home, thanks',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },

  /**
   * Deliver reactor
   */
  [GET_REACTOR]: {
    title: "Morgan's Reactor",
    description: 'There is a reactor to transport, wanna take?',
    occurrence: 0,
    justAMessage: false,
    randomPosition: true,
  },
  [REACTOR_DELIVERED]: {
    title: 'Reactor Delivered',
    description: 'Reactor is delivered, congrats',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [GET_SPECIAL_LASER]: {
    title: "Install Morgan's Laser",
    description: 'Get a laser to install',
    occurrence: 0,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Alien invasion on Gemulon (countdown)
   */
  [ALIEN_INVASION]: {
    title: 'Alien Invasion',
    description: 'There is an alien invasion, go inform the system',
    occurrence: 0,
    justAMessage: true,
    randomPosition: true,
  },
  [GEMULON_INVADED]: {
    title: 'Gemulon Invaded',
    description: '??',
    occurrence: 0,
    justAMessage: true,
    randomPosition: true,
  },
  [GEMULON_RESCUED]: {
    title: 'Gemulon Rescued',
    description: 'Gemulon rescued, thanks',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [GET_FUEL_COMPACTOR]: {
    title: 'Fuel Compactor',
    description: 'Wanna install the fuel compactor?',
    occurrence: 0,
    justAMessage: false,
    randomPosition: true,
  },

  /**
   * Dangerous experiment (countdown)
   */
  [EXPERIMENT]: {
    title: 'Dangerous Experiment',
    description: `
      While reviewing the plans for Dr. Fehler's new space-warping drive,
      Dr. Lowenstam discovered a critical error.
      If you don't go to Daled and stop the experiment within ten days,
      the time-space continuum itself could be damaged.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: true,
  },
  [EXPERIMENT_STOPPED]: {
    title: 'Disaster Averted',
    description: `
      Upon your warning, Dr. Fehler calls off the experiment.
      As your reward, you are given a Portable Singularity.
      This device will, for one time only, instantly transport you to any system in the galaxy.
      The Singularity can be accessed by clicking the J button on the Galactic Chart.
    `,
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
  [EXPERIMENT_NOT_STOPPED]: {
    title: 'Experiment Failed',
    description: '',
    occurrence: 0,
    justAMessage: true,
    randomPosition: false,
  },
};
