import {
  tradeItemTypes,
  TradeItemType,
} from '../data/types/trade-item/trade-item-type';

export function makeTradeItems(): Record<TradeItemType, number> {
  return tradeItemTypes.reduce(
    (tradeItems, type) => ({ ...tradeItems, [type]: 0 }),
    {},
  ) as Record<TradeItemType, number>;
}
