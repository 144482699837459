import { System } from '../data/types/system/system';
import { tradeItemTypes } from '../data/types/trade-item/trade-item-type';
import { makeTradeItems } from '../factories/system-trade-items';
import { Coordinates } from '../data/types/system/coordinates';
import { getTradeItemQuantity } from '../core/trade-items/trade-items.quantity';

export const initSystemData = (systemCoordinates: Coordinates[]): System[] => {
  return systemCoordinates.map<System>((coordinates, nameIndex) => {
    return {
      nameIndex,
      coordinates,
      special: null, // fill in separate function
      tradeItems: makeTradeItems(),
      wormHoleNameIndex: -1, // need logic to fill
      mercenaries: [],
    };
  });
};

export const generateTradeItemsQuantities = (
  difficulty: number,
  currentDay: number,
) => (systems: System[]): System[] => {
  return systems.map<System>((system) => ({
    ...system,
    tradeItems: tradeItemTypes.reduce(
      (tradeItems, tradeItemType) => ({
        ...tradeItems,
        [tradeItemType]: getTradeItemQuantity(
          difficulty,
          currentDay,
          tradeItemType,
          system,
        ),
      }),
      system.tradeItems,
    ),
  }));
};
