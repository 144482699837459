import { GadgetConfigs } from '../../data/types/ship/gadget/gadget-config';
import {
  EXTRA_BAYS,
  AUTO_REPAIR_SYSTEM,
  NAVIGATING_SYSTEM,
  TARGETING_SYSTEM,
  CLOAKING_DEVICE,
  FUEL_COMPACTOR,
} from '../../data/types/ship/gadget/gadget-type';

export const gadgetConfigs: GadgetConfigs = {
  [EXTRA_BAYS]: {
    price: 2500,
    techLevel: 4,
    chance: 35,
    toSell: true,
  },
  [AUTO_REPAIR_SYSTEM]: {
    price: 7500,
    techLevel: 5,
    chance: 20,
    toSell: true,
  },
  [NAVIGATING_SYSTEM]: {
    price: 15000,
    techLevel: 6,
    chance: 20,
    toSell: true,
  },
  [TARGETING_SYSTEM]: {
    price: 25000,
    techLevel: 6,
    chance: 20,
    toSell: true,
  },
  [CLOAKING_DEVICE]: {
    price: 100000,
    techLevel: 7,
    chance: 5,
    toSell: true,
  },
  [FUEL_COMPACTOR]: {
    price: 30000,
    techLevel: 8,
    chance: 0,
    toSell: false,
  },
};
