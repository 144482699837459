import { pipe } from 'rxjs';
import { TRIBBLE_PRICE } from '../../../../app/data/constants/special-events';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { TRIBBLE } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';
import { selectAvailableCredits } from '../../../derived/computational-selectors';

export const selectTribbleAction = pipe(selectAvailableCredits, (credits) => {
  const { title } = getSpecialEventConfig(TRIBBLE);
  if (credits < TRIBBLE_PRICE) {
    return addNotification({
      title,
      message: `You need ${TRIBBLE_PRICE} credits`,
    });
  }
  return invokeSpecialEvent(TRIBBLE);
});
