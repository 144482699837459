import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { equipmentConfigs } from '../app/config/equipment.configs';
import {
  EquipmentItemType,
  getEquipmentType,
} from '../app/data/types/ship/equipment/equipment';
import { baseEquipmentSellPrice } from '../app/core/equipment/equipment.prices';
import { isGadgetType } from '../app/data/types/ship/gadget/gadget-type';
import {
  sellGadget,
  sellWeapon,
  sellShield,
} from '../store/game/ship/ship.actions';
import { isWeaponType } from '../app/data/types/ship/weapon/weapon-type';
import { isShieldType } from '../app/data/types/ship/shield/shield-type';
import { earnCredits } from '../store/game/balance/balance.actions';
import { Installable } from '../app/data/types/ship/equipment/installable';
import { SELL_EQUIPMENT_REASON } from '../app/ui/change-balance-reasons';

const useSellEquipment = (
  equipmentItemType: EquipmentItemType,
  equipmentId: string,
) => {
  const dispatch = useDispatch();
  const equipmentType = getEquipmentType(equipmentItemType);
  const config = equipmentConfigs[equipmentType] as Record<string, Installable>;
  const price = baseEquipmentSellPrice(config[equipmentItemType]);

  const doSell = useCallback(() => {
    if (isGadgetType(equipmentItemType)) {
      dispatch(sellGadget(equipmentId));
    }
    if (isWeaponType(equipmentItemType)) {
      dispatch(sellWeapon(equipmentId));
    }
    if (isShieldType(equipmentItemType)) {
      dispatch(sellShield(equipmentId));
    }
    dispatch(earnCredits({ amount: price, reason: SELL_EQUIPMENT_REASON }));
  }, [equipmentItemType, equipmentId, dispatch, price]);

  return [price, doSell] as const;
};

export default useSellEquipment;
