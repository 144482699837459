import { converge } from 'ramda';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import { ExecuteAttack } from '../../../../app/core/encounter/fight/attack';
import { selectDifficulty } from '../../status/status.selectors';
import { selectIsOpponentDoing } from './is-opponent-doing.selector';
import { FLEE } from '../../../../app/data/interaction/action-type';
import { selectPlayerShip } from '../../ship/ship.selectors';
import {
  selectEncounterType,
  selectOpponentShip,
} from '../encounter.selectors';
import {
  selectIsPlayerDoing,
  selectLastEncounterEventActor,
} from './encounter-events.selector';
import { IShip } from '../../../../app/data/types/ship/ship';

export const selectActorDamage = converge(
  (
    actor: ActorType,
    difficulty: number,
    isOpponentFlyingAway: boolean,
    isPlayerFlyingAway: boolean,
    playerShip: IShip,
    opponentShip: IShip,
    encounterType: ActorType,
  ) => {
    const isPlayerAttack = actor === PLAYER;
    const damagedActor = isPlayerAttack ? encounterType : PLAYER;
    const attacker = isPlayerAttack ? playerShip : opponentShip;
    const defender = isPlayerAttack ? opponentShip : playerShip;
    const isDefenderFlyingAway = isPlayerAttack
      ? isOpponentFlyingAway
      : isPlayerFlyingAway;

    return [
      damagedActor,
      ExecuteAttack(
        difficulty,
        attacker,
        defender,
        isDefenderFlyingAway,
        !isPlayerAttack,
      ),
    ] as const;
  },
  [
    selectLastEncounterEventActor,
    selectDifficulty,
    selectIsOpponentDoing(FLEE),
    selectIsPlayerDoing(FLEE),
    selectPlayerShip,
    selectOpponentShip,
    selectEncounterType,
  ],
);
