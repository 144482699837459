import React from 'react';
import { useSelector } from 'react-redux';
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonNote,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import { selectQuestStatusState } from '../../../../store/game/questStatus/quest-status.selectors';
import { questTypes } from '../../../../app/data/types/game/quest-status';
import { getQuestStatusLabel } from '../../../../app/ui/quest-status.labels';

const QuestStatus: React.FC = () => {
  const questStatusState = useSelector(selectQuestStatusState);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Quest status</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {questTypes.map((questType) => (
              <IonItem key={questType}>
                <IonLabel>{questType}</IonLabel>
                <IonNote slot="end">
                  {getQuestStatusLabel(questType)(questStatusState[questType])}
                </IonNote>
              </IonItem>
            ))}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default QuestStatus;
