import { pipe, UnaryFunction } from 'rxjs';
import { DEBT_TOO_LARGE, DEBT_WARNING } from '../../../app/data/constants/debt';
import { selectGame } from '../../selectors';
import { __, gt, prop, converge } from 'ramda';
import { getInterest } from '../../../app/core/bank/interest';
import { getNewspaperPrice } from '../../../app/core/news/news-price';
import { RootState } from '../../index';

export const selectStatus = pipe(selectGame, prop('status'));

export const selectCurrentDay = pipe(selectStatus, prop('days'));

export const selectDebt = pipe(selectStatus, prop('debt'));

export const selectIsPlayerInDebt = pipe(selectDebt, gt(__, 0));

export const selectIsDebtHigh = pipe(selectDebt, gt(__, DEBT_WARNING));

export const selectIsDebtTooLarge = pipe(selectDebt, gt(__, DEBT_TOO_LARGE));

export const selectDifficulty: (state: RootState) => number = pipe(
  selectStatus,
  prop('difficulty'),
);

export const selectIsMoonBought = pipe(selectStatus, prop('moonBought'));

export const selectInterest = pipe(selectDebt, getInterest);

export const selectNewspaperPrice = pipe(selectDifficulty, getNewspaperPrice);

export const selectLastPaidNewsDay = pipe(
  selectStatus,
  prop('lastPaidNewspaperDay'),
);

export const selectIsAlreadyPaidForNews = converge<
  boolean,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, number>]
>(
  (day, paidDay) => {
    return day === paidDay;
  },
  [selectCurrentDay, selectLastPaidNewsDay],
);
