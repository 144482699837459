import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IonRow, IonCol, IonButton, IonText } from '@ionic/react';
import { TradeItemType } from '../../../../app/data/types/trade-item/trade-item-type';
import useSellItem from '../../../../hooks/useSellItem';
import TradeItemInfo from './TradeItemInfo';
import { selectTradeItemQuantity } from '../../../../store/derived/computational-selectors';
import {
  getStatusColor,
  useTradeItemStatus,
} from '../../../../hooks/useTradeItemStatus';

const TradeItemCard: React.FC<{ tradeItemType: TradeItemType }> = ({
  tradeItemType,
}) => {
  const [isAvailableToBuy, buyStatus] = useTradeItemStatus(tradeItemType);
  const [sell, isAvailableToSell, getSellLabel] = useSellItem(tradeItemType);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const tradeItemQuantity = useSelector(selectTradeItemQuantity(tradeItemType));

  return (
    <IonRow>
      <IonCol>
        <IonText color={getStatusColor(buyStatus)}>
          <h1>
            {tradeItemQuantity} {tradeItemType}
          </h1>
        </IonText>
        <IonText>
          <p>{buyStatus}</p>
        </IonText>
      </IonCol>
      <IonCol>
        <IonButton onClick={() => setIsInfoOpen(true)}>Buy</IonButton>

        {isInfoOpen && (
          <TradeItemInfo
            tradeItemType={tradeItemType}
            isOpen={isInfoOpen}
            onClose={() => setIsInfoOpen(false)}
          />
        )}

        <IonButton disabled={!isAvailableToSell} onClick={sell}>
          {getSellLabel()}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default TradeItemCard;
