// Police Record Score
export const MADSCORE = -Infinity;
export const PSYCHOPATHSCORE = -100;
export const VILLAINSCORE = -70;
export const CRIMINALSCORE = -30;
export const CROOKSCORE = -10;
export const DUBIOUSSCORE = -5;
export const CLEANSCORE = 0;
export const LAWFULSCORE = 5;
export const TRUSTEDSCORE = 10;
export const HELPERSCORE = 25;
export const HEROSCORE = 75;
