import { EquipmentItemType } from '../equipment/equipment';

export const ENERGY_SHIELD = 'Energy shield';
export const REFLECTIVE_SHIELD = 'Reflective shield';
export const LIGHTNING_SHIELD = 'Lightning shield';

export const shields = [
  ENERGY_SHIELD,
  REFLECTIVE_SHIELD,
  LIGHTNING_SHIELD,
] as const;

export function isShieldType(
  equipmentItemType: EquipmentItemType,
): equipmentItemType is ShieldType {
  return shields.some((shieldType) => shieldType === equipmentItemType);
}

export type ShieldType = typeof shields[number];
