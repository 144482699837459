import { converge } from 'ramda';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { selectPlayerHasEmptyCrewCabines } from '../../ship/ship.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { TRANSPORT_AMBASSADOR_JAREK } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectJarekAction = converge<
  AnyAction,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, boolean>]
>(
  (policeRecordScore, hasEmptyCrewCabines) => {
    const { title } = getSpecialEventConfig(TRANSPORT_AMBASSADOR_JAREK);
    if (policeRecordScore < DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record is too low to take this quest',
      });
    }
    if (!hasEmptyCrewCabines) {
      return addNotification({
        title,
        message:
          'Your ship does not have empty crew cabines to take Jarek on board',
      });
    }
    return invokeSpecialEvent(TRANSPORT_AMBASSADOR_JAREK);
  },
  [selectPoliceRecordScore, selectPlayerHasEmptyCrewCabines],
);
