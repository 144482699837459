import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectReputationScore } from '../../../store/game/scores/scores.selectors';
import {
  getReputationColor,
  getReputationRecordScoreLabel,
} from '../../../app/ui/scores.labels';

export const ReputationMenuLabel: React.FC = () => {
  const reputationScore = useSelector(selectReputationScore);
  return (
    <IonNote
      slot="end"
      color={getReputationColor(reputationScore)}
      className="ion-text-uppercase">
      {getReputationRecordScoreLabel(reputationScore)}
    </IonNote>
  );
};
