import { IonChip, IonLabel } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getPoliceRankColor,
  policeScoreRanks,
} from '../../../app/ui/scores.labels';
import { selectPoliceRecordLabel } from '../../../store/game/scores/scores.selectors';

const PoliceRecordProgress: React.FC = () => {
  const currentLabel = useSelector(selectPoliceRecordLabel);

  return (
    <div>
      <h6>Police record</h6>
      {policeScoreRanks.map((scoreRank) => (
        <IonChip
          key={scoreRank.label}
          color={getPoliceRankColor(scoreRank, currentLabel)}>
          <IonLabel>{scoreRank.label}</IonLabel>
          &nbsp;-&nbsp;
          <IonLabel>{scoreRank.minScore}</IonLabel>
        </IonChip>
      ))}
    </div>
  );
};

export default PoliceRecordProgress;
