import { converge } from 'ramda';
import { splitDamageOnShieldsAndHull } from '../../../../app/core/encounter/fight/damage';
import { selectDifficulty } from '../../status/status.selectors';
import { selectPlayerShip } from '../../ship/ship.selectors';
import { selectOpponentShip } from '../encounter.selectors';
import {
  selectLastOpponentDamage,
  selectLastPlayerDamage,
} from './damage-log.selectors';

export const selectReceivedOpponentDamage = converge(
  splitDamageOnShieldsAndHull,
  [selectDifficulty, selectOpponentShip, selectLastOpponentDamage, () => false],
);
export const selectReceivedPlayerDamage = converge(
  splitDamageOnShieldsAndHull,
  [selectDifficulty, selectPlayerShip, selectLastPlayerDamage, () => true],
);
