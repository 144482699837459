import { IShip } from '../../../data/types/ship/ship';
import {
  UPGRADED_HULL,
  UPGRADED_HULL_POINTS,
} from '../../../data/types/ship/equipment/extra';
import { getShipConfig } from '../../../config/ship/get-ship-config';

export const getMaxHullStrength = (ship: IShip): number => {
  if (ship.extra.includes(UPGRADED_HULL)) {
    return getShipConfig(ship).hullStrength + UPGRADED_HULL_POINTS;
  }

  return getShipConfig(ship).hullStrength;
};
