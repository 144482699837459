import {
  ATTACK,
  BRIBE,
  EncounterActionType,
  SUBMIT,
  SURRENDER,
} from '../../../../app/data/interaction/action-type';
import { PLAYER } from '../../../../app/data/interaction/actor-type';
import { selectBribeAction } from './bribe';
import { selectAttackAction } from './attack';
import { selectSubmitAction } from './submit';
import { selectSurrenderAction } from './surrender';
import { makeActionEvent } from '../make-action-event';
import { Action, PayloadAction } from 'typesafe-actions';

export const selectPlayerAction = (action: EncounterActionType) => {
  switch (action) {
    case BRIBE:
      return selectBribeAction;
    case ATTACK:
      return selectAttackAction;
    case SUBMIT:
      return selectSubmitAction;
    case SURRENDER:
      return selectSurrenderAction;
    default:
      return () => makeActionEvent(PLAYER)(action);
  }
};
