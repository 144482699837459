import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IonRow, IonCol, IonText, IonItem } from '@ionic/react';
import SystemInfoCard from '../../debug/Systems/Info/SystemInfoCard';
import { System } from '../../../../app/data/types/system/system';
import {
  getSystemName,
  getSystemDistanceLabel,
} from '../../../../app/ui/solar-system.labels';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';

type SystemProps = {
  system: System;
};

const TravelSystemCard: React.FC<SystemProps> = ({ system }) => {
  const currentSystem = useSelector(selectCurrentSystem);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  return (
    <>
      <IonItem button={true} detail={true} onClick={() => setIsInfoOpen(true)}>
        <IonRow className="ion-no-padding">
          <IonCol className="ion-padding-vertical">
            <IonText color={'dark'}>
              <h1>{getSystemName(system)}</h1>
            </IonText>
            <IonText color={'medium'}>
              <p>{getSystemDistanceLabel(currentSystem, system)}</p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonItem>

      {isInfoOpen && (
        <SystemInfoCard
          isOpen
          system={system}
          onClose={() => setIsInfoOpen(false)}
        />
      )}
    </>
  );
};

export default TravelSystemCard;
