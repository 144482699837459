import React from 'react';
import { IonLabel, IonProgressBar } from '@ionic/react';
import { getPercentageValue } from '../../../app/ui/progressbar';
import { IShip } from '../../../app/data/types/ship/ship';
import { getShipConfig } from '../../../app/config/ship/get-ship-config';

export const ShipHull: React.FC<{ ship: IShip }> = ({ ship }) => {
  return (
    <div>
      <IonLabel className="code">hull</IonLabel>
      <IonProgressBar
        value={getPercentageValue(ship.hull, getShipConfig(ship).hullStrength)}
        color="primary"
      />
    </div>
  );
};
