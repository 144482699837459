import { add } from 'ramda';
import { mercenaryNames } from '../../data/labels/mercenaries';
import { IShip } from '../../data/types/ship/ship';
import { mercenaryHirePrice } from './mercenary-hire-price';

export const mercenaryPayment = ({ crew }: IShip): number =>
  crew
    // skip special characters
    .filter((crewMember) => mercenaryNames.includes(crewMember.name))
    .map(mercenaryHirePrice)
    .reduce(add, 0);
