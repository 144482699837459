import { converge } from 'ramda';
import { selectIsMoonBought } from '../../status/status.selectors';
import { addNotification } from '../../notifications/notifications.actions';
import { MOON_COST } from '../../../../app/data/constants/special-events';
import { MOON_FOR_SALE } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';
import {
  selectAvailableCredits,
  selectPlayerWorth,
} from '../../../derived/computational-selectors';
import { addConfirmation } from '../../confirmations/confirmations.actions';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectMoonForSaleAction = converge<
  AnyAction,
  [
    UnaryFunction<RootState, boolean>,
    UnaryFunction<RootState, number>,
    UnaryFunction<RootState, number>,
  ]
>(
  (isMoonBought, currentWorth, availableCredits) => {
    if (isMoonBought) {
      return addNotification({
        title: 'Moon for sale',
        message: 'You already confirmed your purchase',
      });
    }
    if (currentWorth < (MOON_COST * 4) / 5) {
      return addNotification({
        title: 'Moon for sale',
        message: 'Your current worth is not enough to make a purchase',
      });
    }
    if (MOON_COST > availableCredits) {
      return addNotification({
        title: 'Moon for sale',
        message: 'You do not have enough money to make a purchase',
      });
    }
    return addConfirmation({
      title: 'Moon for sale: confirmation',
      message:
        'Be aware that you can not return money if you have changed your mind. To claim your moon, you have to go to Utopia system. You should have enough money to afford the travel costs after you have purchased a moon. Do you confirm the purchase?',
      action: invokeSpecialEvent(MOON_FOR_SALE),
    });
  },
  [selectIsMoonBought, selectPlayerWorth, selectAvailableCredits],
);
