import { converge } from 'ramda';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { selectPlayerEmptyCargoBaysAmount } from '../../ship/ship.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { JAPORI_DISEASE } from '../../../../app/data/types/system/special-event/special-event-type';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { addNotification } from '../../notifications/notifications.actions';
import { MEDICINE_AMOUNT } from '../../../../app/data/constants/special-events';
import { invokeSpecialEvent } from '../special-events.actions';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectJaporiDiseaseAction = converge<
  AnyAction,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, number>]
>(
  (policeRecordScore, emptyCargoBays) => {
    const { title } = getSpecialEventConfig(JAPORI_DISEASE);
    if (policeRecordScore < DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record is too low to take this quest',
      });
    }
    if (emptyCargoBays < MEDICINE_AMOUNT) {
      return addNotification({
        title,
        message: `Your ship needs ${MEDICINE_AMOUNT} empty cargo bays to take this quest`,
      });
    }
    return invokeSpecialEvent(JAPORI_DISEASE);
  },
  [selectPoliceRecordScore, selectPlayerEmptyCargoBaysAmount],
);
