import { NORMAL } from '../../../data/constants/difficulty';
import { getOpponentShipType } from './get-opponent-ship-type';
import { getOpponentGadgets } from './get-opponent-gadgets';
import { getOpponentCargo } from './get-opponent-cargo';
import { getOpponentShields } from './get-opponent-shields';
import { getOpponentWeapon } from './get-opponent-weapon';
import {
  getOpponentCommander,
  getOpponentCrewMembers,
} from './get-opponent-crew';
import { IShip } from '../../../data/types/ship/ship';
import { defaultOpponent } from '../../../data/ships';
import { System } from '../../../data/types/system/system';
import { ShipType } from '../../../data/types/ship/ship-type';
import { GADGETS, SHIELDS, WEAPONS } from '../../../config/equipment.configs';
import {
  ActorType,
  CAPTAIN,
  DRAGONFLY,
  MANTIS,
  SCARAB,
  SPACE_MONSTER,
} from '../../../data/interaction/actor-type';
import { shipConfigs } from '../../../config/ship/ship.configs';
import {
  getDragonFlyShip,
  getFamousCaptainShip,
  getScarabShip,
  getSpaceMonsterShip,
} from './special-ships';
import { IStatus } from '../../../data/types/game/status';
import { getHull } from './get-opponent-hull';
import { getTries } from './get-tries';

export const getOpponentShip = (
  difficulty: number,
  playerShip: IShip,
  playerCurrentWorth: number,
  policeRecordScore: number,
  warpSystem: System,
  status: IStatus, // we need it only for space monster remaining hull
) => (opponentType: ActorType): IShip => {
  if (opponentType === CAPTAIN) {
    return getFamousCaptainShip();
  }

  if (opponentType === SPACE_MONSTER) {
    return getSpaceMonsterShip(difficulty, status);
  }

  if (opponentType === DRAGONFLY) {
    return getDragonFlyShip(difficulty);
  }

  if (opponentType === SCARAB) {
    return getScarabShip(difficulty);
  }

  const shipGoodsTries =
    opponentType === MANTIS
      ? getTries(
          policeRecordScore,
          difficulty,
          playerCurrentWorth,
        )(opponentType, playerShip)
      : Math.max(1, playerCurrentWorth / 150000 + difficulty - NORMAL);

  const opponentShipType: ShipType = getOpponentShipType(
    difficulty,
    playerCurrentWorth,
    policeRecordScore,
  )(opponentType, warpSystem, playerShip);
  const gadgets = getOpponentGadgets(
    opponentShipType,
    shipGoodsTries,
    difficulty,
  );
  const cargo = getOpponentCargo(
    opponentType,
    opponentShipType,
    gadgets,
    difficulty,
  );
  const shields = getOpponentShields(
    opponentShipType,
    shipGoodsTries,
    difficulty,
  );

  return {
    ...defaultOpponent,
    type: opponentShipType,
    equipment: {
      [GADGETS]: gadgets,
      [WEAPONS]: getOpponentWeapon(
        opponentShipType,
        shipGoodsTries,
        difficulty,
      ),
      [SHIELDS]: shields,
    },
    cargo,
    fuel: shipConfigs[opponentShipType].fuelTanks,
    tribbles: 0,
    hull: getHull(opponentType, opponentShipType, shields),
    commander: getOpponentCommander(warpSystem, difficulty, playerShip),
    crew: getOpponentCrewMembers(opponentShipType, difficulty),
  };
};
