import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './status.actions';
import { IStatus } from '../../../app/data/types/game/status';
import { defaultStatus } from '../../../app/data/game';
import { FABRICRIPINITIALPROBABILITY } from '../../../app/data/constants';

const initialStatusState: IStatus = {
  ...defaultStatus,
};

export type StatusActions = ActionType<typeof actions>;

export const statusReducer = createReducer<IStatus, StatusActions>(
  initialStatusState,
)
  .handleAction(actions.initStatus, (_, action) => ({ ...action.payload }))
  .handleAction(actions.getLoan, (status, action) => ({
    ...status,
    debt: status.debt + action.payload,
  }))
  .handleAction(actions.payLoanBack, (status, action) => ({
    ...status,
    debt: status.debt - action.payload,
  }))
  .handleAction(actions.increaseDays, (status, action) => ({
    ...status,
    days: status.days + action.payload,
  }))
  .handleAction(actions.fabricRipped, (status) => ({
    ...status,
    fabricRipProbability: FABRICRIPINITIALPROBABILITY,
  }))
  .handleAction(actions.decreaseFabricRipProbability, (status) => ({
    ...status,
    fabricRipProbability: Math.max(status.fabricRipProbability - 1, 0),
  }))
  .handleAction(actions.buyMoon, (status) => ({
    ...status,
    moonBought: true,
  }))
  .handleAction(actions.registerNewspaperPurchase, (status, action) => ({
    ...status,
    lastPaidNewspaperDay: action.payload,
  }));
