import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectDebt } from '../../../store/game/status/status.selectors';

export const DebtMenuLabel: React.FC = () => {
  const debt = useSelector(selectDebt);
  return (
    <IonNote
      slot="end"
      className="ion-text-uppercase"
      color={debt > 0 ? 'danger' : ''}>
      {debt > 0 ? `Debt ${debt} cr.` : 'No debt'}
    </IonNote>
  );
};
