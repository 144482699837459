import { Action, createAction } from 'typesafe-actions';
import * as actionTypes from './confirmations.action-types';

export const resetConfirmations = createAction(
  actionTypes.RESET_CONFIRMATIONS,
)();
export const addConfirmation = createAction(actionTypes.ADD_CONFIRMATION)<{
  title: string;
  message: string;
  action: Action;
}>();
export const dismissConfirmation = createAction(
  actionTypes.DISMISS_CONFIRMATION,
)<string>();
