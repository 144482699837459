import { IonNote } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectPlayerFilledCargoBays,
  selectPlayerTotalCargoBays,
} from '../../../store/game/ship/ship.selectors';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export const CargoBaysMenuLabel: React.FC = () => {
  const filledCargoBays = useSelector(selectPlayerFilledCargoBays);
  const totalCargoBays = useTypedSelector(selectPlayerTotalCargoBays);
  return (
    <IonNote slot="end" className="ion-text-uppercase">
      {filledCargoBays}/{totalCargoBays}
    </IonNote>
  );
};
