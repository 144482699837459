// we might have same tradeable item several times, because it was bought by difference price
import { TradeItemType } from '../../../data/types/trade-item/trade-item-type';
import { add, prop } from 'ramda';
import { PurchasedItem } from '../../../data/types/trade-item/purchased-item';

export function getTotalQuantityOfTradeableItem(
  cargo: PurchasedItem[],
  tradeItemType: TradeItemType,
): number {
  return cargo
    .filter((cargoItem) => cargoItem.type === tradeItemType)
    .map(prop('quantity'))
    .reduce(add, 0);
}
