import React from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import ShipItem from './ShipItem';
import { sellableShipTypes } from '../../../app/core/ship/sellable-ship-types';
import { groupBy } from 'ramda';
import { sizeLabels } from '../../../app/data/labels/sizes';
import { shipConfigs } from '../../../app/config/ship/ship.configs';

const BuyShips: React.FC = () => {
  const sizeGroups = groupBy(
    (type) => sizeLabels[shipConfigs[type].size],
    sellableShipTypes,
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Ships</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          {Object.keys(sizeGroups).map((size) => (
            <IonRow key={size}>
              <IonCol className="ion-padding-horizontal" size="12">
                <h1 className="ion-text-uppercase">{size} size</h1>
              </IonCol>
              {sizeGroups[size]?.map((shipType) => (
                <IonCol key={shipType} sizeSm="12" sizeMd="6">
                  <ShipItem shipType={shipType} />
                </IonCol>
              ))}
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default BuyShips;
