import { realDistance } from '../core/coordinates/coordinates';
import { SystemNames } from '../data/labels/solar-system/names';
import { System } from '../data/types/system/system';
import { TechLevel } from '../data/labels/solar-system/technical-levels';
import { Activity } from '../data/labels/solar-system/activity-levels';
import { SystemStatusLabels } from '../data/labels/solar-system/statuses';
import { SpecialResourcesLabels } from '../data/labels/solar-system/special-resources';
import { getSystemStatus } from '../core/solar-system/system-status';
import { getPoliticConfig } from '../config/politic/get-politic-config';
import { pipe } from 'rxjs';
import { prop } from 'ramda';
import { getSystemResource } from '../core/solar-system/system-resource';
import { getSystemSize } from '../core/solar-system/system-size';
import { getSystemTechLevel } from '../core/solar-system/system-tech-level';
import { getSystemPolitics } from '../core/solar-system/system-politics';
import { sizeLabels } from '../data/labels/sizes';
import { IPoliticConfig } from '../data/types/politic/politic-config';

export function getSystemNameByIndex(nameIndex: number): string {
  return SystemNames[nameIndex];
}

export function getSystemName(system: System): string {
  return getSystemNameByIndex(system.nameIndex);
}

export function getSystemSizeName(system: System): string {
  return sizeLabels[getSystemSize(system)];
}

export function getSystemTechLevelName(system: System): string {
  return TechLevel[getSystemTechLevel(system)];
}

export function getSystemPoliticsName(system: System): string {
  return getSystemPolitics(system);
}

export function getSystemResourcesName(system: System): string {
  return SpecialResourcesLabels[getSystemResource(system)];
}

export const getSystemStrength = (strengthKey: keyof IPoliticConfig) =>
  pipe(getPoliticConfig, (config) => config[strengthKey] as number);

export const getActivityLabel = (strength: number) => Activity[strength];

export const getSystemPoliceActivity = pipe(
  getSystemStrength('strengthPolice'),
  getActivityLabel,
);

export const getSystemPiratesActivity = pipe(
  getSystemStrength('strengthPirates'),
  getActivityLabel,
);

export const getSystemTradersActivity = pipe(
  getSystemStrength('strengthTraders'),
  getActivityLabel,
);

export function getStatusLabel(currentDay: number, system: System): string {
  return SystemStatusLabels[getSystemStatus(currentDay, system)];
}

export function getSystemStatusInfo(
  currentDay: number,
  system: System,
): string {
  return `currently ${getStatusLabel(currentDay, system)}`;
}

export function getSystemDistanceLabel(
  currentSystem: System,
  system: System,
): string {
  const distance = Math.round(
    realDistance(currentSystem.coordinates, system.coordinates),
  );

  return `${distance} parsecs`;
}
