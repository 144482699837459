import { IShip } from '../data/types/ship/ship';
import { QuestStatus } from '../data/types/game/quest-status';
import { TradeItemType } from '../data/types/trade-item/trade-item-type';
import { PlayerTotalCargoBays } from '../core/ship/cargo/total-cargo-bays';
import { FilledCargoBays } from '../core/ship/cargo/filled-cargo-bays';
import { getTotalQuantityOfTradeableItem } from '../core/ship/cargo/total-quantity-of-trade-item';
import { PurchasedItem } from '../data/types/trade-item/purchased-item';

export const getFilledCargoInPercentage = (questStatus: QuestStatus) => (
  ship: IShip,
): number => {
  const filledCargo = FilledCargoBays(ship.cargo);
  const totalCargo = PlayerTotalCargoBays(questStatus, ship);
  return filledCargo / totalCargo;
};

export function getTradeItemQuantity(
  cargo: PurchasedItem[],
  tradeItemType: TradeItemType,
): number {
  const quantity = getTotalQuantityOfTradeableItem(cargo, tradeItemType);
  // TODO: we shouldn't receive non-numeric values, it's a bug of buying logic
  return Math.floor(quantity);
}
