import { TradeItemType } from '../../../../app/data/types/trade-item/trade-item-type';
import { isTradeItemInProduction } from '../../../../app/core/trade-items/trade-items.utils';
import { TradeItemProfit } from './TradeItemProfit';
import { IonButton, IonGrid } from '@ionic/react';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { selectReachableSystems } from '../../../../store/derived/computational-selectors';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';
import { BuyTradeItem } from './BuyTradeItem';
import { useTradeItemStatus } from '../../../../hooks/useTradeItemStatus';

export const Profit: React.FC<{
  tradeItemType: TradeItemType;
  onClose: () => unknown;
}> = ({ tradeItemType, onClose }) => {
  const systemsToTravel = useSelector(selectReachableSystems);
  const currentSystem = useSelector(selectCurrentSystem, shallowEqual);
  const [isBuyOpen, setIsBuyOpen] = useState(false);
  const [isAvailableToBuy, buyStatus] = useTradeItemStatus(tradeItemType);

  if (!isTradeItemInProduction(tradeItemType, currentSystem)) {
    return <p>Not in production</p>;
  }

  return (
    <IonGrid>
      {systemsToTravel.map((system) => (
        <TradeItemProfit
          key={system.nameIndex}
          tradeItemType={tradeItemType}
          system={system}
        />
      ))}

      {isBuyOpen && (
        <BuyTradeItem
          tradeItemType={tradeItemType}
          isOpen={isBuyOpen}
          onClose={() => {
            setIsBuyOpen(false);
            onClose();
          }}
        />
      )}

      {isAvailableToBuy && (
        <IonButton onClick={() => setIsBuyOpen(true)}>Buy</IonButton>
      )}
    </IonGrid>
  );
};
