import { prop } from 'ramda';
import { pipe } from 'rxjs';
import { selectGame } from '../../selectors';

export const selectToasts = pipe(selectGame, prop('toasts'));

export const selectFirstToast = pipe(
  selectToasts,
  (toasts) => toasts[0] || null,
);
