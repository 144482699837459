// Randomize the system locations a bit more, otherwise the systems with the first
// names in the alphabet are all in the centre
import { System } from '../../data/types/system/system';
import { shuffle } from '../../utils/shuffle-array';

export function shuffleNames(systems: System[]): System[] {
  return shuffle(systems.map((system) => system.nameIndex)).map(
    (nameIndex, index) => ({
      ...systems[index],
      nameIndex,
    }),
  );
}
