import { Installable } from '../../../data/types/ship/equipment/installable';
import { getRandom } from '../../../utils';

export const getEquipmentType = <T extends string>(
  config: Record<T, Installable>,
  types: readonly T[],
  tries: number,
): T => {
  let trial = 0;
  let probableType = 0;
  while (trial < tries) {
    const chance = getRandom(0, 100);
    let typeIndex = 0;
    let installChance = config[types[0]].chance;
    while (chance < installChance) {
      if (typeIndex === types.length - 1) break;
      typeIndex += 1;
      installChance += config[types[typeIndex]].chance;
    }
    if (typeIndex > probableType) probableType = typeIndex;
    trial += 1;
  }
  return types[probableType];
};
