// return how much fuel we actually purchased
import { IShip } from '../../../data/types/ship/ship';
import { getFuelAmountToFillIn } from './fuel-amount-to-fill-in';
import { getPossibleFuelAmountToPurchase } from './possible-amount-to-purchase';

export const getMaxFuelAmountToPurchase = (
  ship: IShip,
  availableCredits: number,
): number => {
  const maxFuelAmountToFillIn = getFuelAmountToFillIn(ship);
  const possibleFuelAmountToBuy = getPossibleFuelAmountToPurchase(
    ship,
    availableCredits,
  );
  return Math.min(maxFuelAmountToFillIn, possibleFuelAmountToBuy);
};
