import React from 'react';
import { useSelector } from 'react-redux';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonNote,
  IonCard,
  IonCardContent,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import { selectSettings } from '../../../store/game/settings/settings.selectors';
import { Settings } from '../../../app/data/types/game/settings';

const SettingsPage: React.FC = () => {
  const settings = useSelector(selectSettings);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {Object.keys(settings).map((key) => (
              <IonItem key={key}>
                <IonLabel>{key}</IonLabel>
                <IonNote slot="end">
                  {settings[key as keyof Settings].toString()}
                </IonNote>
              </IonItem>
            ))}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
