import * as actionTypes from './special-events.action-type';
import { createAction } from 'typesafe-actions';
import { SpecialEventType } from '../../../app/data/types/system/special-event/special-event-type';

export const validateSpecialEvent = createAction(
  actionTypes.VALIDATE_SPECIAL_EVENT,
)<SpecialEventType>();

export const invokeSpecialEvent = createAction(
  actionTypes.INVOKE_SPECIAL_EVENT,
)<SpecialEventType>();
