import { CLEANSCORE } from '../../data/constants/police-record-score';

export const getMaxLoan = (
  policeRecordScore: number,
  currentWorth: number,
): number => {
  if (policeRecordScore < CLEANSCORE) {
    return 500;
  }
  return Math.round(Math.min(25000, Math.max(1000, currentWorth * 0.1)));
};
