import { IonChip, IonLabel } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getReputationRankColor,
  reputationScoreRanks,
} from '../../../app/ui/scores.labels';
import {
  selectReputationLabel,
  selectReputationScore,
} from '../../../store/game/scores/scores.selectors';

const ReputationProgress: React.FC = () => {
  const currentScore = useSelector(selectReputationScore);
  const currentRank = useSelector(selectReputationLabel);

  return (
    <div>
      <h6>Reputation</h6>
      {reputationScoreRanks.map((scoreRank) => (
        <IonChip
          key={scoreRank.label}
          color={getReputationRankColor(scoreRank, currentScore)}>
          <IonLabel>{scoreRank.label}</IonLabel>
          &nbsp;-&nbsp;
          <IonLabel>
            {scoreRank.label === currentRank
              ? currentScore
              : scoreRank.minScore}
          </IonLabel>
        </IonChip>
      ))}
    </div>
  );
};

export default ReputationProgress;
