import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsCurrentEncounterAccepted } from '../../../../store/game/travel/selectors/current-encounter.selector';
import EncounterForm from '../Encounter/Encounter';

export const EncounterContainer: React.FC = () => {
  const dispatch = useDispatch();
  const accepted = useSelector(selectIsCurrentEncounterAccepted);

  if (accepted) {
    return <EncounterForm isOpen onFinish={dispatch} />;
  }
  return <></>;
};
