import { MAX_TRAVEL_RANGE } from '../../data/constants/ship-types';
import { ShipConfigs } from '../../data/types/ship/ship-config';
import {
  FLEA,
  GNAT,
  FIREFLY,
  MOSQUITO,
  BUMBLEBEE,
  BEETLE,
  HORNET,
  GRASSHOPPER,
  TERMITE,
  WASP,
  SPACE_MONSTER_SHIP,
  DRAGONFLY_SHIP,
  MANTIS_SHIP,
  SCARAB_SHIP,
  BOTTLE,
} from '../../data/types/ship/ship-type';
import { WEAPONS, GADGETS, SHIELDS } from '../equipment.configs';

export const shipConfigs: ShipConfigs = {
  [FLEA]: {
    cargoBays: 10,
    equipmentSlots: {
      [WEAPONS]: 0,
      [SHIELDS]: 0,
      [GADGETS]: 0,
    },
    crewCabines: 0,
    fuelTanks: MAX_TRAVEL_RANGE,
    minTechLevel: 4,
    costOfFuel: 1,
    price: 2000,
    // tuned from original config
    // TODO: smarter occurennce depends on difficulty and game day
    occurrence: 30,
    hullStrength: 25,
    police: -1,
    pirates: -1,
    traders: 0,
    repairCosts: 1,
    size: 0,
    toSell: true,
  },
  [GNAT]: {
    cargoBays: 15,
    equipmentSlots: {
      [WEAPONS]: 1,
      [SHIELDS]: 0,
      [GADGETS]: 1,
    },
    crewCabines: 0,
    fuelTanks: 14,
    minTechLevel: 5,
    costOfFuel: 2,
    price: 10000,
    occurrence: 28,
    hullStrength: 100,
    police: 0,
    pirates: 0,
    traders: 0,
    repairCosts: 1,
    size: 1,
    toSell: true,
  },
  [FIREFLY]: {
    cargoBays: 20,
    equipmentSlots: {
      [WEAPONS]: 1,
      [SHIELDS]: 1,
      [GADGETS]: 1,
    },
    crewCabines: 0,
    fuelTanks: 17,
    minTechLevel: 5,
    costOfFuel: 3,
    price: 25000,
    occurrence: 20,
    hullStrength: 100,
    police: 0,
    pirates: 0,
    traders: 0,
    repairCosts: 1,
    size: 1,
    toSell: true,
  },
  [MOSQUITO]: {
    cargoBays: 15,
    equipmentSlots: {
      [WEAPONS]: 2,
      [SHIELDS]: 1,
      [GADGETS]: 1,
    },
    crewCabines: 0,
    fuelTanks: 13,
    minTechLevel: 5,
    costOfFuel: 5,
    price: 30000,
    occurrence: 20,
    hullStrength: 100,
    police: 0,
    pirates: 1,
    traders: 0,
    repairCosts: 1,
    size: 1,
    toSell: true,
  },
  [BUMBLEBEE]: {
    cargoBays: 25,
    equipmentSlots: {
      [WEAPONS]: 1,
      [SHIELDS]: 2,
      [GADGETS]: 2,
    },
    crewCabines: 1,
    fuelTanks: 15,
    minTechLevel: 5,
    costOfFuel: 7,
    price: 60000,
    occurrence: 15,
    hullStrength: 100,
    police: 1,
    pirates: 1,
    traders: 0,
    repairCosts: 1,
    size: 2,
    toSell: true,
  },
  [BEETLE]: {
    cargoBays: 50,
    equipmentSlots: {
      [WEAPONS]: 0,
      [SHIELDS]: 1,
      [GADGETS]: 1,
    },
    crewCabines: 2,
    fuelTanks: 14,
    minTechLevel: 5,
    costOfFuel: 10,
    price: 80000,
    occurrence: 3,
    hullStrength: 50,
    police: -1,
    pirates: -1,
    traders: 0,
    repairCosts: 1,
    size: 2,
    toSell: true,
  },
  [HORNET]: {
    cargoBays: 20,
    equipmentSlots: {
      [WEAPONS]: 3,
      [SHIELDS]: 2,
      [GADGETS]: 1,
    },
    crewCabines: 1,
    fuelTanks: 16,
    minTechLevel: 6,
    costOfFuel: 15,
    price: 100000,
    occurrence: 6,
    hullStrength: 150,
    police: 2,
    pirates: 3,
    traders: 1,
    repairCosts: 2,
    size: 3,
    toSell: true,
  },
  [GRASSHOPPER]: {
    cargoBays: 30,
    equipmentSlots: {
      [WEAPONS]: 2,
      [SHIELDS]: 2,
      [GADGETS]: 3,
    },
    crewCabines: 2,
    fuelTanks: 15,
    minTechLevel: 6,
    costOfFuel: 15,
    price: 150000,
    occurrence: 2,
    hullStrength: 150,
    police: 3,
    pirates: 4,
    traders: 2,
    repairCosts: 3,
    size: 3,
    toSell: true,
  },
  [TERMITE]: {
    cargoBays: 60,
    equipmentSlots: {
      [WEAPONS]: 1,
      [SHIELDS]: 3,
      [GADGETS]: 2,
    },
    crewCabines: 2,
    fuelTanks: 13,
    minTechLevel: 7,
    costOfFuel: 20,
    price: 225000,
    occurrence: 2,
    hullStrength: 200,
    police: 4,
    pirates: 5,
    traders: 3,
    repairCosts: 4,
    size: 4,
    toSell: true,
  },
  [WASP]: {
    cargoBays: 35,
    equipmentSlots: {
      [WEAPONS]: 3,
      [SHIELDS]: 2,
      [GADGETS]: 2,
    },
    crewCabines: 2,
    fuelTanks: 14,
    minTechLevel: 7,
    costOfFuel: 20,
    price: 300000,
    occurrence: 2,
    hullStrength: 200,
    police: 5,
    pirates: 6,
    traders: 4,
    repairCosts: 5,
    size: 4,
    toSell: true,
  },
  [SPACE_MONSTER_SHIP]: {
    cargoBays: 0,
    equipmentSlots: {
      [WEAPONS]: 3,
      [SHIELDS]: 0,
      [GADGETS]: 0,
    },
    crewCabines: 0,
    fuelTanks: 1,
    minTechLevel: 8,
    costOfFuel: 1,
    price: 500000,
    occurrence: 0,
    hullStrength: 500,
    police: 8,
    pirates: 8,
    traders: 8,
    repairCosts: 1,
    size: 4,
    toSell: false,
  },
  [DRAGONFLY_SHIP]: {
    cargoBays: 0,
    equipmentSlots: {
      [WEAPONS]: 2,
      [SHIELDS]: 3,
      [GADGETS]: 2,
    },
    crewCabines: 0,
    fuelTanks: 1,
    minTechLevel: 8,
    costOfFuel: 1,
    price: 500000,
    occurrence: 0,
    hullStrength: 10,
    police: 8,
    pirates: 8,
    traders: 8,
    repairCosts: 1,
    size: 1,
    toSell: false,
  },
  [MANTIS_SHIP]: {
    cargoBays: 0,
    equipmentSlots: {
      [WEAPONS]: 3,
      [SHIELDS]: 1,
      [GADGETS]: 3,
    },
    crewCabines: 2,
    fuelTanks: 1,
    minTechLevel: 8,
    costOfFuel: 1,
    price: 500000,
    occurrence: 0,
    hullStrength: 300,
    police: 8,
    pirates: 8,
    traders: 8,
    repairCosts: 1,
    size: 2,
    toSell: false,
  },
  [SCARAB_SHIP]: {
    cargoBays: 20,
    equipmentSlots: {
      [WEAPONS]: 2,
      [SHIELDS]: 0,
      [GADGETS]: 0,
    },
    crewCabines: 1,
    fuelTanks: 1,
    minTechLevel: 8,
    costOfFuel: 1,
    price: 500000,
    occurrence: 0,
    hullStrength: 400,
    police: 8,
    pirates: 8,
    traders: 8,
    repairCosts: 1,
    size: 3,
    toSell: false,
  },
  [BOTTLE]: {
    cargoBays: 0,
    equipmentSlots: {
      [WEAPONS]: 0,
      [SHIELDS]: 0,
      [GADGETS]: 0,
    },
    crewCabines: 0,
    fuelTanks: 1,
    minTechLevel: 8,
    costOfFuel: 1,
    price: 100,
    occurrence: 0,
    hullStrength: 10,
    police: 8,
    pirates: 8,
    traders: 8,
    repairCosts: 1,
    size: 1,
    toSell: false,
  },
};
