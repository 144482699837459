import { System } from '../../data/types/system/system';
import { SpecialEventType } from '../../data/types/system/special-event/special-event-type';

export const setSpecialEvent = (
  nameIndex: number,
  special: SpecialEventType,
) => (system: System) => {
  if (system.nameIndex === nameIndex) {
    return {
      ...system,
      special,
    };
  }

  return system;
};
