import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { shipReducer, ShipActions } from './game/ship/ship.reducer';
import { systemsReducer, SystemsActions } from './game/systems/systems.reducer';
import { statusReducer, StatusActions } from './game/status/status.reducer';
import {
  CommonActions,
  CommonState,
  commonReducer,
} from './game/common/common.reducer';
import { ScoresActions, scoresReducer } from './game/scores/scores.reducer';
import {
  travelsReducer,
  TravelActions,
  Travel,
} from './game/travel/travel.reducer';
import {
  questStatusReducer,
  QuestStatusActions,
} from './game/questStatus/quest-status.reducer';
import { Entities } from './entities';
import { travelEpics } from './game/travel/travel.epics';
import {
  settingsReducer,
  SettingsActions,
} from './game/settings/settings.reducer';
import { Settings } from '../app/data/types/game/settings';
import { IShip } from '../app/data/types/ship/ship';
import { IStatus } from '../app/data/types/game/status';
import { System } from '../app/data/types/system/system';
import { IScores } from '../app/data/types/game/scores';
import { QuestStatus } from '../app/data/types/game/quest-status';
import { shipEpics } from './game/ship/ship.epics';
import { BalanceActions, balanceReducer } from './game/balance/balance.reducer';
import { BalanceRecord } from '../app/data/types/balance';
import { statusEpics } from './game/status/status.epics';
import { questsEpics } from './game/questStatus/quest-status.epics';
import { encounterEpics } from './game/encounter/encounter.epics';
import {
  NotificationsActions,
  notificationsReducer,
  NotificationsState,
} from './game/notifications/notifications.reducer';
import { balanceEpics } from './game/balance/balance.epics';
import { scoreEpics } from './game/scores/scores.epics';
import { commonEpics } from './game/common/common.epics';
import {
  ConfirmationsActions,
  confirmationsReducer,
  ConfirmationsState,
} from './game/confirmations/confirmations.reducer';
import { specialEventsEpics } from './game/special-events/special-events.epics';
import { SpecialEventsActions } from './game/special-events/special-events.reducer';
import {
  ToastsActions,
  toastsReducer,
  ToastsState,
} from './game/toasts/toasts.reducer';
import {
  BalanceAlertsActions,
  balanceAlertsReducer,
  BalanceAlertsState,
} from './game/balance-alerts/balance-alerts.reducer';

export type GameActions =
  | ShipActions
  | StatusActions
  | SystemsActions
  | ScoresActions
  | CommonActions
  | TravelActions
  | QuestStatusActions
  | SettingsActions
  | BalanceActions
  | NotificationsActions
  | ConfirmationsActions
  | SpecialEventsActions
  | ToastsActions
  | BalanceAlertsActions;

export interface GameState {
  common: CommonState;
  systems: Entities<System>; // can we restore all system info from game seed?
  travels: Entities<Travel>; // necessary to save
  ship: IShip; // necessary to save
  status: IStatus; // necessary to save
  scores: IScores; // necessary to save
  questStatus: QuestStatus; // necessary to save
  balance: BalanceRecord[]; // necessary to save
  settings: Settings;
  notifications: NotificationsState;
  confirmations: ConfirmationsState;
  toasts: ToastsState;
  balanceAlerts: BalanceAlertsState;
}

export interface RootState {
  game: GameState;
}

const gameReducer = combineReducers<GameState>({
  common: commonReducer,
  ship: shipReducer,
  status: statusReducer,
  systems: systemsReducer,
  scores: scoresReducer,
  travels: travelsReducer,
  questStatus: questStatusReducer,
  settings: settingsReducer,
  balance: balanceReducer,
  notifications: notificationsReducer,
  confirmations: confirmationsReducer,
  toasts: toastsReducer,
  balanceAlerts: balanceAlertsReducer,
});

const rootReducer = combineReducers<RootState>({
  game: gameReducer,
});

export const rootEpic = combineEpics(
  ...commonEpics,
  ...travelEpics,
  ...shipEpics,
  ...statusEpics,
  ...questsEpics,
  ...encounterEpics,
  ...balanceEpics,
  ...scoreEpics,
  ...specialEventsEpics,
);

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(key: string) {
  const epicMiddleware = createEpicMiddleware();

  // do not persist these keys
  const blacklist = [
    'notifications',
    'confirmations',
    'toasts',
    'balanceAlerts',
  ];

  const store = createStore(
    persistReducer({ key, storage, blacklist }, rootReducer),
    composeEnhancers(applyMiddleware(epicMiddleware)),
  );

  epicMiddleware.run(rootEpic);
  return store;
}
