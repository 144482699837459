import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../..';
import { initGame } from '../../../app/init/game';
import { earnCredits } from '../balance/balance.actions';
import { initQuestStatues } from '../questStatus/quest-status.actions';
import { initScores } from '../scores/scores.actions';
import { initSettings } from '../settings/settings.actions';
import { initShip } from '../ship/ship.actions';
import { initStatus } from '../status/status.actions';
import { initSystems } from '../systems/systems.actions';
import { singularityJump } from '../travel/travel.actions';
import { endGame, gameStarted, startGame } from './common.actions';
import { selectPlayerName } from '../ship/ship.selectors';
import { selectCurrentDay, selectDifficulty } from '../status/status.selectors';
import { HighScoreRecord } from '../../../app/data/types/high-score-record';
import {
  loadHighScores,
  saveHighScores,
} from '../../../interface/main-menu/high-scores/high-scores.utils';
import { selectPlayerWorth } from '../../derived/computational-selectors';
import { DEFAULT_PLAYER_NAME } from '../../../app/data/labels/mercenaries';
import { START_AMOUNT_REASON } from '../../../app/ui/change-balance-reasons';

const startNewGameEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(startGame)),
    map(({ payload: { difficulty, playerName, playerSkills } }) =>
      initGame(difficulty, playerName, playerSkills),
    ),
    switchMap((game) =>
      of(
        initSettings(game.settings),
        initScores(game.scores),
        initSystems(game.systems),
        initShip(game.ship),
        initStatus(game.status),
        initQuestStatues(game.questStatus),
        earnCredits({ amount: 1000, reason: START_AMOUNT_REASON }),
        singularityJump(game.status.homeSystem),
        gameStarted(),
      ),
    ),
  );

const endGameEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(endGame)),
    withLatestFrom(state$),
    map(
      ([action, state]) =>
        [
          action.payload,
          selectPlayerName(state) || DEFAULT_PLAYER_NAME,
          selectCurrentDay(state),
          selectPlayerWorth(state),
          selectDifficulty(state),
        ] as const,
    ),
    tap(([status, name, days, worth, difficulty]) => {
      const highScores: HighScoreRecord[] = loadHighScores();
      if (highScores) {
        saveHighScores([
          ...highScores,
          { name, days, worth, difficulty, status },
        ]);
      }
      const currentId = localStorage.getItem('current');
      localStorage.removeItem(`persist:${currentId}`);
      localStorage.removeItem('current');

      // change game status so player can't continue playing
      // https://github.com/supasate/connected-react-router
      window.location.href = '/';
    }),
    ignoreElements(),
  );

export const commonEpics = [startNewGameEpic, endGameEpic];
