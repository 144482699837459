import { fromNullable, map, toNullable } from 'fp-ts/Option';
import { IShip } from '../../../data/types/ship/ship';
import { always, prop, pipe } from 'ramda';

export const getCommanderName = (ship: IShip) =>
  pipe(
    always(ship),
    prop('commander'),
    fromNullable,
    map(prop('name')),
    toNullable,
  )();
