import { StateObservable } from 'redux-observable';
import { Observable, pipe, UnaryFunction } from 'rxjs';
import { withLatestFrom, filter, map } from 'rxjs/operators';
import { RootState } from '..';
import { AnyAction } from 'redux';
import { EncounterActionType } from '../../app/data/interaction/action-type';

export const whenInState =
  (fn: (state: RootState) => boolean) => (state$: StateObservable<RootState>) =>
    pipe(
      withLatestFrom(state$),
      filter(([, state]) => fn(state)),
      map(([, action]) => action),
    );
