import { getRandom } from '../../utils';
import { IShip } from '../../data/types/ship/ship';
import { ShieldConfigs } from '../../data/types/ship/shield/shield-config';
import { ENGINEER_SKILL } from '../../data/types/crew-member/skill';
import { IShield } from '../../data/types/ship/shield/shield';
import { getHullToRepair } from '../ship/hull/hull-to-repair';
import { getSkillLevel } from '../crew-member/skill/skill-level';

export const getRepairPoints = (
  difficulty: number,
  ship: IShip,
): readonly [number, number] => {
  const repairPoints = Math.round(
    getRandom(0, getSkillLevel(ENGINEER_SKILL)(ship, difficulty)) / 2,
  );

  const requiredRepairForHull = getHullToRepair(ship);
  const repairForShields = Math.max(repairPoints - requiredRepairForHull, 0);
  const repairForHull = repairPoints - repairForShields;

  return [repairForHull, repairForShields] as const;
};

export const doShieldRepairs = (shieldConfigs: ShieldConfigs) => (
  shields: IShield[],
  repairForShields: number,
): IShield[] => {
  let remainRepairPoints = repairForShields * 2;
  return shields.map((shield) => {
    const resultStrength = Math.max(
      shield.strength + remainRepairPoints,
      shieldConfigs[shield.type].power,
    );
    remainRepairPoints -= shield.strength;
    return {
      ...shield,
      strength: resultStrength,
    };
  });
};
