import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonButton,
  IonLabel,
  IonNote,
} from '@ionic/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { mercenaryNames } from '../../../app/data/labels/mercenaries';
import { CrewMember } from '../../../app/data/types/crew-member/crew-member';
import { dismissCrewMember } from '../../../store/game/ship/ship.actions';
import { mercenaryHirePrice } from '../../../app/core/mercenaries/mercenary-hire-price';
import ordinal from 'ordinal';

type CabineCardProps = {
  index: number;
  person: CrewMember | null;
};

const CabineCard: React.FC<CabineCardProps> = ({ index, person }) => {
  const dispatch = useDispatch();

  function fire() {
    if (!person) {
      return;
    }
    dispatch(dismissCrewMember(person));
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>{ordinal(index + 1)} room</IonCardSubtitle>
        <IonCardTitle>
          {person ? `occupied by ${person.name}` : 'is vacant'}
        </IonCardTitle>
      </IonCardHeader>
      {person && (
        <IonCardContent>
          {person.equipment.map((equip) => (
            <IonItem key={equip}>{equip}</IonItem>
          ))}
          <IonItem>
            <IonLabel>Daily payment</IonLabel>
            <IonNote slot={'end'}>{mercenaryHirePrice(person)} cr.</IonNote>
          </IonItem>
          {mercenaryNames.includes(person.name) && (
            <IonButton onClick={fire}>Fire</IonButton>
          )}
        </IonCardContent>
      )}
    </IonCard>
  );
};

export default CabineCard;
