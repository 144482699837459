import { IShip } from '../../../app/data/types/ship/ship';
import { PurchasedItem } from '../../../app/data/types/trade-item/purchased-item';
import { SHIELDS } from '../../../app/config/equipment.configs';
import { damageShield } from '../../../app/core/encounter/fight/damage';

export const lostCargoReducer = (
  ship: IShip,
  lostItem: PurchasedItem,
): IShip => ({
  ...ship,
  cargo: ship.cargo.filter((item) => item !== lostItem),
});

export const plunderCargoReducer = (
  ship: IShip,
  item: PurchasedItem,
): IShip => ({
  ...ship,
  cargo: [...ship.cargo, { ...item, itemPrice: 0 }],
});

export const damageShipReducer = (ship: IShip, damage: number): IShip => ({
  ...ship,
  hull: ship.hull - damage,
});

export const damageShipShieldReducer = (
  ship: IShip,
  damage: number,
): IShip => ({
  ...ship,
  equipment: {
    ...ship.equipment,
    [SHIELDS]: damageShield(ship.equipment[SHIELDS], damage),
  },
});
