import { PLAYER, POLICE } from '../../../../data/interaction/actor-type';
import { getRandom, getTotalWeaponsPower } from '../../../../utils';
import {
  EncounterActionType,
  ATTACK,
  FLEE,
} from '../../../../data/interaction/action-type';
import { IShip } from '../../../../data/types/ship/ship';
import { isSeriousPoliceHullDamage } from '../../fight/damage';
import { DamageEvent } from '../../../../data/interaction/damage-event';

export const getPoliceAttackReaction = (
  damageLog: DamageEvent[],
  ship: IShip,
  playerShip: IShip,
): EncounterActionType => {
  if (getTotalWeaponsPower(ship) < 1) {
    return FLEE;
  }

  const opponentDamages = damageLog.filter((item) => item.actor === POLICE);
  const playerDamages = damageLog.filter((item) => item.actor === PLAYER);

  if (
    !(
      opponentDamages.length &&
      isSeriousPoliceHullDamage(
        ship,
        opponentDamages.slice(-1)[0].damage as number,
      )
    )
  ) {
    return ATTACK;
  }

  if (
    !(
      playerDamages.length &&
      isSeriousPoliceHullDamage(
        playerShip,
        playerDamages.slice(-1)[0].damage as number,
      )
    )
  ) {
    return ATTACK;
  }

  if (getRandom(0, 10) < 5) {
    return ATTACK;
  }

  return FLEE;
};
