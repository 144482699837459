import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemStatusNewsLine } from '../../../../app/core/news/system-status-newsline';
import {
  selectCurrentDay,
  selectIsAlreadyPaidForNews,
  selectNewspaperPrice,
} from '../../../../store/game/status/status.selectors';
import {
  selectIsEnoughMoney,
  selectReachableSystems,
} from '../../../../store/derived/computational-selectors';
import { addNotification } from '../../../../store/game/notifications/notifications.actions';
import { buyNewspaper } from '../../../../store/game/status/status.actions';
import { isEventfulStatus } from '../../../../app/core/solar-system/system-status';
import { eqByLength } from '../../../../app/utils';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

const NewsPaper: React.FC = () => {
  const dispatch = useDispatch();
  const systemsToTravel = useSelector(selectReachableSystems, eqByLength);
  const currentDay = useSelector(selectCurrentDay);
  const isAlreadyPaid = useTypedSelector(selectIsAlreadyPaidForNews);
  const newsPrice = useSelector(selectNewspaperPrice);
  const enoughMoney = useSelector(selectIsEnoughMoney(newsPrice));
  const [isOpen, setIsOpen] = useState(false);

  const openNews = useCallback(() => {
    if (!enoughMoney && !isAlreadyPaid) {
      dispatch(
        addNotification({
          title: 'Not enough money',
          message: `You dont have ${newsPrice} credits to pay for news`,
        }),
      );
      return;
    }
    if (!isAlreadyPaid) {
      dispatch(buyNewspaper());
    }
    setIsOpen(true);
  }, [dispatch, isAlreadyPaid, enoughMoney, newsPrice]);

  return (
    <>
      <IonButton onClick={openNews}>
        Read news {!isAlreadyPaid && `(${newsPrice} cr.)`}
      </IonButton>
      {isOpen && (
        <IonModal isOpen backdropDismiss={true}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>News</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardContent>
                {systemsToTravel
                  .filter(isEventfulStatus(currentDay))
                  .map((system) => (
                    <IonItem key={system.nameIndex}>
                      <IonLabel>
                        {getSystemStatusNewsLine(currentDay, system)}
                      </IonLabel>
                    </IonItem>
                  ))}
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};

export default NewsPaper;
