import {
  REFLECTIVE_SHIELD,
  LIGHTNING_SHIELD,
  ENERGY_SHIELD,
} from '../../data/types/ship/shield/shield-type';
import { ShieldConfigs } from '../../data/types/ship/shield/shield-config';

export const shieldConfigs: ShieldConfigs = {
  [ENERGY_SHIELD]: {
    power: 100,
    price: 5000,
    techLevel: 5,
    chance: 70,
    toSell: true,
  },
  [REFLECTIVE_SHIELD]: {
    power: 200,
    price: 20000,
    techLevel: 6,
    chance: 30,
    toSell: true,
  },
  [LIGHTNING_SHIELD]: {
    power: 350,
    price: 45000,
    techLevel: 8,
    chance: 0,
    toSell: false,
  },
};
