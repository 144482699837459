import { RefObject, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  selectCurrentSystem,
  selectCurrentSystemCoordinates,
} from '../../../../store/game/systems/selectors/current-system.selector';
import { VISUAL_MULTIPLIER } from './visual-mulitplier';

export const useScroll = (scrollRef: RefObject<HTMLIonContentElement>) => {
  const currentSystemCoordinates = useSelector(
    selectCurrentSystemCoordinates,
    shallowEqual,
  );
  return useCallback(() => {
    if (!scrollRef.current) {
      return;
    }
    const xOffset = scrollRef.current.clientWidth / 2;
    const yOffset = scrollRef.current.clientHeight / 2;

    scrollRef.current.scrollToPoint(
      currentSystemCoordinates.X * VISUAL_MULTIPLIER - xOffset,
      currentSystemCoordinates.Y * VISUAL_MULTIPLIER - yOffset,
      1000,
    );
  }, [scrollRef, currentSystemCoordinates]);
};
