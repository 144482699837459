import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonText,
} from '@ionic/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import { IShip } from '../../../../app/data/types/ship/ship';
import { getCommanderName } from '../../../../app/core/ship/crew/commander';
import { isDebug } from '../../../../is-debug-mode';
import {
  selectOpponentDamageLog,
  selectPlayerDamageLog,
} from '../../../../store/game/encounter/selectors/damage-log.selectors';
import { DamageAnimation } from './DamageAnimation';
import { CargoModal } from '../../Cargo/CargoModal';
import { PlayerCargoContainer } from '../../Cargo/PlayerCargoContainer';
import { EncounterActionType } from '../../../../app/data/interaction/action-type';
import { GraphicShipCard } from './GraphicShipCard';
import ShipCard from '../../Ship/ShipCard';

type ActorCardProps = {
  active: boolean;
  actor: ActorType;
  ship: IShip;
  action?: EncounterActionType;
};

const OpponentCard: React.FC<ActorCardProps> = ({
  active,
  actor,
  ship,
  action,
}) => {
  const isPlayer = actor === PLAYER;
  const [isShipInfoOpen, setIsShipInfoOpen] = useState(false);
  const commanderName = getCommanderName(ship);

  return (
    <IonCard
      className={classNames('ion-no-margin', 'opponent-card', { active })}>
      <IonCardHeader>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol style={{ position: 'relative' }}>
              <GraphicShipCard ship={ship}>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}>
                  <IonText className="code ion-text-uppercase">
                    {action}
                  </IonText>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    right: '10px',
                    bottom: '30px',
                  }}>
                  <DamageAnimation
                    logSelector={
                      actor === PLAYER
                        ? selectPlayerDamageLog
                        : selectOpponentDamageLog
                    }
                  />
                </div>
              </GraphicShipCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCardSubtitle>
                {active ? 'Current move' : <span>&nbsp;</span>}
              </IonCardSubtitle>
              <IonCardTitle className="ion-no-margin">
                {isPlayer ? commanderName : actor}
              </IonCardTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {isPlayer && (
                <CargoModal title="My cargo">
                  <PlayerCargoContainer />
                </CargoModal>
              )}

              {isDebug() && (
                <>
                  <IonButton onClick={() => setIsShipInfoOpen(true)}>
                    Ship info
                  </IonButton>
                  {isShipInfoOpen && (
                    <IonModal
                      isOpen={true}
                      onDidDismiss={() => setIsShipInfoOpen(false)}>
                      <IonHeader>
                        <IonButtons slot="end">
                          <IonButton onClick={() => setIsShipInfoOpen(false)}>
                            Close
                          </IonButton>
                        </IonButtons>
                      </IonHeader>
                      <IonContent>
                        <ShipCard ship={ship} />
                      </IonContent>
                    </IonModal>
                  )}
                </>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
    </IonCard>
  );
};

export default OpponentCard;
