import { CrewMember } from '../../../data/types/crew-member/crew-member';
import { SkillType, skillTypes } from '../../../data/types/crew-member/skill';
import { MAXSKILL } from '../../../data/constants';
import { shuffle } from '../../../utils/shuffle-array';
import { none, Option, some } from 'fp-ts/Option';

export const getRandomSkillToIncrease = (
  commander: CrewMember,
): Option<SkillType> => {
  const skillsToIncrease = skillTypes.filter(
    (skillType) => commander.skills[skillType] < MAXSKILL,
  );

  if (!skillsToIncrease.length) {
    return none;
  }

  return some(shuffle(skillsToIncrease)[0]);
};
