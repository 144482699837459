import { Settings } from '../../../data/types/game/settings';
import { QuestStatus } from '../../../data/types/game/quest-status';
import { IShip } from '../../../data/types/ship/ship';
import { PlayerTotalCargoBays } from './total-cargo-bays';
import { FilledCargoBays } from './filled-cargo-bays';

export const getAvailableCargoBays = (
  settings: Settings,
  questStatus: QuestStatus,
  ship: IShip,
) =>
  PlayerTotalCargoBays(questStatus, ship) -
  FilledCargoBays(ship.cargo) -
  settings.leaveEmpty;
