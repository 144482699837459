import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../..';
import { mapState } from '../../operators/map-state';
import { addNotification } from '../notifications/notifications.actions';
import { getLoan, payLoanBack } from '../status/status.actions';
import {
  earnCredits,
  registerBalanceChange,
  spendCredits,
} from './balance.actions';
import { selectCurrentBalance } from './balance.selectors';
import { PAY_LOAN_BACK_REASON } from '../../../app/ui/change-balance-reasons';
import { BalanceActions } from './balance.reducer';
import { selectCurrentDay } from '../status/status.selectors';
import { EARN_CREDITS } from './balance.actions-types';
import { addBalanceAlert } from '../balance-alerts/balance-alerts.actions';
import { formatMoney } from '../../../app/ui/format-money';

const registerBalanceChangeEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf([spendCredits, earnCredits])),
    withLatestFrom(state$.pipe(map(selectCurrentDay))),
    map(([action, day]) =>
      registerBalanceChange({
        day,
        amount: action.payload.amount,
        reason: action.payload.reason,
        type: action.type === EARN_CREDITS ? 'income' : 'outcome',
      }),
    ),
  );

const addBalanceAlertOnSpendCreditsEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(spendCredits)),
    withLatestFrom(state$.pipe(map(selectCurrentBalance))),
    map(([action, currentBalance]) =>
      addBalanceAlert({
        header: `You gave ${formatMoney(action.payload.amount)} credits to ${
          action.payload.reason
        }`,
        message: `Current balance: ${formatMoney(
          currentBalance - action.payload.amount,
        )} cr.`,
        color: 'danger',
      }),
    ),
  );

const addBalanceAlertOnEarnCreditsEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(earnCredits)),
    withLatestFrom(state$.pipe(map(selectCurrentBalance))),
    map(([action, currentBalance]) =>
      addBalanceAlert({
        header: `You've got ${formatMoney(action.payload.amount)} credits for ${
          action.payload.reason
        }`,
        message: `Current balance: ${formatMoney(
          currentBalance + action.payload.amount,
        )} cr.`,
        color: 'success',
      }),
    ),
  );

const getLoanOnSpendCredits: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(spendCredits)),
    mapState(selectCurrentBalance)(state$),
    filter((balance) => balance < 0),
    map((balance) => 0 - balance),
    switchMap((loanAmount) =>
      of(
        getLoan(loanAmount),
        addNotification({
          title: 'You owe us',
          message: `
            Because you didn't have enough money to pay, you had to take a loan from bank.
            You take ${loanAmount} credits of loan.
          `,
        }),
      ),
    ),
  );

const spendCreditsOnPayLoanBack: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(payLoanBack)),
    map((action) => action.payload),
    map((amount) => spendCredits({ amount, reason: PAY_LOAN_BACK_REASON })),
  );

export const balanceEpics = [
  getLoanOnSpendCredits,
  spendCreditsOnPayLoanBack,
  addBalanceAlertOnEarnCreditsEpic,
  addBalanceAlertOnSpendCreditsEpic,
  registerBalanceChangeEpic,
];
