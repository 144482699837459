import * as actionTypes from './balance-alerts.action-types';
import { createAction } from 'typesafe-actions';
import { BalanceAlert } from './balance-alert';

export const addBalanceAlert = createAction(actionTypes.ADD_BALANCE_ALERT)<
  Omit<BalanceAlert, 'id'>
>();
export const disposeBalanceAlert = createAction(
  actionTypes.DISPOSE_BALANCE_ALERT,
)<string>();
