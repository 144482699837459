import React from 'react';
import { System } from '../../../../../app/data/types/system/system';
import { tradeItemTypes } from '../../../../../app/data/types/trade-item/trade-item-type';
import TradeItemProfitChart from './TradeItemProfitChart';
import { IonCard, IonCardContent } from '@ionic/react';

const ProfitTab: React.FC<{ system: System }> = ({ system }) => {
  return (
    <IonCard>
      <IonCardContent>
        {tradeItemTypes.map((tradeItemType) => (
          <TradeItemProfitChart
            key={tradeItemType}
            system={system}
            tradeItemType={tradeItemType}
          />
        ))}
      </IonCardContent>
    </IonCard>
  );
};

export default ProfitTab;
