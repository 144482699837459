import { shipConfigs } from '../../config/ship/ship.configs';
import { ShipType } from '../../data/types/ship/ship-type';
import { System } from '../../data/types/system/system';
import { isShipProducible } from './is-producible';

export const sellableShipTypes = (Object.keys(
  shipConfigs,
) as ShipType[]).filter((shipType) => shipConfigs[shipType].toSell);

export const getAvailableShips = (system: System) =>
  sellableShipTypes.filter(isShipProducible(system));
