import { converge, equals, map, prop } from 'ramda';
import { pipe, UnaryFunction } from 'rxjs';
import { unique } from '../../../app/utils/unique-array';
import { mapEntitiesToArray } from '../../entities';
import { arrive, encounter, move } from './travel.actions';
import { selectTravelsState } from './selectors/travels-state.selector';
import { selectClicks } from './selectors/clicks.selector';
import { selectNextEncounterType } from '../encounter/selectors/next-encounter-type.selector';
import { ActorType } from '../../../app/data/interaction/actor-type';
import { AnyAction } from 'redux';
import { RootState } from '../../index';

export const selectTravels = pipe(selectTravelsState, mapEntitiesToArray);

export const selectAmountOfVisits = (systemNameIndex: number) =>
  pipe(
    selectTravels,
    (travels) =>
      travels.map(prop('systemIndex')).filter(equals(systemNameIndex)).length,
  );

export const selectIsVisited = (systemNameIndex: number) =>
  pipe(selectAmountOfVisits(systemNameIndex), (visits) => visits > 0);

export const selectAmountOfVisitedSystems = pipe(
  selectTravels,
  map(prop('systemIndex')),
  (systems) => systems.filter(unique).length,
);

export const selectNextTravelAction = converge<
  AnyAction,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, ActorType | null>]
>(
  (clicks, nextEncounter) => {
    if (clicks < 1) {
      return arrive();
    }
    return !nextEncounter ? move() : encounter(nextEncounter);
  },
  [selectClicks, selectNextEncounterType],
);
