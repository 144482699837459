import React from 'react';
import { useSelector } from 'react-redux';
import {
  IonCardContent,
  IonCard,
  IonGrid,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from '@ionic/react';
import TravelSystemCard from './TravelSystemCard';
import { selectAvailableSystemsToTravel } from '../../../../store/derived/computational-selectors';
import { eqByLength } from '../../../../app/utils';

const SystemsToTravel: React.FC = () => {
  const systemsToTravel = useSelector(
    selectAvailableSystemsToTravel,
    eqByLength,
  );

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>You can travel to following systems:</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {systemsToTravel.length > 0 && (
          <IonGrid>
            {systemsToTravel.map((system) => (
              <TravelSystemCard key={system.nameIndex} system={system} />
            ))}
          </IonGrid>
        )}
        {!systemsToTravel.length && (
          <IonText>
            <h2>
              There are no systems in available distance to travel. Most likely
              your fuel level is too low.
            </h2>
          </IonText>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default SystemsToTravel;
