import { equals, pipe, prop } from 'ramda';
import { selectTravels } from '../../travel/travel.selectors';
import { mapEntitiesToArray } from '../../../entities';
import { DEAD } from '../../../../app/data/interaction/action-type';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import memoize from 'fast-memoize';

export const selectKilledOpponents = memoize(
  pipe(
    selectTravels,
    (travels) =>
      travels.flatMap((travel) => mapEntitiesToArray(travel.encounters)),
    (encounters) =>
      encounters.filter((encounter) =>
        mapEntitiesToArray(encounter.log).some(
          (event) => event.actor !== PLAYER && event.action === DEAD,
        ),
      ),
  ),
);

export const selectKilled = (type: ActorType) =>
  memoize(
    pipe(selectKilledOpponents, (encounters) =>
      encounters.filter(pipe(prop('type'), equals(type))),
    ),
  );
