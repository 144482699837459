import { IonCheckbox, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActorType } from '../../../../../app/data/interaction/actor-type';
import { selectIsIgnoring } from '../../../../../store/game/settings/settings.selectors';
import { toggleAutoIgnore } from '../../../../../store/game/settings/settings.actions';

export const AutoIgnoreToggle: React.FC<{ actorType: ActorType }> = ({
  actorType,
}) => {
  const dispatch = useDispatch();
  const checked = useSelector(selectIsIgnoring(actorType));

  return (
    <IonItem>
      <IonCheckbox
        checked={checked}
        onIonChange={() => dispatch(toggleAutoIgnore(actorType))}
      >
        Ignore {actorType}
      </IonCheckbox>
    </IonItem>
  );
};
