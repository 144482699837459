import { getRandom } from '../../../utils';
import { IShield } from '../../../data/types/ship/shield/shield';
import { HARD } from '../../../data/constants/difficulty';
import { ShipType } from '../../../data/types/ship/ship-type';
import { shields } from '../../../data/types/ship/shield/shield-type';
import { SHIELDS } from '../../../config/equipment.configs';
import { makeShield } from '../../../factories/ship-equipment';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { shieldConfigs } from '../../../config/shield/shield.configs';
import { getEquipmentType } from './get-equipment';

export const getOpponentShields = (
  opponentType: ShipType,
  tries: number,
  difficulty: number,
): IShield[] => {
  const shieldSlots = shipConfigs[opponentType].equipmentSlots[SHIELDS];
  if (shieldSlots < 1) {
    return [];
  }

  let shieldsToFill = shieldSlots;
  if (difficulty <= HARD) {
    shieldsToFill = getRandom(0, shieldSlots);
    if (shieldsToFill < shieldSlots) {
      if (tries > 3) {
        shieldsToFill += 1;
      } else if (tries > 1) {
        shieldsToFill += getRandom(0, shieldSlots - shieldsToFill);
      }
    }
  }

  return Array(shieldsToFill)
    .fill(0)
    .map(() => {
      const shieldType = getEquipmentType(shieldConfigs, shields, tries);
      return makeShield(
        shieldType,
        Math.max(
          ...Array(5)
            .fill(0)
            .map(() => getRandom(1, shieldConfigs[shieldType].power)),
        ),
      );
    });
};
