import { IonRow, IonCol, IonButton, IonLabel } from '@ionic/react';
import React from 'react';
import { Installable } from '../../../../app/data/types/ship/equipment/installable';
import useBuyEquipment from '../../../../hooks/useBuyEquipment';
import { EquipmentItemType } from '../../../../app/data/types/ship/equipment/equipment';
import { useDispatch } from 'react-redux';
import useBuyEquipmentAction from './useBuyEquipmentAction';

type EquipmentItemProps = {
  configs: Record<string, Installable>;
  equipmentItemType: EquipmentItemType;
};

const EquipmentItem: React.FC<EquipmentItemProps> = ({
  configs,
  equipmentItemType,
}) => {
  const dispatch = useDispatch();
  const [price, isDisabled, getPurchaseLabel] = useBuyEquipment(
    configs,
    equipmentItemType,
  );
  const buyAction = useBuyEquipmentAction(equipmentItemType);

  function handleBuy() {
    if (buyAction) {
      dispatch(buyAction);
    }
  }

  return (
    <IonRow>
      <IonCol>{equipmentItemType}</IonCol>
      <IonCol>{price}</IonCol>
      <IonCol>
        {isDisabled && <IonLabel>{getPurchaseLabel()}</IonLabel>}
        {!isDisabled && <IonButton onClick={handleBuy}>Buy</IonButton>}
      </IonCol>
    </IonRow>
  );
};

export default EquipmentItem;
