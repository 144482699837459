import { v4 as uuidv4 } from 'uuid';
import { IWeapon } from '../data/types/ship/weapon/weapon';
import { WeaponType } from '../data/types/ship/weapon/weapon-type';
import { GadgetType } from '../data/types/ship/gadget/gadget-type';
import { IGadget } from '../data/types/ship/gadget/gadget';
import { ShieldType } from '../data/types/ship/shield/shield-type';
import { IShield } from '../data/types/ship/shield/shield';

export function makeWeapon(type: WeaponType): IWeapon {
  return {
    id: uuidv4(),
    type,
  };
}

export function makeGadget(type: GadgetType): IGadget {
  return {
    id: uuidv4(),
    type,
  };
}

export function makeShield(type: ShieldType, strength: number): IShield {
  return {
    id: uuidv4(),
    type,
    strength,
  };
}
