import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCard,
  IonButton,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { SpecialEventType } from '../../../../app/data/types/system/special-event/special-event-type';
import { useSpecialEventVisibility } from '../../../../hooks/useSpecialEventVisibility';
import { makeSpecialEventAlert } from '../../../../store/game/special-events/special-event-alert';

const SpecialEventCard: React.FC<{ specialEvent: SpecialEventType }> = ({
  specialEvent,
}) => {
  const dispatch = useDispatch();
  const isVisible = useSpecialEventVisibility(specialEvent);
  const specialEventAction = makeSpecialEventAlert(specialEvent);

  if (!isVisible) {
    return <></>;
  }

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>There is a special secret event for you:</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonButton expand="block" onClick={() => dispatch(specialEventAction)}>
          Open
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default SpecialEventCard;
