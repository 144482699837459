import memoize from 'fast-memoize';
import { add } from 'ramda';
import { BalanceRecord } from '../../../../app/data/types/balance';

export const getYesterday =
  (day: number) =>
  (groupedBalance: Partial<Record<string, BalanceRecord[]>>): number => {
    const days = Object.keys(groupedBalance).map(Number);
    return days[days.indexOf(day) - 1];
  };

export const getBalanceByDay =
  (day: number) =>
  (groupedBalance: Partial<Record<string, BalanceRecord[]>>): number => {
    if (day === 0) {
      return reduceBalance(groupedBalance[0] || []);
    }
    return (
      reduceBalance(groupedBalance[day] || []) +
      getBalanceByDay(getYesterday(day)(groupedBalance))(groupedBalance)
    );
  };

export const reduceBalance = memoize((balance: BalanceRecord[]): number =>
  balance
    .map((balanceRecord) =>
      balanceRecord.type === 'outcome'
        ? -balanceRecord.amount
        : balanceRecord.amount,
    )
    .reduce(add, 0),
);

export const getBalanceDistribution = (balance: BalanceRecord[]): number[] =>
  balance.reduce<number[]>(
    (sums, balanceRecord, index) => [
      ...sums,
      reduceBalance([...balance].slice(0, index + 1)),
    ],
    [],
  );

export const findHighestBalance = (balance: BalanceRecord[]) =>
  Math.max(...getBalanceDistribution(balance));
