import {
  ActorType,
  MANTIS,
  PIRATE,
  POLICE,
} from '../../../data/interaction/actor-type';
import { IShip } from '../../../data/types/ship/ship';
import { NORMAL } from '../../../data/constants/difficulty';
import { getPoliceWantedLevel } from './get-police-wanted-level';

export const getTries = (
  policeRecordScore: number,
  difficulty: number,
  playerCurrentWorth: number,
) => (opponentType: ActorType, playerShip: IShip): number => {
  if (opponentType === MANTIS) {
    return 1 + difficulty;
  }

  if (opponentType === POLICE) {
    return Math.max(
      1,
      getPoliceWantedLevel(policeRecordScore, playerShip) + difficulty - NORMAL,
    );
  }

  // Pirates become better when you get richer
  if (opponentType === PIRATE) {
    const richnessLevel = 1 + playerCurrentWorth / 100000;
    return Math.max(1, richnessLevel + difficulty - NORMAL);
  }

  return 1;
};
