import {
  IonCardContent,
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelPurchase } from '../../../store/game/ship/ship.actions';
import { PlayerCargoContainer } from './PlayerCargoContainer';
import { selectPlayerCargo } from '../../../store/game/ship/ship.selectors';
import { eqByLength } from '../../../app/utils';

export const Cargo: React.FC = () => {
  const cargo = useSelector(selectPlayerCargo, eqByLength);
  const dispatch = useDispatch();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Cargo</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            <PlayerCargoContainer />
            {cargo.length > 0 && (
              <IonButton onClick={() => dispatch(cancelPurchase())}>
                Cancel purchase
              </IonButton>
            )}
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
