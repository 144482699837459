import React from 'react';
import { IonItem, IonLabel, IonNote } from '@ionic/react';
import { SkillType } from '../../../../app/data/types/crew-member/skill';
import { CrewMember } from '../../../../app/data/types/crew-member/crew-member';
import { selectSkillLevel } from '../../../../store/game/ship/ship.selectors';
import { useSelector } from 'react-redux';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

export const SkillProfit: React.FC<{
  skillType: SkillType;
  person: CrewMember;
}> = ({ skillType, person }) => {
  const playerSkill = useTypedSelector(selectSkillLevel(skillType));
  const personSkill = person.skills[skillType];
  const diff = personSkill - playerSkill;
  const color = diff < 0 ? 'danger' : 'success';
  const sign = diff > 0 ? '+' : '';

  return (
    <IonItem>
      <IonLabel>{skillType}</IonLabel>
      <IonNote slot="end" color={color}>
        {sign}
        {diff}
      </IonNote>
    </IonItem>
  );
};
