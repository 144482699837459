import React from 'react';
import { PlayerCard } from './PlayerCard';
import { shallowEqual, useSelector } from 'react-redux';
import {
  selectPlayerName,
  selectPlayerShip,
} from '../../store/game/ship/ship.selectors';
import {
  selectCurrentDay,
  selectDifficulty,
} from '../../store/game/status/status.selectors';
import { selectCurrentBalance } from '../../store/game/balance/balance.selectors';
import {
  selectPoliceRecordScore,
  selectReputationScore,
} from '../../store/game/scores/scores.selectors';
import { getShipImage } from '../../app/ui/ship.img';
import { pipe } from 'rxjs';

export const PlayerCardContainer: React.FC = () => {
  const playerName = useSelector(selectPlayerName);
  const day = useSelector(selectCurrentDay);
  const credits = useSelector(selectCurrentBalance);
  const difficulty = useSelector(selectDifficulty);
  const reputationScore = useSelector(selectReputationScore);
  const policeRecordScore = useSelector(selectPoliceRecordScore);
  const ship = useSelector(selectPlayerShip, shallowEqual);
  return (
    <PlayerCard
      playerName={playerName || ''}
      day={day}
      credits={credits}
      difficulty={difficulty}
      reputationScore={reputationScore}
      policeScore={policeRecordScore}
      ship={ship}
    />
  );
};
