import { getCrewMemberOnBoard } from '../../../../store/game/ship/ship.actions';
import { CrewMember } from '../../../../app/data/types/crew-member/crew-member';
import { useSelector } from 'react-redux';
import { selectPlayerHasEmptyCrewCabines } from '../../../../store/game/ship/ship.selectors';
import { addNotification } from '../../../../store/game/notifications/notifications.actions';
import { GameActions } from '../../../../store';

export const useMercenaryAlert = (person: CrewMember): GameActions => {
  const hasEmptyCabines = useSelector(selectPlayerHasEmptyCrewCabines);

  if (!hasEmptyCabines) {
    return addNotification({
      title: 'You can not hire mercenary',
      message: 'You do not have empty crew cabines on your ship',
    });
  }

  return getCrewMemberOnBoard(person);
};
