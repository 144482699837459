import { WeaponConfigs } from '../data/types/ship/weapon/weapon-config';
import { ShieldConfigs } from '../data/types/ship/shield/shield-config';
import { GadgetConfigs } from '../data/types/ship/gadget/gadget-config';
import { weaponConfigs } from './weapon/weapon.configs';
import { shieldConfigs } from './shield/shield.configs';
import { gadgetConfigs } from './gadget/gadget.configs';

export const WEAPONS = 'Weapons';
export const SHIELDS = 'Shields';
export const GADGETS = 'Gadgets';

export const equipmentTypes = [WEAPONS, SHIELDS, GADGETS] as const;

export type EquipmentType = typeof equipmentTypes[number];

export type EquipmentConfigs = {
  [WEAPONS]: WeaponConfigs;
  [GADGETS]: GadgetConfigs;
  [SHIELDS]: ShieldConfigs;
};

export const equipmentConfigs: EquipmentConfigs = {
  [WEAPONS]: weaponConfigs,
  [SHIELDS]: shieldConfigs,
  [GADGETS]: gadgetConfigs,
};
