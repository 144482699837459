export const MONSTER_QUEST = 'monster';
export const DRAGONFLY_QUEST = 'dragonfly';
export const SCARAB_QUEST = 'scarab';
export const JAPORI_DESEASE_QUEST = 'japoriDisease';
export const WILD_QUEST = 'wild';
export const REACTOR_QUEST = 'reactor';
export const JAREK_QUEST = 'jarek';
export const INVASION_QUEST = 'invasion';
export const EXPERIMENT_QUEST = 'experiment';
export const ALIEN_ARTIFACT_QUEST = 'artifact';

export const questTypes = [
  MONSTER_QUEST,
  DRAGONFLY_QUEST,
  SCARAB_QUEST,
  JAPORI_DESEASE_QUEST,
  WILD_QUEST,
  REACTOR_QUEST,
  JAREK_QUEST,
  INVASION_QUEST,
  EXPERIMENT_QUEST,
  ALIEN_ARTIFACT_QUEST,
] as const;

export type QuestType = typeof questTypes[number];

export type QuestStatus = Record<QuestType, number>;
