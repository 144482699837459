import { selectPlayerEmptyCargoBaysAmount } from '../../ship/ship.selectors';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { CARGO_FOR_SALE } from '../../../../app/data/types/system/special-event/special-event-type';
import { converge } from 'ramda';
import {
  CARGO_FOR_SALE_AMOUNT,
  CARGO_FOR_SALE_PRICE,
} from '../../../../app/data/constants/special-events';
import { invokeSpecialEvent } from '../special-events.actions';
import { selectAvailableCredits } from '../../../derived/computational-selectors';
import { UnaryFunction } from 'rxjs';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectCargoForSaleAction = converge<
  AnyAction,
  [UnaryFunction<RootState, number>, UnaryFunction<RootState, number>]
>(
  (emptyCargoBays, credits) => {
    const { title } = getSpecialEventConfig(CARGO_FOR_SALE);
    if (credits < CARGO_FOR_SALE_PRICE) {
      return addNotification({
        title,
        message: `You need ${CARGO_FOR_SALE_PRICE} credits to get it`,
      });
    }
    if (emptyCargoBays < CARGO_FOR_SALE_AMOUNT) {
      return addNotification({
        title,
        message: `Your ship needs ${CARGO_FOR_SALE_AMOUNT} empty cargo bays`,
      });
    }
    return invokeSpecialEvent(CARGO_FOR_SALE);
  },
  [selectPlayerEmptyCargoBaysAmount, selectAvailableCredits],
);
