import { StateObservable } from 'redux-observable';
import { pipe } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { RootState } from '..';

export const mapState = <T>(fn: (state: RootState) => T) => (
  state$: StateObservable<RootState>,
) =>
  pipe(
    withLatestFrom(state$),
    map(([, state]) => fn(state)),
  );
