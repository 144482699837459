import { converge } from 'ramda';
import { getNextEncounterType } from '../../../../app/core/encounter/next-encounter-type';
import { selectQuestStatusState } from '../../questStatus/quest-status.selectors';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { selectDifficulty, selectStatus } from '../../status/status.selectors';
import { selectIsRaided } from '../../travel/selectors/is-raided.selector';
import { selectPlayerShip } from '../../ship/ship.selectors';
import { selectCurrentSystem } from '../../systems/selectors/current-system.selector';
import { selectClicks } from '../../travel/selectors/clicks.selector';
import { selectIsArrivedViaWormhole } from '../../travel/selectors/is-arrived-via-wormhole.selector';

export const selectNextEncounterType = converge(getNextEncounterType, [
  selectQuestStatusState,
  selectPoliceRecordScore,
  selectStatus,
  selectDifficulty,
  selectIsRaided,
  selectPlayerShip,
  selectCurrentSystem,
  selectClicks,
  selectIsArrivedViaWormhole,
]);
