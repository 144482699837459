import { useSelector } from 'react-redux';
import {
  selectOpponentShip,
  selectOpponentShipCargo,
} from '../../../store/game/encounter/encounter.selectors';
import { ShipTotalCargoBays } from '../../../app/core/ship/cargo/total-cargo-bays';
import { GADGETS } from '../../../app/config/equipment.configs';
import { FilledCargoBays } from '../../../app/core/ship/cargo/filled-cargo-bays';
import React from 'react';
import { Plunder } from './Plunder';
import { CargoContent } from './CargoContent';
import { eqByLength } from '../../../app/utils';

export const OpponentCargoContainer: React.FC = () => {
  const opponentShip = useSelector(selectOpponentShip);
  const opponentCargo = useSelector(selectOpponentShipCargo, eqByLength);
  const total = ShipTotalCargoBays(
    opponentShip.type,
    opponentShip.equipment[GADGETS],
  );
  const filled = FilledCargoBays(opponentCargo);

  return (
    <CargoContent total={total} filled={filled}>
      <Plunder cargo={opponentCargo} />
    </CargoContent>
  );
};
