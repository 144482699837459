import { isPoliceCorrupted } from '../../../../app/core/encounter/values/bribe';
import { getSystemName } from '../../../../app/ui/solar-system.labels';
import { addConfirmation } from '../../confirmations/confirmations.actions';
import { selectPoliceBribeOffer } from '../opponents/police/police.selectors';
import { addNotification } from '../../notifications/notifications.actions';
import { selectIsAnyIllegalsOnBoard } from '../../ship/ship.selectors';
import { BRIBE } from '../../../../app/data/interaction/action-type';
import { PLAYER } from '../../../../app/data/interaction/actor-type';
import { converge } from 'ramda';
import { selectCurrentSystem } from '../../systems/selectors/current-system.selector';
import { makeActionEvent } from '../make-action-event';
import { System } from '../../../../app/data/types/system/system';

export const selectBribeAction = converge(
  (warpSystem: System, isAnyIllegalsOnBoard: boolean, bribeOffer: number) => {
    if (!isPoliceCorrupted(warpSystem)) {
      return addNotification({
        title: 'You can not bribe this police',
        message: `It appears that ${getSystemName(
          warpSystem,
        )} police is not corrupted and did not accept your bribe offer.`,
      });
    }

    if (!isAnyIllegalsOnBoard) {
      return addNotification({
        title: 'Why to bribe?',
        message:
          'You do not have any illegal stuff on board, so you can safely submit the inspection',
      });
    }

    return addConfirmation({
      title: 'Lets have a deal?',
      message: `Police is ready to forget about you for ${bribeOffer} credits`,
      action: makeActionEvent(PLAYER)(BRIBE),
    });
  },
  [selectCurrentSystem, selectIsAnyIllegalsOnBoard, selectPoliceBribeOffer],
);
