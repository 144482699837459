import {
  ATTACK,
  BRIBE,
  EncounterActionType,
  FLEE,
  IGNORE,
  LET_GO,
  SUBMIT,
  SURRENDER,
} from '../../../../app/data/interaction/action-type';
import { converge } from 'ramda';
import { getPoliceSurrenderReaction } from '../../../../app/core/encounter/reactions/police/surrender.reaction';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { getPoliceAttackReaction } from '../../../../app/core/encounter/reactions/police/attack.reaction';
import { pipe } from 'rxjs';
import { selectDamageLog } from '../selectors/damage-log.selectors';
import { mapEntitiesToArray } from '../../../entities';
import { selectOpponentShip } from '../encounter.selectors';
import { selectPlayerShip } from '../../ship/ship.selectors';
import { getPoliceSubmitReaction } from '../../../../app/core/encounter/reactions/police/submit.reaction';
import {
  ActorType,
  PIRATE,
  POLICE,
  TRADER,
} from '../../../../app/data/interaction/actor-type';
import {
  selectPirateAttackReaction,
  selectPirateSurrenderReaction,
} from './pirate/pirate.selectors';
import { selectTraderAttackReaction } from './trader/trader.selectors';
import { RootState } from '../../../index';

export const selectPoliceReaction = (
  action: EncounterActionType,
): ((state: RootState) => EncounterActionType) => {
  switch (action) {
    case SURRENDER:
      return converge(getPoliceSurrenderReaction, [selectPoliceRecordScore]);
    case ATTACK:
      return converge(getPoliceAttackReaction, [
        pipe(selectDamageLog, mapEntitiesToArray),
        selectOpponentShip,
        selectPlayerShip,
      ]);
    case SUBMIT:
      return converge(getPoliceSubmitReaction, [selectPlayerShip]);
    case BRIBE:
      return () => LET_GO;
    case FLEE:
      return () => ATTACK;
    default:
      return () => IGNORE;
  }
};

export const selectPirateReaction = (
  action: EncounterActionType,
): ((state: RootState) => EncounterActionType) => {
  switch (action) {
    case ATTACK:
      return selectPirateAttackReaction;
    case SURRENDER:
      return selectPirateSurrenderReaction;
    case FLEE:
      return () => ATTACK;
    default:
      return () => IGNORE;
  }
};

export const selectTraderReaction = (
  action: EncounterActionType,
): ((state: RootState) => EncounterActionType) => {
  switch (action) {
    case ATTACK:
      return selectTraderAttackReaction;
    case FLEE:
      return () => ATTACK;
    default:
      return () => IGNORE;
  }
};

export const selectOpponentReaction = (
  opponent: ActorType,
  playerAction: EncounterActionType,
): ((state: RootState) => EncounterActionType) => {
  switch (opponent) {
    case POLICE:
      return selectPoliceReaction(playerAction);
    case PIRATE:
      return selectPirateReaction(playerAction);
    case TRADER:
      return selectTraderAttackReaction;
    default:
      return () => IGNORE;
  }
};
