import {
  getPriceDifference,
  isTradeItemInConsumption,
} from '../core/trade-items/trade-items.utils';
import { System } from '../data/types/system/system';
import { TradeItemType } from '../data/types/trade-item/trade-item-type';

export const getPriceDifferenceLabel = (
  difficulty: number,
  currentDay: number,
  currentSystem: System,
  anotherSystem: System,
  tradeItemType: TradeItemType,
): string => {
  if (!isTradeItemInConsumption(tradeItemType, anotherSystem)) {
    return '---';
  }

  const difference = Math.round(
    getPriceDifference(
      difficulty,
      currentDay,
      currentSystem,
      anotherSystem,
    )(tradeItemType),
  );
  if (difference > 0) {
    return `+${difference} cr.`;
  }

  return `${difference} cr.`;
};

export const getPriceDifferenceColor = (
  differenceLabel: string,
): 'success' | 'danger' | 'default' => {
  if (differenceLabel === '---') {
    return 'default';
  }

  if (differenceLabel[0] === '+') {
    return 'success';
  }

  return 'danger';
};
