import { getShipConfig } from '../app/config/ship/get-ship-config';

export const useSlotsDescription = (
  totalSlots: number,
  usedSlots: number,
  object: string,
) => {
  const hasSlots = totalSlots > 0;
  const hasOneFilledSlot = usedSlots === 1;
  const hasSingleFilledSlot = hasOneFilledSlot && totalSlots === 1;
  const hasSingleEmptySlot = !hasOneFilledSlot && totalSlots === 1;
  const plural = `${object}s`;

  if (!hasSlots) {
    return `You do not have ${plural} on your ship`;
  }

  if (hasSingleFilledSlot) {
    return `You have one filled ${object}`;
  }

  if (hasSingleEmptySlot) {
    return `You have one empty ${object}`;
  }

  return `You have filled ${usedSlots} ${
    hasOneFilledSlot ? object : plural
  } out of ${totalSlots} available`;
};
