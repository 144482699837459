import { WeaponConfigs } from '../../data/types/ship/weapon/weapon-config';
import {
  PULSE_LASER_WEAPON,
  BEAM_LASER_WEAPON,
  MILITARY_LASER_WEAPON,
  MORGAN_LASER_WEAPON,
} from '../../data/types/ship/weapon/weapon-type';

export const weaponConfigs: WeaponConfigs = {
  [PULSE_LASER_WEAPON]: {
    power: 15,
    price: 2000,
    techLevel: 5,
    chance: 50,
    toSell: true,
  },
  [BEAM_LASER_WEAPON]: {
    power: 25,
    price: 12500,
    techLevel: 6,
    chance: 35,
    toSell: true,
  },
  [MILITARY_LASER_WEAPON]: {
    power: 35,
    price: 35000,
    techLevel: 7,
    chance: 15,
    toSell: true,
  },
  [MORGAN_LASER_WEAPON]: {
    power: 85,
    price: 50000,
    techLevel: 8,
    chance: 0,
    toSell: false,
  },
};
