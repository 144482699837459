import { prop } from 'ramda';
import { useSelector } from 'react-redux';
import { selectPlayerCargo } from '../../../../store/game/ship/ship.selectors';
import { unique } from '../../../../app/utils/unique-array';
import { getTotalQuantityOfTradeableItem } from '../../../../app/core/ship/cargo/total-quantity-of-trade-item';
import { eqByLength } from '../../../../app/utils';

export const useShipCargoCheck = () => {
  const cargo = useSelector(selectPlayerCargo, eqByLength);

  if (!cargo.length) {
    return 'Buy some goods to trade';
  }

  const types = cargo.map(prop('type')).filter(unique);
  const [first, ...rest] = types.map(
    (type) =>
      `${getTotalQuantityOfTradeableItem(cargo, type)} units of ${type}`,
  );

  if (!rest.length) {
    return `You carry ${first}`;
  }
  return `You carry ${rest.join(',')} and ${first}`;
};
