import { EquipmentItemType } from '../equipment/equipment';

export const PULSE_LASER_WEAPON = 'Pulse laser';
export const BEAM_LASER_WEAPON = 'Beam laser';
export const MILITARY_LASER_WEAPON = 'Military laser';
export const MORGAN_LASER_WEAPON = "Morgan's laser";

export const weapons = [
  PULSE_LASER_WEAPON,
  BEAM_LASER_WEAPON,
  MILITARY_LASER_WEAPON,
  MORGAN_LASER_WEAPON,
] as const;

export function isWeaponType(
  equipmentItemType: EquipmentItemType,
): equipmentItemType is WeaponType {
  return weapons.some((weaponType) => weaponType === equipmentItemType);
}

export type WeaponType = typeof weapons[number];
