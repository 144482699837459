import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonText,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import MercenaryCard from './MercenaryCard';
import { selectCurrentSystem } from '../../../../store/game/systems/selectors/current-system.selector';
import { pipe } from 'rxjs';
import { prop } from 'ramda';
import { eqByLength } from '../../../../app/utils';

const MercenariesList: React.FC = () => {
  const systemMercenaries = useSelector(
    pipe(selectCurrentSystem, prop('mercenaries')),
    eqByLength,
  );

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>Mercenaries</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {systemMercenaries.length ? (
          systemMercenaries.map((person) => (
            <MercenaryCard key={person.name} person={person} />
          ))
        ) : (
          <IonText>
            <h2>There are no available mercenaries to hire.</h2>
          </IonText>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default MercenariesList;
