import {
  CLEANSCORE,
  CRIMINALSCORE,
  CROOKSCORE,
  DUBIOUSSCORE,
  HELPERSCORE,
  HEROSCORE,
  LAWFULSCORE,
  MADSCORE,
  PSYCHOPATHSCORE,
  TRUSTEDSCORE,
  VILLAINSCORE,
} from '../data/constants/police-record-score';
import {
  ABOVEAVERAGESCORE,
  AVERAGESCORE,
  COMPETENTREP,
  DANGEROUSREP,
  DEADLYREP,
  ELITESCORE,
  HARMLESSREP,
  MOSTLYHARMLESSREP,
  POORREP,
} from '../data/constants/reputation';

export interface ScoreRank {
  label: string;
  minScore: number;
}

export const policeScoreRanks: ScoreRank[] = [
  {
    label: 'Mad',
    minScore: MADSCORE,
  },
  {
    label: 'Psycho',
    minScore: PSYCHOPATHSCORE,
  },
  {
    label: 'Villain',
    minScore: VILLAINSCORE,
  },
  {
    label: 'Criminal',
    minScore: CRIMINALSCORE,
  },
  {
    label: 'Crook',
    minScore: CROOKSCORE,
  },
  {
    label: 'Dubious',
    minScore: DUBIOUSSCORE,
  },
  {
    label: 'Clean',
    minScore: CLEANSCORE,
  },
  {
    label: 'Lawful',
    minScore: LAWFULSCORE,
  },
  {
    label: 'Trusted',
    minScore: TRUSTEDSCORE,
  },
  {
    label: 'Liked',
    minScore: HELPERSCORE,
  },
  {
    label: 'Hero',
    minScore: HEROSCORE,
  },
  {
    label: 'God',
    minScore: +Infinity,
  },
];

export const getRecordScoreLabel = (scoreRanks: ScoreRank[]) => (
  currentScore: number,
): string => {
  return (
    scoreRanks.find(
      (score, index, scores) =>
        currentScore >= score.minScore &&
        currentScore < scores[index + 1].minScore,
    )?.label || ''
  );
};

export function getPoliceRecordScoreLabel(currentScore: number): string {
  return getRecordScoreLabel(policeScoreRanks)(currentScore);
}

export const reputationScoreRanks: ScoreRank[] = [
  {
    label: 'Harmless',
    minScore: HARMLESSREP,
  },
  {
    label: 'Mostly harmless',
    minScore: MOSTLYHARMLESSREP,
  },
  {
    label: 'Poor',
    minScore: POORREP,
  },
  {
    label: 'Average',
    minScore: AVERAGESCORE,
  },
  {
    label: 'Above average',
    minScore: ABOVEAVERAGESCORE,
  },
  {
    label: 'Competent',
    minScore: COMPETENTREP,
  },
  {
    label: 'Dangerous',
    minScore: DANGEROUSREP,
  },
  {
    label: 'Deadly',
    minScore: DEADLYREP,
  },
  {
    label: 'Elite',
    minScore: ELITESCORE,
  },
];

export function getReputationRecordScoreLabel(currentScore: number): string {
  return getRecordScoreLabel(reputationScoreRanks)(currentScore);
}

export function getReputationColor(score: number): 'success' | 'danger' {
  return score < 0 ? 'danger' : 'success';
}

export function getPoliceRankColor(
  rank: ScoreRank,
  currentLabel: string,
): string {
  if (rank.label === currentLabel) {
    return rank.minScore < 0 ? 'danger' : 'success';
  }
  return 'dark';
}

export function getReputationRankColor(
  rank: ScoreRank,
  currentScore: number,
): string {
  if (rank.minScore <= currentScore) {
    return 'success';
  }
  return 'dark';
}
