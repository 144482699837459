import {
  SystemStatus,
  UNEVENTFUL,
  WAR,
  PLAGUE,
  DROUGHT,
  BOREDOM,
  COLD,
  CROPFAILURE,
  LACKOFWORKERS,
} from '../../types/system/system-status';

export const SystemStatusLabels: Record<SystemStatus, string> = {
  [UNEVENTFUL]: 'under no particular pressure',
  [WAR]: 'at war', // Ore and Weapons in demand
  [PLAGUE]: 'ravaged by a plague', // Medicine in demand
  [DROUGHT]: 'suffering from a drought', // Water in demand
  [BOREDOM]: 'suffering from extreme boredom', // Games and Narcotics in demand
  [COLD]: 'suffering from a cold spell', // Furs in demand
  [CROPFAILURE]: 'suffering from a crop failure', // Food in demand
  [LACKOFWORKERS]: 'lacking enough workers', // Machinery and Robots in demand
};
