export const NO_SPECIAL_RESOURCES = 'No special resource';
export const MINERAL_RICH = 'Mineral rich';
export const MINERAL_POOR = 'Mineral poor';
export const DESERT = 'Desert';
export const LOTS_OF_WATER = 'Lots of water';
export const RICH_SOIL = 'Rich soil';
export const POOR_SOIL = 'Poor soil';
export const RICH_FAUNA = 'Rich fauna';
export const LIFELESS = 'Lifeless';
export const WEIRD_MUSHROOMS = 'Weird mushrooms';
export const LOTS_OF_HERBS = 'Lots of herbs';
export const ARTISTIC = 'Artistic';
export const WARLIKE = 'Warlike';

export const resources = [
  NO_SPECIAL_RESOURCES,
  MINERAL_RICH,
  MINERAL_POOR,
  DESERT,
  LOTS_OF_WATER,
  RICH_SOIL,
  POOR_SOIL,
  RICH_FAUNA,
  LIFELESS,
  WEIRD_MUSHROOMS,
  LOTS_OF_HERBS,
  ARTISTIC,
  WARLIKE,
] as const;

export type Resource = typeof resources[number];
