import { IShip } from '../../data/types/ship/ship';
import { System } from '../../data/types/system/system';
import { Installable } from '../../data/types/ship/equipment/installable';
import { EquipmentType, WEAPONS } from '../../config/equipment.configs';
import {
  EquipmentItemType,
  getEquipmentType,
} from '../../data/types/ship/equipment/equipment';
import { getShipConfig } from '../../config/ship/get-ship-config';
import { getSystemTechLevel } from '../solar-system/system-tech-level';
import { WeaponType } from '../../data/types/ship/weapon/weapon-type';
import { getWeaponConfig } from '../../config/weapon/get-weapon-config';
import { equals, prop } from 'ramda';
import { weaponConfigs } from '../../config/weapon/weapon.configs';

export const isEquipmentToSell = (configs: Record<string, Installable>) => (
  type: EquipmentItemType,
): boolean => configs[type].toSell;

export const isEquipmentProducible = (configs: Record<string, Installable>) => (
  system: System,
  type: EquipmentItemType,
): boolean => configs[type].techLevel <= getSystemTechLevel(system);

export const isEquipmentAvailable = (configs: Record<string, Installable>) => (
  system: System,
  type: EquipmentItemType,
): boolean =>
  isEquipmentToSell(configs)(type) &&
  isEquipmentProducible(configs)(system, type);

export const getTotalSlots = (
  ship: IShip,
  equipmentType: EquipmentType,
): number => getShipConfig(ship).equipmentSlots[equipmentType];

export const getUsedSlots = (
  ship: IShip,
  equipmentType: EquipmentType,
): number => ship.equipment[equipmentType].length;

export const hasFreeSlots = (ship: IShip) => (
  equipmentType: EquipmentType,
): boolean =>
  getTotalSlots(ship, equipmentType) > getUsedSlots(ship, equipmentType);

export function hasEquipment(
  ship: IShip,
  equipmentItemType: EquipmentItemType,
): boolean {
  const equipmentType: EquipmentType = getEquipmentType(equipmentItemType);
  return ship.equipment[equipmentType].some(
    (item: { type: string }) => item.type === equipmentItemType,
  );
}

// exactCompare
export const hasWeapon = (weaponType: WeaponType) => (ship: IShip): boolean =>
  ship.equipment[WEAPONS].map(prop('type')).some(equals(weaponType));

// equals or greater than provided weaponType
export const hasWeaponsStrongEnough = (weaponType: WeaponType) => (
  ship: IShip,
): boolean =>
  ship.equipment[WEAPONS].map(getWeaponConfig)
    .map(prop('power'))
    .some((power) => power >= weaponConfigs[weaponType].power);
