import React, { useCallback } from 'react';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import { PurchasedItem } from '../../../app/data/types/trade-item/purchased-item';
import { useDispatch, useSelector } from 'react-redux';
import { plunderCargo } from '../../../store/game/ship/ship.actions';
import { selectPlayerEmptyCargoBaysAmount } from '../../../store/game/ship/ship.selectors';
import { addNotification } from '../../../store/game/notifications/notifications.actions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export const Plunder: React.FC<{
  cargo: PurchasedItem[];
}> = ({ cargo }) => {
  const dispatch = useDispatch();
  const emptyBays = useTypedSelector(selectPlayerEmptyCargoBaysAmount);

  const plunder = useCallback(
    (item: PurchasedItem) => {
      const action =
        emptyBays > item.quantity
          ? plunderCargo(item)
          : addNotification({
              title: 'No empty cargo bays',
              message:
                'You do not have enough empty cargo bays to plunder these units',
            });
      dispatch(action);
    },
    [dispatch, emptyBays],
  );

  return (
    <IonRow>
      {cargo.map((item, index) => (
        <IonCol key={index} size="12">
          <IonButton onClick={() => plunder(item)}>
            {item.quantity} {item.type} units
          </IonButton>
        </IonCol>
      ))}
    </IonRow>
  );
};
