import { createAction } from 'typesafe-actions';
import {
  BalanceChangeReason,
  BalanceChangeType,
} from '../../../app/data/types/balance';
import * as actionTypes from './balance.actions-types';

export const spendCredits = createAction(actionTypes.SPEND_CREDITS)<{
  amount: number;
  reason: BalanceChangeReason;
}>();
export const earnCredits = createAction(actionTypes.EARN_CREDITS)<{
  amount: number;
  reason: BalanceChangeReason;
}>();
export const registerBalanceChange = createAction(
  actionTypes.REGISTER_BALANCE_CHANGE,
)<{
  day: number;
  amount: number;
  reason: BalanceChangeReason;
  type: BalanceChangeType;
}>();

export const removeBalanceChange = createAction(
  actionTypes.REMOVE_BALANCE_CHANGE,
)<string>();
