import { useSelector } from 'react-redux';
import { shieldConfigs } from '../../../../app/config/shield/shield.configs';
import {
  makeGadget,
  makeShield,
  makeWeapon,
} from '../../../../app/factories/ship-equipment';
import { EquipmentItemType } from '../../../../app/data/types/ship/equipment/equipment';
import { isGadgetType } from '../../../../app/data/types/ship/gadget/gadget-type';
import { isShieldType } from '../../../../app/data/types/ship/shield/shield-type';
import { isWeaponType } from '../../../../app/data/types/ship/weapon/weapon-type';
import { addConfirmation } from '../../../../store/game/confirmations/confirmations.actions';
import { addNotification } from '../../../../store/game/notifications/notifications.actions';
import {
  buyGadget,
  buyShield,
  buyWeapon,
} from '../../../../store/game/ship/ship.actions';
import {
  selectBaseEquipmentPrice,
  selectHasFreeSlotsToInstall,
} from '../../../../store/game/ship/ship.selectors';
import { selectIsEnoughMoney } from '../../../../store/derived/computational-selectors';
import { selectIsPlayerInDebt } from '../../../../store/game/status/status.selectors';

function getBuyAction(equipmentItemType: EquipmentItemType) {
  if (isGadgetType(equipmentItemType)) {
    return buyGadget(makeGadget(equipmentItemType));
  }
  if (isShieldType(equipmentItemType)) {
    return buyShield(
      makeShield(equipmentItemType, shieldConfigs[equipmentItemType].power),
    );
  }
  if (isWeaponType(equipmentItemType)) {
    return buyWeapon(makeWeapon(equipmentItemType));
  }
}

const useBuyEquipmentAction = (equipmentItemType: EquipmentItemType) => {
  const hasDebt = useSelector(selectIsPlayerInDebt);
  const hasFreeSlots = useSelector(
    selectHasFreeSlotsToInstall(equipmentItemType),
  );
  const price = useSelector(selectBaseEquipmentPrice(equipmentItemType));
  const isEnoughMoney = useSelector(selectIsEnoughMoney(price));

  if (hasDebt) {
    return addNotification({
      title: 'You are in debt',
      message: 'You can not buy equipment while you have a debt',
    });
  }

  if (!hasFreeSlots) {
    return addNotification({
      title: 'No free slots',
      message: `You do not have free slots on your ship to install ${equipmentItemType}`,
    });
  }

  if (!isEnoughMoney) {
    return addNotification({
      title: 'Not enough money',
      message: `Your current balance is not enough to buy ${equipmentItemType}`,
    });
  }

  const buyAction = getBuyAction(equipmentItemType);
  if (buyAction) {
    return addConfirmation({
      title: `Buy ${equipmentItemType}`,
      message: `Do you want to buy ${equipmentItemType} and spend ${price} credits for it?`,
      action: buyAction,
    });
  }

  return null;
};

export default useBuyEquipmentAction;
