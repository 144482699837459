import { System } from '../../data/types/system/system';
import {
  NARCOTICS,
  ROBOTS,
  TradeItemType,
} from '../../data/types/trade-item/trade-item-type';
import { getSeedRandom } from '../../utils';
import { getSystemUniqueId } from '../solar-system/system-unique-id';
import { getTradeItemConfig } from '../../config/trade-item/get-trade-item-config';
import { getSystemTechLevel } from '../solar-system/system-tech-level';
import { getSystemSize } from '../solar-system/system-size';
import { getSystemResource } from '../solar-system/system-resource';
import {
  isTradeItemDemanded,
  isTradeItemInProduction,
} from './trade-items.utils';
import { difficultyLevels } from '../../data/constants/difficulty';
import { pipe } from 'fp-ts/lib/function';
import { add, max, multiply } from 'ramda';
import { floor } from 'ramda-adjunct';

// how many days between production days (put produced items on the market)
// min = 3 days (day 0, day 3, day 6, day 9)
// max = 7 days (day 0, day 7, day 14, day 21)
export function tradeItemProductionDuration(difficulty: number): number {
  return difficulty + 3;
}

function getQuantitySeed(
  currentDay: number,
  system: System,
  tradeItemType: TradeItemType,
): string {
  return `${currentDay}${tradeItemType}${getSystemUniqueId(system)}`;
}

function getQuantityDeviation(
  currentDay: number,
  system: System,
  tradeItemType: TradeItemType,
  variance: number,
): number {
  const quantitySeed = getQuantitySeed(currentDay, system, tradeItemType);
  return (
    +getSeedRandom(`inc${quantitySeed}`)(0, variance) -
    getSeedRandom(`dec${quantitySeed}`)(0, variance)
  );
}

export const isProductionDay = (day: number, difficulty: number): boolean => {
  return day % tradeItemProductionDuration(difficulty) === 0;
};

export const getBaseQuantity = (
  day: number,
  system: System,
  tradeItemType: TradeItemType,
): number => {
  return (
    getSeedRandom(getQuantitySeed(day, system, tradeItemType))(9, 14) -
    Math.abs(
      getTradeItemConfig(tradeItemType).techTopProduction -
        getSystemTechLevel(system),
    )
  );
};

export const getSystemSizeQuantityFactor = (system: System) =>
  1 + getSystemSize(system);

// Because of the enormous profits possible, there shouldn't be too many robots or narcotics available
export const getLargeProfitFactor = (
  tradeItemType: TradeItemType,
  difficulty: number,
) => {
  if (tradeItemType === ROBOTS || tradeItemType === NARCOTICS) {
    return (
      (difficultyLevels.length - difficulty) /
      (difficultyLevels.length + 1 - difficulty)
    ); // + 1; ??
  }
  return 1;
};

export const getCheapResourceQuantityFactor = (
  tradeItemType: TradeItemType,
  system: System,
) => {
  return getTradeItemConfig(tradeItemType).cheapResource.includes(
    getSystemResource(system),
  )
    ? 4 / 3
    : 1;
};

export const getExpensiveResourceQuantityFactor = (
  tradeItemType: TradeItemType,
  system: System,
) => {
  return getTradeItemConfig(tradeItemType).expensiveResource.includes(
    getSystemResource(system),
  )
    ? 3 / 4
    : 1;
};

export const getDemandedQuantityFactor = (
  currentDay: number,
  system: System,
  tradeItemType: TradeItemType,
) => {
  return isTradeItemDemanded(currentDay, system, tradeItemType) ? 1 / 5 : 1;
};

export const getTradeItemProducedAmount = (
  difficulty: number,
  day: number,
  tradeItemType: TradeItemType,
  system: System,
): number => {
  return pipe(
    getBaseQuantity(day, system, tradeItemType),
    multiply(getSystemSizeQuantityFactor(system)),
    multiply(getLargeProfitFactor(tradeItemType, difficulty)),
    multiply(getCheapResourceQuantityFactor(tradeItemType, system)),
    multiply(getExpensiveResourceQuantityFactor(tradeItemType, system)),
    multiply(getDemandedQuantityFactor(day, system, tradeItemType)),
    floor,
    add(getTradersSalesAmount(difficulty, day, tradeItemType, system)),
    max<number>(0),
  );
};

export const getTradersSalesAmount = (
  difficulty: number,
  day: number,
  tradeItemType: TradeItemType,
  system: System,
): number => {
  const deviation = isProductionDay(day, difficulty) ? 10 : 5;
  return getQuantityDeviation(day, system, tradeItemType, deviation);
};

export const getTradeItemQuantity = (
  difficulty: number,
  day: number,
  tradeItemType: TradeItemType,
  system: System,
): number => {
  // systems do not trade items that not produced (no demand or deficit)
  if (!isTradeItemInProduction(tradeItemType, system)) {
    return 0;
  }

  if (!isProductionDay(day, difficulty)) {
    return pipe(
      system.tradeItems[tradeItemType],
      add(getTradersSalesAmount(difficulty, day, tradeItemType, system)),
      max<number>(0),
    );
  }

  return getTradeItemProducedAmount(difficulty, day, tradeItemType, system);
};

export const getPossibleTradeItemQuantityToPurchase = (
  quantity: number,
  price: number,
  credits: number,
  availableCargoBays: number,
) => {
  const possibleToPurchase = Math.floor(credits / price);
  return Math.min(quantity, possibleToPurchase, availableCargoBays);
};
