import {
  QuestType,
  MONSTER_QUEST,
  DRAGONFLY_QUEST,
  JAPORI_DESEASE_QUEST,
  JAREK_QUEST,
  INVASION_QUEST,
  WILD_QUEST,
  SCARAB_QUEST,
  EXPERIMENT_QUEST,
  REACTOR_QUEST,
  ALIEN_ARTIFACT_QUEST,
} from '../data/types/game/quest-status';

const alientArtifactQuestLabels: Record<number, string> = {
  0: 'Alien artifact is not on board yet',
  1: 'Alien artifact is on board, find a system with professor',
  2: 'Alien artifact is delivered',
};

const monsterQuestLabels: Record<number, string> = {
  0: 'Space monster is not available',
  1: 'Space monster is in Acamar system',
  2: 'Space monster is destroyed',
};

const dragonQuestLabels: Record<number, string> = {
  0: 'Dragonfly not available',
  1: 'Go to Baratas',
  2: 'Go to Melina',
  3: 'Go to Regulas',
  4: 'Go to Zalkon',
  5: 'Dragonfly destroyed',
};

const japoriQuestLabels: Record<number, string> = {
  0: 'No disease on Japori',
  1: 'Go to Japori (always at least 10 medicine cannisters)',
  2: 'Assignment finished or canceled',
};

const jarekQuestLabels: Record<number, string> = {
  0: 'Ambassador Jarek not delivered',
  1: 'Ambassador Jarek on board',
  2: 'Ambassador Jarek delivered',
};

const invasionQuestLabels: Record<number, string> = {
  0: 'Alien invasion of Gemulon not given yet',
  1: 'Alien invasion of Gemulon 1 day from start',
  2: 'Alien invasion of Gemulon 2 day from start',
  3: 'Alien invasion of Gemulon 3 day from start',
  4: 'Alien invasion of Gemulon 4 day from start',
  5: 'Alien invasion of Gemulon 5 day from start',
  6: 'Alien invasion of Gemulon 6 day from start',
  7: 'Alien invasion of Gemulon 7 day from start',
  8: 'Alien invasion of Gemulon happened',
};

const wildQuestLabels: Record<number, string> = {
  0: 'not delivered',
  1: 'on board',
  2: 'delivered',
};

const scarabQuestLabels: Record<number, string> = {
  0: 'Scarab not given yet',
  1: 'Scarab not destroyed',
  2: 'Scarab destroyed',
  3: 'Hull upgrade performed',
};

// Unstable Reactor Status 0=not encountered; 1-20=days of mission (bays of fuel left = 10 - (ReactorStatus/2); 21=delivered

export const getQuestStatusLabel = (questType: QuestType) => (
  questStatus: number,
): string => {
  switch (questType) {
    case ALIEN_ARTIFACT_QUEST:
      return alientArtifactQuestLabels[questStatus];
    case MONSTER_QUEST:
      return monsterQuestLabels[questStatus];
    case DRAGONFLY_QUEST:
      return dragonQuestLabels[questStatus];
    case JAPORI_DESEASE_QUEST:
      return japoriQuestLabels[questStatus];
    case JAREK_QUEST:
      return jarekQuestLabels[questStatus];
    case INVASION_QUEST:
      return invasionQuestLabels[questStatus];
    case WILD_QUEST:
      return wildQuestLabels[questStatus];
    case SCARAB_QUEST:
      return scarabQuestLabels[questStatus];
    case EXPERIMENT_QUEST:
      if (questStatus > 0 && questStatus < 12) {
        return `${questStatus} day of experiment`;
      }
      if (questStatus === 12) {
        return 'Experiment performed';
      }
      if (questStatus === 13) {
        return 'Experiment cancelled';
      }
      return 'Experiment not given yet';
    case REACTOR_QUEST:
      if (questStatus > 0 && questStatus < 21) {
        return `${questStatus} day of mission`;
      }
      if (questStatus === 21) {
        return 'Reactor delivered';
      }
      return 'Reactor not encountered';
    default:
      return questStatus.toString();
  }
};
