import {
  GEMULONSYSTEM,
  DALEDSYSTEM,
  NIXSYSTEM,
} from '../../data/constants/solar-systems';
import { shuffle } from '../../utils/shuffle-array';
import {
  getCandidateForQuest,
  isAcceptableSystemForQuest,
  tryToSetInitialEvent,
  tryToSetDestinationEvent,
} from './quests.utils';
import { specialEventsToSamples } from './events-to-indexes';
import { System } from '../../data/types/system/system';
import {
  SCARAB_DESTROYED,
  ARTIFACT_DELIVERY,
  SCARAB_STOLEN,
  ALIEN_ARTIFACT,
  SpecialEventType,
} from '../../data/types/system/special-event/special-event-type';
import { specialEventConfigs } from '../../config/special-event/special-event.configs';
import { byNameIndex } from '../solar-system/find-by-name-index';
import { getSystemTechLevel } from '../solar-system/system-tech-level';
import { setSpecialEvent } from '../solar-system/set-special-event';
import { TechLevel } from '../../data/labels/solar-system/technical-levels';
import { prop } from 'ramda';

const isArtifactQuestTaken = (systems: System[]) =>
  systems.some((system) => system.special === ARTIFACT_DELIVERY);

const isScarabQuestTaken = (systems: System[]) =>
  systems.some((system) => system.special === SCARAB_DESTROYED);

export const setScarabQuest = (systems: System[]): System[] => {
  if (isScarabQuestTaken(systems)) {
    return systems;
  }

  // Assign a wormhole location endpoint for the Scarab.
  // It's possible that ALL wormhole destinations are already
  // taken. In that case, we don't offer the Scarab quest.
  // TODO: check original logic
  const availableSystems = systems.filter(
    (system) =>
      !system.special &&
      ![GEMULONSYSTEM, DALEDSYSTEM, NIXSYSTEM].includes(system.nameIndex) &&
      system.wormHoleNameIndex > -1,
  );
  const candidateNameIndex =
    availableSystems.length > 0 ? shuffle(availableSystems)[0].nameIndex : -1;
  //
  return systems.map((system) => ({
    ...system,
    special:
      system.nameIndex === candidateNameIndex
        ? SCARAB_DESTROYED
        : system.special,
  }));
};

export const setQuest = (
  destinationNameIndex: number,
  destinationEvent: SpecialEventType,
  initialEvent: SpecialEventType,
) => (systems: System[]): System[] => {
  const destinationSystem = systems.find(byNameIndex(destinationNameIndex));
  if (!destinationSystem) {
    return systems;
  }

  const candidateSystemIndex = getCandidateForQuest(systems, destinationSystem);

  return systems.map((system) => ({
    ...system,
    special:
      tryToSetInitialEvent(system, candidateSystemIndex, initialEvent) ||
      tryToSetDestinationEvent(
        system,
        destinationNameIndex,
        destinationEvent,
      ) ||
      system.special,
  }));
};

// logic here doesn't fit into common setQuest logic because of specific techLevel condition for the system
export const setAlienArtifactQuest = (systems: System[]): System[] => {
  // if some system already has artifact delivery event, it means we already set it, so skip the rest of the function body
  if (isArtifactQuestTaken(systems)) {
    return systems;
  }

  const candidate = systems.find(
    (system) =>
      getSystemTechLevel(system) === TechLevel.length - 1 &&
      isAcceptableSystemForQuest(system),
  );

  if (!candidate) {
    return systems;
  }

  return systems.map(setSpecialEvent(candidate.nameIndex, ARTIFACT_DELIVERY));
};

export const setRegularQuests = (systems: System[]): System[] => {
  const eventTypes: SpecialEventType[] = (Object.keys(
    specialEventConfigs,
  ) as SpecialEventType[]).filter((eventType) => {
    if (eventType === ALIEN_ARTIFACT && !isArtifactQuestTaken(systems)) {
      return false;
    }
    if (eventType === SCARAB_STOLEN && !isScarabQuestTaken(systems)) {
      return false;
    }
    return specialEventConfigs[eventType].randomPosition;
  });
  const eventSamples = specialEventsToSamples(eventTypes);

  return systems.map((system) => ({
    ...system,
    special: !system.special ? eventSamples.pop() || null : system.special,
  }));
};
