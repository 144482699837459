import { createAction } from 'typesafe-actions';
import { ActorType } from '../../../app/data/interaction/actor-type';
import * as actionTypes from './travel.action-types';
import { IShip } from '../../../app/data/types/ship/ship';
import { DamageEvent } from '../../../app/data/interaction/damage-event';
import { InteractionEvent } from '../../../app/data/interaction/interaction-event';
import { PurchasedItem } from '../../../app/data/types/trade-item/purchased-item';

export interface StartTravelPayload {
  clicks: number;
  systemIndex: number;
  viaWormhole: boolean;
}

export const warp = createAction(actionTypes.WARP)<number>();
export const singularityJump = createAction(actionTypes.SINGULARITY_JUMP)<
  number
>();
export const startTravel = createAction(actionTypes.START_TRAVEL)<
  StartTravelPayload
>();
export const move = createAction(actionTypes.MOVE)();
export const encounter = createAction(actionTypes.ENCOUNTER)<ActorType>();
export const arrive = createAction(actionTypes.ARRIVE)();

export const playerRaided = createAction(actionTypes.RAIDED)();
export const playerInspected = createAction(actionTypes.INSPECTED)();
export const initEncounter = createAction(actionTypes.INIT_ENCOUNTER)<{
  type: ActorType;
  ship: IShip;
}>();
export const acceptEncounter = createAction(actionTypes.ACCEPT_ENCOUNTER)();
export const declineEncounter = createAction(actionTypes.DECLINE_ENCOUNTER)();
export const encounterEvent = createAction(actionTypes.ENCOUNTER_EVENT)<
  Omit<InteractionEvent, 'id'>
>();
export const damageEvent = createAction(actionTypes.DAMAGE_EVENT)<
  Omit<DamageEvent, 'id'>
>();
export const changeEncounterTurn = createAction(actionTypes.CHANGE_TURN)();

// 4 similar actions with [Player ship] - to be DRY?
export const damageOpponentShipHull = createAction(
  actionTypes.DAMAGE_OPPONENT_SHIP_HULL,
)<number>();
export const damageOpponentShipShields = createAction(
  actionTypes.DAMAGE_OPPONENT_SHIP_SHIELDS,
)<number>();
export const lostCargoOpponent = createAction(actionTypes.LOST_CARGO_OPPONENT)<
  PurchasedItem
>();
export const plunderCargoOpponent = createAction(
  actionTypes.PLUNDER_CARGO_OPPONENT,
)<PurchasedItem>();
