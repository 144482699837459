import { IShip } from '../../data/types/ship/ship';
import { GADGETS, SHIELDS, WEAPONS } from '../../config/equipment.configs';
import { baseEquipmentSellPrice } from '../equipment/equipment.prices';
import { ShipType } from '../../data/types/ship/ship-type';
import {
  ENGINEER_SKILL,
  FIGHTER_SKILL,
  PILOT_SKILL,
  TRADER_SKILL,
} from '../../data/types/crew-member/skill';
import { IStatus } from '../../data/types/game/status';
import { shipConfigs } from '../../config/ship/ship.configs';
import { add, prop } from 'ramda';
import { getCargoValue } from './cargo/cargo-value';
import { getShipConfig } from '../../config/ship/get-ship-config';
import { getWeaponConfig } from '../../config/weapon/get-weapon-config';
import { getShieldConfig } from '../../config/shield/get-shield-config';
import { getGadgetConfig } from '../../config/gadget/get-gadget-config';
import { getFuelAmountToFillIn } from './fuel/fuel-amount-to-fill-in';
import { getFuelCost } from './fuel/fuel-cost';
import { getHullToRepair } from './hull/hull-to-repair';
import { getHullRepairCost } from './hull/hull-repair-cost';
import { MOON_COST } from '../../data/constants/special-events';
import { getSkillLevel } from '../crew-member/skill/skill-level';

export const shipPriceDiscountFactor = (
  difficulty: number,
  playerShip: IShip,
) => (100 - getSkillLevel(TRADER_SKILL)(playerShip, difficulty)) / 100;

export const BaseShipPrice = (shipType: ShipType) => (
  difficulty: number,
  playerShip: IShip,
): number =>
  shipConfigs[shipType].price * shipPriceDiscountFactor(difficulty, playerShip);

export const EnemyShipPrice = (difficulty: number) => (ship: IShip): number => {
  const totalWeaponCost = ship.equipment[WEAPONS].map(getWeaponConfig)
    .map(prop('price'))
    .reduce(add, 0);
  const totalShieldCost = ship.equipment[SHIELDS].map(getShieldConfig)
    .map(prop('price'))
    .reduce(add, 0);
  let CurPrice: number =
    getShipConfig(ship).price + totalWeaponCost + totalShieldCost;
  // Gadgets aren't counted in the price, because they are already taken into account in
  // the skill adjustment of the price.

  CurPrice =
    (CurPrice *
      (2 * getSkillLevel(PILOT_SKILL)(ship, difficulty) +
        getSkillLevel(ENGINEER_SKILL)(ship, difficulty) +
        3 * getSkillLevel(FIGHTER_SKILL)(ship, difficulty))) /
    60;

  return CurPrice;
};

export const CurrentShipPriceWithoutCargo = (
  ship: IShip,
  forInsurance: boolean,
): number => {
  // Trade-in value is three-fourths the original price
  const CurPrice: number =
    (getShipConfig(ship).price * (ship.tribbles > 0 && !forInsurance ? 1 : 3)) /
      4 -
    // subtract repair costs
    getHullRepairCost(getHullToRepair(ship))(ship) -
    // subtract costs to fill tank with fuel
    getFuelCost(getFuelAmountToFillIn(ship))(ship);

  const weaponsSellPrice = ship.equipment[WEAPONS].map(getWeaponConfig)
    .map(baseEquipmentSellPrice)
    .reduce(add, 0);

  const shieldsSellPrice = ship.equipment[SHIELDS].map(getShieldConfig)
    .map(baseEquipmentSellPrice)
    .reduce(add, 0);

  const gadgetsSellPrice = ship.equipment[GADGETS].map(getGadgetConfig)
    .map(baseEquipmentSellPrice)
    .reduce(add, 0);

  return CurPrice + weaponsSellPrice + shieldsSellPrice + gadgetsSellPrice;
};

// *************************************************************************
// Determine value of current ship, including goods and equipment.
// *************************************************************************
export const currentShipPrice = (forInsurance: boolean) => (
  ship: IShip,
): number =>
  CurrentShipPriceWithoutCargo(ship, forInsurance) + getCargoValue(ship);

export const getInsurancePayment = (ship: IShip, status: IStatus): number => {
  if (!status.insurance) return 0;
  return Math.max(
    1,
    (((CurrentShipPriceWithoutCargo(ship, true) * 5) / 2000) *
      (100 - Math.min(status.noClaim, 90))) /
      100,
  );
};

export const playerWorth = (
  credits: number,
  ship: IShip,
  isMoonBought: boolean,
  debt: number,
): number => {
  const shipPrice = currentShipPrice(false)(ship);
  const moonPrice = isMoonBought ? MOON_COST : 0;
  return credits + shipPrice + moonPrice - debt;
};
