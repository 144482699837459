import {
  onDamageEvent,
  onEncounterEvent,
  onOpponentTurn,
  whenPlayerDoing,
} from './encounter.pipe-operators';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import {
  EncounterActionType,
  ESCAPE,
  FLEE,
} from '../../../../app/data/interaction/action-type';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import { mapState } from '../../../operators/map-state';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import { GameActions, RootState } from '../../../index';
import {
  changeEncounterTurn,
  damageOpponentShipHull,
  damageOpponentShipShields,
  move,
} from '../../travel/travel.actions';
import { Observable, of } from 'rxjs';
import { makeActionEvent } from '../make-action-event';
import { TravelActions } from '../../travel/travel.reducer';
import { selectReceivedOpponentDamage } from '../selectors/received-damage.selectors';
import {
  selectIsOpponentEscaped,
  selectIsPlayerEscaped,
} from '../selectors/is-escaped.selectors';
import { whenInState } from '../../../operators/when-in-state';
import { addToast } from '../../toasts/toasts.actions';
import { selectEncounterType } from '../encounter.selectors';
import { selectOpponentReaction } from './opponent-reaction.selector';
import { getRandom } from '../../../../app/utils';
import { AnyAction } from 'redux';

export const makeOpponentEscapingEpic: (
  actor: ActorType,
) => Epic<GameActions, GameActions> = (actor: ActorType) => (action$, state$) =>
  action$.pipe(
    onEncounterEvent(actor, FLEE),
    mapState(selectIsOpponentEscaped)(state$),
    switchMap((escaped) => {
      if (escaped) {
        return of(null).pipe(
          mapState(selectEncounterType)(state$),
          switchMap((encounterType) => [
            makeActionEvent(actor)(ESCAPE),
            move(),
            addToast({
              header: 'Opponent escaped',
              message: `${encounterType} managed to escape`,
            }),
          ]),
        );
      }
      return of(changeEncounterTurn());
    }),
  );

export const makeOpponentReceivingDamageEpic: (
  actor: ActorType,
) => Epic<TravelActions, TravelActions> =
  (actor: ActorType) => (action$, state$) =>
    action$.pipe(
      onDamageEvent(actor),
      mapState(selectReceivedOpponentDamage)(state$),
      filter(([hullDamage, shieldDamage]) => hullDamage + shieldDamage > 0),
      switchMap(([hullDamage, shieldDamage]) =>
        of(
          damageOpponentShipHull(hullDamage),
          damageOpponentShipShields(shieldDamage),
        ),
      ),
    );

export const makeOpponentReactWhenPlayerDoing: (
  opponent: ActorType,
  playerAction: EncounterActionType,
) => Epic<TravelActions, TravelActions> =
  (opponent: ActorType, playerAction: EncounterActionType) =>
  (action$: ActionsObservable<AnyAction>, state$: StateObservable<RootState>) =>
    action$.pipe(
      onOpponentTurn(opponent)(state$),
      whenPlayerDoing(playerAction)(state$),
      delay(1),
      mapState(selectOpponentReaction(opponent, playerAction))(state$),
      map(makeActionEvent(opponent)),
    );
