import { prop, pipe } from 'ramda';
import { selectTravel } from './travels-state.selector';
import { mapEntitiesToIds } from '../../../entities';

export const selectTravelEncounters = (travelId: string) =>
  pipe(selectTravel(travelId), prop('encounters'));

export const selectTravelEncountersIds = (travelId: string) =>
  pipe(selectTravel(travelId), prop('encounters'), mapEntitiesToIds);

export const selectEncounter = (encounterId: string, travelId: string) =>
  pipe(
    selectTravelEncounters(travelId),
    (encounters) => encounters[encounterId],
  );

export const selectEncounterShipType = (
  encounterId: string,
  travelId: string,
) => pipe(selectEncounter(encounterId, travelId), prop('ship'), prop('type'));

export const selectEncounterClick = (encounterId: string, travelId: string) =>
  pipe(selectEncounter(encounterId, travelId), prop('click'));

export const selectEncounterType = (encounterId: string, travelId: string) =>
  pipe(selectEncounter(encounterId, travelId), prop('type'));
