import React from 'react';
import { IonAlert } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirstConfirmation } from '../../store/game/confirmations/confirmations.selectors';
import { dismissConfirmation } from '../../store/game/confirmations/confirmations.actions';
import { cancelButton, confirmButton } from './buttons';

const ConfirmationsCenter: React.FC = () => {
  const dispatch = useDispatch();
  const confirmation = useSelector(selectFirstConfirmation);

  if (confirmation) {
    return (
      <IonAlert
        key={confirmation.id}
        isOpen
        header={confirmation.title}
        message={confirmation.message}
        onDidDismiss={() => dispatch(dismissConfirmation(confirmation.id))}
        buttons={[
          cancelButton,
          confirmButton(() => dispatch(confirmation.action)),
        ]}
      />
    );
  }

  return <></>;
};

export default ConfirmationsCenter;
