import { Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
import { mapState } from '../../../../operators/map-state';
import { onInitEncounter } from '../encounter.pipe-operators';
import { selectInitialTraderAction } from './trader.selectors';
import { TRADER } from '../../../../../app/data/interaction/actor-type';
import { makeActionEvent } from '../../make-action-event';
import { TravelActions } from '../../../travel/travel.reducer';
import { makeOpponentReactWhenPlayerDoing } from '../opponent.epics.factories';
import { ATTACK, FLEE } from '../../../../../app/data/interaction/action-type';

const encountTraderEpic: Epic<TravelActions, TravelActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onInitEncounter(TRADER),
    mapState(selectInitialTraderAction)(state$),
    map(makeActionEvent(TRADER)),
  );

export const traderEpics = [
  encountTraderEpic,
  makeOpponentReactWhenPlayerDoing(TRADER, ATTACK),
  makeOpponentReactWhenPlayerDoing(TRADER, FLEE),
];
