import { Travel } from '../../../../store/game/travel/travel.reducer';
import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import { getSystemNameByIndex } from '../../../../app/ui/solar-system.labels';
import { TravelEncounters } from '../../../TravelEncounters/TravelEncounters';

export const TravelCard: React.FC<{ travel: Travel }> = ({ travel }) => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Day {travel.id}</IonCardSubtitle>
        <IonCardTitle>{getSystemNameByIndex(travel.systemIndex)}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <TravelEncounters travelId={travel.id.toString()} />
      </IonCardContent>
    </IonCard>
  );
};
