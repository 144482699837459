import { converge } from 'ramda';
import { getPoliceBribeOffer } from '../../../../../app/core/encounter/values/bribe';
import { getIllegalsFineAmount } from '../../../../../app/core/encounter/values/illegals-fine';
import { EncounterActionType } from '../../../../../app/data/interaction/action-type';
import { selectQuestStatusState } from '../../../questStatus/quest-status.selectors';
import {
  selectPoliceRecordScore,
  selectReputationScore,
} from '../../../scores/scores.selectors';
import { selectPlayerShip } from '../../../ship/ship.selectors';
import { selectDifficulty } from '../../../status/status.selectors';
import { selectOpponentShip } from '../../encounter.selectors';
import { getImprisonmentLength } from '../../../../../app/core/encounter/values/imprisonment-length';
import { pipe } from 'rxjs';
import { selectPlayerWorth } from '../../../../derived/computational-selectors';
import { selectCurrentSystem } from '../../../systems/selectors/current-system.selector';
import { getInitialPoliceAction } from '../../../../../app/core/encounter/reactions/police/initial.reaction';
import { selectIsInspected } from '../../../travel/selectors/is-inspected.selector';
import { RootState } from '../../../../index';

export const selectInitialPoliceAction: (
  state: RootState,
) => EncounterActionType = converge(getInitialPoliceAction, [
  selectDifficulty,
  selectReputationScore,
  selectPoliceRecordScore,
  selectIsInspected,
  selectOpponentShip,
  selectPlayerShip,
]);

export const selectPoliceBribeOffer: (
  state: RootState,
) => number = converge(getPoliceBribeOffer, [
  selectPlayerWorth,
  selectQuestStatusState,
  selectDifficulty,
  selectCurrentSystem,
]);

export const selectPoliceIllegalCargoFine: (
  state: RootState,
) => number = converge(getIllegalsFineAmount, [
  selectPlayerWorth,
  selectDifficulty,
]);

export const selectImprisonmentLength = pipe(
  selectPoliceRecordScore,
  getImprisonmentLength,
);
