import { RandomSkill } from '../utils';
import {
  Skills,
  PILOT_SKILL,
  ENGINEER_SKILL,
  TRADER_SKILL,
  FIGHTER_SKILL,
} from '../data/types/crew-member/skill';

export function makeSkills(): Skills {
  return {
    [PILOT_SKILL]: RandomSkill(),
    [ENGINEER_SKILL]: RandomSkill(),
    [TRADER_SKILL]: RandomSkill(),
    [FIGHTER_SKILL]: RandomSkill(),
  };
}
