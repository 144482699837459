import {
  EndGameStatus,
  KILLED,
  RETIRED,
} from '../../data/types/end-game-status';

export const getScore = (endStatus: EndGameStatus) => (
  days: number,
  shipWorth: number,
  difficulty: number,
): number => {
  const worth =
    shipWorth < 1000000 ? shipWorth : 1000000 + (shipWorth - 1000000) / 10;

  if (endStatus === KILLED) {
    return (difficulty + 1) * ((worth * 90) / 50000);
  }
  if (endStatus == RETIRED) {
    return (difficulty + 1) * ((worth * 95) / 50000);
  }

  const d = Math.max(0, (difficulty + 1) * 100 - days);
  return (difficulty + 1) * ((worth + d * 1000) / 500);
};

export const getScorePercentage = (score: number): string => {
  return [Math.floor(score / 50), Math.floor((score % 50) / 5)].join('.');
};
