import { IShip } from '../../data/types/ship/ship';
import { ShipType, shipTypes } from '../../data/types/ship/ship-type';

export const isShipBetterThanOther = (ship: IShip, another: IShip): boolean => {
  return isGreaterByType(ship.type, another.type);
};

export const isGreaterByType = (
  shipType: ShipType,
  anotherShipType: ShipType,
) => {
  return shipTypes.indexOf(shipType) > shipTypes.indexOf(anotherShipType);
};
