import { TradeItemType } from '../../../../app/data/types/trade-item/trade-item-type';
import { System } from '../../../../app/data/types/system/system';
import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useSelector } from 'react-redux';
import {
  selectCurrentDay,
  selectDifficulty,
} from '../../../../store/game/status/status.selectors';
import {
  getBaseQuantity,
  getCheapResourceQuantityFactor,
  getDemandedQuantityFactor,
  getLargeProfitFactor,
  getSystemSizeQuantityFactor,
  tradeItemProductionDuration,
} from '../../../../app/core/trade-items/trade-items.quantity';
import { getExpensiveResourceFactor } from '../../../../app/core/trade-items/trade-items.prices';
import {
  getSystemResourcesName,
  getSystemSizeName,
  getSystemTechLevelName,
} from '../../../../app/ui/solar-system.labels';
import { getSystemStatus } from '../../../../app/core/solar-system/system-status';
import { isTradeItemInProduction } from '../../../../app/core/trade-items/trade-items.utils';

export const QuantityDefinition: React.FC<{
  tradeItemType: TradeItemType;
  system: System;
}> = ({ tradeItemType, system }) => {
  const day = useSelector(selectCurrentDay);
  const difficulty = useSelector(selectDifficulty);

  const isInProduction = isTradeItemInProduction(tradeItemType, system);
  if (!isInProduction) {
    return <p>Not in production</p>;
  }

  const lastResetDay = day - (day % tradeItemProductionDuration(difficulty));
  const baseQuantity = getBaseQuantity(lastResetDay, system, tradeItemType);
  const systemSizeFactor = getSystemSizeQuantityFactor(system);
  const largeProfitFactor = getLargeProfitFactor(tradeItemType, difficulty);
  const cheapResourceFactor = getCheapResourceQuantityFactor(
    tradeItemType,
    system,
  );
  const expensiveResourceFactor = getExpensiveResourceFactor(
    tradeItemType,
    system,
  );
  const demandFactor = getDemandedQuantityFactor(
    lastResetDay,
    system,
    tradeItemType,
  );

  return (
    <IonGrid>
      <IonRow>
        <IonCol>Base quantity</IonCol>
        <IonCol>{baseQuantity}</IonCol>
        <IonCol>
          (from day {lastResetDay} on {getSystemTechLevelName(system)})
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>System size factor</IonCol>
        <IonCol>{systemSizeFactor}</IonCol>
        <IonCol className="ion-text-lowercase">
          ({getSystemSizeName(system)} system size)
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Large profit factor</IonCol>
        <IonCol>{largeProfitFactor}</IonCol>
        <IonCol>(reduced quantity for {tradeItemType})</IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Cheap resource factor</IonCol>
        <IonCol>{cheapResourceFactor}</IonCol>
        <IonCol className="ion-text-lowercase">
          (cheap when {getSystemResourcesName(system)})
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Expensive resource factor</IonCol>
        <IonCol>{expensiveResourceFactor}</IonCol>
        <IonCol className="ion-text-lowercase">
          (expensive when {getSystemResourcesName(system)})
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>Demanded resource factor</IonCol>
        <IonCol>{demandFactor}</IonCol>
        <IonCol className="ion-text-lowercase">
          (demanded on {getSystemStatus(lastResetDay, system)})
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
