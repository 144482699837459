import {
  EncounterActionType,
  SUBMIT_APPROVED,
  SUBMIT_FAILED,
} from '../../../../data/interaction/action-type';
import { IShip } from '../../../../data/types/ship/ship';
import { hasIllegals } from '../../../ship/cargo/has-illegals';

export const getPoliceSubmitReaction = (ship: IShip): EncounterActionType => {
  if (hasIllegals(ship.cargo)) {
    return SUBMIT_FAILED;
  }
  return SUBMIT_APPROVED;
};
