import { PurchasedItem } from '../../../data/types/trade-item/purchased-item';
import { TradeItemType } from '../../../data/types/trade-item/trade-item-type';
import { add, prop } from 'ramda';

export const getBuyingPrice = (cargo: PurchasedItem[]) => (
  type: TradeItemType,
): number => {
  const foundTypeCargo = cargo.filter((cargoItem) => cargoItem.type === type);
  const totalQuantity = foundTypeCargo.map(prop('quantity')).reduce(add, 0);
  return (
    foundTypeCargo
      .map((cargoItem) => cargoItem.itemPrice * cargoItem.quantity)
      .reduce(add, 0) / totalQuantity
  );
};
