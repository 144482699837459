import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
} from '@ionic/react';
import React from 'react';
import { useShipHealthCheck } from './useShipHealthCheck';
import { useShipCargoCheck } from './useShipCargoCheck';

export const Planner: React.FC = () => {
  const shipCheck = useShipHealthCheck();
  const cargoCheck = useShipCargoCheck();

  return (
    <IonCard className="ion-no-margin">
      <IonCardHeader>
        <IonCardTitle>Daily check</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <h1>
          {shipCheck}. {cargoCheck}.
        </h1>
      </IonCardContent>
    </IonCard>
  );
};
