import {
  ACAMARSYSTEM,
  ZALKONSYSTEM,
  GEMULONSYSTEM,
  KRAVATSYSTEM,
} from '../../data/constants/solar-systems';
import { EASY, NORMAL } from '../../data/constants/difficulty';
import { getSeedRandom } from '../../utils';
import { IShip } from '../../data/types/ship/ship';
import {
  ALIEN_ARTIFACT_QUEST,
  DRAGONFLY_QUEST,
  INVASION_QUEST,
  QuestStatus,
  SCARAB_QUEST,
} from '../../data/types/game/quest-status';
import { System } from '../../data/types/system/system';
import { IStatus } from '../../data/types/game/status';
import { WEAKEST_SHIP } from '../../data/constants/ship-types';
import { SCARAB_DESTROYED } from '../../data/types/system/special-event/special-event-type';
import {
  ActorType,
  DRAGONFLY,
  MANTIS,
  PIRATE,
  POLICE,
  SCARAB,
  SPACE_MONSTER,
  TRADER,
} from '../../data/interaction/actor-type';
import { isWildOnBoard } from '../ship/crew/is-on-board';
import { getPoliticConfig } from '../../config/politic/get-politic-config';
import { getSystemUniqueId } from '../solar-system/system-unique-id';
import { StrengthPolice } from '../solar-system/strength-police';

export const getNextEncounterType = (
  questStatus: QuestStatus,
  policeRecordScore: number,
  status: IStatus,
  difficulty: number,
  isRaided: boolean,
  ship: IShip,
  warpSystem: System,
  clicks: number,
  isArrivedViaWormhole: boolean,
): ActorType | null => {
  const getRandom = getSeedRandom(
    `${getSystemUniqueId(warpSystem)}:${clicks}:${status.days}`,
  );

  //TODO: enable encounter with space monster

  // if (
  //   clicks === 1 &&
  //   warpSystem.nameIndex === ACAMARSYSTEM &&
  //   questStatus['monster'] === 1
  // ) {
  //   return SPACE_MONSTER;
  // }

  //TODO: enable encounter with the stolen Scarab

  // if (
  //   clicks === 20 &&
  //   warpSystem.special === SCARAB_DESTROYED &&
  //   questStatus[SCARAB_QUEST] === 1 &&
  //   isArrivedViaWormhole
  // ) {
  //   return SCARAB;
  // }

  //TODO: enable encounter with the Dragonfly

  // if (
  //   clicks === 1 &&
  //   warpSystem.nameIndex === ZALKONSYSTEM &&
  //   questStatus[DRAGONFLY_QUEST] === 4
  // ) {
  //   return DRAGONFLY;
  // }

  //TODO: enable encounter with Mantis

  // if (
  //   warpSystem.nameIndex === GEMULONSYSTEM &&
  //   questStatus[INVASION_QUEST] > 7 &&
  //   getRandom(0, 10) > 4
  // ) {
  //   return MANTIS;
  // }

  // Check if it is time for an encounter
  // encounters are half as likely if you're in a flea.
  const EncounterTest =
    getRandom(0, 44 - 2 * difficulty) * (ship.type === WEAKEST_SHIP ? 2 : 1);
  const politicConfig = getPoliticConfig(warpSystem);

  // When you are already raided, other pirates have little to gain
  if (EncounterTest < politicConfig.strengthPirates && !isRaided) {
    return PIRATE;
  }

  if (
    EncounterTest <
    politicConfig.strengthPirates +
      StrengthPolice(policeRecordScore)(warpSystem)
  ) {
    // StrengthPolice adapts itself to your criminal record: you'll
    // encounter more police if you are a hardened criminal.
    return POLICE;
  }

  const strength =
    politicConfig.strengthPirates +
    StrengthPolice(policeRecordScore)(warpSystem) +
    politicConfig.strengthTraders;
  if (EncounterTest < strength) {
    return TRADER;
  }

  if (isWildOnBoard(ship) && warpSystem.nameIndex === KRAVATSYSTEM) {
    // if you're coming in to Kravat & you have Wild onboard, there'll be swarms o' cops.
    const rareEncounter = getRandom(0, 100);
    if (difficulty <= EASY && rareEncounter < 25) {
      return POLICE;
    }
    if (difficulty === NORMAL && rareEncounter < 33) {
      return POLICE;
    }
    if (difficulty > NORMAL && rareEncounter < 50) {
      return POLICE;
    }
  }

  // TODO: enable mantis opponent
  // if (questStatus[ALIEN_ARTIFACT_QUEST] === 1 && getRandom(0, 20) <= 3) {
  //   return MANTIS;
  // }

  return null;
};
