import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

export const CargoModal: React.FC<{ title: string }> = ({
  title,
  children,
}) => {
  const [isCargoOpen, setIsCargoOpen] = useState(false);

  return (
    <>
      <IonButton onClick={() => setIsCargoOpen(true)}>{title}</IonButton>

      {isCargoOpen && (
        <IonModal isOpen onDidDismiss={() => setIsCargoOpen(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{title}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsCargoOpen(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardContent>{children}</IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>
      )}
    </>
  );
};
