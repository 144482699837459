import { pipe } from 'rxjs';
import { selectGame } from '../../selectors';
import { prop } from 'ramda';
import { ActorType } from '../../../app/data/interaction/actor-type';

export const selectSettings = pipe(selectGame, prop('settings'));

export const selectChanceOfTradeInOrbit = pipe(
  selectSettings,
  prop('chanceOfTradeInOrbit'),
);

export const selectRemindLoans = pipe(selectSettings, prop('remindLoans'));

export const selectOpponentsToIgnore = pipe(selectSettings, prop('autoIgnore'));

export const selectIsIgnoring = (actor: ActorType) =>
  pipe(selectOpponentsToIgnore, (opponents) => opponents.includes(actor));
