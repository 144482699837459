import {
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  IonText,
} from '@ionic/react';
import SavedGame from './SavedGame';
import React from 'react';

export const LoadGame: React.FC<{ ids: string[] }> = ({ ids }) => {
  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol sizeLg="8" size="12">
          <IonList>
            {ids.map((id) => (
              <SavedGame key={id} id={id} />
            ))}
            {!ids.length && <IonItem>No saved games</IonItem>}
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
