import { BalanceRecord } from '../../../../app/data/types/balance';

export const mergeBalance = (balance: BalanceRecord[]) =>
  balance.reduce((mergedBalance, balanceRecord, index) => {
    if (index === 0) {
      return [balanceRecord];
    }
    const lastRecord = mergedBalance.pop();
    if (!lastRecord) {
      return mergedBalance;
    }
    if (
      balanceRecord.reason === lastRecord.reason &&
      balanceRecord.type === lastRecord.type &&
      balanceRecord.day === lastRecord.day
    ) {
      return [
        ...mergedBalance,
        {
          ...lastRecord,
          amount: lastRecord.amount + balanceRecord.amount,
        },
      ];
    }
    return [...mergedBalance, lastRecord, balanceRecord];
  }, balance);
