import { v4 as uuidv4 } from 'uuid';
import { Action, ActionType, createReducer } from 'typesafe-actions';
import * as actions from './confirmations.actions';

export interface Confirmation {
  id: string;
  title: string;
  message: string;
  action: Action;
}

export type ConfirmationsActions = ActionType<typeof actions>;

export type ConfirmationsState = Confirmation[];

const initialConfirmationsState: ConfirmationsState = [];

export const confirmationsReducer = createReducer<
  ConfirmationsState,
  ConfirmationsActions
>(initialConfirmationsState)
  .handleAction(actions.resetConfirmations, () => [])
  .handleAction(
    actions.addConfirmation,
    (state, { payload: { title, message, action } }) => [
      ...state,
      {
        id: uuidv4(),
        title,
        message,
        action,
      },
    ],
  )
  .handleAction(actions.dismissConfirmation, (state, action) =>
    state.filter((item) => item.id !== action.payload),
  );
