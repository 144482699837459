export const INIT_QUEST_STATUSES = 'INIT QUEST STATUSES';
export const UPDATE_QUEST_STATUS = 'UPDATE QUEST STATUS';

export const GET_ARTIFACT = 'GET ALIENT ARTIFACT';
export const RETURN_ARTIFACT = 'RETURN ALIENT ARTIFACT';

export const GET_MEDICINE = 'GET MEDICINE FOR JAPORI';
export const DELIVER_MEDICINE = 'DELIVER MEDICINE';

export const GET_JAREK_ON_BOARD = 'GET JAREK ON BOARD';
export const DELIVER_JAREK = 'DELIVER JAREK';

export const EXPERIMENT_STARTED = 'EXPERIMENT STARTED';
export const EXPERIMENT_ONGOING = 'EXPERIMENT IS ONGOING';
export const EXPERIMENT_STOPPED = 'EXPERIMENT STOPPED';
export const EXPERIMENT_PERFORMED = 'EXPERIMENT PERFORMED';
