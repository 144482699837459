import { pipe } from 'rxjs';
import { selectCurrentTravelEncounters } from './current-travel-encounters.selector';
import { mapEntitiesToArray } from '../../../entities';
import { SURRENDER } from '../../../../app/data/interaction/action-type';
import { PIRATE, PLAYER } from '../../../../app/data/interaction/actor-type';

export const selectIsRaided = pipe(
  selectCurrentTravelEncounters,
  mapEntitiesToArray,
  (encounters) =>
    encounters.some(
      (encounter) =>
        encounter.type === PIRATE &&
        mapEntitiesToArray(encounter.log).some(
          (event) => event.actor === PLAYER && event.action === SURRENDER,
        ),
    ),
);
