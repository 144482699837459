import { PlayerTotalCargoBays } from '../../../app/core/ship/cargo/total-cargo-bays';
import { FilledCargoBays } from '../../../app/core/ship/cargo/filled-cargo-bays';
import {
  selectPlayerCargo,
  selectPlayerShip,
} from '../../../store/game/ship/ship.selectors';
import { useSelector } from 'react-redux';
import { selectQuestStatusState } from '../../../store/game/questStatus/quest-status.selectors';
import { CargoTable } from './CargoTable';
import { CargoContent } from './CargoContent';
import React from 'react';
import { GADGETS } from '../../../app/config/equipment.configs';
import { eqByLength } from '../../../app/utils';

export const PlayerCargoContainer = () => {
  const questStatus = useSelector(selectQuestStatusState);
  const ship = useSelector(selectPlayerShip, (ship1, ship2) => {
    return (
      eqByLength(ship1.equipment[GADGETS], ship2.equipment[GADGETS]) &&
      eqByLength(ship1.cargo, ship2.cargo)
    );
  });
  const shipCargo = useSelector(selectPlayerCargo, eqByLength);
  const total = PlayerTotalCargoBays(questStatus, ship);
  const filled = FilledCargoBays(shipCargo);

  return (
    <CargoContent total={total} filled={filled}>
      <CargoTable cargo={shipCargo} />
    </CargoContent>
  );
};
