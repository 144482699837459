import { System } from '../../data/types/system/system';
import {
  NO_SPECIAL_RESOURCES,
  Resource,
  resources,
} from '../../data/types/system/special-resource';
import { getSeedRandom } from '../../utils';
import { getSystemUniqueId } from './system-unique-id';
import memoize from 'fast-memoize';

export const getSystemResource = memoize(
  (system: System): Resource => {
    const randomize = getSeedRandom(getSystemUniqueId(system));
    if (randomize(0, 5) <= 3) {
      return NO_SPECIAL_RESOURCES;
    }

    const specialResources = resources.filter(
      (resource) => resource !== NO_SPECIAL_RESOURCES,
    );
    const resourceIndex = randomize(0, specialResources.length - 1);
    return specialResources[resourceIndex];
  },
);
