import seedrandom from 'seedrandom';
import { IShip } from './data/types/ship/ship';
import { IStatus } from './data/types/game/status';
import { System } from './data/types/system/system';
import { mercenaryPayment } from './core/mercenaries/mercenary-payment';
import { Settings } from './data/types/game/settings';
import { getInsurancePayment } from './core/ship/ship.prices';
import { WeaponType } from './data/types/ship/weapon/weapon-type';
import { hasFreeSlots } from './core/equipment/equipment';
import { GADGETS, SHIELDS, WEAPONS } from './config/equipment.configs';
import { weaponConfigs } from './config/weapon/weapon.configs';
import { add, eqBy, prop, length } from 'ramda';
import { getWeaponConfig } from './config/weapon/get-weapon-config';
import { wormHoleTax } from './core/wormholes/wormhole-tax';

export function eqByLength<T extends unknown>(
  a1: readonly T[],
  a2: readonly T[],
) {
  return eqBy(length, a1, a2);
}

export function isChance(chance: number): boolean {
  if (chance < 0 || chance > 1) {
    return false;
  }
  const limit = 10 - chance * 10;
  return getRandom(0, 10) > limit;
}

export function getRandom(
  min: number,
  max: number,
  randomizer = Math.random,
): number {
  const random = Math.floor(randomizer() * (max - min + 1)) + min;
  return random;
}

export const getSeedRandom = (seed: string) => (min: number, max: number) =>
  getRandom(min, max, seedrandom(seed));

export function SQR(a: number): number {
  return a * a;
}

export function RandomSkill(): number {
  return getRandom(1, 12);
}

// TODO: pay interest
export function payInterest(status: IStatus, credits: number) {
  let IncDebt: number;

  if (status.debt > 0) {
    IncDebt = Math.max(1, status.debt / 10);

    if (credits > IncDebt) {
      // status.credits -= IncDebt;
    } else {
      // status.debt += IncDebt - status.credits;
      // status.credits = 0;
    }
  }
}

// *************************************************************************
// Calculate total possible weapon strength
// Modified to allow an upper and lower limit on which weapons work.
// Weapons equal to or between minWeapon and maxWeapon (e.g., PULSE_LASER_WEAPON)
// will do damage. Use -1 to allow damage from any weapon, which is almost
// always what you want. SjG
// *************************************************************************
export const getTotalWeaponsPower = (ship: IShip): number =>
  ship.equipment[WEAPONS].map(getWeaponConfig)
    .map(prop('power'))
    .reduce(add, 0);

export const getWeaponPower = (ship: IShip, weaponType: WeaponType): number =>
  ship.equipment[WEAPONS].filter((item) => item.type === weaponType).reduce(
    (sum, item) => sum + weaponConfigs[item.type].power,
    0,
  );

export const getWeaponsPower = (
  ship: IShip,
  weaponTypes: WeaponType[],
): number =>
  ship.equipment[WEAPONS].filter((item) => weaponTypes.includes(item.type))
    .map((item) => weaponConfigs[item.type].power)
    .reduce(add, 0);

export const AnyEmptySlots = (ship: IShip): boolean => {
  const hasFreeSlotsConfigured = hasFreeSlots(ship);
  const weaponsSlots = hasFreeSlotsConfigured(WEAPONS);
  const shieldSlots = hasFreeSlotsConfigured(SHIELDS);
  const gadgetSlots = hasFreeSlotsConfigured(GADGETS);
  return weaponsSlots || shieldSlots || gadgetSlots;
};

// *************************************************************************
// Money available to spend
// *************************************************************************
export const ToSpend = (
  settings: Settings,
  status: IStatus,
  credits: number,
  ship: IShip,
): number => {
  if (!settings.reserveMoney) {
    return credits;
  }
  return Math.max(
    0,
    credits - mercenaryPayment(ship) - getInsurancePayment(ship, status),
  );
};

export const costOfTravel = (status: IStatus) => (
  systemA: System,
  systemB: System,
  ship: IShip,
) =>
  getInsurancePayment(ship, status) +
  mercenaryPayment(ship) +
  (status.debt > 0 ? Math.max(status.debt / 10, 1) : 0) +
  wormHoleTax(systemA, systemB, ship);
