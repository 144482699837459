import React from 'react';
import { IonCol, IonIcon, IonText } from '@ionic/react';

export const SlotIndicator: React.FC<{
  title: string;
  value: number;
  max: number;
  icon: string;
  compress?: number;
}> = ({ title, value, max, icon, compress = 1 }) => {
  const displayValue = Math.round(value / compress);
  const displayMax = Math.round(max / compress);
  const emptyPoints = displayMax - displayValue;

  return (
    <IonCol>
      <IonText>
        <h2>{title}</h2>
      </IonText>
      <IonText>
        <h1>
          {Array(displayValue)
            .fill(0)
            .map((_, index) => (
              <IonIcon key={index} icon={icon} />
            ))}
          {emptyPoints > 0 &&
            Array(emptyPoints)
              .fill(0)
              .map((_, index) => (
                <IonIcon key={index} icon={icon} color="light" />
              ))}
        </h1>
      </IonText>
    </IonCol>
  );
};
