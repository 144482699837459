import { prop } from 'ramda';
import { pipe } from 'rxjs';
import { selectGame } from '../../selectors';

export const selectBalanceAlerts = pipe(selectGame, prop('balanceAlerts'));

export const selectFirstBalanceAlert = pipe(
  selectBalanceAlerts,
  (balanceAlerts) => balanceAlerts[0] || null,
);
