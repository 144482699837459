export const ANARCHY = 'Anarchy';
export const CAPITALISM = 'Capitalist State';
export const COMMUNISM = 'Communist State';
export const CONFEDERACY = 'Confederacy';
export const CORPORATE = 'Corporate State';
export const CYBERNETIC = 'Cybernetic State';
export const DEMOCRACY = 'Democracy';
export const DICTATORSHIP = 'Dictatorship';
export const FASCISM = 'Fascist State';
export const FEUDALISM = 'Feudal State';
export const MILITARISM = 'Military State';
export const MONARCHISM = 'Monarchy';
export const PACIFISM = 'Pacifist State';
export const SOCIALISM = 'Socialist State';
export const SATORI = 'State of Satori';
export const TECHNOCRACY = 'Technocracy';
export const THEOCRACY = 'Theocracy';

export const politics = [
  ANARCHY,
  CAPITALISM,
  COMMUNISM,
  CONFEDERACY,
  CORPORATE,
  CYBERNETIC,
  DEMOCRACY,
  DICTATORSHIP,
  FASCISM,
  FEUDALISM,
  MILITARISM,
  MONARCHISM,
  PACIFISM,
  SOCIALISM,
  SATORI,
  TECHNOCRACY,
  THEOCRACY,
] as const;

export type PoliticType = typeof politics[number];
