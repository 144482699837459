import { CHANCEOFTRADEINORBIT } from './constants';
import { CHANCEOFVERYRAREENCOUNTER } from './constants/very-rare-encounters';
import { NORMAL } from './constants/difficulty';
import { IScores } from './types/game/scores';
import {
  QuestStatus,
  MONSTER_QUEST,
  DRAGONFLY_QUEST,
  JAPORI_DESEASE_QUEST,
  JAREK_QUEST,
  INVASION_QUEST,
  EXPERIMENT_QUEST,
  WILD_QUEST,
  REACTOR_QUEST,
  SCARAB_QUEST,
  ALIEN_ARTIFACT_QUEST,
} from './types/game/quest-status';
import { IStatus } from './types/game/status';
import { Settings } from './types/game/settings';
import { shipConfigs } from '../config/ship/ship.configs';
import { SPACE_MONSTER_SHIP } from './types/ship/ship-type';
import { PIRATE, POLICE, TRADER } from './interaction/actor-type';

export const defaultScores: IScores = {
  policeRecords: [],
  reputationRecords: [],
  cheatCounter: 0,
};

export const defaultQuestStatus: QuestStatus = {
  // delivery quests
  // cargo
  [JAPORI_DESEASE_QUEST]: 0,
  [ALIEN_ARTIFACT_QUEST]: 0,
  // members
  [JAREK_QUEST]: 0,
  [WILD_QUEST]: 0,

  // counting days quests
  [EXPERIMENT_QUEST]: 0,
  [REACTOR_QUEST]: 0, // Unstable Reactor Status 0=not encountered; 1-20=days of mission (bays of fuel left = 10 - (ReactorStatus/2); 21=delivered
  [INVASION_QUEST]: 0, // Status Alien invasion of Gemulon; 0=not given yet; 1-7=days from start; 8=too late

  // destroy quests
  [MONSTER_QUEST]: 0, // 0 = Space monster isn't available, 1 = Space monster is in Acamar system, 2 = Space monster is destroyed
  [DRAGONFLY_QUEST]: 0, // 0 = Dragonfly not available, 1 = Go to Baratas, 2 = Go to Melina, 3 = Go to Regulas, 4 = Go to Zalkon, 5 = Dragonfly destroyed
  [SCARAB_QUEST]: 0, // Scarab: 0=not given yet, 1=not destroyed, 2=destroyed, upgrade not performed, 3=destroyed, hull upgrade performed
};

export const defaultStatus: IStatus = {
  difficulty: NORMAL, // Difficulty level
  debt: 0, // Current Debt
  days: 0, // Number of days playing
  moonBought: false, // Indicates whether a moon is available at Utopia
  escapePod: false, // Escape Pod in ship
  insurance: false, // Insurance bought
  justLootedMarie: false, // flag to indicate whether player looted Marie Celeste
  noClaim: 0, // Days of No-Claim
  fabricRipProbability: 0, // if Experiment = 8, this is the probability of being warped to a random planet.
  possibleToGoThroughRip: false, // if Dr Fehler's experiment happened, we can only go through one space-time rip per warp.
  lastPaidNewspaperDay: -1, // once you buy a paper on a certain day, you don't have to pay again.
  litterWarning: false, // Warning against littering has been issued.
  monsterHull: shipConfigs[SPACE_MONSTER_SHIP].hullStrength, // Hull strength of monster
  trackedSystem: -1, // The short-range chart will display an arrow towards this system if the value is not -1
  homeSystem: -1,
};

export const defaultSettings: Settings = {
  // the next two values are NOT saved between sessions -- they can only be changed via cheats.
  chanceOfVeryRareEncounter: CHANCEOFVERYRAREENCOUNTER,
  chanceOfTradeInOrbit: CHANCEOFTRADEINORBIT,
  // normal user settings
  leaveEmpty: 0, // Number of cargo bays to leave empty when buying goods
  autoFuel: false, // Automatically get a full tank when arriving in a new system
  autoRepair: false, // Automatically get a full hull repair when arriving in a new system
  autoIgnore: [PIRATE, POLICE, TRADER],
  alwaysIgnoreTradeInOrbit: false, // Automatically ignores Trade in Orbit when it is safe to do so
  reserveMoney: false, // Keep enough money for insurance and mercenaries
  priceDifferences: false, // Show price differences instead of absolute prices
  apLscreen: false, // Is true is the APL screen was last shown after the SRC
  tribbleMessage: false, // Is true if the Ship Yard on the current system informed you about the tribbles
  alwaysInfo: false, // Will always go from SRC to Info
  textualEncounters: false, // Show encounters as text.
  autoAttack: false, // Auto-attack mode
  autoFlee: false, // Auto-flee mode
  continuous: false, // Continuous attack/flee mode
  attackIconStatus: false, // Show Attack Star or not
  attackFleeing: false, // Continue attack on fleeing ship
  newsAutoPay: false, // by default, ask each time someone buys a newspaper
  showTrackedRange: true, // display range when tracking a system on Short Range Chart
  trackAutoOff: true, // Automatically stop tracking a system when you get to it?
  remindLoans: true, // remind you every five days about outstanding loan balances
  sharePreferences: true, // Share preferences between switched games.
  identifyStartup: false, // Identify commander at game start
};
