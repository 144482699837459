import { IonCardContent, IonCard, IonText } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { System } from '../../../../../app/data/types/system/system';
import {
  getSystemPiratesActivity,
  getSystemPoliceActivity,
  getSystemPoliticsName,
  getSystemResourcesName,
  getSystemSizeName,
  getSystemStatusInfo,
  getSystemTechLevelName,
  getSystemTradersActivity,
} from '../../../../../app/ui/solar-system.labels';
import {
  selectAmountOfVisits,
  selectIsVisited,
} from '../../../../../store/game/travel/travel.selectors';
import { selectCurrentDay } from '../../../../../store/game/status/status.selectors';

export const InfoTab: React.FC<{ system: System }> = ({ system }) => {
  const isVisited = useSelector(selectIsVisited(system.nameIndex));
  const visits = useSelector(selectAmountOfVisits(system.nameIndex));
  const currentDay = useSelector(selectCurrentDay);
  return (
    <IonCard className="ion-no-margin">
      <IonCardContent className="ion-no-padding">
        <IonText>
          <h2>
            This is a{' '}
            <span className="ion-text-lowercase">
              {getSystemSizeName(system)} {getSystemTechLevelName(system)}{' '}
              {getSystemPoliticsName(system)} system and it is{' '}
              {getSystemStatusInfo(currentDay, system)}
            </span>
            .
          </h2>
          <div className="ion-margin-top">
            <h2>
              {isVisited && (
                <>
                  {getSystemResourcesName(system)} is a special thing of this
                  system.&nbsp;
                </>
              )}
            </h2>
            <h2>
              There are{' '}
              <span className="ion-text-lowercase">
                {getSystemPiratesActivity(system)} pirates,{' '}
                {getSystemPoliceActivity(system)} police and{' '}
                {getSystemTradersActivity(system)} traders ships.
              </span>
            </h2>
          </div>
          <div className="ion-margin-top">
            {!isVisited && <h2>You have not visited this system before.</h2>}
            {isVisited && (
              <h2>
                You have visited this system{' '}
                {visits === 1 ? 'once' : `{times} times`} before.
              </h2>
            )}
          </div>
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};
