export const INIT_SOLAR_SYSTEMS = '[Solar systems] init';
export const INCREASE_TRADE_ITEM_AMOUNT =
  '[Solar systems] increase trade item amount';
export const REDUCE_TRADE_ITEM_AMOUNT =
  '[Solar systems] reduce trade item amount';
export const UPDATE_TRADE_QUANTITIES =
  '[Solar systems] update trade item quantities';
export const UPDATE_SYSTEM = '[Solar systems] update system';
export const SET_SPECIAL_EVENT = '[Solar systems] set special event';
export const DISPOSE_SPECIAL_EVENT = '[Solar systems] dispose special event';
