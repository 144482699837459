import { ShipType } from '../../../data/types/ship/ship-type';
import { IWeapon } from '../../../data/types/ship/weapon/weapon';
import { makeWeapon } from '../../../factories/ship-equipment';
import { weapons } from '../../../data/types/ship/weapon/weapon-type';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { WEAPONS } from '../../../config/equipment.configs';
import { HARD } from '../../../data/constants/difficulty';
import { getRandom } from '../../../utils';
import { weaponConfigs } from '../../../config/weapon/weapon.configs';
import { getEquipmentType } from './get-equipment';

export const getOpponentWeapon = (
  opponentType: ShipType,
  tries: number,
  difficulty: number,
): IWeapon[] => {
  const weaponSlots = shipConfigs[opponentType].equipmentSlots[WEAPONS];
  if (weaponSlots < 1) {
    return [];
  }

  let weaponsToFill = weaponSlots;
  if (weaponSlots > 1 && difficulty <= HARD) {
    weaponsToFill = 1 + getRandom(0, weaponSlots);
    if (weaponsToFill < weaponSlots) {
      if (tries > 4 && difficulty >= HARD) {
        weaponsToFill += 1;
      } else if (tries > 3 || difficulty >= HARD) {
        weaponsToFill += getRandom(0, weaponSlots - weaponsToFill);
      }
    }
  }

  return Array(weaponsToFill)
    .fill(0)
    .map(() => makeWeapon(getEquipmentType(weaponConfigs, weapons, tries)));
};
