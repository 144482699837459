import { isSeriousPirateHullDamage } from '../../fight/damage';
import {
  EncounterActionType,
  FLEE,
  SURRENDER,
} from '../../../../data/interaction/action-type';
import { STRONGEST_SHIP } from '../../../../data/constants/ship-types';
import { IShip } from '../../../../data/types/ship/ship';
import { InteractionEvent } from '../../../../data/interaction/interaction-event';
import { getLastEvent } from '../../interaction';
import { PIRATE, PLAYER } from '../../../../data/interaction/actor-type';
import { isGreaterByType } from '../../../ship/compare';
import { DamageEvent } from '../../../../data/interaction/damage-event';
import { Entities } from '../../../../../store/entities';
import { getRandom } from '../../../../utils';

export const getPirateAttackReaction = (
  opponentActions: Entities<InteractionEvent>,
  damageLog: DamageEvent[],
  pirateShip: IShip,
  playerShip: IShip,
): EncounterActionType => {
  const lastOpponentAction = getLastEvent(opponentActions).action;
  const opponentDamages = damageLog.filter((item) => item.actor === PIRATE);
  const playerDamages = damageLog.filter((item) => item.actor === PLAYER);

  if (
    !(
      opponentDamages.length &&
      isSeriousPirateHullDamage(
        pirateShip,
        opponentDamages.slice(-1)[0].damage as number,
      )
    )
  ) {
    return lastOpponentAction;
  }

  if (!playerDamages.length) {
    return FLEE;
  }

  if (
    !isSeriousPirateHullDamage(
      playerShip,
      playerDamages.slice(-1)[0].damage as number,
    )
  ) {
    if (
      getRandom(0, 10) > 8 &&
      isGreaterByType(STRONGEST_SHIP, pirateShip.type)
    ) {
      return SURRENDER;
    }
    return FLEE;
  }

  if (getRandom(0, 10) > 3) {
    return FLEE;
  }

  return lastOpponentAction;
};
