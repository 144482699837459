import { pipe } from 'rxjs';
import { GameState } from '../..';
import { mergeBalance } from './utils/merge-balance';
import {
  findHighestBalance,
  reduceBalance,
} from './utils/find-highest-balance';
import { selectGame } from '../../selectors';
import { prop } from 'ramda';

export const selectBalance = pipe(selectGame, prop('balance'));

export const selectMergedBalance = pipe(selectBalance, mergeBalance);

export const selectHighestBalance = pipe(selectBalance, findHighestBalance);

export const selectCurrentBalance = pipe(selectBalance, reduceBalance);
