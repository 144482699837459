import { converge } from 'ramda';
import { getBounty } from '../../../../app/core/encounter/values/bounty';
import {
  ATTACKPOLICESCORE,
  ATTACKTRADERSCORE,
} from '../../../../app/data/constants/police-record';
import {
  CLEANSCORE,
  CRIMINALSCORE,
  DUBIOUSSCORE,
} from '../../../../app/data/constants/police-record-score';
import { shipTypes } from '../../../../app/data/types/ship/ship-type';
import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { selectDifficulty } from '../../status/status.selectors';
import {
  selectEncounterType,
  selectOpponentShip,
} from '../encounter.selectors';
import { getFleeFromInspectionChangeScore } from './karma.utils';
import { IShip } from '../../../../app/data/types/ship/ship';

export const selectBounty = converge(getBounty, [
  selectDifficulty,
  selectOpponentShip,
]);

export const selectReputationScoreChange = converge(
  (type: string, ship: IShip) => {
    return [type, 1 + (shipTypes.indexOf(ship.type) >> 1)] as const;
  },
  [selectEncounterType, selectOpponentShip],
);

export const selectFleeFromInspectionChangeScore = converge(
  getFleeFromInspectionChangeScore,
  [selectPoliceRecordScore, selectDifficulty],
);

export const selectPoliceAttackChangeScore = converge(
  (policeRecordScore: number) => {
    if (policeRecordScore > CRIMINALSCORE) {
      return CRIMINALSCORE - policeRecordScore;
    }

    return ATTACKPOLICESCORE;
  },
  [selectPoliceRecordScore],
);

export const selectTraderAttackChangeScore = converge(
  (policeRecordScore: number) => {
    if (policeRecordScore >= CLEANSCORE) {
      return DUBIOUSSCORE - policeRecordScore;
    }

    return ATTACKTRADERSCORE;
  },
  [selectPoliceRecordScore],
);
