import React from 'react';
import { IonToast } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFirstBalanceAlert } from '../../store/game/balance-alerts/balance-alerts.selector';
import { disposeBalanceAlert } from '../../store/game/balance-alerts/balance-alerts.actions';

export const BalanceAlertsCenter: React.FC = () => {
  const dispatch = useDispatch();
  const balanceAlert = useSelector(selectFirstBalanceAlert);
  if (balanceAlert) {
    return (
      <IonToast
        isOpen
        color={balanceAlert.color}
        position="top"
        duration={3000}
        key={balanceAlert.id}
        header={balanceAlert.header}
        message={balanceAlert.message}
        onDidDismiss={() => dispatch(disposeBalanceAlert(balanceAlert.id))}
      />
    );
  }

  return <></>;
};
