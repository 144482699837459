import { of, pipe } from 'rxjs';
import { Epic } from 'redux-observable';
import {
  filter,
  map,
  mapTo,
  pluck,
  switchMap,
  switchMapTo,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GameActions } from '../..';
import {
  changeEncounterTurn,
  damageOpponentShipHull,
  damageEvent,
  declineEncounter,
  encounter,
  encounterEvent,
  initEncounter,
  move,
} from '../travel/travel.actions';
import { pirateEpics } from './opponents/pirate/pirate.epics';
import { selectEncounterType } from './encounter.selectors';
import { damageShipHull, damageShipShields } from '../ship/ship.actions';
import { karmaEpics } from './karma/karma.epics';
import { policeEpics } from './opponents/police/police.epics';
import {
  onDamageEvent,
  onEncounterEvent,
  whenEncounterIsOver,
  whenOpponentDead,
  whenPlayerAlive,
  whenPlayerDead,
  whenTravelling,
} from './opponents/encounter.pipe-operators';
import {
  canEscape,
  canReceiveDamage,
} from '../../../app/data/interaction/abilities';
import { mapState } from '../../operators/map-state';
import { traderEpics } from './opponents/trader/trader.epics';
import { PLAYER } from '../../../app/data/interaction/actor-type';
import {
  ATTACK,
  DEAD,
  ESCAPE,
  FLEE,
  IGNORE,
  MUTUAL_IGNORE,
} from '../../../app/data/interaction/action-type';
import { addNotification } from '../notifications/notifications.actions';
import { endGame } from '../common/common.actions';
import { KILLED } from '../../../app/data/types/end-game-status';
import {
  makeOpponentEscapingEpic,
  makeOpponentReceivingDamageEpic,
} from './opponents/opponent.epics.factories';
import { isFiniteAction } from '../../../app/core/encounter/interaction';
import { makeActionEvent } from './make-action-event';
import { TravelActions } from '../travel/travel.reducer';
import { selectGetOpponentShip } from './selectors/get-opponent-ship.selector';
import { selectReceivedPlayerDamage } from './selectors/received-damage.selectors';
import { selectIsPlayerEscaped } from './selectors/is-escaped.selectors';
import { selectActorDamage } from './selectors/actor-damage.selector';
import { whenInState } from '../../operators/when-in-state';
import { selectEncounterLog } from './selectors/encounter-events.selector';
import { addToast } from '../toasts/toasts.actions';
import { selectIncomingEncounterDecision } from './selectors/incoming-encounter-decision.selector';
import { prop } from 'ramda';

const initEncounterEpic: Epic<GameActions, TravelActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(encounter)),
    withLatestFrom(state$),
    map(
      ([action, state]) =>
        [action.payload, selectGetOpponentShip(state)] as const,
    ),
    map(([type, generateOpponent]) => [type, generateOpponent(type)] as const),
    map(([type, ship]) => initEncounter({ type, ship })),
  );

const acceptOnInit: Epic<TravelActions, TravelActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    whenInState(
      pipe(selectEncounterLog, (log) => Object.keys(log).length === 1),
    )(state$),
    mapState(selectIncomingEncounterDecision)(state$),
    map((decision) => decision),
  );

const changeTurnEpic: Epic<TravelActions, TravelActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    pluck('payload'),
    pluck('action'),
    filter((action) => !isFiniteAction(action) && action !== FLEE),
    mapTo(changeEncounterTurn()),
  );

const playerEscapingEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PLAYER, FLEE),
    mapState(selectIsPlayerEscaped)(state$),
    switchMap((escaped) => {
      if (escaped) {
        return of(null).pipe(
          mapState(selectEncounterType)(state$),
          switchMap((encounterType) => [
            makeActionEvent(PLAYER)(ESCAPE),
            move(),
            addToast({
              header: 'Escaped',
              message: `You managed to escape ${encounterType}`,
            }),
          ]),
        );
      }
      return of(changeEncounterTurn());
    }),
  );

const playerReceiveDamageEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onDamageEvent(PLAYER),
    mapState(selectReceivedPlayerDamage)(state$),
    filter(([hullDamage, shieldDamage]) => hullDamage + shieldDamage > 0),
    switchMap(([hullDamage, shieldDamage]) =>
      of(damageShipHull(hullDamage), damageShipShields(shieldDamage)),
    ),
  );

const deadOpponentEpic: Epic<TravelActions, TravelActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(damageOpponentShipHull)),
    whenOpponentDead(state$),
    mapState(selectEncounterType)(state$),
    map((actor) => makeActionEvent(actor)(DEAD)),
  );

const checkWinPlayerOnDeadOpponent: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    filter((action) => action.payload.action === DEAD),
    whenPlayerAlive(state$),
    mapTo(
      addNotification({
        title: 'You win',
        message: 'You killed your opponent',
        action: move(),
      }),
    ),
  );

const deadPlayerEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(damageShipHull)),
    whenPlayerDead(state$),
    switchMapTo([
      makeActionEvent(PLAYER)(DEAD),
      addNotification({
        title: 'Game over',
        message: 'You are killed',
        action: endGame(KILLED),
      }),
    ]),
  );

const attackEpic: Epic<TravelActions, TravelActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(encounterEvent)),
    filter((action) => action.payload.action === ATTACK),
    mapState(selectActorDamage)(state$),
    map(([actor, damage]) => damageEvent({ actor, damage })),
  );

const mutualIgnoringEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PLAYER, MUTUAL_IGNORE),
    whenEncounterIsOver(state$),
    whenTravelling(state$),
    mapTo(declineEncounter()),
  );

const moveOnDeclineEncounter: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(filter(isActionOf(declineEncounter)), mapTo(move()));

export const encounterEpics = [
  ...karmaEpics,
  initEncounterEpic,
  acceptOnInit,

  // order of those 2 epics matters
  // when player attacks, enemy immediately attacks back, so it triggers enemy attack
  // attackEpic gets last action from log, which is enemy attack now and player receives double damage
  attackEpic,

  ...canEscape.map(makeOpponentEscapingEpic),
  ...canReceiveDamage.map(makeOpponentReceivingDamageEpic),

  deadOpponentEpic,

  playerReceiveDamageEpic,
  playerEscapingEpic,
  deadPlayerEpic,

  checkWinPlayerOnDeadOpponent,

  ...pirateEpics,
  ...policeEpics,
  ...traderEpics,
  mutualIgnoringEpic,
  moveOnDeclineEncounter,
  changeTurnEpic,
];
