import { EquipmentItemType } from '../equipment/equipment';

export const EXTRA_BAYS = '5 extra cargo bays';
export const AUTO_REPAIR_SYSTEM = 'Auto-repair system'; // Increases engineer's effectivity
export const NAVIGATING_SYSTEM = 'Navigating system'; // Increases pilot's effectivity
export const TARGETING_SYSTEM = 'Targeting system'; // Increases fighter's effectivity
export const CLOAKING_DEVICE = 'Cloaking device'; // If you have a good engineer, nor pirates nor police will notice you
export const FUEL_COMPACTOR = 'Fuel compactor';

export const gadgets = [
  EXTRA_BAYS,
  AUTO_REPAIR_SYSTEM,
  NAVIGATING_SYSTEM,
  TARGETING_SYSTEM,
  CLOAKING_DEVICE,
  FUEL_COMPACTOR,
] as const;

export function isGadgetType(
  equipmentItemType: EquipmentItemType,
): equipmentItemType is GadgetType {
  return gadgets.some((gadgetType) => gadgetType === equipmentItemType);
}

export type GadgetType = typeof gadgets[number];
