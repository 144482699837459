import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { GameActions } from '../../../..';
import { onEncounterEvent, onInitEncounter } from '../encounter.pipe-operators';
import {
  selectBlackmailAmount,
  selectInitialPirateAction,
} from './pirate.selectors';
import { mapState } from '../../../../operators/map-state';
import { spendCredits } from '../../../balance/balance.actions';
import { addNotification } from '../../../notifications/notifications.actions';
import { PIRATE } from '../../../../../app/data/interaction/actor-type';
import {
  ATTACK,
  BLACKMAIL,
  FLEE,
  PLUNDER,
  SURRENDER,
} from '../../../../../app/data/interaction/action-type';
import { move, playerRaided } from '../../../travel/travel.actions';
import { lostCargo } from '../../../ship/ship.actions';
import { selectOpponentShip } from '../../encounter.selectors';
import { getShipConfig } from '../../../../../app/config/ship/get-ship-config';
import { makeActionEvent } from '../../make-action-event';
import { TravelActions } from '../../../travel/travel.reducer';
import { BLACKMAIL_REASON } from '../../../../../app/ui/change-balance-reasons';
import { makeOpponentReactWhenPlayerDoing } from '../opponent.epics.factories';
import { selectPlayerCargo } from '../../../ship/ship.selectors';
import { shuffle } from '../../../../../app/utils/shuffle-array';

const encountPirateEpic: Epic<TravelActions, TravelActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    onInitEncounter(PIRATE),
    mapState(selectInitialPirateAction)(state$),
    map(makeActionEvent(PIRATE)),
  );

const pirateBlackmailEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PIRATE, BLACKMAIL),
    mapState(selectBlackmailAmount)(state$),
    switchMap((amount) =>
      of(
        playerRaided(),
        spendCredits({ amount, reason: BLACKMAIL_REASON }),
        addNotification({
          title: 'Blackmailed',
          message: `Pirates didnt find any valuable cargo on your ship and decided to steal some money from you.
            They took ${amount} credits`,
          action: move(),
        }),
      ),
    ),
  );

const piratePlunderEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    onEncounterEvent(PIRATE, PLUNDER),
    mapState(selectPlayerCargo)(state$),
    map((cargo) => cargo[0]),
    switchMap((item) => [
      playerRaided(),
      lostCargo(item),
      addNotification({
        title: 'Pirate plundered you',
        message: `You lost ${item.quantity} units of ${item.type}`,
      }),
    ]),
  );

export const pirateEpics = [
  encountPirateEpic,
  makeOpponentReactWhenPlayerDoing(PIRATE, ATTACK),
  makeOpponentReactWhenPlayerDoing(PIRATE, SURRENDER),
  makeOpponentReactWhenPlayerDoing(PIRATE, FLEE),
  pirateBlackmailEpic,
  piratePlunderEpic,
];
