import { any, complement, equals, filter, pipe, prop } from 'ramda';
import { InteractionEvent } from '../../../../app/data/interaction/interaction-event';
import { ActorType, PLAYER } from '../../../../app/data/interaction/actor-type';
import { selectCurrentEncounter } from '../../travel/selectors/current-encounter.selector';
import {
  EncounterActionType,
  PLUNDER,
} from '../../../../app/data/interaction/action-type';
import {
  getLastEvent,
  hasLastActionOf,
  isGameOver,
} from '../../../../app/core/encounter/interaction';
import { getAvailableActions } from '../../../../app/data/interaction/available-actions';
import { mapArrayToEntities, mapEntitiesToArray } from '../../../entities';
import { RootState } from '../../../index';

export const selectEncounterLog = pipe(selectCurrentEncounter, prop('log'));

export const selectIsGameOver = pipe(selectEncounterLog, isGameOver);

export const selectLastEventOfCurrentEncounter = pipe(
  selectEncounterLog,
  getLastEvent,
);

export const selectLastEncounterEventActor: (state: RootState) => ActorType =
  pipe(selectLastEventOfCurrentEncounter, prop('actor'));

export const selectPlayerEvents = pipe(
  selectEncounterLog,
  mapEntitiesToArray,
  filter<InteractionEvent>(pipe(prop('actor'), equals(PLAYER))),
  (log) =>
    mapArrayToEntities<InteractionEvent>(log, (item) => item.id.toString()),
);

export const selectIsPlayerDoing = (action: EncounterActionType) =>
  pipe(selectPlayerEvents, hasLastActionOf(action));

export const selectOpponentEvents = pipe(
  selectEncounterLog,
  mapEntitiesToArray,
  filter<InteractionEvent>(pipe(prop('actor'), complement(equals(PLAYER)))),
  (log) =>
    mapArrayToEntities<InteractionEvent>(log, (item) => item.id.toString()),
);

const filterByAction =
  (action: EncounterActionType) => (list: InteractionEvent[]) =>
    list.some((event) => event.action === action);

export const selectIsOpponentDid = (action: EncounterActionType) =>
  pipe(selectOpponentEvents, mapEntitiesToArray, filterByAction(action));

export const selectLastOpponentAction = pipe(
  selectOpponentEvents,
  getLastEvent,
  prop('action'),
);

export const selectAvailableActions = pipe(
  selectOpponentEvents,
  getLastEvent,
  getAvailableActions,
);

// filter out plunder because it goes through special flow
export const selectActionsForBoard = pipe(selectAvailableActions, (actions) =>
  actions.filter((action) => action !== PLUNDER),
);

export const selectCanPlunder = pipe(selectAvailableActions, (actions) =>
  actions.includes(PLUNDER),
);
