import { IShip } from '../../../../app/data/types/ship/ship';
import { BEGINNER } from '../../../../app/data/constants/difficulty';
import { getRandom } from '../../../../app/utils';
import { PILOT_SKILL } from '../../../../app/data/types/crew-member/skill';
import { converge } from 'ramda';
import { selectDifficulty } from '../../status/status.selectors';
import { selectPlayerShip } from '../../ship/ship.selectors';
import { selectOpponentShip } from '../encounter.selectors';
import { getSkillLevel } from '../../../../app/core/crew-member/skill/skill-level';

const isOpponentEscaped = (
  difficulty: number,
  opponentShip: IShip,
  playerShip: IShip,
) => {
  const getPilotSkill = getSkillLevel(PILOT_SKILL);
  const opponentChanceToEscape =
    getRandom(0, 7 + getPilotSkill(opponentShip, difficulty) / 3) * 2;
  const playerChanceToHit =
    getRandom(0, getPilotSkill(playerShip, difficulty)) * 4;
  return opponentChanceToEscape >= playerChanceToHit;
};
const isPlayerEscaped = (
  difficulty: number,
  opponentShip: IShip,
  playerShip: IShip,
) => {
  if (difficulty === BEGINNER) {
    return true;
  }
  const getPilotSkill = getSkillLevel(PILOT_SKILL);
  const playerChanceToEscape =
    getRandom(0, 7 + getPilotSkill(opponentShip, difficulty) / 3) * 2;
  const opponentChanceToHit =
    getRandom(0, getPilotSkill(playerShip, difficulty)) * 4;

  return playerChanceToEscape >= opponentChanceToHit;
};
export const selectIsOpponentEscaped = converge(isOpponentEscaped, [
  selectDifficulty,
  selectOpponentShip,
  selectPlayerShip,
]);
export const selectIsPlayerEscaped = converge(isPlayerEscaped, [
  selectDifficulty,
  selectOpponentShip,
  selectPlayerShip,
]);
