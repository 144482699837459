import { System } from '../../data/types/system/system';
import { IShip } from '../../data/types/ship/ship';
import { getShipConfig } from '../../config/ship/get-ship-config';
import { hasWormholeWith } from './has-wormhole';

export const wormHoleTax = (
  systemA: System,
  systemB: System,
  ship: IShip,
): number => {
  if (hasWormholeWith(systemA, systemB)) {
    return getShipConfig(ship).costOfFuel * 25;
  }

  return 0;
};
