import { InteractionEvent } from './interaction-event';
import { ATTACK, EncounterActionType, MUTUAL_IGNORE } from './action-type';
import { PIRATE, POLICE, TRADER } from './actor-type';
import {
  PossiblePoliceAction,
  possibleReactionsToPolice,
} from './police-actions';
import {
  PossiblePirateAction,
  possibleReactionsToPirate,
} from './pirate-actions';
import {
  possibleReactionsToTrader,
  PossibleTraderAction,
} from './trader-actions';

export function getAvailableActions({
  actor,
  action,
}: InteractionEvent): readonly EncounterActionType[] {
  switch (actor) {
    case TRADER:
      return possibleReactionsToTrader[action as PossibleTraderAction];
    case PIRATE:
      return possibleReactionsToPirate[action as PossiblePirateAction];
    case POLICE:
      return possibleReactionsToPolice[action as PossiblePoliceAction];
    default:
      return [ATTACK, MUTUAL_IGNORE];
  }
}
