import React from 'react';
import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import EquipmentList from './Equipment/EquipmentList';
import { EquipmentType } from '../../../app/config/equipment.configs';

const BuyEquipment: React.FC<{ equipmentType: EquipmentType }> = ({
  equipmentType,
}) => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Buy {equipmentType}</IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <EquipmentList key={equipmentType} equipmentType={equipmentType} />
    </IonContent>
  </IonPage>
);

export default BuyEquipment;
