import { IShip } from './types/ship/ship';
import { GNAT } from './types/ship/ship-type';
import { PULSE_LASER_WEAPON } from './types/ship/weapon/weapon-type';
import { GADGETS, SHIELDS, WEAPONS } from '../config/equipment.configs';
import { makeWeapon } from '../factories/ship-equipment';

export const defaultShip: IShip = {
  type: GNAT,
  cargo: [],
  equipment: {
    [WEAPONS]: [makeWeapon(PULSE_LASER_WEAPON)],
    [SHIELDS]: [],
    [GADGETS]: [],
  },
  extra: [],
  commander: null,
  crew: [],
  fuel: 14,
  hull: 100,
  tribbles: 0,
};

export const defaultOpponent: IShip = {
  type: GNAT,
  cargo: [],
  equipment: {
    [WEAPONS]: [makeWeapon(PULSE_LASER_WEAPON)],
    [SHIELDS]: [],
    [GADGETS]: [],
  },
  commander: null,
  crew: [],
  extra: [],
  fuel: 14,
  hull: 100,
  tribbles: 0,
};
