import { IShip } from '../../data/types/ship/ship';
import { SHIELDS } from '../../config/equipment.configs';
import { add, map, prop } from 'ramda';
import { getShieldConfig } from '../../config/shield/get-shield-config';
import { IShield } from '../../data/types/ship/shield/shield';
import { pipe } from 'rxjs';

export function isDead(ship: IShip): boolean {
  return ship.hull < 1;
}

export function getShieldStrength(ship: IShip): number {
  return ship.equipment[SHIELDS].map(prop('strength')).reduce(add, 0);
}

export function getPossibleShieldsStrength(ship: IShip): number {
  return ship.equipment[SHIELDS].map(getPossibleShieldStrength).reduce(add, 0);
}

export const getPossibleShieldStrength = pipe(getShieldConfig, prop('power'));

export function isDamaged(
  shipBeforeDamage: IShip,
  shipAfterDamage: IShip,
): boolean {
  const isHullDamaged = shipBeforeDamage.hull !== shipAfterDamage.hull;
  const isShieldsDamaged =
    getShieldStrength(shipBeforeDamage) !== getShieldStrength(shipAfterDamage);
  return isShieldsDamaged || isHullDamaged;
}
