import {
  IonPage,
  IonHeader,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonItem,
  IonLabel,
  IonNote,
  IonToolbar,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { ScoreRecord } from '../../../app/data/types/score-record';
import {
  selectPoliceRecords,
  selectPoliceRecordScore,
} from '../../../store/game/scores/scores.selectors';

function getPoliceRecordColor(record: ScoreRecord): string {
  return record.score < 0 ? 'danger' : 'success';
}

const PoliceRecords: React.FC = () => {
  const policeRecords = useSelector(selectPoliceRecords);
  const policeRecordScore = useSelector(selectPoliceRecordScore);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Police records</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardContent>
            {policeRecords.map((record) => (
              <IonItem key={record.index}>
                <IonLabel>{record.reason}</IonLabel>
                <IonNote color={getPoliceRecordColor(record)} slot="end">
                  {record.score}
                </IonNote>
              </IonItem>
            ))}
            <IonItem>
              <IonLabel>Current score</IonLabel>
              <IonNote slot="end">{policeRecordScore}</IonNote>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PoliceRecords;
