import { IonCol, IonText } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectEncounterClick,
  selectEncounterShipType,
  selectEncounterType,
} from '../../store/game/travel/selectors/encounters.selectors';
import { ShipAvatar } from '../Ship/ShipAvatar/ShipAvatar';

export const EncounterBadge: React.FC<{
  encounterId: string;
  travelId: string;
}> = ({ encounterId, travelId }) => {
  const shipType = useSelector(selectEncounterShipType(encounterId, travelId));
  const click = useSelector(selectEncounterClick(encounterId, travelId));
  const type = useSelector(selectEncounterType(encounterId, travelId));
  return (
    <IonCol size="auto" className="ion-align-self-center ion-text-center">
      <ShipAvatar shipType={shipType} />
      <div style={{ marginTop: '0.7rem' }}>
        <h4>{click} clicks</h4>
        <IonText color="dark">
          <h2>{type}</h2>
        </IonText>
      </div>
    </IonCol>
  );
};
