import { pipe } from 'rxjs';
import { selectReputationScore } from '../../scores/scores.selectors';
import { AVERAGESCORE } from '../../../../app/data/constants/reputation';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { SCARAB_STOLEN } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';

export const selectScarabStolenAction = pipe(
  selectReputationScore,
  (reputationScore) => {
    const { title } = getSpecialEventConfig(SCARAB_STOLEN);
    if (reputationScore < AVERAGESCORE) {
      return addNotification({
        title,
        message: 'Your reputation is too low',
      });
    }
    return invokeSpecialEvent(SCARAB_STOLEN);
  },
);
