import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  selectBaseEquipmentPrice,
  selectPlayerShip,
} from '../store/game/ship/ship.selectors';
import {
  hasEquipment,
  isEquipmentAvailable,
} from '../app/core/equipment/equipment';
import { Installable } from '../app/data/types/ship/equipment/installable';
import {
  EXTRA_BAYS,
  isGadgetType,
} from '../app/data/types/ship/gadget/gadget-type';
import { EquipmentItemType } from '../app/data/types/ship/equipment/equipment';
import { selectCurrentSystem } from '../store/game/systems/selectors/current-system.selector';

const useBuyEquipment = (
  equipmentItemConfigs: Record<string, Installable>,
  equipmentItemType: EquipmentItemType,
) => {
  const system = useSelector(selectCurrentSystem);
  const playerShip = useSelector(selectPlayerShip);
  const isAvailable = isEquipmentAvailable(equipmentItemConfigs)(
    system,
    equipmentItemType,
  );
  const price = useSelector(selectBaseEquipmentPrice(equipmentItemType));
  const alreadyInstalled =
    isGadgetType(equipmentItemType) &&
    equipmentItemType !== EXTRA_BAYS &&
    hasEquipment(playerShip, equipmentItemType);
  const isDisabled = !isAvailable || alreadyInstalled;
  const getPurchaseLabel = useCallback(() => {
    if (alreadyInstalled) {
      return 'Already installed';
    }
    if (!isAvailable) {
      return 'Not available';
    }
    return 'Buy';
  }, [isAvailable, alreadyInstalled]);

  return [price, isDisabled, getPurchaseLabel] as const;
};

export default useBuyEquipment;
