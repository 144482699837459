import { shipTypes } from '../../../../data/types/ship/ship-type';
import {
  EncounterActionType,
  ATTACK,
  FLEE,
  IGNORE,
  INSPECT,
} from '../../../../data/interaction/action-type';
import { isShipBetterThanOther } from '../../../ship/compare';
import {
  CLEANSCORE,
  DUBIOUSSCORE,
  LAWFULSCORE,
} from '../../../../data/constants/police-record-score';
import { IShip } from '../../../../data/types/ship/ship';
import {
  AVERAGESCORE,
  ELITESCORE,
} from '../../../../data/constants/reputation';
import { getRandom, getTotalWeaponsPower } from '../../../../utils';
import { isShipHiddenForOpponent } from '../../../ship/is-hidden-for-opponent';

export const getInitialPoliceAction = (
  difficulty: number,
  reputationScore: number,
  policeRecordScore: number,
  isInspected: boolean,
  opponent: IShip,
  player: IShip,
): EncounterActionType => {
  const isPlayerHiddenForOpponent = isShipHiddenForOpponent(difficulty)(
    player,
    opponent,
  );
  const isOpponentHiddenForPlayer = isShipHiddenForOpponent(difficulty)(
    opponent,
    player,
  );
  const isOpponentHasBetterShip = isShipBetterThanOther(opponent, player);
  const isOpponentHasNoWeapon = getTotalWeaponsPower(opponent) < 1;

  if (isPlayerHiddenForOpponent) {
    return IGNORE;
  }

  if (policeRecordScore < DUBIOUSSCORE) {
    // If you're a criminal, the police will tend to attack
    if (isOpponentHasNoWeapon) {
      if (isOpponentHiddenForPlayer) {
        return IGNORE;
      }

      // if you're suddenly stuck in a lousy ship, Police won't flee even if you have a fearsome reputation.
      if (isOpponentHasBetterShip) {
        return INSPECT;
      }

      return FLEE;
    }

    if (reputationScore < AVERAGESCORE) {
      return ATTACK;
    }

    if (
      getRandom(0, ELITESCORE) >
      reputationScore / (1 + shipTypes.indexOf(opponent.type))
    ) {
      return ATTACK;
    }

    if (isOpponentHiddenForPlayer) {
      return IGNORE;
    }

    // if you're suddenly stuck in a lousy ship, Police won't flee even if you have a fearsome reputation.
    if (isOpponentHasBetterShip) {
      return ATTACK;
    }

    return FLEE;
  }

  if (
    policeRecordScore >= DUBIOUSSCORE &&
    policeRecordScore < CLEANSCORE &&
    !isInspected
  ) {
    return INSPECT;
  }

  if (policeRecordScore < LAWFULSCORE) {
    if (getRandom(0, 12 - difficulty) < 1 && !isInspected) {
      return INSPECT;
    }
  } else if (getRandom(0, 40) === 1 && !isInspected) {
    // If your record indicates you are a lawful trader, the chance on inspection drops to 2.5%
    return INSPECT;
  }

  return IGNORE;
};
