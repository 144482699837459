import React from 'react';
import { useSelector } from 'react-redux';
import {
  IonHeader,
  IonTitle,
  IonContent,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonPage,
  IonMenuButton,
  IonButtons,
} from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { IStatus } from '../../../app/data/types/game/status';
import { skillTypes } from '../../../app/data/types/crew-member/skill';
import { selectCurrentBalance } from '../../../store/game/balance/balance.selectors';
import {
  selectPlayerShip,
  selectPlayerName,
} from '../../../store/game/ship/ship.selectors';
import { selectStatus } from '../../../store/game/status/status.selectors';
import { selectAmountOfVisitedSystems } from '../../../store/game/travel/travel.selectors';
import { selectPlayerWorth } from '../../../store/derived/computational-selectors';
import { countableKills } from '../../../app/data/interaction/actor-type';
import { getSkillLevel } from '../../../app/core/crew-member/skill/skill-level';
import PoliceRecordProgress from './PoliceRecordProgress';
import ReputationProgress from './ReputationProgress';
import { KillCounter } from './KillCounter';
import { isDebug } from '../../../is-debug-mode';

const Commander: React.FC = () => {
  const credits = useSelector(selectCurrentBalance);
  const ship = useSelector(selectPlayerShip);
  const status = useSelector(selectStatus);
  const playerName = useSelector(selectPlayerName);
  const currentWorth = useSelector(selectPlayerWorth);
  const visitedSystems = useSelector(selectAmountOfVisitedSystems);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Commander status</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size="auto">
              <IonIcon style={{ fontSize: '10rem' }} icon={personCircle} />
            </IonCol>
            <IonCol>
              <IonText>
                <h1>{playerName}</h1>
              </IonText>
              <ReputationProgress />
              <PoliceRecordProgress />
            </IonCol>
          </IonRow>
        </IonGrid>
        {isDebug() && (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Status</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {Object.keys(status).map((key) => (
                <IonItem key={key}>
                  <IonLabel>{key}</IonLabel>
                  <IonNote slot="end">
                    {status[key as keyof IStatus].toString()}
                  </IonNote>
                </IonItem>
              ))}
            </IonCardContent>
          </IonCard>
        )}
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonLabel>Credits</IonLabel>
              <IonNote slot="end">{credits}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Debt</IonLabel>
              <IonNote slot="end">{status.debt}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Ship Worth</IonLabel>
              <IonNote slot="end">{currentWorth}</IonNote>
            </IonItem>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Galaxy</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonItem>
                <IonLabel>Days</IonLabel>
                <IonNote slot="end">{status.days}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Visited systems</IonLabel>
                <IonNote slot="end">{visitedSystems}</IonNote>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Kills</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {countableKills.map((killType) => (
                <KillCounter key={killType} killType={killType} />
              ))}
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Skills</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {skillTypes.map((skillType) => (
                <IonItem key={skillType}>
                  <IonLabel className="ion-text-capitalize">
                    {skillType}
                  </IonLabel>
                  <IonNote slot="end">
                    {getSkillLevel(skillType)(ship, status.difficulty)}
                  </IonNote>
                </IonItem>
              ))}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Commander;
