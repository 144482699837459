import { System } from '../../data/types/system/system';
import { TradeItemType } from '../../data/types/trade-item/trade-item-type';
import { getStandardPrice } from './trade-items.prices';
import { getSystemStatus } from '../solar-system/system-status';
import { getPoliticConfig } from '../../config/politic/get-politic-config';
import { getSystemTechLevel } from '../solar-system/system-tech-level';
import { getTradeItemConfig } from '../../config/trade-item/get-trade-item-config';

export const isTradeItemNotLegal = (system: System) => (
  tradeItemType: TradeItemType,
): boolean => getPoliticConfig(system).illegals.includes(tradeItemType);

export const isTradeItemProducible = (system: System) => (
  tradeItemType: TradeItemType,
): boolean =>
  getSystemTechLevel(system) >=
  getTradeItemConfig(tradeItemType).techProduction;

export const isTradeItemConsumable = (system: System) => (
  tradeItemType: TradeItemType,
): boolean =>
  getSystemTechLevel(system) >= getTradeItemConfig(tradeItemType).techUsage;

export const isTradeItemInProduction = (
  tradeItemType: TradeItemType,
  system: System,
): boolean =>
  !isTradeItemNotLegal(system)(tradeItemType) &&
  isTradeItemProducible(system)(tradeItemType);

export const isTradeItemInConsumption = (
  tradeItemType: TradeItemType,
  system: System,
): boolean =>
  !isTradeItemNotLegal(system)(tradeItemType) &&
  isTradeItemConsumable(system)(tradeItemType);

export const isTradeItemDemanded = (
  currentDay: number,
  system: System,
  tradeItemType: TradeItemType,
): boolean =>
  getSystemStatus(currentDay, system) ===
  getTradeItemConfig(tradeItemType).doublePriceStatus;

export const getPriceDifference = (
  difficulty: number,
  currentDay: number,
  currentSystem: System,
  anotherSystem: System,
) => (tradeItemType: TradeItemType): number => {
  // price to buy an item in the current system
  const priceToBuyInCurrentSystem = getStandardPrice(
    tradeItemType,
    currentSystem,
    currentDay,
  );
  const priceToSellInAnotherSystem = getStandardPrice(
    tradeItemType,
    anotherSystem,
    currentDay,
  );

  return priceToSellInAnotherSystem - priceToBuyInCurrentSystem;
};

export const getPricePercentageChange = (
  difficulty: number,
  currentDay: number,
) => (currentSystem: System, anotherSystem: System) => (
  tradeItemType: TradeItemType,
): number => {
  const priceInCurrentSystem = getStandardPrice(
    tradeItemType,
    currentSystem,
    currentDay,
  );
  return (
    (getPriceDifference(
      difficulty,
      currentDay,
      currentSystem,
      anotherSystem,
    )(tradeItemType) /
      priceInCurrentSystem) *
    100
  );
};
