import { shipTypes } from '../../../../data/types/ship/ship-type';
import { ELITESCORE } from '../../../../data/constants/reputation';
import { getRandom } from '../../../../utils';
import {
  EncounterActionType,
  ESCAPE,
  FLEE,
  IGNORE,
} from '../../../../data/interaction/action-type';
import { IShip } from '../../../../data/types/ship/ship';
import { isShipHiddenForOpponent } from '../../../ship/is-hidden-for-opponent';
import { CRIMINALSCORE } from '../../../../data/constants/police-record-score';

export const getInitialTraderAction = (
  difficulty: number,
  policeRecordScore: number,
  reputationScore: number,
  // chanceOfTrade: number,
  // questStatus: QuestStatus,
  traderShip: IShip,
  playerShip: IShip,
): EncounterActionType => {
  const isPlayerHiddenForOpponent = isShipHiddenForOpponent(difficulty)(
    playerShip,
    traderShip,
  );
  const isOpponentHiddenForPlayer = isShipHiddenForOpponent(difficulty)(
    traderShip,
    playerShip,
  );

  if (isPlayerHiddenForOpponent) {
    return IGNORE;
  }

  if (policeRecordScore <= CRIMINALSCORE) {
    if (
      getRandom(0, ELITESCORE) <=
      (reputationScore * 10) / (1 + shipTypes.indexOf(traderShip.type))
    ) {
      if (isOpponentHiddenForPlayer) {
        return ESCAPE;
      }

      return FLEE;
    }
  }

  // Will there be trade in orbit?
  // if (getRandom(0, 1000) < chanceOfTrade) {
  //   if (FilledCargoBays(playerShip) < PlayerTotalCargoBays(questStatus)(playerShip) && HasTradeableItems(&Opponent, WarpSystem, TRADERSELL)) {
  //     SELL;
  //   } else if (HasTradeableItems(&Ship, WarpSystem, TRADERBUY)) {
  //     EncounterType = TRADERBUY;
  //   }
  // }

  return IGNORE;
};
