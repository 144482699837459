import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
  withLatestFrom,
  map,
  ignoreElements,
  filter,
  mapTo,
  pluck,
  switchMapTo,
  switchMap,
} from 'rxjs/operators';
import { GameActions } from '../..';
import * as actions from './status.actions';
import { experimentOngoing } from '../questStatus/quest-status.actions';
import {
  selectIsExperimentOngoing,
  selectIsExperimentPerformed,
} from '../questStatus/quest-status.selectors';
import { earnCredits, spendCredits } from '../balance/balance.actions';
import {
  selectCurrentDay,
  selectDifficulty,
  selectInterest,
  selectNewspaperPrice,
} from './status.selectors';
import { selectGalaxy } from '../systems/systems.selectors';
import { setSystemsTradeItemQuantities } from '../systems/systems.actions';
import { generateTradeItemsQuantities } from '../../../app/init/galaxy';
import {
  buyMoon,
  buyNewspaper,
  registerNewspaperPurchase,
} from './status.actions';
import { addNotification } from '../notifications/notifications.actions';
import { MOON_COST } from '../../../app/data/constants/special-events';
import {
  BUY_NEWSPAPER_REASON,
  GET_LOAN_REASON,
  PAY_INTEREST_REASON,
  PURCHASE_MOON_REASON,
} from '../../../app/ui/change-balance-reasons';
import { mapState } from '../../operators/map-state';

const increaseOngoingExperimentDaysEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.increaseDays)),
    withLatestFrom(state$),
    filter(([, state]) => selectIsExperimentOngoing(state)),
    map(([action]) => experimentOngoing(action.payload)),
  );

const decreaseFabricRipAfterExperimentEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.increaseDays)),
    withLatestFrom(state$),
    filter(([, state]) => selectIsExperimentPerformed(state)),
    mapTo(actions.decreaseFabricRipProbability()),
  );

const loanMoneyEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.getLoan)),
    pluck('payload'),
    map((amount) => earnCredits({ reason: GET_LOAN_REASON, amount })),
  );

const newTradeItemQuantitiesForNewDayEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.increaseDays)),
    withLatestFrom(state$),
    map(
      ([, state]) =>
        [
          selectCurrentDay(state),
          selectDifficulty(state),
          selectGalaxy(state),
        ] as const,
    ),
    map(([day, difficulty, systems]) =>
      setSystemsTradeItemQuantities(
        generateTradeItemsQuantities(difficulty, day)(systems),
      ),
    ),
  );

const payInterestPerDay: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.increaseDays)),
    withLatestFrom(state$),
    map(([action, state]) => [action.payload, selectInterest(state)] as const),
    filter(([, amount]) => amount > 0),
    switchMap(([days, amount]) =>
      Array(days)
        .fill(0)
        .map(() => spendCredits({ amount, reason: PAY_INTEREST_REASON })),
    ),
  );

const increaseDaysEpic: Epic<GameActions, GameActions> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.increaseDays)),
    withLatestFrom(state$),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    map(([_action, _state]) => {
      // const status = selectStatus(state);
      // const actions = [];
      // const invasionStatus = selectQuestStatus('invasion')(state);
      // const reactorStatus = selectQuestStatus('reactor')(state);
      // if (invasionStatus > 0 && invasionStatus < 8) {
      //   actions.push(updateQuestStatus('invasion', action.payload));
      //   // to the side effect of update quest status
      //   if (invasionStatus >= 8) {
      //     actions.push(
      //       updateSystem(GEMULONSYSTEM, {
      //         special: GEMULON_INVADED,
      //         techLevel: 0,
      //         politics: ANARCHY,
      //       })
      //     )
      //   }
      // }
      // if (reactorStatus > 0 && reactorStatus < 21) {
      //   reactorStatus += Amount;
      //   if (reactorStatus > 20)
      //     ReactorStatus = 20;
      // }
    }),
    ignoreElements(),
  );

const buyMoonEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(buyMoon)),
    switchMapTo([
      spendCredits({
        amount: MOON_COST,
        reason: PURCHASE_MOON_REASON,
      }),
      addNotification({
        title: 'Moon bought',
        message:
          'You bought a moon in the Utopia system. Go there to claim it.',
      }),
    ]),
  );

const spendCreditsOnBuyNewspaper: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(buyNewspaper)),
    mapState(selectNewspaperPrice)(state$),
    map((amount) =>
      spendCredits({
        amount,
        reason: BUY_NEWSPAPER_REASON,
      }),
    ),
  );

const registerBuyNewspaperEpic: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(buyNewspaper)),
    mapState(selectCurrentDay)(state$),
    map(registerNewspaperPurchase),
  );

export const statusEpics = [
  increaseDaysEpic,
  newTradeItemQuantitiesForNewDayEpic,
  increaseOngoingExperimentDaysEpic,
  decreaseFabricRipAfterExperimentEpic,
  loanMoneyEpic,
  buyMoonEpic,
  payInterestPerDay,
  spendCreditsOnBuyNewspaper,
  registerBuyNewspaperEpic,
];
