import { EncounterActionType } from '../../../../app/data/interaction/action-type';
import { IonButton } from '@ionic/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlayerAction } from '../../../../store/game/encounter/player-actions/select-player-action';
import { AnyAction } from 'redux';

export const PlayerAction: React.FC<{ action: EncounterActionType }> = ({
  action,
}) => {
  const dispatch = useDispatch();
  const actionToDispatch = useSelector(selectPlayerAction(action));

  return (
    <IonButton
      size="large"
      onClick={() => dispatch(actionToDispatch as AnyAction)}
      className="ion-text-uppercase"
    >
      {action}
    </IonButton>
  );
};
