import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { GameActions, RootState } from '../../index';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import * as actions from './special-events.actions';
import { specialEventsActionMap } from './special-events-action-map';
import { mapState } from '../../operators/map-state';
import { disposeSpecialEvent } from '../systems/systems.actions';
import { selectSpecialEventValidationResult } from './validation/select-action';
import { selectLastVisitedSystem } from '../travel/selectors/last-visited-system.selector';
import { AnyAction } from 'redux';

const validateSpecialEventEpic: Epic<GameActions, GameActions> = (
  action$: ActionsObservable<AnyAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.validateSpecialEvent)),
    map(({ payload }) => payload),
    withLatestFrom(state$),
    map(([specialEvent, state]) =>
      selectSpecialEventValidationResult(specialEvent)(state),
    ),
  );

const invokeSpecialEventEpic: Epic<GameActions, GameActions> = (action$) =>
  action$.pipe(
    filter(isActionOf(actions.invokeSpecialEvent)),
    map((action) => specialEventsActionMap[action.payload]),
  );

const disposeSpecialEventOnInvoke: Epic<GameActions, GameActions> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(actions.invokeSpecialEvent)),
    mapState(selectLastVisitedSystem)(state$),
    map((nameIndex) => disposeSpecialEvent(nameIndex)),
  );

export const specialEventsEpics = [
  validateSpecialEventEpic,
  invokeSpecialEventEpic,
  disposeSpecialEventOnInvoke,
];
