import { pipe } from 'rxjs';
import { IGame } from '../data/types/game/game';
import {
  defaultStatus,
  defaultSettings,
  defaultScores,
  defaultQuestStatus,
} from '../data/game';
import { generateTradeItemsQuantities, initSystemData } from './galaxy';
import { findHomeSystemIndex } from './find-home-system';
import { initShip } from './ship';
import {
  initSpecialEvents,
  placeLotteryEvent,
} from '../core/quests/special-events';
import { getSystemsCoordinates } from '../core/coordinates/coordinates';
import { placeMercenaries } from './mercenaries';
import { System } from '../data/types/system/system';
import { shuffle } from '../utils/shuffle-array';
import { shuffleNames } from '../core/solar-system/shuffle-names';
import { setWormHoles } from '../core/wormholes/set-wormholes';
import { makeCrewMember } from '../factories/crew-member';
import { Skills } from '../data/types/crew-member/skill';

export function initGame(
  difficulty: number,
  playerName: string,
  playerSkills: Skills,
): IGame {
  return pipe(
    initSystemData,
    shuffleNames,
    setWormHoles,
    initSpecialEvents,
    shuffle,
    placeMercenaries,
    generateTradeItemsQuantities(difficulty, 0), // day 0
    (systems: System[]) => [systems, findHomeSystemIndex(systems)] as const,
    ([systems, homeSystemIndex]: readonly [System[], number]): IGame => ({
      systems: placeLotteryEvent(homeSystemIndex, difficulty)(systems),
      ship: {
        ...initShip(),
        commander: makeCrewMember(playerName, playerSkills),
      },
      status: {
        ...defaultStatus,
        difficulty,
        homeSystem: homeSystemIndex,
      },
      scores: defaultScores,
      questStatus: defaultQuestStatus,
      settings: defaultSettings,
    }),
  )(getSystemsCoordinates());
}
