import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './quest-status.actions';
import {
  ALIEN_ARTIFACT_QUEST,
  EXPERIMENT_QUEST,
  JAPORI_DESEASE_QUEST,
  JAREK_QUEST,
  QuestStatus,
} from '../../../app/data/types/game/quest-status';
import { defaultQuestStatus } from '../../../app/data/game';
import {
  ARTIFACT_DELIVERED_CODE,
  ARTIFACT_ON_BOARD_CODE,
  EXPERIMENT_LAST_DAY,
  EXPERIMENT_STOPPED_CODE,
  JAREK_DELIVERED_CODE,
  JAREK_ON_BOARD_CODE,
  MEDICINE_DELIVERED_CODE,
  MEDICINE_ON_BOARD_CODE,
} from '../../../app/data/constants/special-events';

export type QuestStatusActions = ActionType<typeof actions>;

const initialState: QuestStatus = defaultQuestStatus;

export const questStatusReducer = createReducer<
  QuestStatus,
  QuestStatusActions
>(initialState)
  .handleAction(actions.initQuestStatues, (_, action) => ({
    ...action.payload,
  }))
  .handleAction(actions.updateQuestStatus, (state, action) => ({
    ...state,
    [action.payload.type]: state[action.payload.type] + action.payload.amount,
  }))
  .handleAction(actions.getArtifact, (state) => ({
    ...state,
    [ALIEN_ARTIFACT_QUEST]: ARTIFACT_ON_BOARD_CODE,
  }))
  .handleAction(actions.returnArtifact, (state) => ({
    ...state,
    [ALIEN_ARTIFACT_QUEST]: ARTIFACT_DELIVERED_CODE,
  }))
  .handleAction(actions.getMedicine, (state) => ({
    ...state,
    [JAPORI_DESEASE_QUEST]: MEDICINE_ON_BOARD_CODE,
  }))
  .handleAction(actions.deliverMedicine, (state) => ({
    ...state,
    [JAPORI_DESEASE_QUEST]: MEDICINE_DELIVERED_CODE,
  }))
  .handleAction(actions.getJarek, (state) => ({
    ...state,
    [JAREK_QUEST]: JAREK_ON_BOARD_CODE,
  }))
  .handleAction(actions.deliverJarek, (state) => ({
    ...state,
    [JAREK_QUEST]: JAREK_DELIVERED_CODE,
  }))
  .handleAction(actions.experimentStarted, (state) => ({
    ...state,
    [EXPERIMENT_QUEST]: 1,
  }))
  .handleAction(actions.experimentOngoing, (state, action) => ({
    ...state,
    [EXPERIMENT_QUEST]: state[EXPERIMENT_QUEST] + action.payload,
  }))
  .handleAction(actions.experimentPerformed, (state) => ({
    ...state,
    [EXPERIMENT_QUEST]: EXPERIMENT_LAST_DAY,
  }))
  .handleAction(actions.experimentStopped, (state) => ({
    ...state,
    [EXPERIMENT_QUEST]: EXPERIMENT_STOPPED_CODE,
  }));
