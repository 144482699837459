import {
  IonItem,
  IonLabel,
  IonNote,
  IonList,
  IonCardContent,
  IonCard,
} from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { System } from '../../../../../app/data/types/system/system';
import { selectStatusHistory } from '../../../../../store/game/systems/systems.selectors';

const StatusTab: React.FC<{ system: System }> = ({ system }) => {
  const statusHistory = useSelector(selectStatusHistory(system));

  return (
    <IonCard>
      <IonCardContent>
        <IonList>
          {statusHistory.map((item) => (
            <IonItem key={item.day}>
              <IonLabel>{item.status}</IonLabel>
              <IonNote slot="start">Day {item.day}</IonNote>
            </IonItem>
          ))}
        </IonList>
      </IonCardContent>
    </IonCard>
  );
};

export default StatusTab;
