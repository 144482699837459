import { add, converge, or, prop, length, gte } from 'ramda';
import { pipe } from 'rxjs';
import { IShip } from '../../../app/data/types/ship/ship';
import {
  UPGRADED_HULL,
  UPGRADED_HULL_POINTS,
} from '../../../app/data/types/ship/equipment/extra';
import { hasIllegals } from '../../../app/core/ship/cargo/has-illegals';
import { WEAPONS } from '../../../app/config/equipment.configs';
import { getTotalWeaponsPower } from '../../../app/utils';
import {
  BaseShipPrice,
  currentShipPrice,
} from '../../../app/core/ship/ship.prices';
import { selectDifficulty } from '../status/status.selectors';
import { ShipType } from '../../../app/data/types/ship/ship-type';
import { shipConfigs } from '../../../app/config/ship/ship.configs';
import {
  hasFreeSlots,
  hasWeaponsStrongEnough,
} from '../../../app/core/equipment/equipment';
import {
  EquipmentItemType,
  getEquipmentType,
} from '../../../app/data/types/ship/equipment/equipment';
import { baseEquipmentPrice } from '../../../app/core/equipment/equipment.prices';
import { getRepairPoints } from '../../../app/core/travel/do-repairs';
import { selectGame } from '../../selectors';
import { getFuelTanks } from '../../../app/core/ship/fuel/fuel-tanks-size';
import { getCurrentFuelLevel } from '../../../app/core/ship/fuel/current-fuel-level';
import { getFuelCost } from '../../../app/core/ship/fuel/fuel-cost';
import { isOnBoard } from '../../../app/core/ship/crew/is-on-board';
import { hasEmptyCrewCabines } from '../../../app/core/ship/crew/has-empty-cabines';
import { getEmptyCargoBays } from '../../../app/core/ship/cargo/empty-cargo-bays';
import { selectQuestStatusState } from '../questStatus/quest-status.selectors';
import { getAvailableCargoBays } from '../../../app/core/ship/cargo/available-cargo-bays';
import { selectSettings } from '../settings/settings.selectors';
import { WeaponType } from '../../../app/data/types/ship/weapon/weapon-type';
import { flatten, fromNullable, map, toNullable } from 'fp-ts/Option';
import { getRandomSkillToIncrease } from '../../../app/core/crew-member/skill/get-random-skill-to-increase';
import { PlayerTotalCargoBays } from '../../../app/core/ship/cargo/total-cargo-bays';
import { FilledCargoBays } from '../../../app/core/ship/cargo/filled-cargo-bays';
import { SkillType } from '../../../app/data/types/crew-member/skill';
import { getSkillLevel } from '../../../app/core/crew-member/skill/skill-level';
import { RootState } from '../../index';

export const selectPlayerShip = pipe(selectGame, prop('ship'));

export const selectPlayerShipType = pipe(selectPlayerShip, prop('type'));

export const selectShipFuelTanks = pipe(selectPlayerShip, getFuelTanks);

export const selectCurrentFuel = pipe(selectPlayerShip, getCurrentFuelLevel);

export const selectPlayerCargo = pipe(selectPlayerShip, prop('cargo'));

export const selectPlayerEmptyCargoBaysAmount = converge(getEmptyCargoBays, [
  selectQuestStatusState,
  selectPlayerShip,
]);

export const selectPlayerTotalCargoBays = converge(PlayerTotalCargoBays, [
  selectQuestStatusState,
  selectPlayerShip,
]);

export const selectPlayerAvailableCargoBaysAmount: (
  state: RootState,
) => number = converge(getAvailableCargoBays, [
  selectSettings,
  selectQuestStatusState,
  selectPlayerShip,
]);

export const selectPlayerFilledCargoBays = pipe(
  selectPlayerCargo,
  FilledCargoBays,
);

export const selectPlayerCrew = pipe(selectPlayerShip, prop('crew'));

export const selectRepairPoints = converge(getRepairPoints, [
  selectDifficulty,
  selectPlayerShip,
]);

export const selectPlayerEquipment = pipe(selectPlayerShip, prop('equipment'));

export const selectPlayerWeapon = pipe(selectPlayerEquipment, prop(WEAPONS));

export const selectPlayerWeaponPower = pipe(
  selectPlayerShip,
  getTotalWeaponsPower,
);

export const selectPlayerHasWeaponPower = pipe(
  selectPlayerWeaponPower,
  (power) => power > 0,
);

export const selectHullStrength = pipe(selectPlayerShip, prop('hull'));

export const selectHullBoost = pipe(selectPlayerShip, (ship: IShip) =>
  ship.extra.includes(UPGRADED_HULL) ? UPGRADED_HULL_POINTS : 0,
);

export const selectTotalHullStrength = converge(add, [
  selectHullStrength,
  selectHullBoost,
]);

export const selectPlayer = pipe(selectPlayerShip, prop('commander'));

export const selectPlayerName = pipe(
  selectPlayer,
  fromNullable,
  map(prop('name')),
  toNullable,
);

export const selectRandomSkillToIncrease = pipe(
  selectPlayer,
  fromNullable,
  map(getRandomSkillToIncrease),
  flatten,
  toNullable,
);

export const selectIsMemberOnBoard = (name: string) =>
  pipe(selectPlayerShip, isOnBoard(name));

export const selectPlayerHasIllegalCargo = pipe(selectPlayerCargo, hasIllegals);

export const selectIsAnyIllegalsOnBoard: (state: RootState) => boolean =
  converge(or, [selectIsMemberOnBoard('Wild'), selectPlayerHasIllegalCargo]);

export const selectShipPrice = (
  shipType: ShipType,
): ((state: RootState) => number) =>
  converge<number, [(state: RootState) => number, (state: RootState) => IShip]>(
    BaseShipPrice(shipType),
    [selectDifficulty, selectPlayerShip],
  );

export const selectCurrentShipPrice = pipe(
  selectPlayerShip,
  currentShipPrice(false),
);

export const selectIsEnoughSpaceForCrew = (shipType: ShipType) =>
  pipe(selectPlayerCrew, length, gte(shipConfigs[shipType].crewCabines));

export const selectPlayerHasEmptyCrewCabines = pipe(
  selectPlayerShip,
  hasEmptyCrewCabines,
);

export const selectHasFreeSlotsToInstall = (
  equipmentItemType: EquipmentItemType,
) =>
  pipe(selectPlayerShip, (playerShip) =>
    pipe(getEquipmentType, hasFreeSlots(playerShip))(equipmentItemType),
  );

export const selectBaseEquipmentPrice = (
  equipmentItemType: EquipmentItemType,
): ((state: RootState) => number) =>
  converge(baseEquipmentPrice(equipmentItemType), [
    selectDifficulty,
    selectPlayerShip,
  ]);

export const selectFuelCost = (amount: number) =>
  pipe(selectPlayerShip, getFuelCost(amount));

export const selectPlayerShipHasWeaponsStrongEnough = (
  weaponType: WeaponType,
) => pipe(selectPlayerShip, hasWeaponsStrongEnough(weaponType));

export const selectSkillLevel = (skillType: SkillType) =>
  converge(getSkillLevel(skillType), [selectPlayerShip, selectDifficulty]);
