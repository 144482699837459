import { System } from '../../data/types/system/system';
import { getSeedRandom } from '../../utils';
import { getSystemUniqueId } from './system-unique-id';
import { getPoliticConfig } from '../../config/politic/get-politic-config';
import memoize from 'fast-memoize';

export const getSystemTechLevel = memoize((system: System) => {
  return getSeedRandom(getSystemUniqueId(system))(
    getPoliticConfig(system).minTechLevel,
    getPoliticConfig(system).maxTechLevel,
  );
});
