import { selectPoliceRecordScore } from '../../scores/scores.selectors';
import { DUBIOUSSCORE } from '../../../../app/data/constants/police-record-score';
import { addNotification } from '../../notifications/notifications.actions';
import { getSpecialEventConfig } from '../../../../app/config/special-event/get-special-event-conifg';
import { TRANSPORT_WILD } from '../../../../app/data/types/system/special-event/special-event-type';
import { invokeSpecialEvent } from '../special-events.actions';
import { converge } from 'ramda';
import {
  selectPlayerHasEmptyCrewCabines,
  selectPlayerShipHasWeaponsStrongEnough,
} from '../../ship/ship.selectors';
import { BEAM_LASER_WEAPON } from '../../../../app/data/types/ship/weapon/weapon-type';
import { selectIsReactOnBoard } from '../../questStatus/quest-status.selectors';

export const selectTransportWildAction = converge(
  (
    policeRecordScore: number,
    hasEmptyCrewCabines: boolean,
    hasStrongWeapons: boolean,
    isReactorOnBoard: boolean,
  ) => {
    const { title } = getSpecialEventConfig(TRANSPORT_WILD);
    if (policeRecordScore >= DUBIOUSSCORE) {
      return addNotification({
        title,
        message: 'Your police record is too high',
      });
    }
    if (!hasEmptyCrewCabines) {
      return addNotification({
        title,
        message:
          'Your ship does not have empty crew cabines to take Wild on board',
      });
    }
    if (!hasStrongWeapons) {
      return addNotification({
        title,
        message: 'Wild will not go with you because you have too weak weapons',
      });
    }
    if (isReactorOnBoard) {
      return addNotification({
        title,
        message: 'Wild is afraid of reactor that you are carrying on your ship',
      });
    }
    return invokeSpecialEvent(TRANSPORT_WILD);
  },
  [
    selectPoliceRecordScore,
    selectPlayerHasEmptyCrewCabines,
    selectPlayerShipHasWeaponsStrongEnough(BEAM_LASER_WEAPON),
    selectIsReactOnBoard,
  ],
);
