import { pipe } from 'rxjs';
import {
  ACAMARSYSTEM,
  BARATASSYSTEM,
  MELINASYSTEM,
  REGULASSYSTEM,
  ZALKONSYSTEM,
  JAPORISYSTEM,
  UTOPIASYSTEM,
  DEVIDIASYSTEM,
  KRAVATSYSTEM,
  GEMULONSYSTEM,
  DALEDSYSTEM,
  NIXSYSTEM,
} from '../../data/constants/solar-systems';
import {
  setScarabQuest,
  setAlienArtifactQuest,
  setRegularQuests,
  setQuest,
} from './set-quest';
import { System } from '../../data/types/system/system';
import {
  MONSTER_KILLED,
  FLY_BARATAS,
  FLY_MELINA,
  FLY_REGULAS,
  DRAGONFLY_DESTROYED,
  MEDICINE_DELIVERY,
  MOON_BOUGHT,
  JAREK_GETS_OUT,
  WILD_GETS_OUT,
  REACTOR_DELIVERED,
  GET_REACTOR,
  GEMULON_RESCUED,
  ALIEN_INVASION,
  EXPERIMENT_STOPPED,
  EXPERIMENT,
  LOTTERY_WINNER,
} from '../../data/types/system/special-event/special-event-type';
import { NORMAL } from '../../data/constants/difficulty';
import { setSpecialEvent } from '../solar-system/set-special-event';

export const placeLotteryEvent = (homeIndex: number, difficulty: number) => (
  systems: System[],
): System[] => {
  return systems.map((system) => ({
    ...system,
    special:
      system.nameIndex === homeIndex && !system.special && difficulty < NORMAL
        ? LOTTERY_WINNER
        : system.special,
  }));
};

export const initSpecialEvents = (inputSystems: System[]): System[] => {
  const withQuests = inputSystems
    .map(setSpecialEvent(ACAMARSYSTEM, MONSTER_KILLED))
    .map(setSpecialEvent(BARATASSYSTEM, FLY_BARATAS))
    .map(setSpecialEvent(MELINASYSTEM, FLY_MELINA))
    .map(setSpecialEvent(REGULASSYSTEM, FLY_REGULAS))
    .map(setSpecialEvent(ZALKONSYSTEM, DRAGONFLY_DESTROYED))
    .map(setSpecialEvent(JAPORISYSTEM, MEDICINE_DELIVERY))
    .map(setSpecialEvent(UTOPIASYSTEM, MOON_BOUGHT))
    .map(setSpecialEvent(DEVIDIASYSTEM, JAREK_GETS_OUT))
    .map(setSpecialEvent(KRAVATSYSTEM, WILD_GETS_OUT));

  return pipe(
    setScarabQuest,
    setQuest(NIXSYSTEM, REACTOR_DELIVERED, GET_REACTOR),
    setAlienArtifactQuest,
    setQuest(GEMULONSYSTEM, GEMULON_RESCUED, ALIEN_INVASION),
    setQuest(DALEDSYSTEM, EXPERIMENT_STOPPED, EXPERIMENT),
    setRegularQuests,
  )(withQuests);
};
