import {
  WATER,
  FURS,
  FOOD,
  ORE,
  GAMES,
  FIREARMS,
  MEDICINE,
  MACHINES,
  NARCOTICS,
  ROBOTS,
} from '../../data/types/trade-item/trade-item-type';
import { TradeItemConfigs } from '../../data/types/trade-item/trade-item-config';
import {
  DROUGHT,
  COLD,
  CROPFAILURE,
  WAR,
  BOREDOM,
  PLAGUE,
  LACKOFWORKERS,
} from '../../data/types/system/system-status';
import {
  LOTS_OF_WATER,
  DESERT,
  RICH_FAUNA,
  LIFELESS,
  RICH_SOIL,
  POOR_SOIL,
  MINERAL_RICH,
  MINERAL_POOR,
  ARTISTIC,
  WARLIKE,
  LOTS_OF_HERBS,
  WEIRD_MUSHROOMS,
} from '../../data/types/system/special-resource';

export const tradeItemConfigs: TradeItemConfigs = {
  [WATER]: {
    techProduction: 0,
    techUsage: 0,
    techTopProduction: 2,
    priceLowTech: 30,
    priceInc: +3,
    variance: 4,
    doublePriceStatus: DROUGHT,
    cheapResource: [LOTS_OF_WATER],
    expensiveResource: [DESERT],
    minTradePrice: 30,
    maxTradePrice: 50,
    roundOff: 1,
  },
  [FURS]: {
    techProduction: 0,
    techUsage: 0,
    techTopProduction: 0,
    priceLowTech: 250,
    priceInc: +10,
    variance: 10,
    doublePriceStatus: COLD,
    cheapResource: [RICH_FAUNA],
    expensiveResource: [LIFELESS],
    minTradePrice: 230,
    maxTradePrice: 280,
    roundOff: 5,
  },
  [FOOD]: {
    techProduction: 1,
    techUsage: 0,
    techTopProduction: 1,
    priceLowTech: 100,
    priceInc: +5,
    variance: 5,
    doublePriceStatus: CROPFAILURE,
    cheapResource: [RICH_SOIL],
    expensiveResource: [POOR_SOIL],
    minTradePrice: 90,
    maxTradePrice: 160,
    roundOff: 5,
  },
  [ORE]: {
    techProduction: 2,
    techUsage: 2,
    techTopProduction: 3,
    priceLowTech: 350,
    priceInc: +20,
    variance: 10,
    doublePriceStatus: WAR,
    cheapResource: [MINERAL_RICH],
    expensiveResource: [MINERAL_POOR],
    minTradePrice: 350,
    maxTradePrice: 420,
    roundOff: 10,
  },
  [GAMES]: {
    techProduction: 3,
    techUsage: 1,
    techTopProduction: 6,
    priceLowTech: 250,
    priceInc: -10,
    variance: 5,
    doublePriceStatus: BOREDOM,
    cheapResource: [ARTISTIC],
    expensiveResource: [],
    minTradePrice: 160,
    maxTradePrice: 270,
    roundOff: 5,
  },
  [FIREARMS]: {
    techProduction: 3,
    techUsage: 1,
    techTopProduction: 5,
    priceLowTech: 1250,
    priceInc: -75,
    variance: 100,
    doublePriceStatus: WAR,
    cheapResource: [WARLIKE],
    expensiveResource: [],
    minTradePrice: 600,
    maxTradePrice: 1100,
    roundOff: 25,
  },
  [MEDICINE]: {
    techProduction: 4,
    techUsage: 1,
    techTopProduction: 6,
    priceLowTech: 650,
    priceInc: -20,
    variance: 10,
    doublePriceStatus: PLAGUE,
    cheapResource: [LOTS_OF_HERBS],
    expensiveResource: [],
    minTradePrice: 400,
    maxTradePrice: 700,
    roundOff: 25,
  },
  [MACHINES]: {
    techProduction: 4,
    techUsage: 3,
    techTopProduction: 5,
    priceLowTech: 900,
    priceInc: -30,
    variance: 5,
    doublePriceStatus: LACKOFWORKERS,
    cheapResource: [],
    expensiveResource: [],
    minTradePrice: 600,
    maxTradePrice: 800,
    roundOff: 25,
  },
  [NARCOTICS]: {
    techProduction: 5,
    techUsage: 0,
    techTopProduction: 5,
    priceLowTech: 3500,
    priceInc: -125,
    variance: 150,
    doublePriceStatus: BOREDOM,
    cheapResource: [WEIRD_MUSHROOMS],
    expensiveResource: [],
    minTradePrice: 2000,
    maxTradePrice: 3000,
    roundOff: 50,
  },
  [ROBOTS]: {
    techProduction: 6,
    techUsage: 4,
    techTopProduction: 7,
    priceLowTech: 5000,
    priceInc: -150,
    variance: 100,
    doublePriceStatus: LACKOFWORKERS,
    cheapResource: [],
    expensiveResource: [],
    minTradePrice: 3500,
    maxTradePrice: 5000,
    roundOff: 100,
  },
};
