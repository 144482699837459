import { System } from '../../data/types/system/system';
import { getPoliticConfig } from '../../config/politic/get-politic-config';
import {
  PSYCHOPATHSCORE,
  VILLAINSCORE,
} from '../../data/constants/police-record-score';

export const StrengthPolice = (policeRecordScore: number) => (
  system: System,
): number => {
  const { strengthPolice } = getPoliticConfig(system);
  if (policeRecordScore < PSYCHOPATHSCORE) {
    return 3 * strengthPolice;
  }
  if (policeRecordScore < VILLAINSCORE) {
    return 2 * strengthPolice;
  }
  return strengthPolice;
};
