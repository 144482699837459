import { pipe } from 'rxjs';
import { selectTravelsState } from './travels-state.selector';
import { lastId } from '../../../entities';

export const selectLastTravel = pipe(
  selectTravelsState,
  (entities) => entities[lastId(entities)],
);

export const selectLastTravelId = pipe(selectTravelsState, lastId);
