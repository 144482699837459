import React, { useState } from 'react';
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { SystemModalProps } from '../../../../props/SystemModalProps';
import { getSystemName } from '../../../../../app/ui/solar-system.labels';
import { InfoTab } from './InfoTab';
import ProfitTab from './ProfitTab';
import StatusTab from './StatusTab';
import { WarpTab } from './WarpTab';

const SystemInfoCard: React.FC<SystemModalProps> = ({
  system,
  isOpen,
  onClose,
}) => {
  const [tab, setTab] = useState('general');

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{getSystemName(system)}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={tab}
            onIonChange={(e) => setTab(e.detail.value as string)}>
            <IonSegmentButton value="general">General</IonSegmentButton>
            <IonSegmentButton value="profit">Profit</IonSegmentButton>
            <IonSegmentButton value="status">Status</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {tab === 'general' && (
          <IonGrid>
            <IonRow>
              <IonCol>
                <InfoTab system={system} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <WarpTab system={system} onWarp={onClose} />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
        {tab === 'profit' && <ProfitTab system={system} />}
        {tab === 'status' && <StatusTab system={system} />}
      </IonContent>
    </IonModal>
  );
};

export default SystemInfoCard;
