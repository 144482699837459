import { Action, createAction } from 'typesafe-actions';
import * as actionTypes from './notifications.action-types';

export const resetNotifications = createAction(
  actionTypes.RESET_NOTIFICATIONS,
)();
export const addNotification = createAction(actionTypes.ADD_NOTIFICATION)<{
  title: string;
  message: string;
  action?: Action;
}>();
export const disposeNotification = createAction(
  actionTypes.DISPOSE_NOTIFICATION,
)<string>();
