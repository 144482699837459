import { converge } from 'ramda';
import { pipe } from 'rxjs';
import { System } from '../../app/data/types/system/system';
import { selectGalaxy } from '../game/systems/systems.selectors';
import {
  selectPlayerAvailableCargoBaysAmount,
  selectPlayerShip,
} from '../game/ship/ship.selectors';
import { playerWorth } from '../../app/core/ship/ship.prices';
import {
  selectCurrentDay,
  selectDebt,
  selectDifficulty,
  selectIsMoonBought,
  selectStatus,
} from '../game/status/status.selectors';
import { selectCurrentBalance } from '../game/balance/balance.selectors';
import { ToSpend } from '../../app/utils';
import { selectSettings } from '../game/settings/settings.selectors';
import { getMaxFuelAmountToPurchase } from '../../app/core/ship/fuel/max-amount-to-purchase';
import { TradeItemType } from '../../app/data/types/trade-item/trade-item-type';
import {
  getReachableSystems,
  getSystemsToTravel,
} from '../../app/navigator/reachable-systems';
import {
  applyTraderSkillToPrice,
  getPriceToBuy,
  getPriceToSell,
} from '../../app/core/trade-items/trade-items.prices';
import { selectPoliceRecordScore } from '../game/scores/scores.selectors';
import { selectCurrentSystem } from '../game/systems/selectors/current-system.selector';
import { getMaxLoan } from '../../app/core/bank/max-loan';
import { getPossibleTradeItemQuantityToPurchase } from '../../app/core/trade-items/trade-items.quantity';
import { byNameIndex } from '../../app/core/solar-system/find-by-name-index';
import { getRepairedHull } from '../../app/core/ship/hull/repaired-hull';
import { RootState } from '../index';

export const selectPlayerWorth: (state: RootState) => number = converge(
  playerWorth,
  [selectCurrentBalance, selectPlayerShip, selectIsMoonBought, selectDebt],
);

export const selectAvailableCredits: (state: RootState) => number = converge(
  ToSpend,
  [selectSettings, selectStatus, selectCurrentBalance, selectPlayerShip],
);

export const selectIsEnoughMoney = (amount: number) =>
  pipe(selectAvailableCredits, (available: number) => available >= amount);

export const selectFuelAmountToPurchase = converge(getMaxFuelAmountToPurchase, [
  selectPlayerShip,
  selectAvailableCredits,
]);

export const selectHullToRepair = converge(getRepairedHull, [
  selectPlayerShip,
  selectAvailableCredits,
]);

export const selectTradeItemQuantity = (
  tradeItemType: TradeItemType,
): ((state: RootState) => number) =>
  pipe(
    selectCurrentSystem,
    (system: System): number => system.tradeItems[tradeItemType],
  );

export const selectPossibleTradeItemQuantityToPurchase = (
  tradeItemType: TradeItemType,
) =>
  converge(getPossibleTradeItemQuantityToPurchase, [
    selectTradeItemQuantity(tradeItemType),
    selectFinalBuyPrice(tradeItemType),
    selectAvailableCredits,
    selectPlayerAvailableCargoBaysAmount,
  ]);

export const selectAvailableSystemsToTravel: (state: RootState) => System[] =
  converge(getSystemsToTravel, [
    selectCurrentSystem,
    selectPlayerShip,
    selectGalaxy,
  ]);

export const selectIsSystemAvailableToTravel = (systemIndex: number) =>
  pipe(selectAvailableSystemsToTravel, (systems) =>
    systems.some(byNameIndex(systemIndex)),
  );

export const selectReachableSystems: (state: RootState) => System[] = converge(
  getReachableSystems,
  [selectPlayerShip, selectCurrentSystem, selectGalaxy],
);

export const selectPriceToBuy = (
  tradeItemType: TradeItemType,
): ((state: RootState) => number) =>
  converge(getPriceToBuy(tradeItemType), [
    selectDifficulty,
    selectCurrentDay,
    selectCurrentSystem,
  ]);

export const selectFinalBuyPrice = (
  tradeItemType: TradeItemType,
): ((state: RootState) => number) =>
  converge(applyTraderSkillToPrice, [
    selectPriceToBuy(tradeItemType),
    selectDifficulty,
    selectPlayerShip,
  ]);

export const selectPriceToSell = (
  tradeItemType: TradeItemType,
): ((state: RootState) => number) =>
  converge(getPriceToSell(tradeItemType), [
    selectDifficulty,
    selectCurrentDay,
    selectPoliceRecordScore,
    selectCurrentSystem,
  ]);

export const selectFinalSellPrice = (
  tradeItemType: TradeItemType,
): ((state: RootState) => number) =>
  converge(applyTraderSkillToPrice, [
    selectPriceToSell(tradeItemType),
    selectDifficulty,
    selectPlayerShip,
  ]);

export const selectMaxLoan = converge(getMaxLoan, [
  selectPoliceRecordScore,
  selectPlayerWorth,
]);
