import { converge, prop } from 'ramda';
import { pipe } from 'rxjs';
import { System } from '../../../app/data/types/system/system';
import { mapEntitiesToArray } from '../../entities';
import { TradeItemType } from '../../../app/data/types/trade-item/trade-item-type';
import { selectCurrentDay, selectDifficulty } from '../status/status.selectors';
import {
  getPricesHistory,
  getStatusHistory,
  StatusStat,
  TradeItemStat,
} from '../../../app/core/trade-items/trade-item-history';
import { selectGame } from '../../selectors';
import { RootState } from '../../index';

export const selectSystemsState = pipe(selectGame, prop('systems'));

export const selectGalaxy = pipe(selectSystemsState, mapEntitiesToArray);

export const selectSystemByIndex = (index: number) =>
  pipe(selectSystemsState, (entities) => entities[index]);

export const selectPricesHistory = (
  system: System,
  tradeItemType: TradeItemType,
): ((state: RootState) => TradeItemStat[]) =>
  converge(getPricesHistory(system, tradeItemType), [
    selectDifficulty,
    selectCurrentDay,
  ]);

export const selectStatusHistory = (
  system: System,
): ((state: RootState) => StatusStat[]) =>
  converge(getStatusHistory(system), [selectCurrentDay]);
