import {
  ALIEN_ARTIFACT,
  CARGO_FOR_SALE,
  DRAGONFLY_EVENT,
  ERASE_RECORD,
  EXPERIMENT,
  GET_REACTOR,
  JAPORI_DISEASE,
  MOON_FOR_SALE,
  SCARAB_STOLEN,
  SKILL_INCREASE,
  SpecialEventType,
  TRANSPORT_AMBASSADOR_JAREK,
  TRANSPORT_WILD,
  TRIBBLE,
} from '../../../../app/data/types/system/special-event/special-event-type';
import { selectMoonForSaleAction } from './moon-for-sale';
import { selectJarekAction } from './jarek';
import { selectJaporiDiseaseAction } from './japori-desease';
import { selectCargoForSaleAction } from './cargo-for-sale';
import { selectAlienArtifactAction } from './alien-artifact';
import { selectGetReactorAction } from './reactor';
import { selectEraseRecordAction } from './erase-record';
import { selectDragonflyAction } from './dragonfly';
import { selectExperimentAction } from './experiment';
import { selectScarabStolenAction } from './scarab-stolen';
import { selectTransportWildAction } from './transport-wild';
import { selectSkillIncreaseAction } from './skill-increase';
import { selectTribbleAction } from './tribble';
import { invokeSpecialEvent } from '../special-events.actions';
import { RootState } from '../../../index';
import { AnyAction } from 'redux';

export const selectSpecialEventValidationResult = (
  specialEvent: SpecialEventType,
): ((state: RootState) => AnyAction) => {
  switch (specialEvent) {
    case MOON_FOR_SALE:
      return selectMoonForSaleAction;
    case TRANSPORT_AMBASSADOR_JAREK:
      return selectJarekAction;
    case JAPORI_DISEASE:
      return selectJaporiDiseaseAction;
    case CARGO_FOR_SALE:
      return selectCargoForSaleAction;
    case ALIEN_ARTIFACT:
      return selectAlienArtifactAction;
    case GET_REACTOR:
      return selectGetReactorAction;
    case ERASE_RECORD:
      return selectEraseRecordAction;
    case DRAGONFLY_EVENT:
      return selectDragonflyAction;
    case EXPERIMENT:
      return selectExperimentAction;
    case SCARAB_STOLEN:
      return selectScarabStolenAction;
    case TRANSPORT_WILD:
      return selectTransportWildAction;
    case SKILL_INCREASE:
      return selectSkillIncreaseAction;
    case TRIBBLE:
      return selectTribbleAction;
    default:
      return () => invokeSpecialEvent(specialEvent);
  }
};
