import { QuestStatus } from '../../../data/types/game/quest-status';
import { IShip } from '../../../data/types/ship/ship';
import { getShipConfig } from '../../../config/ship/get-ship-config';
import { hasEquipment } from '../../equipment/equipment';
import {
  EXTRA_BAYS,
  gadgets,
} from '../../../data/types/ship/gadget/gadget-type';
import { shipConfigs } from '../../../config/ship/ship.configs';
import { GADGETS } from '../../../config/equipment.configs';
import { ShipType } from '../../../data/types/ship/ship-type';
import { IGadget } from '../../../data/types/ship/gadget/gadget';

export const ShipTotalCargoBays = (shipType: ShipType, gadgets: IGadget[]) => {
  return gadgets
    .map((gadget) => (gadget.type === EXTRA_BAYS ? 5 : 0))
    .reduce((bays, bonus) => {
      return bays + bonus;
    }, shipConfigs[shipType].cargoBays);
};

export const PlayerTotalCargoBays = (
  questStatus: QuestStatus,
  ship: IShip,
): number => {
  let result = ShipTotalCargoBays(ship.type, ship.equipment[GADGETS]);

  if (questStatus.japoriDisease === 1) {
    result -= 10;
  }

  if (questStatus.reactor > 0 && questStatus.reactor < 21)
    result -= 5 + 10 - (questStatus.reactor - 1) / 2;

  return result;
};
