// common
export const LOTTERY_WINNER = 'Lottery Winner';
export const SKILL_INCREASE = 'Skill Increase';
export const ERASE_RECORD = 'Erase Record';
export const CARGO_FOR_SALE = 'Cargo For Sale';

// dragonfly
export const DRAGONFLY_EVENT = 'Dragonfly';
export const FLY_BARATAS = 'Weird Ship';
export const FLY_MELINA = 'Lightning Ship';
export const FLY_REGULAS = 'Strange Ship';
export const DRAGONFLY_DESTROYED = 'Dragonfly Destroyed';

// japori
export const JAPORI_DISEASE = 'Japori Disease';
export const MEDICINE_DELIVERY = 'Medicine Delivery';

// moon
export const MOON_BOUGHT = 'Retirement';
export const MOON_FOR_SALE = 'Moon For Sale';

// tribble
export const TRIBBLE = 'Merchant Prince';
export const BUY_TRIBBLE = 'Tribble Buyer';

// monster
export const SPACE_MONSTER_EVENT = 'Space Monster';
export const MONSTER_KILLED = 'Monster Killed';

// artifact
export const ALIEN_ARTIFACT = 'Alien Artifact';
export const ARTIFACT_DELIVERY = 'Artifact Delivery';

// jarek
export const TRANSPORT_AMBASSADOR_JAREK = 'Transport Ambassador Jarek';
export const JAREK_GETS_OUT = 'Jarek Gets Out';

// invasion
export const ALIEN_INVASION = 'Alien Invasion';
export const GEMULON_INVADED = 'Gemulon Invaded';
export const GEMULON_RESCUED = 'Gemulon Rescued';

// reactor
export const GET_REACTOR = "Morgan's Reactor";
export const REACTOR_DELIVERED = 'Reactor Delivered';

// some benefits
export const GET_SPECIAL_LASER = "Install Morgan's Laser";
export const INSTALL_LIGHTNING_SHIELD = 'Lightning Shield';
export const GET_FUEL_COMPACTOR = 'Fuel Compactor';
export const GET_HULL_UPGRADED = 'Upgrade Hull';

// scarab
export const SCARAB_STOLEN = 'Scarab Stolen';
export const SCARAB_DESTROYED = 'Scarab Destroyed';

// experiment
export const EXPERIMENT = 'Dangerous Experiment';
export const EXPERIMENT_STOPPED = 'Disaster Averted';
export const EXPERIMENT_NOT_STOPPED = 'Experiment Failed';

// wild
export const TRANSPORT_WILD = 'Transport Jonathan Wild';
export const WILD_GETS_OUT = 'Wild Gets Out';

export const specialEvents = [
  DRAGONFLY_DESTROYED,
  FLY_BARATAS,
  FLY_MELINA,
  FLY_REGULAS,
  MONSTER_KILLED,
  MEDICINE_DELIVERY,
  MOON_BOUGHT,
  MOON_FOR_SALE,
  SKILL_INCREASE,
  TRIBBLE,
  ERASE_RECORD,
  BUY_TRIBBLE,
  SPACE_MONSTER_EVENT,
  DRAGONFLY_EVENT,
  CARGO_FOR_SALE,
  INSTALL_LIGHTNING_SHIELD,
  JAPORI_DISEASE,
  LOTTERY_WINNER,
  ARTIFACT_DELIVERY,
  ALIEN_ARTIFACT,
  TRANSPORT_AMBASSADOR_JAREK,
  ALIEN_INVASION,
  GEMULON_INVADED,
  GET_FUEL_COMPACTOR,
  EXPERIMENT,
  TRANSPORT_WILD,
  GET_REACTOR,
  GET_SPECIAL_LASER,
  SCARAB_STOLEN,
  GET_HULL_UPGRADED,
  SCARAB_DESTROYED,
  REACTOR_DELIVERED,
  JAREK_GETS_OUT,
  GEMULON_RESCUED,
  EXPERIMENT_STOPPED,
  EXPERIMENT_NOT_STOPPED,
  WILD_GETS_OUT,
] as const;

export type SpecialEventType = typeof specialEvents[number];
