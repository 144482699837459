import { IMPOSSIBLE, NORMAL } from '../../../data/constants/difficulty';
import {
  QuestStatus,
  WILD_QUEST,
  REACTOR_QUEST,
} from '../../../data/types/game/quest-status';
import { System } from '../../../data/types/system/system';
import { getPoliticConfig } from '../../../config/politic/get-politic-config';

export const isPoliceCorrupted = (warpSystem: System): boolean => {
  return getPoliticConfig(warpSystem).bribeLevel > 0;
};

export const getPoliceBribeOffer = (
  playerCurrentWorth: number,
  questStatus: QuestStatus,
  difficulty: number,
  warpSystem: System,
): number => {
  let Bribe =
    playerCurrentWorth /
    ((10 + 5 * (IMPOSSIBLE - difficulty)) *
      getPoliticConfig(warpSystem).bribeLevel);
  if (Bribe % 100 !== 0) {
    Bribe += 100 - (Bribe % 100);
  }

  if (
    questStatus[WILD_QUEST] === 1 ||
    (questStatus[REACTOR_QUEST] > 0 && questStatus[REACTOR_QUEST] < 21)
  ) {
    if (difficulty <= NORMAL) Bribe *= 2;
    else Bribe *= 3;
  }
  return Math.max(100, Math.min(Bribe, 10000));
};
