export const INIT_STATUS = '[Status] init';
export const INCREASE_DAYS = '[Status] increase days';
export const LOAN_MONEY = '[Status] loan money';
export const PAY_BACK = '[Status] pay back';

export const FABRIC_RIPPED = '[Status] fabric ripped';
export const DECREASE_FABRIC_RIP_PROBABILITY =
  '[Status] decrease fabric rip probability';

export const BUY_MOON = '[Status] Moon bought';
export const BUY_NEWSPAPER = '[Status] buy today newspaper';
export const REGISTER_NEWSPAPER_PURCHASE = '[Status] register buy newspaper';
