import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import { getGroupedCargo } from '../../../app/core/ship/cargo/grouped-cargo';
import NumberFormat from 'react-number-format';
import React from 'react';
import { PurchasedItem } from '../../../app/data/types/trade-item/purchased-item';

export const CargoTable: React.FC<{ cargo: PurchasedItem[] }> = ({ cargo }) => {
  if (!cargo.length) {
    return <p className="ion-text-center">Your cargo is empty</p>;
  }

  return (
    <>
      {getGroupedCargo(cargo).map((item, index) => (
        <IonCard key={index}>
          <IonCardHeader>
            <IonCardSubtitle>
              {item.quantity}&times;
              <NumberFormat
                value={item.itemPrice}
                displayType={'text'}
                thousandSeparator={','}
                suffix={' cr.'}
              />
            </IonCardSubtitle>
            <IonCardTitle>{item.type}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText>
              <h3>
                <NumberFormat
                  value={item.quantity * item.itemPrice}
                  displayType={'text'}
                  thousandSeparator={','}
                  suffix={' cr.'}
                />
              </h3>
            </IonText>
          </IonCardContent>
        </IonCard>
      ))}
    </>
  );
};
